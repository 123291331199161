import React from "react";
import PropTypes from 'prop-types';
import Page from "../@common/pages/Page";
import PageMissing from "../@common/pages/PageMissing";
import HomePage from '../pages/HomePage';
import ModelParametersPage from '../pages/ModelParametersPage';
import ImpactORsPage from '../pages/ImpactORsPage';
import InterventionsPage from '../pages/Interventions/InterventionsPage';
import SenseCheckPage from "../pages/SenseCheckPage";
import CostsPage from '../pages/CostsPage';
import ResultsPage from '../pages/ResultsPage';
import FPBCStepperContainer from "../components/FPBCStepperContainer";
import InfoDialog from "../dialogs/InfoDialog";

class AppContent extends React.Component {

    state = {
        showInfoDialog : false,
        info : ''
    };

    onInfoBtnClick = (info) => {
        this.setState ({
            showInfoDialog : true,
            info : info
        })
    };

    onInfoCloseBtnClick = () => {
        this.setState ({
            showInfoDialog : false
        })
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        const {pageID, onPageChange, onStateChange, parametersSet, selectedCountry, programIdx, 
            subnatAreaName, shareOfPop, startYearIdx, modelParametersData, interQuartileRangeIdx, 
            impactORsData, costsData, intervReachIdx, interventionsData, resultsData, finalYearScaleIdx,
            cumulativeScaleIdx, finalYearRadioGroupIdx, cumulativeRadioGroupIdx, finalYearSupport,
            cumulativeSupport, restoreBtnDisabledModelParams1, restoreBtnDisabledModelParams2,
            restoreBtnDisabledModelParams3, restoreBtnDisabledModelParams4, restoreBtnDisabledImpactORs,
            restoreBtnDisabledCosts, itemIndex, onComboBoxChange} = this.props;

        const styles = {
            appContent: {
                paddingLeft: 25,
                paddingRight: 25, 
                paddingTop: 40,
                paddingBottom: 30,
                minHeight: 400
            },
            innerStyle : {
                paddingRight: 0
            }
        };

        switch (pageID) {            
            case "HomePage":
                return (
                    <div style={styles.appContent}>
                        <HomePage
                            onPageChange={onPageChange}
                            onStateChange={onStateChange}
                        />
                    </div>
                );

            case "ModelParametersPage" :
                return (
                    <div style={styles.appContent}>
                        <FPBCStepperContainer
                            activeStep={0}
                            onPageChange={onPageChange}
                            editMode={parametersSet}
                        />
                        <ModelParametersPage
                            onPageChange={onPageChange}
                            onStateChange={onStateChange}
                            onInfoBtnClick={this.onInfoBtnClick}
                            selectedCountry={selectedCountry}
                            programIdx={programIdx}
                            subnatAreaName={subnatAreaName}
                            shareOfPop={shareOfPop}
                            startYearIdx={startYearIdx}
                            modelParametersData={modelParametersData}
                            restoreBtnDisabledModelParams1={restoreBtnDisabledModelParams1}
                            restoreBtnDisabledModelParams2={restoreBtnDisabledModelParams2}
                            restoreBtnDisabledModelParams3={restoreBtnDisabledModelParams3}
                            restoreBtnDisabledModelParams4={restoreBtnDisabledModelParams4}
                        />
                        {
                            (this.state.showInfoDialog) ?
                                <InfoDialog
                                    onClose={this.onInfoCloseBtnClick}
                                    info={this.state.info}
                                />
                                :
                                null
                        }
                    </div>
                );

            case "ImpactORsPage" :
                return (
                    <div style={styles.appContent}>
                        <FPBCStepperContainer
                            activeStep={1}
                            onPageChange={onPageChange}
                            editMode={true}
                        />
                        <ImpactORsPage
                            onPageChange={onPageChange}
                            onStateChange={onStateChange}
                            onInfoBtnClick={this.onInfoBtnClick}
                            interQuartileRangeIdx={interQuartileRangeIdx}
                            impactORsData={impactORsData}
                            restoreBtnDisabledImpactORs={restoreBtnDisabledImpactORs}
                        />
                        {
                            (this.state.showInfoDialog) ?
                                <InfoDialog
                                    onClose={this.onInfoCloseBtnClick}
                                    info={this.state.info}
                                />
                                :
                                null
                        }
                    </div>
                );
            
            case "CostsPage" :
                return (
                    <div style={styles.appContent}>
                        <FPBCStepperContainer
                            activeStep={2}
                            onPageChange={onPageChange}
                            editMode={true}
                        />
                        <CostsPage
                            onPageChange={onPageChange}
                            onStateChange={onStateChange}
                            selectedCountry={selectedCountry}
                            costsData={costsData}
                            modelParametersData={modelParametersData}
                            restoreBtnDisabledCosts={restoreBtnDisabledCosts}
                        />
                    </div>
                );

            case "InterventionsPage" :
                return (
                    <div style={styles.appContent}>
                        <FPBCStepperContainer
                            activeStep={3}
                            onPageChange={onPageChange}
                            editMode={true}
                        />
                        <InterventionsPage
                            onPageChange={onPageChange}
                            onStateChange={onStateChange}
                            onInfoBtnClick={this.onInfoBtnClick}
                            intervReachIdx={intervReachIdx}
                            startYearIdx={startYearIdx}
                            interventionsData={interventionsData}
                            finalYearScaleIdx={finalYearScaleIdx}
                            cumulativeScaleIdx={cumulativeScaleIdx}
                            finalYearRadioGroupIdx={finalYearRadioGroupIdx}
                            cumulativeRadioGroupIdx={cumulativeRadioGroupIdx}
                            modelParametersData={modelParametersData}
                            shareOfPop={shareOfPop}
                            selectedCountry={selectedCountry}
                            costsData={costsData}
                            finalYearSupport={finalYearSupport}
                            cumulativeSupport={cumulativeSupport}
                        />
                        {
                            (this.state.showInfoDialog) ?
                                <InfoDialog
                                    onClose={this.onInfoCloseBtnClick}
                                    info={this.state.info}
                                />
                                :
                                null
                        }
                    </div>
                );

            case "SenseCheckPage" :
                return (
                    <div style={styles.appContent}>
                        <FPBCStepperContainer
                            activeStep={4}
                            onPageChange={onPageChange}
                            editMode={true}
                        />
                        <SenseCheckPage
                            onPageChange={onPageChange}
                            intervReachIdx={intervReachIdx}
                            startYearIdx={startYearIdx}
                            shareOfPop={shareOfPop}
                            selectedCountry={selectedCountry}
                            interventionsData={interventionsData}
                            modelParametersData={modelParametersData}
                            costsData={costsData}
                        />
                    </div>
                );

            case "ResultsPage" :
                return (
                    <div style={styles.appContent}>
                        <FPBCStepperContainer
                            activeStep={5}
                            onPageChange={onPageChange}
                            editMode={true}
                        />
                        <ResultsPage
                            onPageChange={onPageChange}
                            onStateChange={onStateChange}
                            resultsData={resultsData}
                            intervReachIdx={intervReachIdx}
                            startYearIdx={startYearIdx}
                            shareOfPop={shareOfPop}
                            selectedCountry={selectedCountry}
                            interventionsData={interventionsData}
                            modelParametersData={modelParametersData}
                            costsData={costsData}
                            interQuartileRangeIdx={interQuartileRangeIdx}
                            impactORsData={impactORsData}
                            itemIndex={itemIndex}
                            onComboBoxChange={onComboBoxChange}
                            onInfoBtnClick={this.onInfoBtnClick}
                        />
                        {
                            (this.state.showInfoDialog) ?
                                <InfoDialog
                                    onClose={this.onInfoCloseBtnClick}
                                    info={this.state.info}
                                />
                                :
                                null
                        }
                    </div>
                );

            default:
                return (
                    <Page pageID={pageID} style={styles.appContent}>
                        <PageMissing pageID={pageID} onPageChange={onPageChange} />
                    </Page>
                );
        }
    }
}

export default React.memo(AppContent);

AppContent.propTypes = {
    pageID                        : PropTypes.string.isRequired,
    onPageChange                  : PropTypes.func.isRequired,
    onStateChange                 : PropTypes.func.isRequired,
    parametersSet                 : PropTypes.bool.isRequired,
    selectedCountry               : PropTypes.string.isRequired,
    programIdx                    : PropTypes.number.isRequired,
    subnatAreaName                : PropTypes.string.isRequired,
    shareOfPop                    : PropTypes.number.isRequired,
    startYearIdx                  : PropTypes.number.isRequired,
    modelParametersData           : PropTypes.object.isRequired,
    impactORsData                 : PropTypes.object.isRequired,
    interQuartileRangeIdx         : PropTypes.number.isRequired,
    costsData                     : PropTypes.object.isRequired,
    intervReachIdx                : PropTypes.number.isRequired,
    interventionsData             : PropTypes.object.isRequired,
    finalYearScaleIdx             : PropTypes.number.isRequired,
    cumulativeScaleIdx            : PropTypes.number.isRequired,
    finalYearRadioGroupIdx        : PropTypes.number.isRequired,
    cumulativeRadioGroupIdx       : PropTypes.number.isRequired,
    finalYearSupport              : PropTypes.object.isRequired,
    cumulativeSupport             : PropTypes.object.isRequired,
    restoreBtnDisabledModelParams1: PropTypes.bool.isRequired,
    restoreBtnDisabledModelParams2: PropTypes.bool.isRequired,
    restoreBtnDisabledModelParams3: PropTypes.bool.isRequired,
    restoreBtnDisabledModelParams4: PropTypes.bool.isRequired,
    restoreBtnDisabledImpactORs   : PropTypes.bool.isRequired,
    restoreBtnDisabledCosts       : PropTypes.bool.isRequired,
};