import React from 'react';
import PropTypes from "prop-types";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Theme from "../app/Theme"

const styles = {
    root : {
        color: Theme.palette.secondary.main
    },
    active: {
        color: Theme.palette.primary.main 
    },
};

class FPBCStepper extends React.Component {

    static propTypes = {
        steps          : PropTypes.array,
        activeStep     : PropTypes.number,
        completedSteps : PropTypes.array,
        inactiveCursor : PropTypes.bool,
        onStepBtnClick : PropTypes.func,
        useAltColors   : PropTypes.bool,
    };

    static defaultProps = {
        steps          : ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"],
        activeStep     : 0,
        completedSteps : [0, 1, 2, 3],
        inactiveCursor : false,
        onStepBtnClick : () => console.log("onStepBtnClick"),
        useAltColors   : PropTypes.bool,
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onStepBtnClick = (index) => {
        this.props.onStepBtnClick(index);
    };

    render() {
        const styles = {
            activeStep: {
                fontWeight: 'bold',
                fontSize: '14px',
            },
            nonActiveStep : {
                fontWeight: 'normal',
                fontSize: '14px'
            },
            stepper : {
                marginTop: -5,
                marginBottom: 20,
                marginLeft: -5,
                paddingBottom: '7px',
                paddingTop: '7px',
                minWidth: '300px',
                background: Theme.stepper.backgroundColor, 
                borderRadius: '5px',
                border: '1px solid #CCC',
            }
        };

        return (
            <Stepper
                style      = {styles.stepper}
                nonLinear  = {true}
                activeStep = {this.props.activeStep}
            >
                {this.props.steps.map((label, index) => {

                    let completed = this.props.completedSteps.indexOf(index) >= 0;
                    let isActive = this.props.activeStep === index;

                    return (
                        <Step
                            key={label}
                            completed={completed}
                        >
                            <StepButton
                                key={index}
                                onClick={() => this.onStepBtnClick(index)}
                                style={{cursor: this.props.inactiveCursor ? "default" : "pointer"}}
                            >
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            root : this.props.useAltColors ? `${this.props.classes.root} stepLabel` : 'stepLabel',
                                            active: `${this.props.classes.active} stepLabel`
                                        }
                                    }}
                                >
                                    <Typography
                                        style={(isActive) ? styles.activeStep : styles.nonActiveStep}
                                    >
                                        {label}
                                    </Typography>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        );
    }
}

export default withStyles(styles)(FPBCStepper);