import React from "react";
import PropTypes from "prop-types";
import Theme from "../app/Theme";
import GBDialog from "../@common/components/GBDialog";

class InfoDialog extends React.PureComponent {

    static propTypes = {
        onClose : PropTypes.func,
        info    : PropTypes.string,
    };

    static defaultProps = {
        onClose : () => console.log("onClose"),
        info    : '',
    };

    getInfo = () => {
        let result = [];

        if (Array.isArray(this.props.info)){
            for (let i = 0; i < this.props.info.length; i++) {
                result.push(this.props.info[i]);
            }
        }
        else{
            result.push(this.props.info);
        }

        return result;
    };

    getContent = () => {
        let result = [];
        result.push(
            <div>
                {this.getInfo()}
            </div>
        );

        return result;
    };


    render() {
        return (
            <GBDialog
                title={''}
                Theme={Theme}
                onClose={this.props.onClose}
                content={this.getContent()}
            />
        );
    }
}

export default InfoDialog;