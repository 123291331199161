import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import * as Constants from "../../utilities/Constants"

const Create = (countryName, modelParametersData) => {

    let editorName = 'Intermediate outcomes of FP use';
    let packTable = Setup();
    packTable = SetSSData(packTable, countryName, modelParametersData);
    packTable = RecalcGrid(packTable, countryName, modelParametersData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = () => {
    let numRows = 7;
    let numCols = 3;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][1] = 'Percent';
    pt.tableData.value[0][2] = 'Default source';

    pt.tableData.value[1][0] = 'FP approval (women)';
    pt.tableData.value[2][0] = 'Perception of benefits';
    pt.tableData.value[3][0] = 'Self-efficacy';
    pt.tableData.value[4][0] = "Men's attitude and support";
    pt.tableData.value[5][0] = 'Communication with partner';
    pt.tableData.value[6][0] = 'Communication with others';
    
    for (let row = 1; row < numRows; row++) {
        pt.FontStyles[row][0]["intArray"] = [Constants.Bold];
        pt.RDec[row][1] = 1;
        pt.Alignments[row][1] = Constants.Right;
        pt.MinAllowedVal[row][1] = 0;
        pt.MaxAllowedVal[row][1] = 100;
    }

    pt.GBColWidths[1] = 70;
    pt.GBColWidths[2] = 550;

    return pt;
};

const getValues = (source_Data) => {
    return {
        'FP_approval' : source_Data["Value FP Approval"],
        'PercBenefit' : source_Data["Value Perception of Benefits"],
        'selfEfficac' : source_Data["Value Self-efficacy"],
        'mensAttitud' : source_Data["Value Men's attitude and support"],
        'commPartner' : source_Data["Value Communication with partner"],
        'comm_Others' : source_Data["Value Communication with others"],
    }
};

const getSources = (source_Data) => {
    return {
        'FP_approval' : source_Data["Source FP Approval"],
        'PercBenefit' : source_Data["Source Perception of Benefits"],
        'selfEfficac' : source_Data["Source Self-efficacy"],
        'mensAttitud' : source_Data["Source Men's attitude and support"],
        'commPartner' : source_Data["Source Communication with partner"],
        'comm_Others' : source_Data["Source Communication with others"],
    }
};

const prepValuesForSetSSData = (value) => {
    return (typeof value !== 'number') ? value : (value * 100);
};

const getValuesForSetSSData = (source_Data) => {
    return {
        'FP_approval' : prepValuesForSetSSData(source_Data["Value FP Approval"]),
        'PercBenefit' : prepValuesForSetSSData(source_Data["Value Perception of Benefits"]),
        'selfEfficac' : prepValuesForSetSSData(source_Data["Value Self-efficacy"]),
        'mensAttitud' : prepValuesForSetSSData(source_Data["Value Men's attitude and support"]),
        'commPartner' : prepValuesForSetSSData(source_Data["Value Communication with partner"]),
        'comm_Others' : prepValuesForSetSSData(source_Data["Value Communication with others"]),
    }
};

const SetSSData = (pt, countryName, modelParametersData) => {
    for (let c = 0; c < modelParametersData["internalUser"].length; c++) {
        if (modelParametersData["internalUser"][c]["COUNTRY"] === countryName) {

            let values = getValuesForSetSSData(modelParametersData["internalUser"][c]);
            let sources = getSources(modelParametersData["internalUser"][c]);

            pt.tableData.value[1][1] = values['FP_approval'];
            pt.tableData.value[2][1] = values['PercBenefit'];
            pt.tableData.value[3][1] = values['selfEfficac'];
            pt.tableData.value[4][1] = values['mensAttitud'];
            pt.tableData.value[5][1] = values['commPartner'];
            pt.tableData.value[6][1] = values['comm_Others'];

            pt.tableData.value[1][2] = sources["FP_approval"];
            pt.tableData.value[2][2] = sources["PercBenefit"];
            pt.tableData.value[3][2] = sources["selfEfficac"];
            pt.tableData.value[4][2] = sources["mensAttitud"];
            pt.tableData.value[5][2] = sources["commPartner"];
            pt.tableData.value[6][2] = sources["comm_Others"];
        }
    }

    return pt;
};

const getValueForGetSSData = (value) => {
    return (typeof value !== 'number') ? value : (value / 100);
};

const GetSSData = (pt, countryName, modelParametersData) => {
    let country;
    for (let i = 0; i < modelParametersData["internalUser"].length; i++) {
        if (modelParametersData["internalUser"][i]["COUNTRY"] === countryName) {
            country = i;
        }
    }

    let FP_approval = getValueForGetSSData(pt.tableData.value[1][1]);
    let PercBenefit = getValueForGetSSData(pt.tableData.value[2][1]);
    let selfEfficac = getValueForGetSSData(pt.tableData.value[3][1]);
    let mensAttitud = getValueForGetSSData(pt.tableData.value[4][1]);
    let commPartner = getValueForGetSSData(pt.tableData.value[5][1]);
    let comm_Others = getValueForGetSSData(pt.tableData.value[6][1]);

    modelParametersData["internalUser"][country]["Value FP Approval"] = FP_approval;
    modelParametersData["internalUser"][country]["Value Perception of Benefits"] = PercBenefit;
    modelParametersData["internalUser"][country]["Value Self-efficacy"] = selfEfficac;
    modelParametersData["internalUser"][country]["Value Men's attitude and support"] = mensAttitud;
    modelParametersData["internalUser"][country]["Value Communication with partner"] = commPartner;
    modelParametersData["internalUser"][country]["Value Communication with others"] = comm_Others;

    modelParametersData["internalUser"][country]["Source FP Approval"] = pt.tableData.value[1][2];
    modelParametersData["internalUser"][country]["Source Perception of Benefits"] = pt.tableData.value[2][2];
    modelParametersData["internalUser"][country]["Source Self-efficacy"] = pt.tableData.value[3][2];
    modelParametersData["internalUser"][country]["Source Men's attitude and support"] = pt.tableData.value[4][2];
    modelParametersData["internalUser"][country]["Source Communication with partner"] = pt.tableData.value[5][2];
    modelParametersData["internalUser"][country]["Source Communication with others"] = pt.tableData.value[6][2];

    return (modelParametersData)
};

const preValuesForComparison = (value) => {
    return (typeof value !== 'number') ? value : (value * 100).toFixed(1);
};

const getValuesForComparison = (source_Data) => {
    return {
        'FP_approval' : preValuesForComparison(source_Data["Value FP Approval"]),
        'PercBenefit' : preValuesForComparison(source_Data["Value Perception of Benefits"]),
        'selfEfficac' : preValuesForComparison(source_Data["Value Self-efficacy"]),
        'mensAttitud' : preValuesForComparison(source_Data["Value Men's attitude and support"]),
        'commPartner' : preValuesForComparison(source_Data["Value Communication with partner"]),
        'comm_Others' : preValuesForComparison(source_Data["Value Communication with others"]),
    }
};

const RecalcGrid = (pt, countryName, modelParametersData) => {
    for (let c = 0; c < modelParametersData["internalUser"].length; c++) {
        if (modelParametersData["internalUser"][c]["COUNTRY"] === countryName) {
            let user = getValuesForComparison(modelParametersData["internalUser"][c]);
            let def_ = getValuesForComparison(modelParametersData["internalDefault"][c]);

            let userSources = getSources(modelParametersData["internalUser"][c]);
            let defSources_ = getSources(modelParametersData["internalDefault"][c]);

            let defaultColor = 0;
            let changedColor = 6930250;

            pt.FontColors[1][1] = user["FP_approval"] === def_["FP_approval"] ? defaultColor : changedColor;
            pt.FontColors[2][1] = user["PercBenefit"] === def_["PercBenefit"] ? defaultColor : changedColor;
            pt.FontColors[3][1] = user["selfEfficac"] === def_["selfEfficac"] ? defaultColor : changedColor;
            pt.FontColors[4][1] = user["mensAttitud"] === def_["mensAttitud"] ? defaultColor : changedColor;
            pt.FontColors[5][1] = user["commPartner"] === def_["commPartner"] ? defaultColor : changedColor;
            pt.FontColors[6][1] = user["comm_Others"] === def_["comm_Others"] ? defaultColor : changedColor;

            pt.FontColors[1][2] = userSources["FP_approval"] === defSources_["FP_approval"] ? defaultColor : changedColor;
            pt.FontColors[2][2] = userSources["PercBenefit"] === defSources_["PercBenefit"] ? defaultColor : changedColor;
            pt.FontColors[3][2] = userSources["selfEfficac"] === defSources_["selfEfficac"] ? defaultColor : changedColor;
            pt.FontColors[4][2] = userSources["mensAttitud"] === defSources_["mensAttitud"] ? defaultColor : changedColor;
            pt.FontColors[5][2] = userSources["commPartner"] === defSources_["commPartner"] ? defaultColor : changedColor;
            pt.FontColors[6][2] = userSources["comm_Others"] === defSources_["comm_Others"] ? defaultColor : changedColor;
        }
    }

    return (pt);
};

const RestoreDefaultValues = (countryName, modelParametersData) => {
    for (let c = 0; c < modelParametersData["internalUser"].length; c++) {
        if (modelParametersData["internalUser"][c]["COUNTRY"] === countryName) {

            let defaultValues = getValues(modelParametersData["internalDefault"][c]);
            let defaultSources = getSources(modelParametersData["internalDefault"][c]);

            modelParametersData["internalUser"][c]['Value FP Approval'] = defaultValues['FP_approval'];
            modelParametersData["internalUser"][c]['Value Perception of Benefits'] = defaultValues['PercBenefit'];
            modelParametersData["internalUser"][c]['Value Self-efficacy'] = defaultValues['selfEfficac'];
            modelParametersData["internalUser"][c]["Value Men's attitude and support"] = defaultValues['mensAttitud'];
            modelParametersData["internalUser"][c]['Value Communication with partner'] = defaultValues['commPartner'];
            modelParametersData["internalUser"][c]['Value Communication with others'] = defaultValues['comm_Others'];

            modelParametersData["internalUser"][c]["Source FP Approval"] = defaultSources["FP_approval"];
            modelParametersData["internalUser"][c]["Source Perception of Benefits"] = defaultSources["PercBenefit"];
            modelParametersData["internalUser"][c]["Source Self-efficacy"] = defaultSources["selfEfficac"];
            modelParametersData["internalUser"][c]["Source Men's attitude and support"] = defaultSources["mensAttitud"];
            modelParametersData["internalUser"][c]["Source Communication with partner"] = defaultSources["commPartner"];
            modelParametersData["internalUser"][c]["Source Communication with others"] = defaultSources["comm_Others"];
        }
    }

    return modelParametersData;
};

export const IntermediateDeterminantsEditor = {
    Create               : Create,
    Setup                : Setup,
    SetSSData            : SetSSData,
    GetSSData            : GetSSData,
    RecalcGrid           : RecalcGrid,
    RestoreDefaultValues : RestoreDefaultValues,
};