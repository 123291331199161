import React from 'react';
import PropTypes from "prop-types";
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Constants from "../../utilities/Constants";
import TComboBox from "../../@common/components/TComboBox";
import TEdit from "../../@common/components/TEdit";
import { getMassMediaInterventionName, NumberFormatCustom } from "../../utilities/GBUtil"

class MassMediaInterventions extends React.Component {

    static propTypes = {
        onStateChange                         : PropTypes.func,
        onMassMediaInterventionComboBoxChange : PropTypes.func,
        onMassMediaOptionComboBoxChange       : PropTypes.func,
        onMassMediaEditChange                 : PropTypes.func,
        interventionSupport                   : PropTypes.object,   
        massMediaInterventionIdx              : PropTypes.number,   
        massMediaDirectEntryValue             : PropTypes.number,
        massMediaReachValue                   : PropTypes.number,
        massMediaReachMediaSpotsValue         : PropTypes.number,
        numWRAValue                           : PropTypes.number,
        intervReachIdx                        : PropTypes.number,
        finalYearStr                          : PropTypes.string,
    };

    static defaultProps = {
        onStateChange                         : () => console.log('onStateChange'),
        onMassMediaInterventionComboBoxChange : () => console.log('onMassMediaInterventionComboBoxChange'),
        onMassMediaOptionComboBoxChange       : () => console.log('onMassMediaOptionComboBoxChange'),
        onMassMediaEditChange                 : () => console.log('onMassMediaEditChange'),
        interventionSupport                   : {},
        massMediaInterventionIdx              : 0,
        massMediaDirectEntryValue             : 0,
        massMediaReachValue                   : 0,
        numWRAValue                           : 0,
        massMediaReachMediaSpotsValue         : 0,
        intervReachIdx                        : 0,
        finalYearStr                          : '',
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
   
    onMassMediaInterventionComboBoxChange = (idx) => {
        this.props.onMassMediaInterventionComboBoxChange(idx);
    };

    onMassMediaOptionComboBoxChange = (idx) => {
        this.props.onMassMediaOptionComboBoxChange(idx);
    };

    onMassMediaEditChange = (value, name) => {
        value = Number(value);
        if (name === 'womenReached') {
            if (value > 100) {
                value = 100;
            }
            if (value < 0) {
                value = 0;
            }
        }
        this.props.onMassMediaEditChange(value, name);
    };

    //==================================================================================================================
    //
    //                                                String Functions
    //
    //==================================================================================================================
    getDirectEntryStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Value for ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Value over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getWomenReachedStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = '% of women reached in ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = '% of women reached over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getReachStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Reach in ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Reach over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getMediaSpotsStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Media spots aired in ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Media spots aired over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    //==================================================================================================================
    //
    //                                                Formula function
    //
    //==================================================================================================================  
    getFormula = () => {
        const styles = {
            operandStyle : {
                fontWeight: 'bold',
                fontSize: 18,
                display: 'inline-block',
                marginLeft: 25,
                marginRight: 25,
                marginTop: 35
            },
            TEditStyle : {
                width: 205, 
                marginTop: 20,
                marginRight: 0,
                marginBottom: 0,
                color: 'secondary'
            }
        }
        let directEntry = 0;
        let byReach = 1;
        let byNumMediaSpots = 2;
        let option = this.props.interventionSupport.massMedia.optionIdxArray[this.props.massMediaInterventionIdx];

        if (option === directEntry) {
            return (
                <div>
                    <TEdit
                        key={'directEntry'}
                        name='directEntry'
                        caption={this.getDirectEntryStr()}
                        value={this.props.massMediaDirectEntryValue}
                        onChange={this.onMassMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter value'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === byReach) {
            return (
                <div>
                    <TEdit
                        key={'womenReached'}
                        name='womenReached'
                        caption={this.getWomenReachedStr()}
                        value={this.props.interventionSupport.massMedia[getMassMediaInterventionName(this.props.massMediaInterventionIdx)]['womenReached']}
                        onChange={this.onMassMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter percent'}
                        focused={true}
                        type={'number'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'numberWRA'}
                        name='numberWRA'
                        caption={'Number of WRA in ' + this.props.finalYearStr}
                        value={this.props.numWRAValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'From model parameters'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reach'}
                        name='reach'
                        caption={this.getReachStr()}
                        value={this.props.massMediaReachValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === byNumMediaSpots) {
            return (
                <div>
                    <TEdit
                        key={'mediaSpots'}
                        name='mediaSpots'
                        caption={this.getMediaSpotsStr()}
                        value={this.props.interventionSupport.massMedia[getMassMediaInterventionName(this.props.massMediaInterventionIdx)]['mediaSpots']}
                        onChange={this.onMassMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'listenersPerAd'}
                        name='listenersPerAd'
                        caption={'Women listeners per ad'}
                        value={this.props.interventionSupport.massMedia[getMassMediaInterventionName(this.props.massMediaInterventionIdx)]['listenersPerAd']}
                        onChange={this.onMassMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachMediaSpots'}
                        name='reachMediaSpots'
                        caption={this.getReachStr()}
                        value={this.props.massMediaReachMediaSpotsValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            margins : {
                marginBottom : 20,
            },
        }

        return (
            <div>
                <TComboBox
                    caption={"Interventions"}
                    items={[
                        "Radio",
                        "TV",
                        "Newspaper/magazine",
                        "Mixed mass media"
                    ]}
                    itemIndex={this.props.massMediaInterventionIdx}
                    onChange={this.onMassMediaInterventionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />               
                <TComboBox
                    caption={"Calculation options"}
                    items={[
                        "Direct entry",
                        "By reach",
                        "By number of media spots",
                    ]}
                    itemIndex={this.props.interventionSupport.massMedia.optionIdxArray[this.props.massMediaInterventionIdx]}
                    onChange={this.onMassMediaOptionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />
                {this.getFormula()}
            </div>
        );
    }
}

export default MassMediaInterventions;