import React from 'react';
import PropTypes from "prop-types";
import * as Constants from "../../utilities/Constants";
import { GBEditor } from '../../editors/GBEditors';
import TComboBox from "../../@common/components/TComboBox";
import TRadioGroup from "../../@common/components/TRadioGroup";
import TButton from '../../@common/components/TButton';
import infoImage from "../../img/infoImage3.png";

class CumulativeInterventionReach extends React.Component {

    static propTypes = {
        onPackTableChanged              : PropTypes.func,
        onCumulativeScaleComboBoxChange : PropTypes.func,
        onCumulativeRadioGroupChange    : PropTypes.func,
        onInterventionSupportBtnClick   : PropTypes.func,
        onInfoBtnClick                  : PropTypes.func,
        packTable                       : PropTypes.object,     
        cumulativeScaleIdx              : PropTypes.number,
        cumulativeRadioGroupIdx         : PropTypes.number,   
    };

    static defaultProps = {
        onPackTableChanged              : () => console.log('onPackTableChanged'),
        onCumulativeScaleComboBoxChange : () => console.log('onCumulativeScaleComboBoxChange'),
        onCumulativeRadioGroupChange    : () => console.log('onCumulativeRadioGroupChange'),
        onInterventionSupportBtnClick   : () => console.log('onInterventionSupportBtnClick'),
        onInfoBtnClick                  : () => console.log('onInfoBtnClick'),
        packTable                       : {},
        cumulativeScaleIdx              : 0,
        cumulativeRadioGroupIdx         : 0
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
    onPackTableChange = (packTable) => {
        this.props.onPackTableChanged(packTable);
    };

    onCumulativeScaleComboBoxChange = (idx) => {
        this.props.onCumulativeScaleComboBoxChange(idx);
    };

    onCumulativeRadioGroupChange = (idx) => {
        this.props.onCumulativeRadioGroupChange(idx);
    };

    onInfoBtnClick = (info) => {
        this.props.onInfoBtnClick(info);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            margins : {
                marginBottom : 30,
            },
            radioGroup : { 
                width: 400,
                boxShadow: 'none',
                marginBottom: 10,
                paddingLeft: 0
            },
            radioGroupTitleStyle : {
                color: '#0000008A',
                fontWeight: 'normal',
                fontSize : 12,
                padding : 0,
                fontFamily: 'Lato, san-serif, serif',
                marginTop: -20,
                marginLeft: 0,
                marginBottom: -7
            },
            helpSetValues : {
                marginBottom: 0,
                marginLeft: 0,
                marginTop: 15,
                paddingLeft: 0,
                paddingRight: 0
            },
        };

        return (
            <div>
                <div style={{display: "inline-block"}}>
                    <TComboBox
                        caption={"Scale-up shape"}
                        items={["S-curve", "Linear", "Constant"]}
                        itemIndex={this.props.cumulativeScaleIdx}
                        onChange={this.onCumulativeScaleComboBoxChange}
                        width={"300px"}
                        style={styles.margins}
                    />
                </div>

                <div style={{display: "inline-block"}}>
                    <img
                        src     = {infoImage}
                        alt     = ""
                        style   = {{cursor: 'pointer'}}
                        onClick = {() => this.onInfoBtnClick([
                            <p>When opting to specify the final year 5 reach or cumulative reach, you should select
                                one of three scale-up shapes:</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Constant –</b>&nbsp;assumes the same
                                number reached each year</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Linear –</b>&nbsp;assumes a steady
                                increase in persons reached each year</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>S-shaped -</b>
                                &nbsp;assumes a gradual start in the early year with a steep increase in years from
                                years 2 to 4 and a more gradual increase from years 4 to 5.</p>
                        ])}
                    />
                </div>

                <br />

                <div>
                    <TRadioGroup
                        caption={"Edit values"}
                        items={["Keep generated values","Edit generated values"]}
                        style={styles.radioGroup}
                        titleStyle={styles.radioGroupTitleStyle}
                        itemIndex={this.props.cumulativeRadioGroupIdx}
                        handleChange={this.onCumulativeRadioGroupChange}
                        row={true}
                    />

                    <TButton
                        caption={"Help me set values"}
                        color={'secondary'}
                        onClick={this.props.onInterventionSupportBtnClick}
                        style={styles.helpSetValues}
                        disabled={this.props.finalYearRadioGroupIdx === Constants.CustomValues} 
                        toolTipTitle={"Support for calculating intervention reach and coverage"}
                    />
                </div>

                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold'}} >
                    Cumulative reach over 5 years
                </text>

                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold', fontStyle:'italic', color:'darkred'}}>
                    &nbsp;*(required)&nbsp;
                </text>

                <br/>

                <div style={{width: 1100, display: "inline-block"}}>
                    <GBEditor
                        key={'cumIntervReach'} //{Date.now()+2}
                        id={Constants.Editor_IntervReachCumulative}
                        minHeight={445}
                        maxHeight={445}
                        packTable={this.props.packTable}
                        onPackTableChanged={this.onPackTableChange}
                        showTitle={false}
                    />
                </div>
            </div>
        );
    }
}

export default CumulativeInterventionReach;