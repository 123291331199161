import * as React from "react";
import PropTypes from "prop-types";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

class TComboBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption      : PropTypes.string,
        items        : PropTypes.array,
        itemIndex    : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        selectedText : PropTypes.string,
        width        : PropTypes.string,
        display      : PropTypes.string,
        onChange     : PropTypes.func,
        style        : PropTypes.object,
        captionStyle : PropTypes.object,
        outerStyle   : PropTypes.object,
        disabled     : PropTypes.bool,
        disabledArr  : PropTypes.array,
        native       : PropTypes.bool,
    };

    static defaultProps = {
        caption      : 'Caption',
        items        : ['Item 1', 'Item 2', 'Item 3'],
        itemIndex    : 0,
        selectedText : '',
        width        : '250px',
        display      : 'inline-block',
        onChange     : (value) => console.log('TComboBox onChange [' + value + ']'),
        style        : {},
        captionStyle : {},
        outerStyle   : {},
        disabled     : false,
        disabledArr  : [],
        native       : false,
    };

    state = {
        itemIndex : 0
    }; 

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onChange = (event) => {
        this.props.onChange(event.target.value, this.props.items[event.target.value]);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderItems = () => {
        const items = [];

        for (let i = 0; i <= this.props.items.length-1; i++) {
            if (typeof this.props.items[i] === 'object') {
                Object.entries(this.props.items[i]).forEach(([key, value]) => {
                    items.push(
                        <MenuItem disabled={this.props.disabledArr[i]} value={i} key={i} style={{backgroundColor: value}}>{this.props.items[i][key]}</MenuItem>
                    );
                });
            }
            else {
                items.push(
                    <MenuItem disabled={this.props.disabledArr[i]} value={i} key={i}>{this.props.items[i]}</MenuItem>
                );
            }
        }

        if (items.length === 0) {
            items.push(<MenuItem value={1} key={1}>No items available</MenuItem>);
        }

        return items;
    };

    renderNativeItems = () => {
        const items = [];

        for (let i = 0; i <= this.props.items.length-1; i++) {
            if (typeof this.props.items[i] === 'object') {
                Object.entries(this.props.items[i]).forEach(([key, value]) => {
                    items.push(
                        <option disabled={this.props.disabledArr[i]} value={i} key={i} style={{backgroundColor: value}}>{this.props.items[i][key]}</option>
                    );
                });
            }
            else {
                items.push(
                    <option disabled={this.props.disabledArr[i]} value={i} key={i}>{this.props.items[i]}</option>
                );
            }
        }

        if (items.length === 0) {
            items.push(<option value={1} key={1}>No items available</option>);
        }

        return items;
    };

    getItemIndex = (selectedText) => {
        for (let i = 0; i <= this.props.items.length-1; i++) {
            if (this.props.selectedText === this.props.items[i]) {
                this.setState({
                    itemIndex : i
                });
                return;
            }
        }
    };

    render() {
        const selectFieldStyle = {
            marginRight: 25,
            paddingLeft: 0,
            width : this.props.width,
        };

        const value = this.props.itemIndex;

        if (this.props.native) {
            const items = this.renderNativeItems();
            return (
                <NativeSelect
                    value={value}
                    onChange={this.onChange}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-label-placeholder',
                    }}
                    style={{...selectFieldStyle, ...this.props.style}}
                    disabled={this.props.disabled}
                >
                    {items}
                </NativeSelect>
            );  
        }
        else {
            const items = this.renderItems();
            return (
                <FormControl style={{display: this.props.display, ...this.props.outerStyle}}>
                    <InputLabel style={{...this.props.captionStyle}} htmlFor="name-native-disabled">{this.props.caption}</InputLabel>
                    <Select
                        value={value}
                        onChange={this.onChange}
                        style={{...selectFieldStyle, ...this.props.style}}
                        disabled={this.props.disabled}
                    >
                        {items}
                    </Select>
                </FormControl>
            );
        }
    }
}

export default TComboBox;