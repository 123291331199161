import React, { Component } from 'react';
import USAIDLogo from "../img/USAIDLogo.svg";
import Theme from "./Theme";
import Divider from "@material-ui/core/Divider";

class Footer extends Component {

    onUSAIDLogoClick = () => {

        window.open("https://www.usaid.gov/");

    };

    render() {
        const logo =
            <img
                onClick = {this.onUSAIDLogoClick}
                style   = {{
                    cursor       : "pointer",
                    height       : 55,
                    marginLeft   : 25,
                    marginTop    : 15,
                    marginBottom : 15,
                }}
                src   = {USAIDLogo}
                alt   = ""
            />;

        const styles = {
            footerStyle : {
                backgroundColor : Theme.footer.color,
                height          : 200,
                justifyContent  : "left", // horizontally center stuff
                width           : "100%",
                borderTop       : "1px solid lightGray",
            },
            instructionsText : {
                fontSize: 14,
                color: Theme.palette.primary.main,
                marginBottom: 25,
                marginLeft: 25,
                marginRight: 25,
            },
            instructionsText2 : {
                fontSize: 14,
                fontStyle : 'italic',
                color: Theme.palette.primary.main,
                marginBottom: 25,
                marginLeft: 25,
                marginRight: 25,
            },
        };

        return (
            <div style = {styles.footerStyle}>
                {logo}
                <Divider />
                <p style={styles.instructionsText}>Breakthrough RESEARCH is made possible by the generous support of the American people through the United States Agency for International Development (USAID) under the terms of cooperative agreement no. AID-OAA-A-17-00018. The contents of this website are the sole responsibility of the Breakthrough RESEARCH and do not necessarily reflect the views of USAID or the United States Government.</p>
                <p style={styles.instructionsText2}>Suggested citation: Breakthrough RESEARCH. 2022. “The Social and Behavior Change Business Case Model for Family Planning: An Interactive Tool,” Breakthrough RESEARCH Tool. Washington, D.C.: Population Council.</p>
            </div>
        );
    }

}

export default Footer;
