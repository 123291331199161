import React from 'react';
import PropTypes from "prop-types";
import TButton from "../@common/components/TButton";
import Theme from "../app/Theme";
import * as Constants from "../utilities/Constants";
import { SBCUnitCostsEditor } from '../editors/Costs/SBCUnitCostsEditor';
import { DirectServiceDeliveryCostsEditor } from '../editors/Costs/DirectServiceDeliveryCostsEditor';
import { GBEditor } from '../editors/GBEditors';
import { clone } from "../utilities/GBUtil"

class CostsPage extends React.Component {

    static propTypes = {
        onPageChange            : PropTypes.func,
        onStateChange           : PropTypes.func,
        selectedCountry         : PropTypes.string,
        costsData               : PropTypes.object,
        modelParametersData     : PropTypes.object,
        restoreBtnDisabledCosts : PropTypes.bool,
    };

    static defaultProps = {
        onPageChange            : () => console.log('onPageChange'),
        onStateChange           : () => console.log('onStateChange'),
        selectedCountry         : 'Afghanistan',
        costsData               : {},
        modelParametersData     : {},
        restoreBtnDisabledCosts : true,
    };

    state = {
        packTable1 : undefined,
        packTable2 : undefined
    }

        //==================================================================================================================
    //
    //                                                 Life Cycles
    //
    //==================================================================================================================

    componentDidMount() {
        this.setState({
            packTable1 : SBCUnitCostsEditor.Create(this.props.costsData),
            packTable2 : DirectServiceDeliveryCostsEditor.Create(this.props.selectedCountry, this.props.modelParametersData, this.props.costsData),
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const hasChanged = 
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState));
        
        return hasChanged;
    };

    onPackTable1Change = (packTable) => {
        let costsData = SBCUnitCostsEditor.GetSSData(packTable, clone(this.props.costsData));
        packTable = SBCUnitCostsEditor.Create(costsData);

        this.setState ({
            packTable1 : packTable
        });
        this.props.onStateChange ({
            costsData               : costsData,
            restoreBtnDisabledCosts : false,
        })
    };

    onPackTable2Change = (packTable) => {
        let costsData = DirectServiceDeliveryCostsEditor.GetSSData(packTable, this.props.selectedCountry, this.props.modelParametersData, clone(this.props.costsData));
        packTable = DirectServiceDeliveryCostsEditor.Create(this.props.selectedCountry, this.props.modelParametersData, costsData);

        this.setState ({
            packTable2 : packTable
        });
        this.props.onStateChange ({
            costsData               : costsData,
            restoreBtnDisabledCosts : false,
        })
    };

    onRestoreDefaultValuesClick = () => {
        let costsData = clone(this.props.costsData);

        costsData['SBCUnitCostsUser'] = costsData['SBCUnitCostsDefault'];
        costsData['FPCostAIUUser']    = costsData['FPCostAIUDefault'];

        this.setState({
            packTable1         : SBCUnitCostsEditor.Create(costsData),
            packTable2         : DirectServiceDeliveryCostsEditor.Create(this.props.selectedCountry, this.props.modelParametersData, costsData)
        });

        this.props.onStateChange ({
            costsData               : costsData,
            restoreBtnDisabledCosts : true
        })
    };

    onBackBtnClick = () => {
        this.props.onPageChange("ImpactORsPage");
    };

    onNextBtnClick = () => {
        this.props.onPageChange("InterventionsPage");
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            page : {
                marginLeft : 25
            },
            margins : {
                marginBottom : 30,
            },
            header : {
                fontSize: 24,
                fontWeight: 'bold',
                color: Theme.palette.primary.main,
                marginBottom: 20,
                display: 'inline'
            },
            header2 : {
                fontSize: 24,
                fontWeight: 'bold',
                fontStyle:'italic',
                color: Theme.palette.primary.main,
                marginBottom: 20,
                display: 'inline'
            },
            instructionsText : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginBottom: 25,
                marginRight: 25
            },
            restoreDefaultValues : {
                marginBottom: 30,
                marginLeft: 100,
                fontSize: 12,
                height: 25,
                paddingLeft: 0,
                paddingRight: 0
            },
            editorDiv : {
                display: 'inline-block',
                marginRight: 80
            },
            image : {
                display: 'inline',
            },
            backBtn : {
                marginRight: 20
            }
        };

        return (
            <div style={styles.page}>
                <text style={styles.header}>
                    Review unit costs
                </text>

                <text style={styles.header2}>
                    &nbsp;(no inputs required)
                </text>

                <TButton
                    caption={"Restore default values"}
                    color={'secondary'}
                    onClick={this.onRestoreDefaultValuesClick}
                    style={styles.restoreDefaultValues}
                    disabled={this.props.restoreBtnDisabledCosts}
                />
                <div style={styles.instructionsText}>{'In this step, you can review the median SBC unit costs based on ' +
                'a synthesis of the literature on SBC impact in low- and middle-income countries and adjusted for ' +
                'family planning. A unit cost is the cost per person reached by an SBC intervention. The costs have ' +
                'been adjusted to 2020 USD.'}</div>

                <div style={styles.instructionsText}>{'No inputs are required but the unit costs can be edited if unit ' +
                'cost data are available. If desired, The SBC Costing Tool can be used to estimate the unit costs based ' +
                'on intervention characteristics.'}</div>

                { 
                    (this.state.packTable1) ?
                        <GBEditor
                            key                 = {'costs1'} //{Date.now()}
                            id                  = {Constants.Editor_SBCUnitCosts}
                            minHeight           = {445}
                            maxHeight           = {445}
                            packTable           = {this.state.packTable1}
                            onPackTableChanged  = {this.onPackTable1Change}

                        /> 
                        : null
                }

                <p style={styles.instructionsText}>
                    The unit costs for service delivery for additional FP users in the table below are sub-regional estimates of the average annual direct cost per user based on &nbsp;
                    <a href="https://www.guttmacher.org/report/adding-it-up-investing-in-sexual-reproductive-health-2019-methodology " target="_blank" rel="noopener noreferrer">Adding It Up 2019</a> &nbsp;
                    Methodology Appendix Table MA8.5. These costs include the cost of commodities, supplies and personnel and have been adjusted to 2020 USD. We recommend you use these values, but if you have country- or program-specific service provision costs you can replace the values below.
                </p>
                
                {
                    (this.state.packTable2) ?
                        <GBEditor
                            key                 = {'costs2'} //{Date.now()+1}
                            id                  = {Constants.Editor_DirectServiceDeliveryCosts}
                            minHeight           = {445}
                            maxHeight           = {445}
                            packTable           = {this.state.packTable2}
                            onPackTableChanged  = {this.onPackTable2Change}
                        />
                        : null
                }
        
                <TButton
                    caption={"Back"}
                    color={'secondary'}
                    style={styles.backBtn}
                    onClick={this.onBackBtnClick}
                />
                <TButton
                    caption={"Next"}
                    color={'primary'}
                    onClick={this.onNextBtnClick}
                />
            </div>
        );
    }
}

export default CostsPage;