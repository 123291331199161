import * as Constants from "../../../utilities/Constants";
import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import { getAggregateCatName } from "../../../utilities/GBUtil"
import { getIntermediateDeterminantName } from "../../../utilities/GBUtil"
import { generatePackChart } from "../../../utilities/GBUtil"

const Create = (resultsData) => {
    let editorName = 'mCPR Increase Pathways';

    let packTable = Setup();
    packTable = SetSSData(packTable, resultsData);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true, [],
        [
            '#6DBD52',
            '#F07B2F',
            '#1BA9E0',
            '#043A5B',
        ]);
    return ([packTable, packChart]);
};

const Setup = () => {
    let pt = createSimpleDefaultPackTable(Constants.Final_Agg_Cat + 2, Constants.Direct_To_mCPR + 2, true);

    let c = 1;
    for (let i = 0; i <= Constants.Direct_To_mCPR; i++) {
        pt.tableData.value[0][c] = getIntermediateDeterminantName(i);
        if (i === Constants.Direct_To_mCPR){
            pt.tableData.value[0][c] = 'Other*';
        }
        c++;
    }

    let r = 1;
    for (let i = Constants.First_Agg_Cat; i <= Constants.Final_Agg_Cat; i++){
        pt.tableData.value[r][0] = getAggregateCatName(i);
        r++;
    }

    r = 1;
    for (let a = Constants.First_Agg_Cat; a <= Constants.Final_Agg_Cat; a++) {
        c = 1;
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            pt.RDec[r][c] = 2;
            c++;
        }
        r++;
    }

    return pt;
};

const SetSSData = (pt, resultsData) => {
    let r = 1;
    for (let a = Constants.First_Agg_Cat; a <= Constants.Final_Agg_Cat; a++){
        let c = 1;
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            pt.tableData.value[r][c] = resultsData["impactData"]["mCPR_Increase_Pathways"][i][a] * 100;
            c++;
        }
        r++;
    }

    return pt;
};

export const mCPR_Increase_Pathways = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};