import React from 'react';
import PropTypes from "prop-types";
import TButton from "../@common/components/TButton"
import Theme from "../app/Theme";
import * as Constants from "../utilities/Constants";
import { InterventionSenseCheckEditor } from '../editors/Interventions/InterventionSenseCheckEditor';
import { GBEditor } from '../editors/GBEditors';

class SenseCheckPage extends React.Component {

    static propTypes = {
        onPageChange            : PropTypes.func,
        intervReachIdx          : PropTypes.number,
        startYearIdx            : PropTypes.number,
        shareOfPop              : PropTypes.number,
        selectedCountry         : PropTypes.string,
        interventionsData       : PropTypes.object,
        modelParametersData     : PropTypes.object,
        costsData               : PropTypes.object,
    };

    static defaultProps = {
        onPageChange            : () => console.log('onPageChange'),
        intervReachIdx          : Constants.IntervReach_Manual,
        startYearIdx            : 0,
        shareOfPop              : 100,
        selectedCountry         : 'Afghanistan',
        interventionsData       : {},
        modelParametersData     : {},
        costsData               : {},
    };

    state = {
        packTable1 : undefined,
    };

    //==================================================================================================================
    //
    //                                                 Life Cycles
    //
    //==================================================================================================================

    componentDidMount() {
        this.setState({
          packTable1 : InterventionSenseCheckEditor.Create(this.props.intervReachIdx, this.props.startYearIdx, this.props.shareOfPop, 
            this.props.selectedCountry, this.props.interventionsData, this.props.modelParametersData, this.props.costsData),
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const hasChanged = 
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState));
        
        return hasChanged;
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================

    onPackTable1Change = () => {
        //blank on purpose
    };

    getSource = () => {
        let countryIdx;
        for (let i = 0; i < this.props.modelParametersData["externalUser"].length; i++) {
            if (this.props.modelParametersData["externalUser"][i]["COUNTRY"] === this.props.selectedCountry) {
                countryIdx = i;
            }
        }

        let source = "* Source: " + this.props.modelParametersData["externalUser"][countryIdx]["Source"];

        return (source)
    };

    getCostsNote = () => {
        let countryIdx;
        for (let i = 0; i < this.props.modelParametersData["externalUser"].length; i++) {
            if (this.props.modelParametersData["externalUser"][i]["COUNTRY"] === this.props.selectedCountry) {
                countryIdx = i;
            }
        }

        let costsNote = "Note: costs are in 2020 USD and are discounted at " + this.props.modelParametersData["externalUser"][countryIdx]["Cost Discount Rate"] + "%";

        return (costsNote)

    };

    onBackBtnClick = () => {
        this.props.onPageChange("InterventionsPage");
    };

    onNextBtnClick = () => {
        this.props.onPageChange("ResultsPage");
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            page : {
                marginLeft : 25
            },
            margins : {
                marginBottom : 30,
            },
            header : {
                fontSize: 24,
                fontWeight: 'bold',
                color: Theme.palette.primary.main,
                marginBottom: 20
            },
            instructionsText : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginBottom: 25,
                marginRight: 25
            },
            instructionsText2 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginRight: 25
            },
            instructionsText3 : {
                fontSize: 18,
                textIndent: 50,
                color: Theme.palette.primary.main,
                marginRight: 25
            },
            backBtn : {
                marginRight: 20
            },
            source : {
                fontSize: 12,
                marginTop: -20,
                marginBottom: 10, 
            }
        };

        return (
            <div style={styles.page}>
                <div style={styles.header}>{"Sense Check"}</div>
                <div style={styles.instructionsText2}>
                    {"This step is to help you sense-check the information you entered in Step 4. There are no inputs " +
                    "or edits for this step. Instead, you should review the table and ask the following questions:"}
                </div>
                <div style={styles.instructionsText3}>
                    {"- Is the proportion of women of reproductive age (WRA) reached feasible for the intervention?"}
                </div>
                <div style={styles.instructionsText3}>
                    {"- For Radio and TV interventions, are the proportions of WRA reached feasible given radio/TV " +
                    "ownership and the proportion accessing radio/TV in the past week?"}
                </div>
                <div style={styles.instructionsText3}>
                    {"- For Newspaper/magazine interventions, is the proportion of WRA reached feasible given the " +
                    "proportion accessing newspapers/magazines in the past week?"}
                </div>
                <div style={styles.instructionsText3}>
                    {"- For SMS/text message reminders, is the proportion of WRA reached feasible given mobile phone " +
                    "ownership?"}
                </div>
                <div style={styles.instructionsText3}>
                    {"- Are the total cost reasonable given budget or funding expectations?"}
                </div>
                <div style={styles.instructionsText}>
                    {"If the answers to any of these questions is \"no\", you can return to Step 4 and revise your inputs."}
                </div>

                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold'}} >
                    Intervention sense check based on inputs from Step 4
                </text>

                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold', fontStyle:'italic', marginBottom:5}}>
                    &nbsp;(no inputs required)
                </text>

                <GBEditor
                    key={'senseCheck'}
                    id={Constants.Editor_IntervSenseCheck}
                    minHeight={445}
                    maxHeight={445}
                    packTable={this.state.packTable1}
                    onPackTableChanged={this.onPackTable1Change}
                    showTitle={false}
                />

                <div style={styles.source}>
                    {this.getSource()}
                    <br />
                    {this.getCostsNote()}
                </div>

                <br />
                        
                <TButton 
                    caption={"Back"} 
                    color={"secondary"} 
                    style={styles.backBtn} 
                    onClick={this.onBackBtnClick} 
                />
                <TButton 
                    caption={"Next"} 
                    color={"primary"} 
                    onClick={this.onNextBtnClick} 
                />
            </div>
        );
    }
}

export default SenseCheckPage;