import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import RightComponents from "./AppBarRightComponents";
import LeftComponents from "./AppBarLeftComponents";

const useStyles = makeStyles(theme => ({
    toolbar: {
        minHeight: "63px !important",
        paddingLeft: 10,
        marginLeft: 0
    }
}));

const AppBarFPBC = (props) => {
    const classes = useStyles();

    return (
        <div className={`AppBar ${classes.root}`}>
            <AppBar className={classes.appBar} color='inherit'>
                <Toolbar className={classes.toolbar}>
                    <LeftComponents  {...props} />
                    <div style={{ flex: 1 }}></div>
                    <RightComponents {...props} />
                </Toolbar>
            </AppBar>

            <Toolbar className={classes.toolbar} />
        </div>
    );
};

AppBarFPBC.propTypes = {
    onStateChange : PropTypes.func.isRequired,
    onPageChange  : PropTypes.func.isRequired
};

export default React.memo(AppBarFPBC);