import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "../../@common/components/TComboBox";
import TEdit from "../../@common/components/TEdit";
import * as Constants from "../../utilities/Constants";
import { getPhoneSMSInterventionName, NumberFormatCustom  } from "../../utilities/GBUtil"

class PhoneSMSInterventions extends React.Component {

    static propTypes = {
        onStateChange                        : PropTypes.func,
        onPhoneSMSInterventionComboBoxChange : PropTypes.func,
        onPhoneSMSOptionComboBoxChange       : PropTypes.func,
        onPhoneSMSEditChange                 : PropTypes.func,
        interventionSupport                  : PropTypes.object,  
        phoneSMSInterventionIdx              : PropTypes.number,  
        phoneSMSDirectEntryValue             : PropTypes.number,
        phoneSMSReachMessagesValue           : PropTypes.number,
        intervReachIdx                       : PropTypes.number,
        finalYearStr                         : PropTypes.string,
    };

    static defaultProps = {
        onStateChange                        : () => console.log('onStateChange'),
        onPhoneSMSInterventionComboBoxChange : () => console.log('onPhoneSMSInterventionComboBoxChange'),
        onPhoneSMSOptionComboBoxChange       : () => console.log('onPhoneSMSOptionComboBoxChange'),
        onPhoneSMSEditChange                 : () => console.log('onPhoneSMSEditChange'),
        interventionSupport                  : {},  
        phoneSMSInterventionIdx              : 0, 
        phoneSMSDirectEntryValue             : 0,
        phoneSMSReachMessagesValue           : 0,
        intervReachIdx                       : 0,
        finalYearStr                         : '',
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
   
    onPhoneSMSInterventionComboBoxChange = (idx) => {
        this.props.onPhoneSMSInterventionComboBoxChange(idx);
    };

    onPhoneSMSOptionComboBoxChange = (idx) => {
        this.props.onPhoneSMSOptionComboBoxChange(idx);
    };

    onPhoneSMSEditChange = (value, name) => {
        value = Number(value);
        if (name === 'messagesPerWoman') {
            if (value <= 0) {
                value = 1;
            }
        }
        this.props.onPhoneSMSEditChange(Number(value), name);
    };

    
    //==================================================================================================================
    //
    //                                                String Functions
    //
    //==================================================================================================================
    getDirectEntryStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Value for ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Value over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getMessagesSentStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Messages sent in ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Messages sent over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getReachStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Reach in ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Reach over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    //==================================================================================================================
    //
    //                                                Formula function
    //
    //==================================================================================================================  
    getFormula = () => {
        const styles = {
            operandStyle : {
                fontWeight: 'bold',
                fontSize: 18,
                display: 'inline-block',
                marginLeft: 25,
                marginRight: 25,
                marginTop: 35
            },
            TEditStyle : {
                width: 205, 
                marginTop: 20,
                marginRight: 0,
                marginBottom: 0,
                color: 'secondary'
            }
        }
        let directEntry = 0;
        let byMessages = 1;
        let option = this.props.interventionSupport.phoneSMS.optionIdxArray[this.props.phoneSMSInterventionIdx];

        if (option === directEntry) {
            return (
                <div>
                    <TEdit
                        key={'directEntry'}
                        name='directEntry'
                        caption={this.getDirectEntryStr()}
                        value={this.props.phoneSMSDirectEntryValue}
                        onChange={this.onPhoneSMSEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter value'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === byMessages) {
            return (
                <div>
                    <TEdit
                        key={'messagesSent'}
                        name='messagesSent'
                        caption={this.getMessagesSentStr()}
                        value={this.props.interventionSupport.phoneSMS[getPhoneSMSInterventionName(this.props.phoneSMSInterventionIdx)]['messagesSent']}
                        onChange={this.onPhoneSMSEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>/</p>
                    <TEdit
                        key={'messagesPerWoman'}
                        name='messagesPerWoman'
                        caption={'Messages per woman'}
                        value={this.props.interventionSupport.phoneSMS[getPhoneSMSInterventionName(this.props.phoneSMSInterventionIdx)]['messagesPerWoman']}
                        onChange={this.onPhoneSMSEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachMessages'}
                        name='reachMessages'
                        caption={this.getReachStr()}
                        value={this.props.phoneSMSReachMessagesValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            margins : {
                marginBottom : 20,
            },
        }

        return (
            <div>
                 <TComboBox
                    caption={"Interventions"}
                    items={[
                        "SMS/text message reminders",
                    ]}
                    itemIndex={this.props.phoneSMSInterventionIdx}
                    onChange={this.onPhoneSMSInterventionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />
                <TComboBox
                    caption={"Calculation options"}
                    items={[
                        "Direct entry",
                        "By messages",
                    ]}
                    itemIndex={this.props.interventionSupport.phoneSMS.optionIdxArray[this.props.phoneSMSInterventionIdx]}
                    onChange={this.onPhoneSMSOptionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />
                {this.getFormula()}
            </div>
        );
    }
}

export default PhoneSMSInterventions;