import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "../../@common/components/TComboBox";
import TEdit from "../../@common/components/TEdit";
import * as Constants from "../../utilities/Constants";
import { getMiddleMediaInterventionName, NumberFormatCustom  } from "../../utilities/GBUtil"

class MiddleMediaInterventions extends React.Component {

    static propTypes = {
        onStateChange                           : PropTypes.func,
        onMiddleMediaInterventionComboBoxChange : PropTypes.func,
        onMiddleMediaOptionComboBoxChange       : PropTypes.func,
        onMiddleMediaEditChange                 : PropTypes.func,
        interventionSupport                     : PropTypes.object,   
        middleMediaInterventionIdx              : PropTypes.number, 
        middleMediaDirectEntryValue             : PropTypes.number,
        middleMediaReachEventValue              : PropTypes.number,
        middleMediaReachCommValue               : PropTypes.number,
        intervReachIdx                          : PropTypes.number,
        finalYearStr                            : PropTypes.string,
    };

    static defaultProps = {
        onStateChange                           : () => console.log('onStateChange'),
        onMiddleMediaInterventionComboBoxChange : () => console.log('onMiddleMediaInterventionComboBoxChange'),
        onMiddleMediaOptionComboBoxChange       : () => console.log('onMiddleMediaOptionComboBoxChange'),
        onMiddleMediaEditChange                 : () => console.log('onMiddleMediaEditChange'),
        interventionSupport                     : {},   
        middleMediaInterventionIdx              : 0, 
        middleMediaDirectEntryValue             : 0,
        middleMediaReachEventValue              : 0,
        middleMediaReachCommValue               : 0,
        intervReachIdx                          : 0,
        finalYearStr                            : '',
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
   
    onMiddleMediaInterventionComboBoxChange = (idx) => {
        this.props.onMiddleMediaInterventionComboBoxChange(idx);
    };

    onMiddleMediaOptionComboBoxChange = (idx) => {
        this.props.onMiddleMediaOptionComboBoxChange(idx);
    };

    onMiddleMediaEditChange = (value, name) => {
        this.props.onMiddleMediaEditChange(Number(value), name);
    };

    //==================================================================================================================
    //
    //                                                String Functions
    //
    //==================================================================================================================
    getDirectEntryStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Value for ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Value over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getEventsHeldStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Events held in  ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Events held over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getReachStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Reach in ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Reach over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getCommunitiesStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Communities reached ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Communities reached over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    //==================================================================================================================
    //
    //                                                Formula function
    //
    //==================================================================================================================  
    getFormula = () => {
        const styles = {
            operandStyle : {
                fontWeight: 'bold',
                fontSize: 18,
                display: 'inline-block',
                marginLeft: 25,
                marginRight: 25,
                marginTop: 35
            },
            TEditStyle : {
                width: 205, 
                marginTop: 20, 
                marginRight: 0,
                marginBottom: 0,
                color: 'secondary'
            }
        }
        let directEntry = 0;
        let byEvent = 1;
        let byCommunity = 2;
        let option = this.props.interventionSupport.middleMedia.optionIdxArray[this.props.middleMediaInterventionIdx];

        if (option === directEntry) {
            return (
                <div>
                    <TEdit
                        key={'directEntry'}
                        name='directEntry'
                        caption={this.getDirectEntryStr()}
                        value={this.props.middleMediaDirectEntryValue}
                        onChange={this.onMiddleMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter value'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === byEvent) {
            return (
                <div>
                    <TEdit
                        key={'eventsHeld'}
                        name='eventsHeld'
                        caption={this.getEventsHeldStr()}
                        value={this.props.interventionSupport.middleMedia[getMiddleMediaInterventionName(this.props.middleMediaInterventionIdx)]['eventsHeld']}
                        onChange={this.onMiddleMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'womenPerEvent'}
                        name='womenPerEvent'
                        caption={'Women per event'}
                        value={this.props.interventionSupport.middleMedia[getMiddleMediaInterventionName(this.props.middleMediaInterventionIdx)]['womenPerEvent']}
                        onChange={this.onMiddleMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachEvent'}
                        name='reachEvent'
                        caption={this.getReachStr()}
                        value={this.props.middleMediaReachEventValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === byCommunity) {
            return (
                <div>
                    <TEdit
                        key={'communities'}
                        name='communities'
                        caption={this.getCommunitiesStr()}
                        value={this.props.interventionSupport.middleMedia[getMiddleMediaInterventionName(this.props.middleMediaInterventionIdx)]['communities']}
                        onChange={this.onMiddleMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'womenPerComm'}
                        name='womenPerComm'
                        caption={'Women per community'}
                        value={this.props.interventionSupport.middleMedia[getMiddleMediaInterventionName(this.props.middleMediaInterventionIdx)]['womenPerComm']}
                        onChange={this.onMiddleMediaEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachComm'}
                        name='reachComm'
                        caption={this.getReachStr()}
                        value={this.props.middleMediaReachCommValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            margins : {
                marginBottom : 20,
            },
        }

        return (
            <div>
                <TComboBox
                    caption={"Interventions"}
                    items={[
                        "Billboards/flyers",
                        "Live drama",
                        "Community announcements",
                    ]}
                    itemIndex={this.props.middleMediaInterventionIdx}
                    onChange={this.onMiddleMediaInterventionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />
                <TComboBox
                    caption={"Calculation options"}
                    items={[
                        "Direct entry",
                        "By event",
                        "By community",
                    ]}
                    itemIndex={this.props.interventionSupport.middleMedia.optionIdxArray[this.props.middleMediaInterventionIdx]}
                    onChange={this.onMiddleMediaOptionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />
                {this.getFormula()}
            </div>
        );
    }
}

export default MiddleMediaInterventions;