import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import {fnAddCommas, generatePackChart, roundToSigFigure} from "../../../utilities/GBUtil"
import * as Constants from "../../../utilities/Constants";

const Create = (props) => {
    const {resultsData} = props;

    let editorName = 'Cost-effectiveness results (cumulative over the 5 years)';

    let packTable = Setup();
    packTable = SetSSData(packTable, resultsData);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true);
    return ([packTable, packChart]);
};

const Setup = () => {
    let pt = createSimpleDefaultPackTable(5, 2, true);

    pt.GBFixedCols = 0;

    pt.tableData.value[1][1] = 'cost per additional FP user year';
    pt.tableData.value[2][1] = 'cost per unintended pregnancy averted';
    pt.tableData.value[3][1] = 'cost per maternal death averted';
    pt.tableData.value[4][1] = 'cost per maternal DALY averted';

    pt.LockedCells[1][0] = true;
    pt.LockedCells[2][0] = true;
    pt.LockedCells[3][0] = true;
    pt.LockedCells[4][0] = true;

    pt.LockedCells[1][1] = true;
    pt.LockedCells[2][1] = true;
    pt.LockedCells[3][1] = true;
    pt.LockedCells[4][1] = true;

    pt.RDec[1][0] = 1;
    pt.RDec[2][0] = 0;
    pt.RDec[3][0] = 0;
    pt.RDec[4][0] = 0;

    pt.Alignments[1][0] = Constants.Right;
    pt.Alignments[2][0] = Constants.Right;
    pt.Alignments[3][0] = Constants.Right;
    pt.Alignments[4][0] = Constants.Right;

    pt.FontStyles[1][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[2][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[3][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[4][0]["intArray"] = [Constants.Bold];

    pt.FontColors[1][0] = 6660569;
    pt.FontColors[2][0] = 6660569;
    pt.FontColors[3][0] = 6660569;
    pt.FontColors[4][0] = 6660569;

    pt.GBColWidths[0] = 80;
    pt.GBColWidths[1] = 300;

    return pt;
};

const SetSSData = (pt, resultsData) => {

    pt.tableData.value[1][0] = '$ ' + fnAddCommas(resultsData["costEffData"]["cost per additional FP user year"].toFixed());
    pt.tableData.value[2][0] = '$ ' + fnAddCommas(resultsData["costEffData"]["cost per unintended pregnancy averted"].toFixed());
    pt.tableData.value[3][0] = '$ ' + fnAddCommas(resultsData["costEffData"]["cost per maternal death averted"].toFixed());
    pt.tableData.value[4][0] = '$ ' + fnAddCommas(resultsData["costEffData"]["cost per maternal DALY averted"][1].toFixed());

    return pt;
};

export const CostEffResults = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};