import { createVector, createMatrix } from '../other/utilities';

/* These are the fields of a packTable seperated by what type of 
  variable they are.  Helpful for easing manipulation potentially. */
export const PackTableFields = {
    general : [
        "GBFixedRows",
        "GBFixedCols",
        "GBRowCount",
        "GBColCount",
        "TotalRow",
        "TotalCol",
        "Title",
        "SourceMode",
        "SourceMap",
        "Source",
    ],
    special : [
        "tableData",
        "MergedCells"
    ],
    row : [
        "IndentRowText",
        "RowSubHeadings",
        "RowIds",
        "GBRowHeights",
    ],
    col : [
        "WordWrappedCols",
        "ColIds",
        "GBColWidths",
    ],
    rowCol : [
        "RDec",
        "PercRDec",
        "MinAllowedVal",
        "MaxAllowedVal",
        "FontStyles",

        "FontBold",
        "FontItalic",
        "FontUnderline",

        "FontColors",
        "BGColors",
        "GBCellComment",
        "GBUseTriangle",
        "LockedCells",
        "HasCheckBox",
        "CheckBoxState",
        "Alignments",
        "allowPercent",
        "showPercent",
    ],
    dead : [
        "Proj",
        "ModID",
        "editorMstID",
    ]
};

/* If any changes are done here, please remember to check the utilities 
  file and change transposePackTable and resizePackTable when needed.  Also,
  change createDefaultPackTable below. */
export const createSimpleDefaultPackTable = (    
    rowCount = 1,
    columnCount = 1,
    isResult = false
) => ({
    tableData: {
        value : createMatrix(rowCount, columnCount, "")
    },
    GBFixedRows: 1,
    GBFixedCols: 1,
    GBRowCount: rowCount,
    GBColCount: columnCount,
    TotalRow: 0,
    TotalCol: 0,
    WordWrappedCols: createVector(columnCount, false),
    RDec: createMatrix(rowCount, columnCount, 0),
    PercRDec: createMatrix(rowCount, columnCount, 1),
    MinAllowedVal: createMatrix(rowCount, columnCount, 0),
    MaxAllowedVal: createMatrix(rowCount, columnCount, 0),
    FontStyles: createMatrix(rowCount, columnCount, { intArray: [] }),

    FontSize: createMatrix(rowCount, columnCount, isResult ? '16px' : '12px'),
    FontBold: createMatrix(rowCount, columnCount, 0),
    FontItalic: createMatrix(rowCount, columnCount, 0),
    FontUnderline: createMatrix(rowCount, columnCount, 0),
    
    FontColors: createMatrix(rowCount, columnCount, "#000"),
    BGColors: createMatrix(rowCount, columnCount, 536870911),
    GBCellComment: createMatrix(rowCount, columnCount, ""),
    GBUseTriangle: createMatrix(rowCount, columnCount, false),
    LockedCells: createMatrix(rowCount, columnCount, false),
    MergedCells: [],
    IndentRowText: createVector(rowCount, {
        xoffset: 0,
        Value: false
    }),
    RowSubHeadings: createVector(rowCount, false),
    HasCheckBox: createMatrix(rowCount, columnCount, false),
    CheckBoxState: createMatrix(rowCount, columnCount, false),
    Alignments: createMatrix(rowCount, columnCount, 0),
    allowPercent: createMatrix(rowCount, columnCount, 0),
    showPercent: createMatrix(rowCount, columnCount, 0),
    Title: "",
    editorMstID: 0,
    Proj: 0,
    ModID: 0,
    RowIds: createMatrix(rowCount, 2, [0, 0]),
    ColIds: createMatrix(columnCount, 2, [0, 0]),
    SourceMode: 0,
    SourceMap: [],
    Source: [""],
    GBRowHeights : createVector(rowCount, -1),
    GBColWidths : createVector(columnCount, -1),
});

export const createDefaultPackTable = (
    data,
    fixedRowCount,
    fixedColumnCount,
    rowCount,
    columnCount,
    displayTotalRow,
    displayTotalColumn
) => ({
    tableData: data,
    GBFixedRows: fixedRowCount,
    GBFixedCols: fixedColumnCount,
    GBRowCount: rowCount,
    GBColCount: columnCount,
    TotalRow: displayTotalRow ? 1 : 0,
    TotalCol: displayTotalColumn ? 1 : 0,
    WordWrappedCols: createVector(columnCount, false),
    RDec: createMatrix(rowCount, columnCount, 0),
    PercRDec: createMatrix(rowCount, columnCount, 1),
    MinAllowedVal: createMatrix(rowCount, columnCount, 0),
    MaxAllowedVal: createMatrix(rowCount, columnCount, 0),
    FontStyles: createMatrix(rowCount, columnCount, { intArray: [] }),

    FontBold: createMatrix(rowCount, columnCount, 0),
    FontItalic: createMatrix(rowCount, columnCount, 0),
    FontUnderline: createMatrix(rowCount, columnCount, 0),

    FontColors: createMatrix(rowCount, columnCount, "#000"),
    BGColors: createMatrix(rowCount, columnCount, 536870911),
    GBCellComment: createMatrix(rowCount, columnCount, ""),
    GBUseTriangle: createMatrix(rowCount, columnCount, false),
    LockedCells: createMatrix(rowCount, columnCount, false),
    MergedCells: [],
    IndentRowText: createVector(rowCount, {
        xoffset: 0,
        Value: false
    }),
    RowSubHeadings: createVector(rowCount, false),
    HasCheckBox: createMatrix(rowCount, columnCount, false),
    CheckBoxState: createMatrix(rowCount, columnCount, false),
    Alignments: createMatrix(rowCount, columnCount, 0),
    allowPercent: createMatrix(rowCount, columnCount, 0),
    showPercent: createMatrix(rowCount, columnCount, 0),
    Title: "",
    editorMstID: 0,
    Proj: 0,
    ModID: 0,
    RowIds: createMatrix(rowCount, 2, [0, 0]),
    ColIds: createMatrix(columnCount, 2, [0, 0]),
    SourceMode: 0,
    SourceMap: [],
    Source: [""],
    GBRowHeights : createVector(rowCount, -1),
    GBColWidths : createVector(columnCount, -1)
});