import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        // flexGrow: 1,
        // width: '100%',
        // backgroundColor: theme.palette.background.paper,
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 24, ...props.style }}>
            {props.children}
        </Typography>
    );
}

class TTabs2 extends React.Component {

    static propTypes = {
        tabTitles             : PropTypes.any,
        tabContents           : PropTypes.arrayOf(PropTypes.any),
        icon                  : PropTypes.any,
        classes               : PropTypes.object.isRequired,
        centered              : PropTypes.bool,
        tabStyle              : PropTypes.object,
        tabContentStyle       : PropTypes.object,
        selectedTab           : PropTypes.number,
        onTabChange           : PropTypes.func,
        variant               : PropTypes.string,
        tabBarOutline         : PropTypes.string,
        tabValue              : PropTypes.number,
        tabFontSize           : PropTypes.number,
        fullHeight            : PropTypes.bool,
        containerStyle        : PropTypes.object,
        appBarBackgroundColor : PropTypes.string,
        tabBackgroundColor    : PropTypes.string,
        tabSelectedColor      : PropTypes.string,
        tabTitleColor         : PropTypes.string,
        tabTitleWeight        : PropTypes.string,
        appBarHeight          : PropTypes.number,
    };

    static defaultProps = {
        tabTitles             : ["Tab 1", "Tab 2", "Tab 3"],
        tabContents           : ["Content 1", "Content 2", "Content 3"],
        icon                  : null,
        classes               : {},
        centered              : false,
        tabStyle              : {},
        tabContentStyle       : {},
        selectedTab           : 0,
        onTabChange           : () => {},
        variant               : 'standard',
        tabBarOutline         : undefined,
        tabValue              : undefined,
        tabFontSize           : 16,
        fullHeight            : false,
        containerStyle        : {},
        appBarBackgroundColor : '#FFFFFF',
        tabBackgroundColor    : '#FFFFFF',
        tabSelectedColor      : 'secondary',
        tabTitleColor         : '#000000',
        tabTitleWeight        : 'normal',
        appBarHeight          : 48
    };

    state = {
        value: this.props.selectedTab,
    };

    componentDidMount() {
        if (typeof this.props.tabValue !== 'undefined') {
            this.setState({
                value: this.props.tabValue
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (typeof props.tabValue !== 'undefined') {
            return ({
                value : props.tabValue
            });
        }
        else {
            return null;
        }
    }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    handleChange = (event, value) => {
        if (typeof this.props.tabValue !== 'undefined') {
            this.props.onTabChange(value);
        }
        else {
            this.setState({ 
                value : value
            }, () => {
                this.props.onTabChange(value);
            });
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderTabTitles = () => {
        let arr = [];

        for (var i = 0; i < this.props.tabTitles.length; i++) {
            arr.push(<Tab key={i} style={{backgroundColor : this.props.tabBackgroundColor, textTransform : 'none', fontSize: this.props.tabFontSize, color : this.props.tabTitleColor, fontWeight: this.props.tabTitleWeight}} label={this.props.tabTitles[i]} />);
        }

        return arr;
    };

    render() {
        const { classes, variant, centered, tabBarOutline, fullHeight, containerStyle } = this.props;
        const { value } = this.state;

        const innerStyles = {
            appBar : {
                height: this.props.tabTitles.length > 0 ? this.props.appBarHeight : 0,
                backgroundColor : this.props.appBarBackgroundColor
            }
        };

        if (typeof tabBarOutline !== 'undefined') {
            innerStyles.appBar.boxShadow = tabBarOutline;
        }

        return (
            <div className={classes.root} style={fullHeight ? {height: "calc(100% - 0)", ...containerStyle} : {...containerStyle}}>
                <AppBar position="static" color="inherit" style={innerStyles.appBar}>
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        indicatorColor= {this.props.tabSelectedColor}
                        // textColor="primary"
                        variant={variant}
                        scrollButtons="on"
                        centered={centered}
                    >
                        {this.renderTabTitles()}
                    </Tabs>
                </AppBar>

                <TabContainer 
                    style={{
                        ...this.props.tabContentStyle, 
                        // height: this.props.fullHeight ? "calc(100% - 50px)" : undefined
                    }}
                >
                    {this.props.tabContents[this.state.value]}
                </TabContainer>
            </div>
        );
    }
}

export default withStyles(styles)(TTabs2);