import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import * as Constants from "../../utilities/Constants"

const Create = (countryName, modelParametersData, costsData) => {
    let region = '';
    for (let i = 0; i < modelParametersData['internalUser'].length; i++) {
        if (modelParametersData['internalUser'][i]["COUNTRY"] === countryName) {
            region = modelParametersData['internalUser'][i]["UNPD Region for FP Costs"];
        }
    }

    let editorName = '2. Review service delivery costs for ' + region;
    let packTable = Setup();
    packTable = SetSSData(packTable, region, costsData);
    packTable = RecalcGrid(packTable, region, costsData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = () => {
    let numRows = 8;
    let numCols = 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][0] = 'Method';
    pt.tableData.value[0][1] = 'Average annual unit cost';

    pt.tableData.value[1][0] = 'Sterilization';
    pt.tableData.value[2][0] = 'IUD';
    pt.tableData.value[3][0] = 'Implants';
    pt.tableData.value[4][0] = 'Injection';
    pt.tableData.value[5][0] = 'Pill';
    pt.tableData.value[6][0] = 'Condoms';
    pt.tableData.value[7][0] = 'Other';

    
    for (let row = 1; row < numRows; row++) {
        pt.FontStyles[row][0]["intArray"] = [Constants.Bold];
        pt.RDec[row][1] = 2;
        pt.Alignments[row][1] = Constants.Right;
        pt.MinAllowedVal[row][1] = 0;
        pt.MaxAllowedVal[row][1] = 100;
    }

    pt.GBColWidths[1] = 110;

    return pt;
};

const SetSSData = (pt, region, costsData) => {
    for (let i = 0; i < costsData['FPCostAIUUser'].length; i++){
        if (costsData['FPCostAIUUser'][i]["Income/geographic/other group"] === region) {
            pt.tableData.value[1][1] = costsData['FPCostAIUUser'][i]["2020 Female sterilization"];
            pt.tableData.value[2][1] = costsData['FPCostAIUUser'][i]["2020 IUD"];
            pt.tableData.value[3][1] = costsData['FPCostAIUUser'][i]["2020 Implant"];
            pt.tableData.value[4][1] = costsData['FPCostAIUUser'][i]["2020 Injectable"];
            pt.tableData.value[5][1] = costsData['FPCostAIUUser'][i]["2020 Pill"];
            pt.tableData.value[6][1] = costsData['FPCostAIUUser'][i]["2020 Male condom"];
            pt.tableData.value[7][1] = costsData['FPCostAIUUser'][i]["2020 Other"];
        }
    }

    return pt;
};

const GetSSData = (pt, countryName, modelParametersData, costsData) => {
    let region = '';
    for (let i = 0; i < modelParametersData['internalUser'].length; i++) {
        if (modelParametersData['internalUser'][i]["COUNTRY"] === countryName) {
            region = modelParametersData['internalUser'][i]["UNPD Region for FP Costs"];
        }
    }

    for (let i = 0; i < costsData['FPCostAIUUser'].length; i++){
        if (costsData['FPCostAIUUser'][i]["Income/geographic/other group"] === region) {
            costsData['FPCostAIUUser'][i]["2020 Female sterilization"] = pt.tableData.value[1][1];
            costsData['FPCostAIUUser'][i]["2020 IUD"] = pt.tableData.value[2][1];
            costsData['FPCostAIUUser'][i]["2020 Implant"] = pt.tableData.value[3][1];
            costsData['FPCostAIUUser'][i]["2020 Injectable"] = pt.tableData.value[4][1];
            costsData['FPCostAIUUser'][i]["2020 Pill"] = pt.tableData.value[5][1];
            costsData['FPCostAIUUser'][i]["2020 Male condom"] = pt.tableData.value[6][1];
            costsData['FPCostAIUUser'][i]["2020 Other"] = pt.tableData.value[7][1];
        }
    }

    return (costsData)

};

const RecalcGrid = (pt, region, costsData) => {
    for (let i = 0; i < costsData['FPCostAIUUser'].length; i++){
        if (costsData['FPCostAIUUser'][i]["Income/geographic/other group"] === region) {

            let user = costsData['FPCostAIUUser'][i];
            let def = costsData['FPCostAIUDefault'][i];

            let defColor = 0;
            let changedColor = 6930250;

            pt.FontColors[1][1] = user["2020 Female sterilization"] === def["2020 Female sterilization"] ? defColor : changedColor;
            pt.FontColors[2][1] = user["2020 IUD"] === def["2020 IUD"] ? defColor : changedColor;
            pt.FontColors[3][1] = user["2020 Implant"] === def["2020 Implant"] ? defColor : changedColor;
            pt.FontColors[4][1] = user["2020 Injectable"] === def["2020 Injectable"] ? defColor : changedColor;
            pt.FontColors[5][1] = user["2020 Pill"] === def["2020 Pill"] ? defColor : changedColor;
            pt.FontColors[6][1] = user["2020 Male condom"] === def["2020 Male condom"] ? defColor : changedColor;
            pt.FontColors[7][1] = user["2020 Other"] === def["2020 Other"] ? defColor : changedColor;
        }
    }

    return (pt);
};

export const DirectServiceDeliveryCostsEditor = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
    GetSSData        : GetSSData,
    RecalcGrid       : RecalcGrid,
};