import React from 'react';
import GBStdTable from "../@common/components/GBStdTableAG/GbStdTable";
import ErrorBoundaryEditor from "../@common/components/ErrorBoundaryEditor";
import Theme from "../app/Theme";

let styles = {
    error : {
        width: 300, 
        height: 300, 
        padding: 20, 
        background: '#e8b7b7', 
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
    editor : {
        marginBottom: 10,
    }
};

const GBErrorEditor = (props) => {
    return (
        <div style={styles.error}>
            Something went wrong!  
            <br />
            {props.msg}
        </div>
    );
};

const GBMissingPackTable = (props) => {
    return (
        <React.Fragment>
            Missing PackTable
        </React.Fragment>
    );
};

const GBEditor = (props) => {
    const { id, tableRef, minHeight, maxHeight, packTable, onPackTableChanged, ...rest } = props;

    const headerBackgroundColor = Theme.editor.headerColor;
    const oddRowBackgroundColor = Theme.editor.bandColor;
    const subheadingRowBackgroundColor = Theme.editor.subheadingRowBackgroundColor;

    return (
        <div style={styles.editor}>
            <ErrorBoundaryEditor>
                {
                    props.packTable ?
                        <GBStdTable
                            id                           = {id} // This does nothing
                            ref                          = {tableRef}
                            minHeight                    = {minHeight}
                            maxHeight                    = {maxHeight}
                            packTable                    = {packTable}
                            onSheetCellsChanged          = {onPackTableChanged}
                            onPackTableChanged           = {onPackTableChanged} 
                            headerBackgroundColor        = {headerBackgroundColor}
                            oddRowBackgroundColor        = {oddRowBackgroundColor}
                            subheadingRowBackgroundColor = {subheadingRowBackgroundColor}
                            pixelsPerCharacter           = {12}
                            {...rest}
                        /> :
                        <GBMissingPackTable />
                }
                
            </ErrorBoundaryEditor>
        </div>
    );
};

export {
    GBErrorEditor,
    GBEditor
}