import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import { addMergedCellsToArray, indentPackTable } from "../../@common/components/GBStdTableAG/other/utilities";
import * as Constants from "../../utilities/Constants";
import { fnAddCommas, CloneObj } from "../../utilities/GBUtil"
import { getTotalSBC_Costs } from "../../utilities/calculations"

const Create = (intervReachIdx, startYearIdx, shareOfPop, countryName, interventionsData, modelParametersData, costsData) => {
    let editorName = 'Intervention sense check based on inputs from Step 4';

    let packTable = Setup(startYearIdx);
    packTable = SetSSData(packTable, intervReachIdx, startYearIdx, shareOfPop, countryName, interventionsData, modelParametersData, costsData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = (startYearIdx) => {
    let numRows = 17;
    let numCols = 7;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    let finalYear = (Constants.startYear + startYearIdx + 5).toString();

    pt.tableData.value[0][0] = 'Intervention';
    pt.tableData.value[0][1] = 'Reach denominator';
    pt.tableData.value[0][2] = 'Cumulative reach over 5 years';
    pt.tableData.value[0][3] = 'Check proportion of WRA reached in ' + finalYear;
    pt.tableData.value[0][4] = 'Check percent of women owning radio, TV, or mobile phone *';
    pt.tableData.value[0][5] = 'Check percent of women accessing media in past week *';
    pt.tableData.value[0][6] = 'Check total costs';

    pt.tableData.value[1][0] = 'Mass media';
    pt.FontStyles[1][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 1, 0, 1, 9);
    for (let i = 1; i < numCols; i++) {
        pt.LockedCells[1][i] = true;
    }
    pt.tableData.value[2][0] = 'Radio';
    pt.tableData.value[3][0] = 'TV';
    pt.tableData.value[4][0] = 'Newspaper/magazine';
    pt.tableData.value[5][0] = 'Mixed mass media';
    for (let r = 2; r <=5; r++){
        pt = indentPackTable(pt, r, true, Constants.ED_Indent1);
    }

    pt.tableData.value[6][0] = 'Middle media';
    pt.FontStyles[6][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 6, 0, 1, 5);
    pt.tableData.value[7][0] = 'Billboards/flyers';
    pt.tableData.value[8][0] = 'Live drama';
    pt.tableData.value[9][0] = 'Community announcements';
    for (let r1 = 7; r1 <=9; r1++){
        pt = indentPackTable(pt, r1, true, Constants.ED_Indent1);
    }

    pt.tableData.value[10][0] = 'Phone/SMS';
    pt.FontStyles[10][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 10, 0, 1, 5);
    pt.tableData.value[11][0] = 'SMS/text message reminders';
    pt = indentPackTable(pt, 11, true, Constants.ED_Indent1);

    pt.tableData.value[12][0] = 'Interpersonal communication';
    pt.FontStyles[12][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 12, 0, 1, 5);
    pt.tableData.value[13][0] = 'Individual/household IPC';
    pt.tableData.value[14][0] = 'Group IPC';
    pt.tableData.value[15][0] = 'IPC + Community engagement';
    for (let r2 = 13; r2 <=15; r2++){
        pt = indentPackTable(pt, r2, true, Constants.ED_Indent1);
    }

    pt.tableData.value[16][0] = 'Total';
    pt.FontStyles[16][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[16][6]["intArray"] = [Constants.Bold];

    for (let row = 2; row < numRows; row++) {
        for (let col = 1; col < numCols; col++) {
            pt.LockedCells[row][col] = true;
            if (col !== 1) {
                pt.Alignments[row][col] = Constants.Right;
            }
            pt.MinAllowedVal[row][col] = 0;
            if (col === 6) {
                pt.MaxAllowedVal[row][col] = 0;
            }
            else {
                pt.MaxAllowedVal[row][col] = 999999999999;
            }
        }
        pt.RDec[row][2] = 0;
        pt.RDec[row][3] = 2;
        pt.RDec[row][4] = 2;
        pt.RDec[row][5] = 2;
        pt.RDec[row][6] = 0;

        pt.GBRowHeights[0] = 50;
    }

    for (let c = 1; c < numCols; c++) {
        pt.GBColWidths[c] = 130;
    }

    pt.GBColWidths[1] = 150;
    pt.GBColWidths[4] = 140;
    pt.GBColWidths[5] = 140;

    return pt;
};

const SetSSData = (pt, intervReachIdx, startYearIdx, shareOfPop, countryName, interventionsData, modelParametersData, costsData) => {
    let iv = 0;
    let RadioRow = 2;
    let TVRow = 3;
    let NewspaperRow = 4;
    let SMSRow = 11;
    let MiddleMediaRow = 6;
    let PhoneSMSRow = 10;
    let InterCommRow = 12;
    let TotalRow = 16;
    let OwnMediaCol = 4;
    let AccessMediaCol = 5;
    let TotalCol = 6;

    let intervReach = "manualUser";
    if (intervReachIdx === Constants.IntervReach_FinalYear) {
        intervReach = "finalYearUser";
    }
    else if (intervReachIdx === Constants.IntervReach_Cumulative) {
        intervReach = "cumulativeUser"
    }

    let countryIdx;
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            countryIdx = i;
        }
    }

    let finalYear = (Constants.startYear + startYearIdx + 5).toString();

    let SBC_Unit_Costs = [new Array(), new Array(), new Array()];

    for (let i = Constants.First_Interv; i <= Constants.Max_Num_Intervs; i++) {
        SBC_Unit_Costs[0].push(costsData["SBCUnitCostsUser"][i]["Median Unit Cost Defaults"] / 2);
        SBC_Unit_Costs[1].push(costsData["SBCUnitCostsUser"][i]["Median Unit Cost Defaults"]);
        SBC_Unit_Costs[2].push(costsData["SBCUnitCostsUser"][i]["Median Unit Cost Defaults"] * 2);
    }

    let Cost_Discount_Rate = modelParametersData["externalUser"][countryIdx]["Cost Discount Rate"] / 100;

    let intervData = CloneObj(interventionsData[intervReach]);

    let TotalSBC_Costs = getTotalSBC_Costs(SBC_Unit_Costs, Cost_Discount_Rate, intervData);

    for (let row = 2; row < pt.GBRowCount - 1; row++) {
        if ((row !== MiddleMediaRow) && (row !== PhoneSMSRow) && (row !== InterCommRow)) {
            pt.tableData.value[row][1] = interventionsData["finalYearUser"][iv]["Reach denominator"];
            pt.tableData.value[row][2] = intervData[iv]["Cumulative Reach over 5 Years"];
            pt.tableData.value[row][3] = (((intervData[iv]["Year 5"] /
                                         (modelParametersData["externalUser"][countryIdx]["WRA " + finalYear] * shareOfPop / 100) * 100)).toFixed()).toString() + ' %';
            pt.tableData.value[row][TotalCol] = '$ ' + fnAddCommas(((TotalSBC_Costs.Cummulative_IV_Cost_With_Discount[1][iv]).toFixed()).toString());

            iv++
        }
    }

    pt.tableData.value[RadioRow][OwnMediaCol] = ((modelParametersData["externalUser"][countryIdx]["Percent households possessing a radio"]).toFixed(2)).toString() + ' %';
    pt.tableData.value[TVRow][OwnMediaCol] = ((modelParametersData["externalUser"][countryIdx]["Percent households possessing a TV"]).toFixed(2)).toString() + ' %';
    pt.tableData.value[SMSRow][OwnMediaCol] = ((modelParametersData["externalUser"][countryIdx]["Percent households possessing a cell phone"]).toFixed(2)).toString() + ' %';

    pt.tableData.value[RadioRow][AccessMediaCol] = ((modelParametersData["externalUser"][countryIdx]["Percent of women who listen to the radio at least once a week"]).toFixed(2)).toString() + ' %';
    pt.tableData.value[TVRow][AccessMediaCol] = ((modelParametersData["externalUser"][countryIdx]["Percent of women who watch TV at least once a week"]).toFixed(2)).toString() + ' %';
    pt.tableData.value[NewspaperRow][AccessMediaCol] = ((modelParametersData["externalUser"][countryIdx]["Percent women who read a newspaper at least once a week"]).toFixed(2)).toString() + ' %';

    pt.tableData.value[TotalRow][TotalCol] =  '$ ' + fnAddCommas((TotalSBC_Costs.Cummulative_IV_Cost_With_Discount_Total[1].toFixed()).toString());

    return pt;
};

export const InterventionSenseCheckEditor = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};