import React from "react";
import PropTypes from "prop-types";
import Theme from "../app/Theme";
import GBDialog from "../@common/components/GBDialog";
import TButton from "../@common/components/TButton";

class LeaveCustomDialog extends React.PureComponent {

    static propTypes = {
        onYesBtnClick : PropTypes.func,
        onNoBtnClick  : PropTypes.func,
    };

    static defaultProps = {
        onYesBtnClick : () => console.log("onYesBtnClick"),
        onNoBtnClick  : () => console.log("onNoBtnClick"),
    };

    render() {
        const styles = {
            yesBtn : {
                marginRight: 20
            },
        }

        return (
            <GBDialog
                title={'Are you sure?'}
                Theme={Theme}
                onClose={this.props.onNoBtnClick}
                content={
                    <div>
                        <div style={{marginBottom: 25}}>{'If you switch to using the selected scale, any custom values that were entered will be lost.'}</div>
                        <div>{'Would you like to continue?'}</div>
                    </div>
                }
                actions={[
                    <TButton 
                        key={'yes'}
                        caption={"Yes"} 
                        color={"secondary"}  
                        onClick={this.props.onYesBtnClick} 
                        style={styles.yesBtn}
                    />,
                    <TButton 
                        key={'no'}
                        caption={"No"} 
                        color={"primary"} 
                        onClick={this.props.onNoBtnClick} 
                    />
                ]}
            />
        );
    }
}

export default LeaveCustomDialog;