import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import { addMergedCellsToArray, indentPackTable } from "../../@common/components/GBStdTableAG/other/utilities";
import * as Constants from "../../utilities/Constants"
import { removeCommas } from "../../utilities/GBUtil"

const Create = (interventionsData, startYearIdx) => {
    let editorName = 'Annual reach';

    let packTable = Setup(startYearIdx);
    packTable = SetSSData(packTable, interventionsData);
    packTable = RecalcGrid(packTable);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = (startYearIdx) => {
    let numRows = 16;
    let numCols = 8;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][0] = 'Intervention';
    pt.tableData.value[0][1] = 'Reach denominator';
    pt.tableData.value[0][2] = (Constants.startYear + startYearIdx + 1).toString();
    pt.tableData.value[0][3] = (Constants.startYear + startYearIdx + 2).toString();
    pt.tableData.value[0][4] = (Constants.startYear + startYearIdx + 3).toString();
    pt.tableData.value[0][5] = (Constants.startYear + startYearIdx + 4).toString();
    pt.tableData.value[0][6] = (Constants.startYear + startYearIdx + 5).toString();
    pt.tableData.value[0][7] = 'Cumulative reach over 5 years';

    pt.tableData.value[1][0] = 'Mass media';
    pt.FontStyles[1][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 1, 0, 1, 9);
    for (let i = 1; i < numCols; i++) {
        pt.LockedCells[1][i] = true;
    }
    pt.tableData.value[2][0] = 'Radio';
    pt.tableData.value[3][0] = 'TV';
    pt.tableData.value[4][0] = 'Newspaper/magazine';
    pt.tableData.value[5][0] = 'Mixed mass media';
    for (let r = 2; r <=5; r++){
        pt = indentPackTable(pt, r, true, Constants.ED_Indent1);
    }

    pt.tableData.value[6][0] = 'Middle media';
    pt.FontStyles[6][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 6, 0, 1, 9);
    for (let j = 1; j < numCols; j++) {
        pt.LockedCells[6][j] = true;
    }
    pt.tableData.value[7][0] = 'Billboards/flyers';
    pt.tableData.value[8][0] = 'Live drama';
    pt.tableData.value[9][0] = 'Community announcements';
    for (let r1 = 7; r1 <=9; r1++){
        pt = indentPackTable(pt, r1, true, Constants.ED_Indent1);
    }

    pt.tableData.value[10][0] = 'Phone/SMS';
    pt.FontStyles[10][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 10, 0, 1, 9);
    for (let k = 1; k < numCols; k++) {
        pt.LockedCells[10][k] = true;
    }
    pt.tableData.value[11][0] = 'SMS/text message reminders';
    pt = indentPackTable(pt, 11, true, Constants.ED_Indent1);

    pt.tableData.value[12][0] = 'Interpersonal communication';
    pt.FontStyles[12][0]["intArray"] = [Constants.Bold];
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 12, 0, 1, 9);
    for (let m = 1; m < numCols; m++) {
        pt.LockedCells[12][m] = true;
    }
    pt.tableData.value[13][0] = 'Individual/household IPC';
    pt.tableData.value[14][0] = 'Group IPC';
    pt.tableData.value[15][0] = 'IPC + Community engagement';
    for (let r2 = 13; r2 <=15; r2++){
        pt = indentPackTable(pt, r2, true, Constants.ED_Indent1);
    }

    for (let row = 2; row < numRows; row++) {
        pt.LockedCells[row][1] = true;
        pt.LockedCells[row][7] = true;
        pt["FontColors"][row][7] = Constants.LockedCellColor;
        for (let col = 2; col < numCols; col++){
            pt.RDec[row][col] = 0;
            pt.Alignments[row][col] = Constants.Right;
            pt.MinAllowedVal[row][col] = 0;
            pt.MaxAllowedVal[row][col] = 999999999999;
        }
    }

    for (let c = 1; c < numCols; c++) {
        pt.GBColWidths[c] = 120;
    }

    pt.GBColWidths[1] = 150;

    return pt;
};

const SetSSData = (pt, interventionsData) => {
    let iv = 0;
    let MiddleMediaRow = 6;
    let PhoneSMSRow = 10;
    let InterCommRow = 12;
    for (let row = 2; row < pt.GBRowCount; row++) {
        if ((row !== MiddleMediaRow) && (row !== PhoneSMSRow) && (row !== InterCommRow)) {
            pt.tableData.value[row][1] = interventionsData["manualUser"][iv]["Reach denominator"];
            pt.tableData.value[row][2] = interventionsData["manualUser"][iv]["Year 1"];
            pt.tableData.value[row][3] = interventionsData["manualUser"][iv]["Year 2"];
            pt.tableData.value[row][4] = interventionsData["manualUser"][iv]["Year 3"];
            pt.tableData.value[row][5] = interventionsData["manualUser"][iv]["Year 4"];
            pt.tableData.value[row][6] = interventionsData["manualUser"][iv]["Year 5"];
            pt.tableData.value[row][7] = interventionsData["manualUser"][iv]["Cumulative Reach over 5 Years"];
            iv++
        }
    }

    return pt;
};

const GetSSData = (pt, interventionsData) => {
    let iv = 0;
    let MiddleMediaRow = 6;
    let PhoneSMSRow = 10;
    let InterCommRow = 12;
    for (let row = 2; row < pt.GBRowCount; row++) {
        if ((row !== MiddleMediaRow) && (row !== PhoneSMSRow) && (row !== InterCommRow)) {
            interventionsData["manualUser"][iv]["Year 1"] = pt.tableData.value[row][2];
            interventionsData["manualUser"][iv]["Year 2"] = pt.tableData.value[row][3];
            interventionsData["manualUser"][iv]["Year 3"] = pt.tableData.value[row][4];
            interventionsData["manualUser"][iv]["Year 4"] = pt.tableData.value[row][5];
            interventionsData["manualUser"][iv]["Year 5"] = pt.tableData.value[row][6];
            interventionsData["manualUser"][iv]["Cumulative Reach over 5 Years"] = pt.tableData.value[row][7];
            iv++
        }
    }
    return interventionsData;
};

const RecalcGrid = (pt) => {
    let MiddleMediaRow = 6;
    let PhoneSMSRow = 10;
    let InterCommRow = 12;
    
    for (let r = 2; r < pt.GBRowCount; r++) {
        if ((r !== MiddleMediaRow) && (r !== PhoneSMSRow) && (r !== InterCommRow)) {
            let sum = 0;
            for (let c = 2; c < pt.GBColCount - 1; c++) {
                sum += removeCommas(pt.tableData.value[r][c]);
            }
            pt.tableData.value[r][Constants.CumulativeCol] = sum;
        }
    }
    return (pt);
};

export const ManualInterventionReachEditor = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
    GetSSData        : GetSSData,
    RecalcGrid       : RecalcGrid,
};