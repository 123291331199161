import React from 'react';
import PropTypes from "prop-types";
import * as Constants from "../../utilities/Constants";
import { GBEditor } from '../../editors/GBEditors';
import TComboBox from "../../@common/components/TComboBox";
import TRadioGroup from "../../@common/components/TRadioGroup";
import TButton from '../../@common/components/TButton';
import infoImage from "../../img/infoImage3.png";

class FinalYearInterventionReach extends React.Component {

    static propTypes = {
        onPackTableChanged             : PropTypes.func,
        onFinalYearScaleComboBoxChange : PropTypes.func,
        onFinalYearRadioGroupChange    : PropTypes.func,
        onInterventionSupportBtnClick  : PropTypes.func,
        onInfoBtnClick                 : PropTypes.func,
        packTable                      : PropTypes.object,     
        finalYearScaleIdx              : PropTypes.number,
        finalYearRadioGroupIdx         : PropTypes.number,   
    };

    static defaultProps = {
        onPackTableChanged             : () => console.log('onPackTableChanged'),
        onFinalYearScaleComboBoxChange : () => console.log('onFinalYearScaleComboBoxChange'),
        onFinalYearRadioGroupChange    : () => console.log('onFinalYearRadioGroupChange'),
        onInterventionSupportBtnClick  : () => console.log('onInterventionSupportBtnClick'),
        onInfoBtnClick                 : () => console.log('onInfoBtnClick'),
        packTable                      : {},
        finalYearScaleIdx              : 0,
        finalYearRadioGroupIdx         : 0
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
    onPackTableChange = (packTable) => {
        this.props.onPackTableChanged(packTable);
    };

    onFinalYearScaleComboBoxChange = (idx) => {
        this.props.onFinalYearScaleComboBoxChange(idx);
    };

    onFinalYearRadioGroupChange = (idx) => {
        this.props.onFinalYearRadioGroupChange(idx);
    };

    onInfoBtnClick = (info) => {
        this.props.onInfoBtnClick(info);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            margins : {
                marginBottom : 30,
            },
            radioGroup : { 
                width: 400,
                boxShadow: 'none',
                marginBottom: 10,
                paddingLeft: 0
            },
            radioGroupTitleStyle : {
                color: '#0000008A',
                fontWeight: 'normal',
                fontSize : 12,
                padding : 0,
                fontFamily: 'Lato, san-serif, serif',
                marginTop: -20,
                marginLeft: 0,
                marginBottom: -7
            },
            helpSetValues : {
                marginBottom: 0,
                marginLeft: 0,
                marginTop: 15,
                paddingLeft: 0,
                paddingRight: 0
            },
        };

        return (
            <div>
                <div style={{display: "inline-block"}}>
                    <TComboBox
                        caption={"Scale-up shape"}
                        items={["S-curve", "Linear", "Constant"]}
                        itemIndex={this.props.finalYearScaleIdx}
                        onChange={this.onFinalYearScaleComboBoxChange}
                        width={"300px"}
                        style={styles.margins}
                    />
                </div>

                <div style={{display: "inline-block"}}>
                    <img
                        src     = {infoImage}
                        alt     = ""
                        style   = {{cursor: 'pointer'}}
                        onClick = {() => this.onInfoBtnClick([
                            <p>When opting to specify the final year 5 reach or cumulative reach, you should select
                                one of three scale-up shapes:</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Constant –</b>&nbsp;assumes the same
                                number reached each year</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Linear –</b>&nbsp;assumes a steady
                                increase in persons reached each year</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>S-shaped -</b>
                                &nbsp;assumes a gradual start in the early year with a steep increase in years from
                                years 2 to 4 and a more gradual increase from years 4 to 5.</p>
                        ])}
                    />
                </div>

                <br />

                <div>
                    <TRadioGroup
                        caption={"Edit values"}
                        items={["Keep generated values","Edit generated values"]}
                        style={styles.radioGroup}
                        titleStyle={styles.radioGroupTitleStyle}
                        itemIndex={this.props.finalYearRadioGroupIdx}
                        handleChange={this.onFinalYearRadioGroupChange}
                        row={true}
                    />

                    <TButton
                        caption={"Help me set values"}
                        color={'secondary'}
                        onClick={this.props.onInterventionSupportBtnClick}
                        style={styles.helpSetValues}
                        disabled={this.props.finalYearRadioGroupIdx === Constants.CustomValues} 
                        toolTipTitle={"Support for calculating intervention reach and coverage"}
                    />
                        
                </div>

                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold'}} >
                    Final year 5 reach / scale up
                </text>

                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold', fontStyle:'italic', color:'darkred'}}>
                    &nbsp;*(required)&nbsp;
                </text>

                <br/>

                <div style={{width: 1100, display: "inline-block"}}>
                    <GBEditor
                        key={'finalYearIntervReach'} //{Date.now()+1}
                        id={Constants.Editor_IntervReachFinalYear}
                        minHeight={445}
                        maxHeight={445}
                        packTable={this.props.packTable}
                        onPackTableChanged={this.onPackTableChange}
                        showTitle={false}
                    />
                </div>
            </div>
        );
    }
}

export default FinalYearInterventionReach;