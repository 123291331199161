import React from 'react';
import PropTypes from "prop-types";
import TButton from "../@common/components/TButton";
import TComboBox from '../@common/components/TComboBox';
import Theme from "../app/Theme";
import * as Constants from "../utilities/Constants";
import { SBCToIntermeidateDetEditor } from "../editors/ImpactORs/SBCToIntermediateDetEditor";
import { IntermediateDetTomCPREditor } from '../editors/ImpactORs/IntermediateDetTomCPREditor';
import { DirectSBCTomCPREditor } from '../editors/ImpactORs/DirectSBCTomCPREditor';
import impactORsImage from '../img/impactORsImage.png'
import { GBEditor } from '../editors/GBEditors';
import { clone } from "../utilities/GBUtil"
import infoImage1 from "../img/infoImage1.png";
import infoImage2 from "../img/infoImage2.png";

class ImpactORsPage extends React.Component {

    static propTypes = {
        onPageChange                : PropTypes.func,
        onStateChange               : PropTypes.func,
        onInfoBtnClick              : PropTypes.func,
        interQuartileRangeIdx       : PropTypes.number,
        impactORsData               : PropTypes.object,
        restoreBtnDisabledImpactORs : PropTypes.bool,
    };

    static defaultProps = {
        onPageChange                : () => console.log('onPageChange'),
        onStateChange               : () => console.log('onStateChange'),
        onInfoBtnClick              : () => console.log('onInfoBtnClick'),
        interQuartileRangeIdx       : Constants.Median,
        impactORsData               : {},
        restoreBtnDisabledImpactORs : true,
    };

    state = {
        packTable1         : undefined,
        packTable2         : undefined,
        packTable3         : undefined,
    };

        //==================================================================================================================
    //
    //                                                 Life Cycles
    //
    //==================================================================================================================

    componentDidMount() {
        this.setState({
            packTable1 : SBCToIntermeidateDetEditor.Create(this.props.interQuartileRangeIdx, this.props.impactORsData),
            packTable2 : IntermediateDetTomCPREditor.Create(this.props.interQuartileRangeIdx, this.props.impactORsData),
            packTable3 : DirectSBCTomCPREditor.Create(this.props.interQuartileRangeIdx, this.props.impactORsData),
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const hasChanged = 
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState));
        
        return hasChanged;
    };

    onInterQuartileRangeComboBoxChange = (idx) => {
       this.props.onStateChange ({
            interQuartileRangeIdx : idx
        });

        this.setState ({
            packTable1 : SBCToIntermeidateDetEditor.Create(idx, this.props.impactORsData),
            packTable2 : IntermediateDetTomCPREditor.Create(idx, this.props.impactORsData),
            packTable3 : DirectSBCTomCPREditor.Create(idx, this.props.impactORsData),
        })
    };

    onPackTable1Change = (packTable) => {
        let impactORsData = SBCToIntermeidateDetEditor.GetSSData(packTable, this.props.interQuartileRangeIdx, clone(this.props.impactORsData));
        packTable = SBCToIntermeidateDetEditor.Create(this.props.interQuartileRangeIdx, impactORsData);

        this.setState ({
            packTable1 : packTable
        });
        this.props.onStateChange ({
            impactORsData               : impactORsData,
            restoreBtnDisabledImpactORs : false,
        })
    };

    onPackTable2Change = (packTable) => {
        let impactORsData = IntermediateDetTomCPREditor.GetSSData(packTable, this.props.interQuartileRangeIdx, clone(this.props.impactORsData));
        packTable = IntermediateDetTomCPREditor.Create(this.props.interQuartileRangeIdx, impactORsData);

        this.setState ({
            packTable2 : packTable
        });
        this.props.onStateChange ({
            impactORsData               : impactORsData,
            restoreBtnDisabledImpactORs : false,
        })
    };

    onPackTable3Change = (packTable) => {
        let impactORsData = DirectSBCTomCPREditor.GetSSData(packTable, this.props.interQuartileRangeIdx, clone(this.props.impactORsData));
        packTable = DirectSBCTomCPREditor.Create(this.props.interQuartileRangeIdx, impactORsData);

        this.setState ({
            packTable3 : packTable
        });
        this.props.onStateChange ({
            impactORsData               : impactORsData,
            restoreBtnDisabledImpactORs : false,
        })
    };

    onRestoreDefaultValuesClick = () => {
        let impactORsData = clone(this.props.impactORsData);

        impactORsData['SBC_Q1User']     = impactORsData['SBC_Q1Default'];
        impactORsData['SBC_MedianUser'] = impactORsData['SBC_MedianDefault'];
        impactORsData['SBC_Q3User']     = impactORsData['SBC_Q3Default'];
        impactORsData['mCPRUser']       = impactORsData['mCPRDefault'];
        impactORsData['SBC_mCPRUser']   = impactORsData['SBC_mCPRDefault'];

        this.setState({
            packTable1 : SBCToIntermeidateDetEditor.Create(this.props.interQuartileRangeIdx, impactORsData),
            packTable2 : IntermediateDetTomCPREditor.Create(this.props.interQuartileRangeIdx, impactORsData),
            packTable3 : DirectSBCTomCPREditor.Create(this.props.interQuartileRangeIdx, impactORsData)
        });

        this.props.onStateChange ({
            impactORsData               : impactORsData,
            restoreBtnDisabledImpactORs : true
        })
    };

    onBackBtnClick = () => {
        this.props.onPageChange("ModelParametersPage");
    };

    onNextBtnClick = () => {
        this.props.onPageChange("CostsPage");
    };

    onInfoBtnClick = (info) => {
        this.props.onInfoBtnClick(info);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            page : {
                marginLeft : 25
            },
            ComboBox : {
                marginBottom : 30,
                display: "inline-block"
            },
            header : {
                fontSize: 24,
                fontWeight: 'bold',
                color: Theme.palette.primary.main,
                marginBottom: 20,
                display: 'inline'
            },
            header2 : {
                fontSize: 24,
                fontWeight: 'bold',
                fontStyle:'italic',
                color: Theme.palette.primary.main,
                marginBottom: 20,
                display: 'inline'
            },
            instructionsText : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginRight: 25
            },
            instructionsText1 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginRight: 25,
                textIndent: 50
            },
            instructionsText2 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginBottom: 25,
                marginRight: 25
            },
            instructionsText3 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginBottom: 25,
                marginRight: 25,
                textIndent: 50
            },
            restoreDefaultValues : {
                marginBottom: 30,
                marginLeft: 100,
                fontSize: 12,
                height: 25,
                paddingLeft: 0,
                paddingRight: 0
            },
            editorDiv : {
                display: 'inline-block',
                marginRight: 80
            },
            image : {
                display: 'inline',
            },
            backBtn : {
                marginRight: 20
            }
        };

        return (
            <div style={styles.page}>
                <text style={styles.header}>
                    Review SBC effectiveness odds ratios
                </text>

                <text style={styles.header2}>
                    &nbsp;(no inputs required)
                </text>

                <TButton
                    caption={"Restore default values"}
                    color={'secondary'}
                    onClick={this.onRestoreDefaultValuesClick}
                    style={styles.restoreDefaultValues}
                    disabled={this.props.restoreBtnDisabledImpactORs}
                />
                <div style={styles.instructionsText}>{"In this step, you can review the SBC effectiveness odds ratios based on a synthesis of the literature on SBC impact in low- and middle-income countries. The odds ratios look at three links: "}</div>
                <div style={styles.instructionsText1}>{"1) the relationship between SBC interventions to intermediate outcomes like attitudes and communication, "}</div>
                <div style={styles.instructionsText1}>{"2) the relationship between intermediate outcomes and modern contraceptive use, and "}</div>
                <div style={styles.instructionsText3}>{"3) the direct relationship between SBC interventions and modern contraceptive use."}</div>
                <div style={styles.instructionsText}>{'No inputs are required, however, the odds ratios can be edited to represent the specific intervention if impact data are available for your intervention.'}</div>
                <br/>
                <div style={styles.instructionsText2}>{'We recommend you first use the median odds ratios shown here. To conduct sensitivity analysis in later iterations of the application, use the dropdown menus to select the Q1 and Q3 odds ratios from the interquartile range to examine the impact and cost-effectiveness if interventions were less or more effective than the median value from the literature.'} </div>
                <TComboBox
                    caption={"Select OR level to use"}
                    items={['Q1', 'Median', 'Q3']}
                    itemIndex={this.props.interQuartileRangeIdx}
                    onChange={this.onInterQuartileRangeComboBoxChange}
                    width={'180px'}
                    style={styles.ComboBox}
                />
                <div style={{display: "inline-block"}}>
                    <img
                        src     = {infoImage2}
                        alt     = ""
                        style   = {{cursor: 'pointer'}}
                        onClick = {() => this.onInfoBtnClick([
                            <p>Odds ratios (ORs) are a measure of association between an exposure and an outcome.
                                Here, ORs measure the strength of the association between exposure between: </p>,
                            <p style={{textIndent: 50}}>(1) SBC interventions and intermediate outcomes</p>,
                            <p style={{textIndent: 50}}>(2) intermediate outcomes and mCPR, and </p>,
                            <p style={{textIndent: 50}}>(3) SBC interventions and mCPR.  </p>,
                            <p>ORs are measured on a log scale where an OR less than 1.0 implies a negative
                                relationship and a ratio greater than 1.0 implies a positive relationship between the
                                SBC intervention and a positive change in the outcome. For example, a radio show that
                                aims to improve attitudes on the approval of FP with a resulting OR = 2.0 implies that
                                those exposed to the show have twice the odds of approving of FP compared to those not
                                exposed.</p>])}
                    />
                </div>

                <br />

                <GBEditor
                    key                 = {'impactORS1'} //{Date.now()}
                    id                  = {Constants.Editor_SBCToIntermediateDet}
                    minHeight           = {445}
                    maxHeight           = {445}
                    packTable           = {this.state.packTable1}
                    onPackTableChanged  = {this.onPackTable1Change}
                />

                <br />

                <div style={styles.editorDiv}>
                    <GBEditor
                        key                 = {'impactORS2'} //{Date.now()+1}
                        id                  = {Constants.Editor_IntermediateDetTomCPR}
                        minHeight           = {445}
                        maxHeight           = {445}
                        packTable           = {this.state.packTable2}
                        onPackTableChanged  = {this.onPackTable2Change}
                    />

                    <div style={{display:'inline-block'}}>
                        <GBEditor
                            key                 = {'impactORS3'} //{Date.now()+2}
                            id                  = {Constants.Editor_IntermediateDetTomCPR}
                            minHeight           = {445}
                            maxHeight           = {445}
                            packTable           = {this.state.packTable3}
                            onPackTableChanged  = {this.onPackTable3Change}
                        />
                    </div>

                    <div style={{marginLeft : 10, display: "inline-block"}}>
                        <img
                            src     = {infoImage1}
                            alt     = ""
                            style   = {{cursor: 'pointer'}}
                            onClick = {() => this.onInfoBtnClick(<p>SBC interventions classified as IPC + Community
                                engagement are those that include IPC (individual and/or group IPC) but also work at the
                                community level by engaging with local leaders (e.g., religious and traditional leaders)
                                and/or community groups in designing and implementing behavior change messaging and
                                activities.</p>)}
                        />
                    </div>
                </div>
            
                <img style={styles.image} src={impactORsImage} alt="" />

                <div style={{marginBottom: 10}}></div>
               
                <TButton
                    caption={"Back"}
                    color={'secondary'}
                    style={styles.backBtn}
                    onClick={this.onBackBtnClick}
                />
                <TButton
                    caption={"Next"}
                    color={'primary'}
                    onClick={this.onNextBtnClick}
                />
            </div>
        );
    }
}

export default ImpactORsPage;