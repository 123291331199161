import React from "react";
import FPBCStepper from "./FPBCStepper";

const FPBCStepperContainer = (props) => {
    const { activeStep, editMode, onPageChange } = props;

    const onStepChange = (idx) => {
        if (!editMode) {
            return;
        }
        
        switch(idx) {
            case 0  : onPageChange("ModelParametersPage"); break;
            case 1  : onPageChange("ImpactORsPage"); break;
            case 2  : onPageChange("CostsPage"); break;
            case 3  : onPageChange("InterventionsPage"); break;
            case 4  : onPageChange("SenseCheckPage"); break;
            case 5  : onPageChange("ResultsPage"); break;
            default : break;
        }
    };

    return (
        <FPBCStepper
            steps={['Model Parameters', 'Impact ORs', 'Costs', 'Interventions', 'Sense Check', 'Results']}
            activeStep={activeStep}
            completedSteps={[]}
            inactiveCursor={!editMode}
            onStepBtnClick={onStepChange}
            useAltColors={editMode}
        />
    );
};

export default FPBCStepperContainer;