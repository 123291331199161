import * as Constants from "../../../utilities/Constants";
import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import { getAggregateCatName } from "../../../utilities/GBUtil"
import { generatePackChart } from "../../../utilities/GBUtil"

const Create = (resultsData) => {
    let editorName = 'Relative contribution of SBC interventions to increases in mCPR';

    let packTable = Setup();
    packTable = SetSSData(packTable, resultsData);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true, [],
        [
            '#043A5B',
            '#1BA9E0',
            '#F07B2F',
            '#6DBD52'
        ]);
    return ([packTable, packChart]);
};

const Setup = () => {
    let pt = createSimpleDefaultPackTable(Constants.Final_Agg_Cat + 2, 2, true);

    let r = 1;
    for (let i = Constants.Final_Agg_Cat; i >= Constants.First_Agg_Cat; i--){
        pt.tableData.value[r][0] = getAggregateCatName(i);
        r++;
    }

    r = 1;
    for (let a = Constants.First_Agg_Cat; a <= Constants.Final_Agg_Cat; a++) {
        pt.RDec[r][1] = 2;
        r++;
    }

    return pt;
};

const SetSSData = (pt, resultsData) => {
    let r = 1;
    for (let a = Constants.Final_Agg_Cat; a >= Constants.First_Agg_Cat; a--){
        pt.tableData.value[r][1] = 0;
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            pt.tableData.value[r][1] += (resultsData["impactData"]["RelContrOfSBCIntervToIncIn_mCPR"][i][a] * 100);
        }
        r++;
    }

    return pt;
};

export const RelContrOfSBCIntervToIncIn_mCPR = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};