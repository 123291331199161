import * as React from "react";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Tooltip from "@material-ui/core/Tooltip";

class TButton extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption        : PropTypes.any,
        captionStyles  : PropTypes.object,
        variant        : PropTypes.string,
        color          : PropTypes.string,
        width          : PropTypes.string,
        disabled       : PropTypes.bool,
        pictureLeft    : PropTypes.any,
        pictureRight   : PropTypes.any,
        transparent    : PropTypes.bool,
        shape          : PropTypes.string,
        hasPopupMenu   : PropTypes.bool,
        popupMenuItems : PropTypes.arrayOf(PropTypes.object),
        onClick        : PropTypes.func,
        style          : PropTypes.any,
        containerStyle : PropTypes.any,
        toolTipTitle   : PropTypes.any,
    };

    static defaultProps = {
        caption        : 'Caption',
        captionStyles  : {},
        variant        : 'contained',
        color          : 'primary',
        width          : null,
        disabled       : false,
        pictureLeft    : null,
        pictureRight   : null,
        hasPopupMenu   : false,
        popupMenuItems : [],
        onClick        : (event) => console.log('TButton onClick'),
        transparent    : false,
        shape          : 'rectangle',
        style          : {},
        containerStyle : {display: 'inline-block'},
        toolTipTitle   : "",
    };

    state = {
        anchorEl: null,
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onClick = (event) => {
        this.props.onClick(event);
    };

    onPopupMenuShow = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    onPopupMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderCaption = () => {
        if (this.props.caption === '') {
            return null;
        }
        else {
            return (
                <div style={{marginLeft: 10, marginRight: 10, fontWeight: 'normal', ...this.props.captionStyles}}>
                    {this.props.caption}
                </div>
            );
        }
    };

    render() {

        let styles = {
            button: {
                margin: "3px",
                marginLeft :0,
                textTransform : 'none',
                width: this.props.width, ...this.props.style,
                minWidth: null,
                height : 36,
                // fontSize : 16,
                paddingTop: 3,
                // minHeight: '30px',
                // padding: 0,
                // fontSize: '12px'
            }
        };

        if (this.props.transparent) {
            styles.button.backgroundColor = 'inherit';
            styles.button.boxShadow = 'inherit';
        }

        if (this.props.shape === 'circle') {
            styles.button.borderRadius = '50%';
            styles.button.minWidth = '40px';
            styles.button.minHeight = '40px';
            styles.button.padding = '0';
        }

        let styles2 = {};

        if (this.props.disabled) {
            styles2.color = '#7b7b7b';
            styles2.textDecoration = 'none';
        }

        const { anchorEl } = this.state;

        return (
            <div style={{...this.props.containerStyle}}>
                <Tooltip title={this.props.toolTipTitle}>
                    <Button
                        variant={this.props.variant}
                        color={this.props.color}
                        disabled={this.props.disabled}
                        aria-owns={anchorEl ? 'simple-menu' : null}
                        aria-haspopup={this.props.hasPopupMenu}
                        onClick={(this.props.hasPopupMenu) ? this.onPopupMenuShow : this.onClick}
                        style={{...styles.button, ...this.props.style, ...styles2}}
                    >
                        {this.props.pictureLeft}
                        {this.renderCaption()}
                        {this.props.pictureRight}
                        {this.props.children}
                    </Button>
                </Tooltip>
                

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.onPopupMenuClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                >
                    {this.props.popupMenuItems}
                </Menu>
            </div>
        );
    }
}

export default TButton;