import React from "react";
import PropTypes from "prop-types";
import Theme from "../../app/Theme";
import GBDialog from "../../@common/components/GBDialog";
import TButton from "../../@common/components/TButton";
import TTabs2 from "../../@common/components/TTabs2";
import { getMassMediaInterventionName, getMiddleMediaInterventionName, 
    getPhoneSMSInterventionName, getIPCInterventionName, clone } from "../../utilities/GBUtil";
import MassMediaInterventions from "./MassMediaInterventions";
import MiddleMediaInterventions  from "./MiddleMediaInterventions";
import PhoneSMSInterventions  from "./PhoneSMSInterventions";
import IPCInterventions  from "./IPCInterventions";
import * as Constants from "../../utilities/Constants";

class InterventionSupportDialog extends React.PureComponent {

    static propTypes = {
        onStateChange                      : PropTypes.func,
        onInterventionSupportCloseBtnClick : PropTypes.func,
        onInterventionSupportApplyBtnClick : PropTypes.func,
        startYearIdx                       : PropTypes.number,
        intervReachIdx                     : PropTypes.number,
        interventionSupport                : PropTypes.object,
        interventionsData                  : PropTypes.object,
        modelParametersData                : PropTypes.object,
        selectedCountry                    : PropTypes.string,
        shareOfPop                         : PropTypes.number,
    };

    static defaultProps = {
        onStateChange                      : () => console.log("onStateChange"),
        onInterventionSupportCloseBtnClick : () => console.log("onInterventionSupportCloseBtnClick"),
        onInterventionSupportApplyBtnClick : () => console.log("onInterventionSupportApplyBtnClick"),
        startYearIdx                       : 0,
        intervReachIdx                     : 0,
        interventionSupport                : {},
        interventionsData                  : {},
        modelParametersData                : {},
        selectedCountry                    : 'Afghanistan',
        shareOfPop                         : 0,
    };

    state = {
        massMediaInterventionIdx      : 0,
        middleMediaInterventionIdx    : 0,
        phoneSMSInterventionIdx       : 0,
        IPCInterventionIdx            : 0,
        massMediaDirectEntryValue     : 0,
        massMediaReachValue           : 0,
        massMediaReachMediaSpotsValue : 0,
        numWRAValue                   : 0,
        middleMediaDirectEntryValue   : 0,
        middleMediaReachEventValue    : 0,
        middleMediaReachCommValue     : 0,
        phoneSMSDirectEntryValue      : 0,
        phoneSMSReachMessagesValue    : 0,
        IPCDirectEntryValue           : 0,
        IPCReachCHWValue              : 0,
        IPCReachProvidersValue        : 0,
        IPCReachPeerEducationValue    : 0,
        IPCReachGroupCounsellingValue : 0,
        IPCReachSchoolBasedValue      : 0,
        IPCReachReligiousLeadersValue : 0,
        IPCReachCommLeadersValue      : 0,
        localInterventionsData        : {},
        localInterventionSupport      : {}
    };

    componentDidMount() {
        this.setState({
            localInterventionsData : clone(this.props.interventionsData),
            localInterventionSupport : this.getLocalInterventionSupport(),          
        },
           () => this.initCalculatedValues()  
        );
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================

    getLocalInterventionSupport = () => {
        let localSupport = clone(this.props.interventionSupport);

        localSupport.massMedia.radio.directEntry               = this.props.interventionsData[this.getInterventionReach()][Constants.radio][this.getInterventionEditorCol()];
        localSupport.massMedia.TV.directEntry                  = this.props.interventionsData[this.getInterventionReach()][Constants.TV][this.getInterventionEditorCol()];
        localSupport.massMedia.newspaper.directEntry           = this.props.interventionsData[this.getInterventionReach()][Constants.newspaper][this.getInterventionEditorCol()];
        localSupport.massMedia.mixedMassMedia.directEntry      = this.props.interventionsData[this.getInterventionReach()][Constants.mixedMassMedia][this.getInterventionEditorCol()];

        localSupport.middleMedia.billboards.directEntry        = this.props.interventionsData[this.getInterventionReach()][Constants.billboards][this.getInterventionEditorCol()];
        localSupport.middleMedia.liveDrama.directEntry         = this.props.interventionsData[this.getInterventionReach()][Constants.liveDrama][this.getInterventionEditorCol()];
        localSupport.middleMedia.commAnnouncements.directEntry = this.props.interventionsData[this.getInterventionReach()][Constants.commAnnouncements][this.getInterventionEditorCol()];

        localSupport.phoneSMS.SMSTextMessage.directEntry       = this.props.interventionsData[this.getInterventionReach()][Constants.SMSTextMessage][this.getInterventionEditorCol()];

        localSupport.IPC.householdIPC.directEntry              = this.props.interventionsData[this.getInterventionReach()][Constants.householdIPC][this.getInterventionEditorCol()];
        localSupport.IPC.groupIPC.directEntry                  = this.props.interventionsData[this.getInterventionReach()][Constants.groupIPC][this.getInterventionEditorCol()];
        localSupport.IPC.IPCCommEngagement.directEntry         = this.props.interventionsData[this.getInterventionReach()][Constants.IPCCommEngagement][this.getInterventionEditorCol()];

        return (localSupport)
    };

    initCalculatedValues = () => {
        this.setState ({
            massMediaDirectEntryValue     : this.getMassMediaReachValue('directEntry'),
            massMediaReachValue           : this.getMassMediaReachValue('reach'),
            massMediaReachMediaSpotsValue : this.getMassMediaReachValue('reachMediaSpots'),
            numWRAValue                   : this.getWRAValue(),

            middleMediaDirectEntryValue   : this.getMiddleMediaReachValue('directEntry'),
            middleMediaReachEventValue    : this.getMiddleMediaReachValue('reachEvent'),
            middleMediaReachCommValue     : this.getMiddleMediaReachValue('reachComm'),

            phoneSMSDirectEntryValue      : this.getPhoneSMSReachValue('directEntry'),
            phoneSMSReachMessagesValue    : this.getPhoneSMSReachValue('reachMessages'),

            IPCDirectEntryValue           : this.getIPCReachValue('directEntry'),
            IPCReachCHWValue              : this.getIPCReachValue('reachCHW'),
            IPCReachProvidersValue        : this.getIPCReachValue('reachProviders'),
            IPCReachPeerEducationValue    : this.getIPCReachValue('reachPeerEducation'),
            IPCReachGroupCounsellingValue : this.getIPCReachValue('reachGroupCounselling'),
            IPCReachSchoolBasedValue      : this.getIPCReachValue('reachSchoolBased'),
            IPCReachReligiousLeadersValue : this.getIPCReachValue('reachReligiousLeaders'),
            IPCReachCommLeadersValue      : this.getIPCReachValue('reachCommLeaders'),
        })
    };

    getInterventionEditorCol = () => {
        let interventionEditorCol = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : interventionEditorCol = "Year 5"; break;
            case Constants.IntervReach_Cumulative : interventionEditorCol = "Cumulative Reach over 5 Years"; break;
            default : interventionEditorCol = ''; break;
        }
        return (interventionEditorCol)
    };

    getInterventionReach = () => {
        let interventionReach = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : interventionReach = "finalYearUser"; break;
            case Constants.IntervReach_Cumulative : interventionReach = "cumulativeUser"; break;
            default : interventionReach = ''; break;
        }
        return (interventionReach)
    };

    getSupportDialogTitle = () => {
        let supportDialogTitle = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : supportDialogTitle = "Support for calculating final year intervention reach and coverage"; break;
            case Constants.IntervReach_Cumulative : supportDialogTitle = "Support for calculating cumulative intervention reach and coverage"; break;
            default : supportDialogTitle = ''; break;
        }
        return (supportDialogTitle)
    };

    getInstructionsStep5 = () => {
        let instructionsStep5 = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : instructionsStep5 = '5. Click "Apply" to have calculated values inserted into the final year column of the intervention summary table'; break;
            case Constants.IntervReach_Cumulative : instructionsStep5 = '5. Click "Apply" to have calculated values inserted into the cumulative reach over five years column of the intervention summary table'; break;
            default : instructionsStep5 = ''; break;
        }
        return (instructionsStep5)
    };

    getMassMediaReachValue = (id) => {
        let value = 0;
        if (id === 'directEntry') {
            value = this.state.localInterventionSupport.massMedia[getMassMediaInterventionName(this.state.massMediaInterventionIdx)]['directEntry'];
        }
        else if (id === 'reach') {
            value = (this.state.localInterventionSupport.massMedia[getMassMediaInterventionName(this.state.massMediaInterventionIdx)]['womenReached'] *
            this.getWRAValue()) / 100;
        }
        else if (id === 'reachMediaSpots') {
            value = this.state.localInterventionSupport.massMedia[getMassMediaInterventionName(this.state.massMediaInterventionIdx)]['mediaSpots'] * 
            this.state.localInterventionSupport.massMedia[getMassMediaInterventionName(this.state.massMediaInterventionIdx)]['listenersPerAd'];
        }
        return (value)
    };

    getWRAValue = () => {
        let finalYear = (Constants.startYear + this.props.startYearIdx + 5).toString();
        let countryData = {};
        for (let i = 0; i < this.props.modelParametersData["externalUser"].length; i++) {
            if (this.props.modelParametersData["externalUser"][i]["COUNTRY"] === this.props.selectedCountry) {
                countryData = this.props.modelParametersData["externalUser"][i];
            }
        };

        let WRAValue = countryData["WRA " + finalYear] * this.props.shareOfPop / 100;

        return (WRAValue)
    };

    getMiddleMediaReachValue = (id) => {
        let value = 0;
        if (id === 'directEntry') {
            value = this.state.localInterventionSupport.middleMedia[getMiddleMediaInterventionName(this.state.middleMediaInterventionIdx)]['directEntry'];
        }
        else if (id === 'reachEvent') {
            value = this.state.localInterventionSupport.middleMedia[getMiddleMediaInterventionName(this.state.middleMediaInterventionIdx)]['eventsHeld'] * 
            this.state.localInterventionSupport.middleMedia[getMiddleMediaInterventionName(this.state.middleMediaInterventionIdx)]['womenPerEvent'];
        }
        else if (id === 'reachComm') {
            value = this.state.localInterventionSupport.middleMedia[getMiddleMediaInterventionName(this.state.middleMediaInterventionIdx)]['communities'] * 
            this.state.localInterventionSupport.middleMedia[getMiddleMediaInterventionName(this.state.middleMediaInterventionIdx)]['womenPerComm'];
        }
        return (value)
    };

    getPhoneSMSReachValue = (id) => {
        let value = 0;
        if (id === 'directEntry') {
            value = this.state.localInterventionSupport.phoneSMS[getPhoneSMSInterventionName(this.state.phoneSMSInterventionIdx)]['directEntry'];
        }
        else if (id === 'reachMessages') {
            value = this.state.localInterventionSupport.phoneSMS[getPhoneSMSInterventionName(this.state.phoneSMSInterventionIdx)]['messagesSent'] / 
            this.state.localInterventionSupport.phoneSMS[getPhoneSMSInterventionName(this.state.phoneSMSInterventionIdx)]['messagesPerWoman'];
        }
        return (value)
    };

    getIPCReachValue = (id) => {
        let value = 0;
        if (id === 'directEntry') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['directEntry'];
        }
        else if (id === 'reachCHW') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['commHealthWorkers'] * 
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['womenPerCHW'];
        }
        else if (id === 'reachProviders') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['providers'] * 
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['womenPerProvider'];
        }
        else if (id === 'reachPeerEducation') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['peerEducator'] * 
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['classesPerEducator'] *
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['womenPerClass'];
        }
        else if (id === 'reachGroupCounselling') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['counselors'] * 
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['sessionsPerCounselor'] *
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['womenPerSession'];
        }
        else if (id === 'reachSchoolBased') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['teachers'] * 
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['classesPerTeacher'] *
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['girlsPerClass'];
        }
        else if (id === 'reachReligiousLeaders') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['religiousLeaders'] * 
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['congregantsPerLeader'] *
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['percWomenCongregants'];
        }
        else if (id === 'reachCommLeaders') {
            value = this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['communityLeaders'] * 
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['eventsPerLeader'] *
            this.state.localInterventionSupport.IPC[getIPCInterventionName(this.state.IPCInterventionIdx)]['womenPerEvent']; 
        }
        return (value)
    };

    onMassMediaInterventionComboBoxChange = (idx) => {
        this.setState ({
            massMediaInterventionIdx : idx
        },
            () => this.updateMassMediaCalcValue()
        );
    };

    onMassMediaOptionComboBoxChange = (idx) => {
        let support = clone(this.state.localInterventionSupport);
        support.massMedia.optionIdxArray[this.state.massMediaInterventionIdx] = idx;
        this.setState ({
            localInterventionSupport : support
        },
            () => this.updateMassMediaCalcValue()
        );
    };

    onMassMediaEditChange = (value, name) => {
        let option = '';
        switch (this.state.massMediaInterventionIdx) {
            case Constants.radioOpt          : option="radio"; break;
            case Constants.TVOpt             : option="TV"; break;
            case Constants.newspaperOpt      : option="newspaper"; break;
            case Constants.mixedMassMediaOpt : option="mixedMassMedia"; break;
            default : option = ''; break;
        }

        let support = clone(this.state.localInterventionSupport);
        support.massMedia[option][name] = value;

        this.setState ({
            localInterventionSupport : support
        },
            () => this.updateMassMediaCalcValue()
        );
    };

    updateMassMediaCalcValue = () => {
        let directEntry = 0;
        let reach = 1;
        let reachMediaSpots = 2;
        let data = clone(this.state.localInterventionsData);
        let support = clone(this.state.localInterventionSupport);
        let optIdx = support.massMedia.optionIdxArray[this.state.massMediaInterventionIdx];

        let idx = 0;
        let option = '';
        switch (this.state.massMediaInterventionIdx) {
            case Constants.radioOpt          : idx = Constants.radio; option="radio"; break;
            case Constants.TVOpt             : idx = Constants.TV; option="TV"; break;
            case Constants.newspaperOpt      : idx = Constants.newspaper; option="newspaper"; break;
            case Constants.mixedMassMediaOpt : idx = Constants.mixedMassMedia; option="mixedMassMedia"; break;
            default: idx = 0; break;
        };
        
        if (optIdx === directEntry) {
            support.massMedia[option]['directEntry'] = this.getMassMediaReachValue('directEntry');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getMassMediaReachValue('directEntry');
            this.setState ({
                massMediaDirectEntryValue : this.getMassMediaReachValue('directEntry'),
                localInterventionsData    : data,
                localInterventionSupport  : support
            })
        }
        else if (optIdx === reach) {
            support.massMedia[option]['reach'] = this.getMassMediaReachValue('reach');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getMassMediaReachValue('reach');
            this.setState ({
                massMediaReachValue      : this.getMassMediaReachValue('reach'),
                localInterventionsData   : data,
                localInterventionSupport : support
            })
        }
        else if (optIdx === reachMediaSpots) {
            support.massMedia[option]['reachMediaSpots'] = this.getMassMediaReachValue('reachMediaSpots');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getMassMediaReachValue('reachMediaSpots');
            this.setState ({
                massMediaReachMediaSpotsValue : this.getMassMediaReachValue('reachMediaSpots'),
                localInterventionsData        : data,
                localInterventionSupport      : support
            })
        }
    };

    onMiddleMediaInterventionComboBoxChange = (idx) => {
        this.setState ({
            middleMediaInterventionIdx : idx
        },
            () => this.updateMiddleMediaCalcValue()
        );
    };

    onMiddleMediaOptionComboBoxChange = (idx) => {
        let support = clone(this.state.localInterventionSupport);
        support.middleMedia.optionIdxArray[this.state.middleMediaInterventionIdx] = idx;
        this.setState ({
            localInterventionSupport : support
        },
            () => this.updateMiddleMediaCalcValue()
        );
    };

    onMiddleMediaEditChange = (value, name) => {
        let option = '';
        switch (this.state.middleMediaInterventionIdx) {
            case Constants.billboardsOpt        : option="billboards"; break;
            case Constants.liveDramaOpt         : option="liveDrama"; break;
            case Constants.commAnnouncementsOpt : option="commAnnouncements"; break;
            default : option = ''; break;
        }

        let support = clone(this.state.localInterventionSupport);
        support.middleMedia[option][name] = value;

        this.setState ({
            localInterventionSupport : support
        },
            () => this.updateMiddleMediaCalcValue()
        );
    };

    updateMiddleMediaCalcValue = () => {
        let directEntry = 0;
        let reachEvent = 1;
        let reachComm = 2;
        let data = clone(this.state.localInterventionsData);
        let support = clone(this.state.localInterventionSupport);
        let optIdx = support.middleMedia.optionIdxArray[this.state.middleMediaInterventionIdx];

        let idx = 0;
        let option = '';
        switch (this.state.middleMediaInterventionIdx) {
            case Constants.billboardsOpt        : idx = Constants.billboards; option="billboards"; break;
            case Constants.liveDramaOpt         : idx = Constants.liveDrama; option="liveDrama"; break;
            case Constants.commAnnouncementsOpt : idx = Constants.commAnnouncements; option="commAnnouncements"; break;
            default: idx = 0; break;
        };
        
        if (optIdx === directEntry) {
            support.middleMedia[option]['directEntry'] = this.getMiddleMediaReachValue('directEntry');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getMiddleMediaReachValue('directEntry');
            this.setState ({
                middleMediaDirectEntryValue : this.getMiddleMediaReachValue('directEntry'),
                localInterventionsData      : data,
                localInterventionSupport    : support
            })
        }
        else if (optIdx === reachEvent) {
            support.middleMedia[option]['reachEvent'] = this.getMiddleMediaReachValue('reachEvent');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getMiddleMediaReachValue('reachEvent');
            this.setState ({
                middleMediaReachEventValue : this.getMiddleMediaReachValue('reachEvent'),
                localInterventionsData     : data,
                localInterventionSupport   : support
            })
        }
        else if (optIdx === reachComm) {
            support.middleMedia[option]['reachComm'] = this.getMiddleMediaReachValue('reachComm');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getMiddleMediaReachValue('reachComm');
            this.setState ({
                middleMediaReachCommValue : this.getMiddleMediaReachValue('reachComm'),
                localInterventionsData    : data,
                localInterventionSupport  : support
            })
        }
    };

    onPhoneSMSInterventionComboBoxChange = (idx) => {
        this.setState ({
            phoneSMSInterventionIdx : idx
        },
            () => this.updatePhoneSMSCalcValue()
        );
    };

    onPhoneSMSOptionComboBoxChange = (idx) => {
        let support = clone(this.state.localInterventionSupport);
        support.phoneSMS.optionIdxArray[this.state.phoneSMSInterventionIdx] = idx;
        this.setState ({
            localInterventionSupport : support
        },
            () => this.updatePhoneSMSCalcValue()
        );
    };

    onPhoneSMSEditChange = (value, name) => {
        let option = '';
        switch (this.state.phoneSMSInterventionIdx) {
            case Constants.SMSTextMessageOpt : option="SMSTextMessage"; break;
            default : option = ''; break;
        }

        let support = clone(this.state.localInterventionSupport);
        support.phoneSMS[option][name] = value;

        this.setState ({
            localInterventionSupport : support
        },
            () => this.updatePhoneSMSCalcValue()
        );
    };

    updatePhoneSMSCalcValue = () => {
        let directEntry = 0;
        let reachMessages = 1;
        let data = clone(this.state.localInterventionsData);
        let support = clone(this.state.localInterventionSupport);
        let optIdx = support.phoneSMS.optionIdxArray[this.state.phoneSMSInterventionIdx];

        let idx = 0;
        let option = '';
        switch (this.state.phoneSMSInterventionIdx) {
            case Constants.SMSTextMessageOpt : idx = Constants.SMSTextMessage; option="SMSTextMessage"; break;
            default: idx = 0; break;
        };
        
        if (optIdx === directEntry) {
            support.phoneSMS[option]['directEntry'] = this.getPhoneSMSReachValue('directEntry');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getPhoneSMSReachValue('directEntry');
            this.setState ({
                phoneSMSDirectEntryValue : this.getPhoneSMSReachValue('directEntry'),
                localInterventionsData   : data,
                localInterventionSupport : support
            })
        }
        else if (optIdx === reachMessages) {
            support.phoneSMS[option]['reachEvent'] = this.getPhoneSMSReachValue('reachMessages');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getPhoneSMSReachValue('reachMessages');
            this.setState ({
                phoneSMSReachMessagesValue : this.getPhoneSMSReachValue('reachMessages'),
                localInterventionsData     : data,
                localInterventionSupport   : support
            })
        }
    };

    onIPCInterventionComboBoxChange = (idx) => {
        this.setState ({
            IPCInterventionIdx : idx
        },
            () => this.updateIPCCalcValue()
        );
    };

    onIPCOptionComboBoxChange = (idx) => {
        let support = clone(this.state.localInterventionSupport);
        support.IPC.optionIdxArray[this.state.IPCInterventionIdx] = idx;
        this.setState ({
            localInterventionSupport : support
        },
            () => this.updateIPCCalcValue()
        );
    };

    onIPCEditChange = (value, name) => {
        let option = '';
        switch (this.state.IPCInterventionIdx) {
            case Constants.householdIPCOpt      : option="householdIPC"; break;
            case Constants.groupIPCOpt          : option="groupIPC"; break;
            case Constants.IPCCommEngagementOpt : option="IPCCommEngagement"; break;
            default : option = ''; break;
        }

        let support = clone(this.state.localInterventionSupport);
        support.IPC[option][name] = value;

        this.setState ({
            localInterventionSupport : support
        },
            () => this.updateIPCCalcValue()
        );
    };

    updateIPCCalcValue = () => {
        let directEntry = 0;
        let reachCHW = 1;
        let reachProviders = 2;
        let reachPeerEducation = 1;
        let reachGroupCounselling = 2;
        let reachSchoolBased = 3;
        let reachReligiousLeaders = 1;
        let reachCommLeaders = 2;
        let data = clone(this.state.localInterventionsData);
        let support = clone(this.state.localInterventionSupport);
        let optIdx = support.IPC.optionIdxArray[this.state.IPCInterventionIdx];

        let idx = 0;
        let option = '';
        switch (this.state.IPCInterventionIdx) {
            case Constants.householdIPCOpt      : idx = Constants.householdIPC; option="householdIPC"; break;;
            case Constants.groupIPCOpt          : idx = Constants.groupIPC; option="groupIPC"; break;
            case Constants.IPCCommEngagementOpt : idx = Constants.IPCCommEngagement; option="IPCCommEngagement"; break;
            default: idx = 0; break;
        };
        
        if (optIdx === directEntry) {
            support.IPC[option]['directEntry'] = this.getIPCReachValue('directEntry');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('directEntry');
            this.setState ({
                IPCDirectEntryValue      : this.getIPCReachValue('directEntry'),
                localInterventionsData   : data,
                localInterventionSupport : support
            })
        }
        else if ((idx === Constants.householdIPC) && (optIdx === reachCHW)) {
            support.IPC[option]['reachCHW'] = this.getIPCReachValue('reachCHW');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('reachCHW');
            this.setState ({
                IPCReachCHWValue         : this.getIPCReachValue('reachCHW'),
                localInterventionsData   : data,
                localInterventionSupport : support
            })
        }
        else if ((idx === Constants.householdIPC) && (optIdx === reachProviders)) {
            support.IPC[option]['reachProviders'] = this.getIPCReachValue('reachProviders');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('reachProviders');
            this.setState ({
                IPCReachProvidersValue   : this.getIPCReachValue('reachProviders'),
                localInterventionsData   : data,
                localInterventionSupport : support
            })
        }
        else if ((idx === Constants.groupIPC) && (optIdx === reachPeerEducation)) {
            support.IPC[option]['reachPeerEducation'] = this.getIPCReachValue('reachPeerEducation');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('reachPeerEducation');
            this.setState ({
                IPCReachPeerEducationValue : this.getIPCReachValue('reachPeerEducation'),
                localInterventionsData     : data,
                localInterventionSupport   : support
            })
        }
        else if ((idx === Constants.groupIPC) && (optIdx === reachGroupCounselling)) {
            support.IPC[option]['reachGroupCounselling'] = this.getIPCReachValue('reachGroupCounselling');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('reachGroupCounselling');
            this.setState ({
                IPCReachGroupCounsellingValue : this.getIPCReachValue('reachGroupCounselling'),
                localInterventionsData        : data,
                localInterventionSupport      : support
            })
        }
        else if ((idx === Constants.groupIPC) && (optIdx === reachSchoolBased)) {
            support.IPC[option]['reachSchoolBased'] = this.getIPCReachValue('reachSchoolBased');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('reachSchoolBased');
            this.setState ({
                IPCReachSchoolBasedValue : this.getIPCReachValue('reachSchoolBased'),
                localInterventionsData   : data,
                localInterventionSupport : support
            })
        }
        else if ((idx === Constants.IPCCommEngagement) && (optIdx === reachReligiousLeaders)) {
            support.IPC[option]['reachReligiousLeaders'] = this.getIPCReachValue('reachReligiousLeaders');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('reachReligiousLeaders');
            this.setState ({
                IPCReachReligiousLeadersValue : this.getIPCReachValue('reachReligiousLeaders'),
                localInterventionsData        : data,
                localInterventionSupport      : support
            })
        }
        else if ((idx === Constants.IPCCommEngagement) && (optIdx === reachCommLeaders)) {
            support.IPC[option]['reachCommLeaders'] = this.getIPCReachValue('reachCommLeaders');
            data[this.getInterventionReach()][idx][this.getInterventionEditorCol()] = this.getIPCReachValue('reachCommLeaders');
            this.setState ({
                IPCReachCommLeadersValue : this.getIPCReachValue('reachCommLeaders'),
                localInterventionsData   : data,
                localInterventionSupport : support
            })
        }
    };

    onInterventionSupportApplyBtnClick = () => {
        this.props.onStateChange ({
            interventionsData   : this.state.localInterventionsData,
            interventionSupport : this.state.localInterventionSupport
        },
            this.props.onInterventionSupportApplyBtnClick
        )
    }

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================    

    render() {
        const styles = {
            introStyle1 : {
                fontSize: 14,
                fontWeight: 'bold',
                color: Theme.palette.secondary.main,
                paddingTop: 8,
                paddingBottom: 4,
                paddingLeft: 3
            },
            introStyle : {
                fontSize: 12,
                color: Theme.palette.primary.main,
                paddingBottom: 4,
                paddingLeft: 15
            },
            introStyle2 : {
                fontSize: 12,
                color: Theme.palette.primary.main,
                paddingBottom: 15,
                paddingLeft: 15
            },
            tabContent : {
                marginLeft: -21, 
                paddingBottom: 0
            },
        }
        return (
            <GBDialog
                title={this.getSupportDialogTitle()}
                Theme={Theme}
                minWidth={900}
                onClose={this.props.onInterventionSupportCloseBtnClick}
                content={
                    <div>
                        <div style={styles.introStyle1}>
                            {'This tool is desinged to help you calculate intervention reach.'}
                        </div>
                        <div style={styles.introStyle}>
                            {'1. Select an intervention'}
                        </div>
                        <div style={styles.introStyle}>
                            {'2. Select a calculation option for the intervention'}
                        </div>
                        <div style={styles.introStyle}>
                            {'3. Enter values to reflect your scenario into the green boxes'}
                        </div>
                        <div style={styles.introStyle}>
                            {'4. Repeat steps 1 - 3 for all interventions you would like to use'}
                        </div>
                        <div style={styles.introStyle2}>
                            {this.getInstructionsStep5()}
                        </div>

                        <br />

                        <TTabs2
                            variant='scrollable'
                            tabFontSize={14}
                            tabContentStyle={styles.tabContent}
                            tabTitles={['Mass media', 'Middle media', 'Phone/SMS', 'Interpersonal communication']}
                            tabBackgroundColor={Theme.stepper.backgroundColor}
                            appBarBackgroundColor={Theme.stepper.backgroundColor}
                            tabTitleWeight={'bold'}
                            tabContents={[
                                <MassMediaInterventions 
                                    onStateChange={this.props.onStateChange}
                                    onMassMediaInterventionComboBoxChange={this.onMassMediaInterventionComboBoxChange}
                                    onMassMediaOptionComboBoxChange={this.onMassMediaOptionComboBoxChange}
                                    onMassMediaEditChange={this.onMassMediaEditChange}
                                    interventionSupport={this.state.localInterventionSupport}   
                                    massMediaInterventionIdx={this.state.massMediaInterventionIdx}  
                                    massMediaDirectEntryValue={this.state.massMediaDirectEntryValue}
                                    massMediaReachValue={this.state.massMediaReachValue}
                                    massMediaReachMediaSpotsValue={this.state.massMediaReachMediaSpotsValue}
                                    numWRAValue={this.state.numWRAValue}
                                    intervReachIdx={this.props.intervReachIdx}
                                    finalYearStr={(Constants.startYear + this.props.startYearIdx + 5).toString()}
                                />,

                                <MiddleMediaInterventions 
                                    onStateChange={this.props.onStateChange}
                                    onMiddleMediaInterventionComboBoxChange={this.onMiddleMediaInterventionComboBoxChange}
                                    onMiddleMediaOptionComboBoxChange={this.onMiddleMediaOptionComboBoxChange}
                                    onMiddleMediaEditChange={this.onMiddleMediaEditChange}
                                    interventionSupport={this.state.localInterventionSupport}   
                                    middleMediaInterventionIdx={this.state.middleMediaInterventionIdx}  
                                    middleMediaDirectEntryValue={this.state.middleMediaDirectEntryValue}
                                    middleMediaReachEventValue={this.state.middleMediaReachEventValue}
                                    middleMediaReachCommValue={this.state.middleMediaReachCommValue}
                                    intervReachIdx={this.props.intervReachIdx}
                                    finalYearStr={(Constants.startYear + this.props.startYearIdx + 5).toString()}
                                />,

                                <PhoneSMSInterventions 
                                    onStateChange={this.props.onStateChange}
                                    onPhoneSMSInterventionComboBoxChange={this.onPhoneSMSInterventionComboBoxChange}
                                    onPhoneSMSOptionComboBoxChange={this.onPhoneSMSOptionComboBoxChange}
                                    onPhoneSMSEditChange={this.onPhoneSMSEditChange}
                                    interventionSupport={this.state.localInterventionSupport}   
                                    phoneSMSInterventionIdx={this.state.phoneSMSInterventionIdx}  
                                    phoneSMSDirectEntryValue={this.state.phoneSMSDirectEntryValue}
                                    phoneSMSReachMessagesValue={this.state.phoneSMSReachMessagesValue}
                                    intervReachIdx={this.props.intervReachIdx}
                                    finalYearStr={(Constants.startYear + this.props.startYearIdx + 5).toString()}
                                />,

                                <IPCInterventions
                                    onStateChange={this.props.onStateChange}
                                    onIPCInterventionComboBoxChange={this.onIPCInterventionComboBoxChange}
                                    onIPCOptionComboBoxChange={this.onIPCOptionComboBoxChange}
                                    onIPCEditChange={this.onIPCEditChange}
                                    interventionSupport={this.state.localInterventionSupport}   
                                    IPCInterventionIdx={this.state.IPCInterventionIdx}  
                                    IPCDirectEntryValue={this.state.IPCDirectEntryValue}
                                    IPCReachCHWValue={this.state.IPCReachCHWValue}
                                    IPCReachProvidersValue={this.state.IPCReachProvidersValue}
                                    IPCReachPeerEducationValue={this.state.IPCReachPeerEducationValue}
                                    IPCReachGroupCounsellingValue={this.state.IPCReachGroupCounsellingValue}
                                    IPCReachSchoolBasedValue={this.state.IPCReachSchoolBasedValue}
                                    IPCReachReligiousLeadersValue={this.state.IPCReachReligiousLeadersValue}
                                    IPCReachCommLeadersValue={this.state.IPCReachCommLeadersValue}
                                    intervReachIdx={this.props.intervReachIdx}
                                    finalYearStr={(Constants.startYear + this.props.startYearIdx + 5).toString()}
                                />
                            ]}
                        />
                    </div>
                }
                actions={[
                    <TButton 
                        key={'apply'}
                        caption={"Apply"} 
                        color={"primary"}  
                        onClick={this.onInterventionSupportApplyBtnClick} 
                    />
                ]}
            />
        );
    }
}

export default InterventionSupportDialog;