import React from "react";
import * as PropTypes from "prop-types";
import { copyJsonToClipboard, copyToClipboard, fieldToColumnIndex, toDelimitedText } from "./other/utilities";
import { normalizePackTable } from "./data/packTable";
import UndoHistory from "./data/undo";
import {
    duplicateAcrossRows,
    duplicateDownColumns,
    incrementPrecision,
    interpolate,
    normalize,
    recalculateTotals,
    multiply
} from "./data/transformations";
import CoreGbStdTable from "./CoreGbStdTable";

const isNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

const updateCellValue = (params, packTable) => {
    const columnIndex = fieldToColumnIndex(params.columnId);

    const newValue =
        params.rowIndex < packTable.GBFixedRows || columnIndex < packTable.GBFixedCols
            ? params.newValue
            : params.newValue; // TGP Note: If we are inputting a string into a data cell (and its legal), then we should allow it.  // : parseFloat(params.newValue);

    const oldPercentage = packTable.showPercent[packTable.GBFixedRows + params.rowIndex][columnIndex];
    const newPercentage = params.percentage ? 1 : 0;

    if (params.oldValue === newValue && oldPercentage === newPercentage) {
        return packTable;
    }

    let newData = packTable.tableData.value;

    if (params.oldValue !== newValue) {
        newData = packTable.tableData.value.map(row => row.map(cell => cell));
        newData[packTable.GBFixedRows + params.rowIndex][columnIndex] = newValue;
    }

    let percentages = packTable.showPercent;

    if (oldPercentage !== newPercentage) {
        percentages = packTable.showPercent.map(row => row.map(cell => cell));
        percentages[packTable.GBFixedRows + params.rowIndex][columnIndex] = newPercentage;
    }

    return {
        ...packTable,
        tableData: {
            value: newData
        },
        showPercent: percentages
    };
};

const updateCheckboxStatus = (params, packTable) => {
    const columnIndex = fieldToColumnIndex(params.columnId);

    const newCheckboxStates = packTable.CheckBoxState.map(row => row.map(state => state));

    newCheckboxStates[packTable.GBFixedRows + params.rowIndex][columnIndex] = params.value;

    return {
        ...packTable,
        CheckBoxState: newCheckboxStates
    };
};

class GbStdTable extends React.Component {
    constructor(props) {
        super(props);

        this.undoHistory = new UndoHistory();

        this.state = {
            showThousandsSeparator: true
        };

        this.gridRef = React.createRef();

        this.exportToExcel.bind(this);
    }

    exportToExcel(filename) {
        this.gridRef.current.api.exportDataAsExcel({
            fileName: filename
        });
    }

    componentDidMount() {
        this.undoHistory.push(this.props.packTable);
        //this.props.passRef(this.gridRef)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps);
    }

    render() {
        let originalPackTable = this.props.packTable;
        let packTable = {};

        try {
            if (this.props.autosizeColumns) {
                originalPackTable = {
                    ...originalPackTable,
                    GBColWidths: this.props.packTable.GBColWidths && this.props.packTable.GBColWidths.map(_ => -1)
                };
            }

            packTable = {
                ...normalizePackTable(
                    originalPackTable,
                    this.props.frameworkComponents,
                    this.props.font,
                    this.state.showThousandsSeparator
                ),
                readOnly: this.props.readOnly
            };
        } catch (e) {
            console.error(e);

            let styles = {
                errorBox: {
                    display: "inline-block",
                    width: "300px",
                    height: "300px",
                    margin: "20px",
                    overflow: "auto",
                    background: "#ffd0d0",
                    padding: "20px"
                }
            };

            return (
                <div style={styles.errorBox}>
                    mstID: {originalPackTable.mstID} <br />
                    errorMsg: {e.message} <br />
                </div>
            );
        }

        return (
            <CoreGbStdTable
                packTable={packTable}
                headerBackgroundColor={this.props.headerBackgroundColor}
                banding={this.props.banding}
                theme={this.props.theme}
                subheadingRowBackgroundColor={
                    this.props.subheadingRowBackgroundColor === "transparent"
                        ? undefined
                        : this.props.subheadingRowBackgroundColor
                }
                oddRowBackgroundColor={this.props.oddRowBackgroundColor}
                rounded={this.props.rounded}
                disableUndo={!this.undoHistory.canUndo()}
                disableRedo={!this.undoHistory.canRedo()}
                rowHeight={this.props.rowHeight}
                gridRef={this.gridRef}
                onCellValueChanged={params => {
                    if (isNumeric(params.newValue)) {
                        params.newValue = parseFloat(params.newValue);
                    }

                    const packTable = recalculateTotals(updateCellValue(params, this.props.packTable));

                    this.undoHistory.push(packTable);

                    this.props.onCellValueChanged && this.props.onCellValueChanged(params);
                    this.props.onPackTableChanged(packTable);
                }}
                onCheckboxToggled={params => {
                    const packTable = updateCheckboxStatus(params, this.props.packTable);

                    this.props.onCheckboxToggled && this.props.onCheckboxToggled(params);
                    this.props.onPackTableChanged(packTable);
                }}
                onUndo={() => {
                    this.props.onPackTableChanged(this.undoHistory.undo());
                }}
                onRedo={() => {
                    this.props.onPackTableChanged(this.undoHistory.redo());
                }}
                onCopyAll={() => {
                    copyToClipboard(toDelimitedText(this.props.packTable, "\t"));
                }}
                onIncrementDecimalPlaces={params => {
                    const packTable = incrementPrecision(params, this.props.packTable, params.by);

                    this.props.onPrecisionChanged && this.props.onPrecisionChanged(params);
                    this.props.onPackTableChanged(packTable);
                }}
                onDuplicateAcrossRows={params => {
                    const packTable = duplicateAcrossRows(params, this.props.packTable);

                    this.props.onDuplicateAcrossRows && this.props.onDuplicateAcrossRows(params);
                    this.props.onPackTableChanged(packTable);
                }}
                onDuplicateDownColumns={params => {
                    const packTable = duplicateDownColumns(params, this.props.packTable);

                    this.props.onDuplicateDownColumns && this.props.onDuplicateDownColumns(params);
                    this.props.onPackTableChanged(packTable);
                }}
                onInterpolate={params => {
                    const packTable = interpolate(params, this.props.packTable);

                    this.props.onInterpolate && this.props.onInterpolate(params);
                    this.props.onPackTableChanged(packTable);
                }}
                onNormalize={params => {
                    const packTable = normalize(params, this.props.packTable);

                    this.props.onNormalize && this.props.onNormalize(params);
                    this.props.onPackTableChanged(packTable);
                }}
                onMultiply={params => {
                    this.props.onMultiply(params);
                }}
                onSelectCells={params => {
                    if (typeof this.props.onSelectCells !== "undefined") {
                        this.props.onSelectCells(params);
                    }
                }}
                onSourceRequested={params => {
                    if (!this.props.onSourceRequested) {
                        return;
                    }

                    const normalizedPackTable = normalizePackTable(
                        this.props.packTable,
                        this.props.frameworkComponents,
                        this.props.font,
                        this.state.showThousandsSeparator
                    );

                    let source;
                    let idx;
                    let title;

                    switch (normalizedPackTable.sourceMode) {
                        // Single source for the editor
                        case 0:
                            idx = 0;
                            source = normalizedPackTable.sources[idx];
                            title = this.props.Title;

                            break;

                        // Source by row
                        case 1:
                            idx = normalizedPackTable.sourceMap[params.firstRowIndex];
                            source = normalizedPackTable.sources[idx];
                            title = this.props.packTable.tableData.value[params.firstRowIndex][0];
                            //source = normalizedPackTable.sources[params.firstRowIndex];

                            break;

                        // Source by column
                        case 2:
                            const columnIndex = fieldToColumnIndex(params.columnIds[0]);
                            idx = normalizedPackTable.sourceMap[columnIndex];
                            source = normalizedPackTable.sources[idx];
                            title = this.props.packTable.tableData.value[0][columnIndex];
                            // source = normalizedPackTable.sources[columnIndex];

                            break;

                        default:
                            break;
                    }

                    this.props.onSourceRequested({
                        sourceMode: normalizedPackTable.sourceMode,
                        idx: idx,
                        source: source,
                        editable: this.props.sourceEditable,
                        title: title
                    });
                }}
                onToggleThousandsSeparator={value => {
                    this.setState({
                        showThousandsSeparator: value
                    });
                }}
                onCopyPackTableJsonToClipboard={() => {
                    copyJsonToClipboard(JSON.stringify(this.props.packTable, undefined, 2));
                }}
                style={this.props.style}
                pagination={this.props.pagination}
                paginationPageSize={this.props.paginationPageSize}
                width={this.props.width}
                height={this.props.height}
                onCellClicked={this.props.onCellClicked}
                filterText={this.props.filterText}
                showTitle={this.props.showTitle}
                wrapperClassNames={this.props.wrapperClassNames}
                removedMenuNames={this.props.removedMenuNames}
                customContextMenu={this.props.customContextMenu}
                frameworkComponents={this.props.frameworkComponents}
                captionStyles={this.props.captionStyles}
                disablePopupMenu={this.props.disablePopupMenu}
            />
        );
    }
}

GbStdTable.propTypes = {
    packTable: PropTypes.object.isRequired,
    headerBackgroundColor: PropTypes.string,
    subheadingRowBackgroundColor: PropTypes.string,
    oddRowBackgroundColor: PropTypes.string,
    rounded: PropTypes.bool,
    font: PropTypes.string,
    onPackTableChanged: PropTypes.func.isRequired,
    onCellValueChanged: PropTypes.func,
    onCheckboxToggled: PropTypes.func,
    onPrecisionChanged: PropTypes.func,
    onDuplicateAcrossRows: PropTypes.func,
    onDuplicateDownColumns: PropTypes.func,
    onInterpolate: PropTypes.func,
    onMultiply: PropTypes.func,
    onSourceRequested: PropTypes.func,
    onSelectCells: PropTypes.func,
    style: PropTypes.object,
    pagination: PropTypes.bool,
    paginationPageSize: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onCellClicked: PropTypes.func,
    filterText: PropTypes.string,
    showTitle: PropTypes.bool,
    readOnly: PropTypes.bool,
    wrapperClassNames: PropTypes.string,
    autosizeColumns: PropTypes.bool,
    banding: PropTypes.bool,
    theme:PropTypes.string,
    removedMenuNames: PropTypes.arrayOf(PropTypes.string),
    customContextMenu: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            action: PropTypes.func,
            disabled: PropTypes.bool
        })
    ),
    frameworkComponents: PropTypes.object,
    captionStyles: PropTypes.object,
    disablePopupMenu: PropTypes.bool,
    sourceEditable: PropTypes.bool
};

GbStdTable.defaultProps = {
    rounded: false,
    pagination: false,
    paginationPageSize: 3,
    font: "12px Arial",
    width: "100%",
    height: "auto",
    onCellClicked: () => {},
    filterText: "",
    showTitle: true,
    readOnly: false,
    autosizeColumns: false,
    banding: true,
    theme:"ag-theme-balham",
    // subheadingRowBackgroundColor: "#CCCCCC",
    captionStyles: {},
    onSelectCells: () => {},
    sourceEditable: true
};

export const multiplySelectedPackTableCells = (selectedRange, multiplier, packTable) => {
    const newData = multiply(
        {
            ...selectedRange,
            multiplier
        },
        packTable
    );

    if (newData.error) {
        return packTable;
    } else {
        return {
            ...packTable,
            tableData: {
                value: newData.data
            }
        };
    }
};

export default GbStdTable;
