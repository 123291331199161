import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import {addRowToPCFromPT, generatePackChart, getNewPC} from "../../../utilities/GBUtil"

const Create = (props, itemIndex) => {
    let editorName = 'Cost per maternal DALY averted';

    let packTable = Setup();
    packTable = SetSSData(packTable, props, itemIndex);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true, [], [
        '#F07B2F',
        '#6DBD52',
        '#66FFFF',
        '#1474F7',
        '#1AA8DF',
    ]);

    packChart.showOrangeLine = packTable.tableData.value[5][1] > packTable.tableData.value[4][1];

    return ([packTable, packChart]);
};

const Setup = () => {
    let pt = createSimpleDefaultPackTable(6, 2, true);

    pt.tableData.value[0][1] = 'Cost per Maternal DALY Averted';

    pt.tableData.value[1][0] = 'High';
    pt.tableData.value[2][0] = 'Median costs';
    pt.tableData.value[3][0] = 'Low';
    pt.tableData.value[4][0] = 'Threshold for "highly cost-effective" (1 GDP per capita)';
    pt.tableData.value[5][0] = 'Threshold for "cost-effective" (3 GDP per capita)';

    pt.GBColWidths[1] = 130;

    return pt;
};

const SetSSData = (pt, props, itemIndex) => {
    const {selectedCountry, modelParametersData, resultsData} = props;

    let countryData = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === selectedCountry) {
            countryData = modelParametersData["externalUser"][i];
        }
    }

    let val = 0;

    switch(itemIndex){
        case 0 : val = countryData["National GDP per capita"]; break;
        case 1 : val = countryData["Regional GDP per Capita"]; break;
    }

    pt.tableData.value[1][1] = Math.round(resultsData["costEffData"]["cost per maternal DALY averted"][2]);
    pt.tableData.value[2][1] = Math.round(resultsData["costEffData"]["cost per maternal DALY averted"][1]);
    pt.tableData.value[3][1] = Math.round(resultsData["costEffData"]["cost per maternal DALY averted"][0]);
    pt.tableData.value[4][1] = Math.round(val);
    pt.tableData.value[5][1] = Math.round(val * 3);

    return pt;
};

export const CostPerMatDALY = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};