import React, { useEffect, useState, useRef } from "react";
import PageError from "./PageError";

const Page = (props) => {
    const { style, children, pageID, innerStyle, classes, noPadding = false } = props;   
    const [isLoading] = useState(true);

    let resultsRef = useRef();

    useEffect(
        () => {
            if (resultsRef.current) {
                window.scrollTo({
                    top: 0
                });
            }
        },
        [isLoading]
    );

    const styles = {
        page : {
            position: 'absolute', 
            top: 0,
            bottom: 0, 
            left: 0, 
            right: 0, 
            // color: '#eeeeee', 
            transition:  'all 0.1s linear',
            overflow : 'auto', 
            // background: "#eeeeee",
            ...style
        },
        stars: {
            zIndex: 9
        },
        inner : {
            padding: noPadding ? 0 : '24px',
            paddingTop: 0,
            ...innerStyle
        }
    };

    return (
        <React.Fragment>
            <div id="Page" className={classes} style={styles.page} ref={resultsRef} key={pageID}>
                <div id="PageInner" style={styles.inner}>
                    <PageError>
                        {children}
                    </PageError>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Page;