import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import { addMergedCellsToArray, indentPackTable } from "../../@common/components/GBStdTableAG/other/utilities";
import * as Constants from "../../utilities/Constants";

const Create = (countryName, startYearIdx, modelParametersData) => {

    let editorName = 'Impact and cost-effectiveness';
    let packTable = Setup(startYearIdx, );
    packTable = SetSSData(packTable, countryName, startYearIdx, modelParametersData);
    packTable = RecalcGrid(packTable, countryName, startYearIdx, modelParametersData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = (startYearIdx, ) => {
    let numRows = 25;
    let numCols = 3;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][1] = 'Value';
    pt.tableData.value[0][2] = 'Default source';

    pt.tableData.value[1][0] = 'Typical user failure rates';
    pt.FontStyles[1][0]["intArray"] = [Constants.Bold];
    pt.LockedCells[1][1] = true;
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 1, 0, 1, 3);

    pt.tableData.value[2][0] = 'Sterilization';
    pt.tableData.value[3][0] = 'IUD';
    pt.tableData.value[4][0] = 'Implants';
    pt.tableData.value[5][0] = 'Injection';
    pt.tableData.value[6][0] = 'Pill';
    pt.tableData.value[7][0] = 'Condoms';
    pt.tableData.value[8][0] = 'LAM';
    pt.tableData.value[9][0] = 'Other';

    for (let r1 = 2; r1 <= 9; r1++) {
        pt = indentPackTable(pt, r1, true, Constants.ED_Indent1);
        pt.RDec[r1][1] = 2;
        pt.MaxAllowedVal[r1][1] = 100;
    }

    pt.tableData.value[10][0] = 'Comparison Pregnancy Rate (if not using)';
    pt.FontStyles[10][0]["intArray"] = [Constants.Bold];
    pt.MaxAllowedVal[10][1] = 100;

    pt.tableData.value[11][0] = 'Maternal deaths per unintended pregnancy';
    pt.FontStyles[11][0]["intArray"] = [Constants.Bold];
    pt.LockedCells[11][1] = true;
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 11, 0, 1, 3);

    for (let r2 = 12; r2 <= 16; r2++) {
        let yearStr = "Year: " + (startYearIdx + Constants.startYear + r2 - 11).toString();
        pt.tableData.value[r2][0] = yearStr;
        pt = indentPackTable(pt, r2, true, Constants.ED_Indent1);
        pt.RDec[r2][1] = 3;
    }

    pt.tableData.value[17][0] = 'Average age at maternal death';
    pt.FontStyles[17][0]["intArray"] = [Constants.Bold];
    pt.RDec[17][1] = 2;
    pt.MaxAllowedVal[17][1] = 60;

    pt.tableData.value[18][0] = 'YLD per YLL';
    pt.FontStyles[18][0]["intArray"] = [Constants.Bold];
    pt.RDec[18][1] = 2;
    pt.MaxAllowedVal[18][1] = 100;

    pt.tableData.value[19][0] = 'Life expectancy for DALY calculations';
    pt.FontStyles[19][0]["intArray"] = [Constants.Bold];
    pt.RDec[19][1] = 0;
    pt.MaxAllowedVal[19][1] = 110;

    pt.tableData.value[20][0] = 'DALY discount rate';
    pt.FontStyles[20][0]["intArray"] = [Constants.Bold];
    pt.RDec[20][1] = 2;
    pt.MaxAllowedVal[20][1] = 6;

    pt.tableData.value[21][0] = 'Cost discount rate';
    pt.FontStyles[21][0]["intArray"] = [Constants.Bold];
    pt.RDec[21][1] = 2;
    pt.MaxAllowedVal[21][1] = 6;

    pt.tableData.value[22][0] = 'GDP per capita for benchmarking';
    pt.FontStyles[22][0]["intArray"] = [Constants.Bold];
    pt.LockedCells[22][1] = true;
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 22, 0, 1, 3);

    pt.tableData.value[23][0] = 'National GDP per capita';
    pt.tableData.value[24][0] = 'Regional GDP per capita';

    for (let r3 = 23; r3 <= 24; r3++) {
        pt = indentPackTable(pt, r3, true, Constants.ED_Indent1);
        pt.RDec[r3][1] = 2;
        pt.MaxAllowedVal[r3][1] = 999999999;
    }

    for (let row = 1; row < numRows; row++) {
        pt.Alignments[row][1] = Constants.Right;
        pt.MinAllowedVal[row][1] = 0;
        // pt.LockedCells[row][2] = true;
    }

    pt.GBColWidths[1] = 70;

    return pt;
};

const SetSSData = (pt, countryName, startYearIdx, modelParametersData) => {
    let countryData = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            countryData = modelParametersData["externalUser"][i];
        }
    }

    let countryDataDALYs = {};
    for (let i = 0; i < modelParametersData["dataInputsDALYsUser"].length; i++) {
        if (modelParametersData["dataInputsDALYsUser"][i]["COUNTRY"] === countryName) {
            countryDataDALYs = modelParametersData["dataInputsDALYsUser"][i];
        }
    }

    let years = [];
    
    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }
   
    let GPD;
    for (let c = 0; c < modelParametersData["internalUser"].length; c++) {
        if (modelParametersData["internalUser"][c]["COUNTRY"] === countryName) {
            GPD = modelParametersData["internalUser"][c]["GDP Per Capital Region"];
        }
    }

    pt.tableData.value[2][1] = countryData["Failure Rates Sterilization"];
    pt.tableData.value[2][2] = countryData["Failure Rates Sterilization Source"];
    pt.tableData.value[3][1] = countryData["Failure Rates IUD"];
    pt.tableData.value[3][2] = countryData["Failure Rates IUD Source"];
    pt.tableData.value[4][1] = countryData["Failure Rates Implants"];
    pt.tableData.value[4][2] = countryData["Failure Rates Implants Source"];
    pt.tableData.value[5][1] = countryData["Failure Rates Injection"];
    pt.tableData.value[5][2] = countryData["Failure Rates Injection Source"];
    pt.tableData.value[6][1] = countryData["Failure Rates Pill"];
    pt.tableData.value[6][2] = countryData["Failure Rates Pill Source"];
    pt.tableData.value[7][1] = countryData["Failure Rates Condoms"];
    pt.tableData.value[7][2] = countryData["Failure Rates Condoms Source"];
    pt.tableData.value[8][1] = countryData["Failure Rates LAM"];
    pt.tableData.value[8][2] = countryData["Failure Rates LAM Source"];
    pt.tableData.value[9][1] = countryData["Failure Rates Other"];
    pt.tableData.value[9][2] = countryData["Failure Rates Other Source"];

    pt.tableData.value[10][1] = countryData["Comparison Pregnancy Rate"];
    pt.tableData.value[10][2] = countryData["Comparison Pregnancy Rate Source"];

    pt.tableData.value[12][1] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[1]];
    pt.tableData.value[12][2] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[1] + ' Source'];
    pt.tableData.value[13][1] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[2]];
    pt.tableData.value[13][2] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[2] + ' Source'];
    pt.tableData.value[14][1] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[3]];
    pt.tableData.value[14][2] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[3] + ' Source'];
    pt.tableData.value[15][1] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[4]];
    pt.tableData.value[15][2] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[4] + ' Source'];
    pt.tableData.value[16][1] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[5]];
    pt.tableData.value[16][2] = countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[5] + ' Source'];

    pt.tableData.value[17][1] = countryDataDALYs["Avg age maternal death"];
    pt.tableData.value[17][2] = countryDataDALYs["Avg age maternal death Source"];

    pt.tableData.value[18][1] = countryDataDALYs["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016"];
    pt.tableData.value[18][2] = countryDataDALYs["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016 Source"];

    pt.tableData.value[19][1] = countryData["Life expectancy for DALY calculations"];
    pt.tableData.value[19][2] = countryData["Life expectancy for DALY calculations Source"];

    pt.tableData.value[20][1] = countryData["DALY Discount Rate"];
    pt.tableData.value[20][2] = countryData["DALY Discount Rate Source"];

    pt.tableData.value[21][1] = countryData["Cost Discount Rate"];
    pt.tableData.value[21][2] = countryData["Cost Discount Rate Source"];
    
    pt.tableData.value[23][1] = countryData["National GDP per capita"];
    pt.tableData.value[23][2] = countryData["National GDP per capita Source"];
    pt.tableData.value[24][1] = countryData["Regional GDP per Capita"];
    pt.tableData.value[24][2] = GPD;

    return pt;
};

const GetSSData = (pt, countryName, startYearIdx, modelParametersData) => {
    let country;
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            country = i;
        }
    }

    let years = [];
    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }

    modelParametersData["externalUser"][country]["Failure Rates Sterilization"] = pt.tableData.value[2][1];
    modelParametersData["externalUser"][country]["Failure Rates IUD"] = pt.tableData.value[3][1];
    modelParametersData["externalUser"][country]["Failure Rates Implants"] = pt.tableData.value[4][1];
    modelParametersData["externalUser"][country]["Failure Rates Injection"] = pt.tableData.value[5][1];
    modelParametersData["externalUser"][country]["Failure Rates Pill"] = pt.tableData.value[6][1];
    modelParametersData["externalUser"][country]["Failure Rates Condoms"] = pt.tableData.value[7][1];
    modelParametersData["externalUser"][country]["Failure Rates LAM"] = pt.tableData.value[8][1];
    modelParametersData["externalUser"][country]["Failure Rates Other"] = pt.tableData.value[9][1] ;
    modelParametersData["externalUser"][country]["Comparison Pregnancy Rate"] = pt.tableData.value[10][1];

    modelParametersData["externalUser"][country]["Failure Rates Sterilization Source"] = pt.tableData.value[2][2];
    modelParametersData["externalUser"][country]["Failure Rates IUD Source"] = pt.tableData.value[3][2];
    modelParametersData["externalUser"][country]["Failure Rates Implants Source"] = pt.tableData.value[4][2];
    modelParametersData["externalUser"][country]["Failure Rates Injection Source"] = pt.tableData.value[5][2];
    modelParametersData["externalUser"][country]["Failure Rates Pill Source"] = pt.tableData.value[6][2];
    modelParametersData["externalUser"][country]["Failure Rates Condoms Source"] = pt.tableData.value[7][2];
    modelParametersData["externalUser"][country]["Failure Rates LAM Source"] = pt.tableData.value[8][2];
    modelParametersData["externalUser"][country]["Failure Rates Other Source"] = pt.tableData.value[9][2] ;
    modelParametersData["externalUser"][country]["Comparison Pregnancy Rate Source"] = pt.tableData.value[10][2];

    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[1]] = pt.tableData.value[12][1];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[2]] = pt.tableData.value[13][1];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[3]] = pt.tableData.value[14][1];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[4]] = pt.tableData.value[15][1];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[5]] = pt.tableData.value[16][1];
    modelParametersData["dataInputsDALYsUser"][country]["Avg age maternal death"] = pt.tableData.value[17][1];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016"] = pt.tableData.value[18][1];

    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[1] + ' Source'] = pt.tableData.value[12][2];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[2] + ' Source'] = pt.tableData.value[13][2];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[3] + ' Source'] = pt.tableData.value[14][2];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[4] + ' Source'] = pt.tableData.value[15][2];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal Deaths Averted Per Preg Averted " + years[5] + ' Source'] = pt.tableData.value[16][2];
    modelParametersData["dataInputsDALYsUser"][country]["Avg age maternal death Source"] = pt.tableData.value[17][2];
    modelParametersData["dataInputsDALYsUser"][country]["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016 Source"] = pt.tableData.value[18][2];

    modelParametersData["externalUser"][country]["Life expectancy for DALY calculations"] = pt.tableData.value[19][1];
    modelParametersData["externalUser"][country]["DALY Discount Rate"] = pt.tableData.value[20][1];
    modelParametersData["externalUser"][country]["Cost Discount Rate"] = pt.tableData.value[21][1];
    modelParametersData["externalUser"][country]["National GDP per capita"] = pt.tableData.value[23][1];
    modelParametersData["externalUser"][country]["Regional GDP per Capita"] = pt.tableData.value[24][1];

    modelParametersData["externalUser"][country]["Life expectancy for DALY calculations Source"] = pt.tableData.value[19][2];
    modelParametersData["externalUser"][country]["DALY Discount Rate Source"] = pt.tableData.value[20][2];
    modelParametersData["externalUser"][country]["Cost Discount Rate Source"] = pt.tableData.value[21][2];
    modelParametersData["externalUser"][country]["National GDP per capita Source"] = pt.tableData.value[23][2];
    modelParametersData["internalUser"][country]["GDP Per Capital Region"] = pt.tableData.value[24][2];

    return (modelParametersData)
};

const RecalcGrid = (pt, countryName, startYearIdx, modelParametersData) => {
    let countryDataUser = {};
    let countryDataDefault = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            countryDataUser = modelParametersData["externalUser"][i];
            countryDataDefault = modelParametersData["externalDefault"][i];
        }
    }

    let countryDataDALYsUser = {};
    let countryDataDALYsDefault = {};
    for (let i = 0; i < modelParametersData["dataInputsDALYsUser"].length; i++) {
        if (modelParametersData["dataInputsDALYsUser"][i]["COUNTRY"] === countryName) {
            countryDataDALYsUser = modelParametersData["dataInputsDALYsUser"][i];
            countryDataDALYsDefault = modelParametersData["dataInputsDALYsDefault"][i];
        }
    }

    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }

    let GPDUser;
    let GPDDefault;
    for (let c = 0; c < modelParametersData["internalUser"].length; c++) {
        if (modelParametersData["internalUser"][c]["COUNTRY"] === countryName) {
            GPDUser = modelParametersData["internalUser"][c]["GDP Per Capital Region"];
            GPDDefault = modelParametersData["internalDefault"][c]["GDP Per Capital Region"];
        }
    }

    let defColor = 0;
    let changedColor = 6930250;

    pt.FontColors[2][1] = countryDataUser["Failure Rates Sterilization"] === countryDataDefault["Failure Rates Sterilization"] ? defColor : changedColor;
    pt.FontColors[2][2] = countryDataUser["Failure Rates Sterilization Source"] === countryDataDefault["Failure Rates Sterilization Source"]  ? defColor : changedColor;
    pt.FontColors[3][1] = countryDataUser["Failure Rates IUD"] === countryDataDefault["Failure Rates IUD"]  ? defColor : changedColor;
    pt.FontColors[3][2] = countryDataUser["Failure Rates IUD Source"] === countryDataDefault["Failure Rates IUD Source"]  ? defColor : changedColor;
    pt.FontColors[4][1] = countryDataUser["Failure Rates Implants"] === countryDataDefault["Failure Rates Implants"]  ? defColor : changedColor;
    pt.FontColors[4][2] = countryDataUser["Failure Rates Implants Source"] === countryDataDefault["Failure Rates Implants Source"]  ? defColor : changedColor;
    pt.FontColors[5][1] = countryDataUser["Failure Rates Injection"] === countryDataDefault["Failure Rates Injection"]  ? defColor : changedColor;
    pt.FontColors[5][2] = countryDataUser["Failure Rates Injection Source"] === countryDataDefault["Failure Rates Injection Source"]  ? defColor : changedColor;
    pt.FontColors[6][1] = countryDataUser["Failure Rates Pill"] === countryDataDefault["Failure Rates Pill"]  ? defColor : changedColor;
    pt.FontColors[6][2] = countryDataUser["Failure Rates Pill Source"] === countryDataDefault["Failure Rates Pill Source"]  ? defColor : changedColor;
    pt.FontColors[7][1] = countryDataUser["Failure Rates Condoms"] === countryDataDefault["Failure Rates Condoms"]  ? defColor : changedColor;
    pt.FontColors[7][2] = countryDataUser["Failure Rates Condoms Source"] === countryDataDefault["Failure Rates Condoms Source"]  ? defColor : changedColor;
    pt.FontColors[8][1] = countryDataUser["Failure Rates LAM"] === countryDataDefault["Failure Rates LAM"]  ? defColor : changedColor;
    pt.FontColors[8][2] = countryDataUser["Failure Rates LAM Source"] === countryDataDefault["Failure Rates LAM Source"]  ? defColor : changedColor;
    pt.FontColors[9][1] = countryDataUser["Failure Rates Other"] === countryDataDefault["Failure Rates Other"]  ? defColor : changedColor;
    pt.FontColors[9][2] = countryDataUser["Failure Rates Other Source"] === countryDataDefault["Failure Rates Other Source"]  ? defColor : changedColor;

    pt.FontColors[10][1] = countryDataUser["Comparison Pregnancy Rate"] === countryDataDefault["Comparison Pregnancy Rate"]  ? defColor : changedColor;
    pt.FontColors[10][2] = countryDataUser["Comparison Pregnancy Rate Source"] === countryDataDefault["Comparison Pregnancy Rate Source"]  ? defColor : changedColor;

    pt.FontColors[12][1] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[1]] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[1]]  ? defColor : changedColor;
    pt.FontColors[12][2] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[1] + ' Source'] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[1] + ' Source']  ? defColor : changedColor;
    pt.FontColors[13][1] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[2]] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[2]]  ? defColor : changedColor;
    pt.FontColors[13][2] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[2] + ' Source'] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[2] + ' Source']  ? defColor : changedColor;
    pt.FontColors[14][1] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[3]] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[3]]  ? defColor : changedColor;
    pt.FontColors[14][2] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[3] + ' Source'] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[3] + ' Source']  ? defColor : changedColor;
    pt.FontColors[15][1] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[4]] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[4]]  ? defColor : changedColor;
    pt.FontColors[15][2] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[4] + ' Source'] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[4] + ' Source']  ? defColor : changedColor;
    pt.FontColors[16][1] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[5]] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[5]]  ? defColor : changedColor;
    pt.FontColors[16][2] = countryDataDALYsUser["Maternal Deaths Averted Per Preg Averted " + years[5] + ' Source'] === countryDataDALYsDefault["Maternal Deaths Averted Per Preg Averted " + years[5] + ' Source']  ? defColor : changedColor;

    pt.FontColors[17][1] = countryDataDALYsUser["Avg age maternal death"] === countryDataDALYsDefault["Avg age maternal death"]  ? defColor : changedColor;
    pt.FontColors[17][2] = countryDataDALYsUser["Avg age maternal death Source"] === countryDataDALYsDefault["Avg age maternal death Source"]  ? defColor : changedColor;

    pt.FontColors[18][1] = countryDataDALYsUser["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016"] === countryDataDALYsDefault["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016"]  ? defColor : changedColor;
    pt.FontColors[18][2] = countryDataDALYsUser["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016 Source"] === countryDataDALYsDefault["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016 Source"]  ? defColor : changedColor;

    pt.FontColors[19][1] = countryDataUser["Life expectancy for DALY calculations"] === countryDataDefault["Life expectancy for DALY calculations"]  ? defColor : changedColor;
    pt.FontColors[19][2] = countryDataUser["Life expectancy for DALY calculations Source"] === countryDataDefault["Life expectancy for DALY calculations Source"]  ? defColor : changedColor;

    pt.FontColors[20][1] = countryDataUser["DALY Discount Rate"] === countryDataDefault["DALY Discount Rate"]  ? defColor : changedColor;
    pt.FontColors[20][2] = countryDataUser["DALY Discount Rate Source"] === countryDataDefault["DALY Discount Rate Source"]  ? defColor : changedColor;

    pt.FontColors[21][1] = countryDataUser["Cost Discount Rate"] === countryDataDefault["Cost Discount Rate"]  ? defColor : changedColor;
    pt.FontColors[21][2] = countryDataUser["Cost Discount Rate Source"] === countryDataDefault["Cost Discount Rate Source"]  ? defColor : changedColor;

    pt.FontColors[23][1] = countryDataUser["National GDP per capita"] === countryDataDefault["National GDP per capita"]  ? defColor : changedColor;
    pt.FontColors[23][2] = countryDataUser["National GDP per capita Source"] === countryDataDefault["National GDP per capita Source"]  ? defColor : changedColor;
    pt.FontColors[24][1] = countryDataUser["Regional GDP per Capita"] === countryDataDefault["Regional GDP per Capita"]  ? defColor : changedColor;
    pt.FontColors[24][2] = GPDUser === GPDDefault ? defColor : changedColor;

    return (pt);
};

const UpdateValues = (modelParametersData, field1, field2, index, field3) => {
    modelParametersData[field1][index][field3] = modelParametersData[field2][index][field3];
    return modelParametersData;
};

const RestoreDefaultValues = (countryName, startYearIdx, modelParams) => {
    let i1 = -1;
    for (let i = 0; i < modelParams["externalUser"].length; i++) {
        if (modelParams["externalUser"][i]["COUNTRY"] === countryName) {
            i1 = i;
        }
    }

    let i2 = -1;
    for (let i = 0; i < modelParams["dataInputsDALYsUser"].length; i++) {
        if (modelParams["dataInputsDALYsUser"][i]["COUNTRY"] === countryName) {
            i2 = i;
        }
    }

    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }

    let i3;
    for (let c = 0; c < modelParams["internalUser"].length; c++) {
        if (modelParams["internalUser"][c]["COUNTRY"] === countryName) {
            i3 = c;
        }
    }

    let index = i1;
    let field1 = 'externalUser';
    let field2 = 'externalDefault';

    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Sterilization");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Sterilization Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates IUD");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates IUD Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Implants");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Implants Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Injection");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Injection Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Pill");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Pill Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Condoms");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Condoms Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates LAM");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates LAM Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Other");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Failure Rates Other Source");

    modelParams = UpdateValues(modelParams, field1, field2, index, "Comparison Pregnancy Rate");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Comparison Pregnancy Rate Source");

    index = i2;
    field1 = 'dataInputsDALYsUser';
    field2 = 'dataInputsDALYsDefault';

    let field3 = "Maternal Deaths Averted Per Preg Averted ";

    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[1]);
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[1] + ' Source');
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[2]);
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[2] + ' Source');
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[3]);
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[3] + ' Source');
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[4]);
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[4] + ' Source');
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[5]);
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + years[5] + ' Source');

    modelParams = UpdateValues(modelParams, field1, field2, index, "Avg age maternal death");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Avg age maternal death Source");

    field3 = "Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016";

    modelParams = UpdateValues(modelParams, field1, field2, index, field3);
    modelParams = UpdateValues(modelParams, field1, field2, index, field3 + " Source");

    index = i1;
    field1 = 'externalUser';
    field2 = 'externalDefault';

    modelParams = UpdateValues(modelParams, field1, field2, index, "Life expectancy for DALY calculations");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Life expectancy for DALY calculations Source");

    modelParams = UpdateValues(modelParams, field1, field2, index, "DALY Discount Rate");
    modelParams = UpdateValues(modelParams, field1, field2, index, "DALY Discount Rate Source");

    modelParams = UpdateValues(modelParams, field1, field2, index, "Cost Discount Rate");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Cost Discount Rate Source");

    modelParams = UpdateValues(modelParams, field1, field2, index, "National GDP per capita");
    modelParams = UpdateValues(modelParams, field1, field2, index, "National GDP per capita Source");
    modelParams = UpdateValues(modelParams, field1, field2, index, "Regional GDP per Capita");

    index = i3;
    field1 = 'internalUser';
    field2 = 'internalDefault';

    modelParams = UpdateValues(modelParams, field1, field2, index, "GDP Per Capital Region");

    return modelParams;
};

export const ImpactAndCostEffectivenessEditor = {
    Create               : Create,
    Setup                : Setup,
    SetSSData            : SetSSData,
    GetSSData            : GetSSData,
    RecalcGrid           : RecalcGrid,
    RestoreDefaultValues : RestoreDefaultValues,
};