import * as Constants from "../../../utilities/Constants";
import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import { getIntermediateDeterminantName } from "../../../utilities/GBUtil"
import { generatePackChart } from "../../../utilities/GBUtil"

const Create = (resultsData) => {
    let editorName = 'Relative contribution of changes to intermediate outcomes to increases in mCPR';

    let packTable = Setup();
    packTable = SetSSData(packTable, resultsData);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true, [],
        [
            '#0F779F',
            '#138AB8',
            '#179ACC',
            '#1AA8DF',
            '#75BBE5',
            '#A1CCEB',
            '#C0DBF0'
        ]);
    return ([packTable, packChart]);
};

const Setup = () => {
    let pt = createSimpleDefaultPackTable(Constants.Direct_To_mCPR + 2, 2, true);

    let r = 1;
    for (let i = Constants.Direct_To_mCPR; i >= Constants.FirstInterDeter; i--) {
        pt.tableData.value[r][0] = getIntermediateDeterminantName(i);
        if (i === Constants.Direct_To_mCPR){
            pt.tableData.value[r][0] = 'Other*';
        }
        r++;
    }

    r = 1;
    for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
        pt.RDec[r][1] = 2;
        r++;
    }

    return pt;
};

const SetSSData = (pt, resultsData) => {
   let r = 1;
   for (let i = Constants.Direct_To_mCPR; i >= Constants.FirstInterDeter; i--) {
       pt.tableData.value[r][1] = 0;
       for (let a = Constants.First_Agg_Cat; a <= Constants.Final_Agg_Cat; a++) {
           pt.tableData.value[r][1] += (resultsData["impactData"]["RelContrOfSBCIntervToIncIn_mCPR"][i][a] * 100);
       }
       r++;
   }

   return pt;
};

export const RelContrOfChangeToInterOutToIncIn_mCPR = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};