import React from 'react';
import NumberFormat from "react-number-format";
import * as Constants from "./Constants";

export const clone = obj => {
    return JSON.parse(JSON.stringify(obj));
};


export const fnAddCommas = (str) => {
    if (str === null) {
        return null;
    }

    else if (typeof str !== 'undefined') {
        var parts = str.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
};

export const removeCommas = (str) => {
    return Number(str.toString().replace(/,/g, ''));
};

export const getMassMediaInterventionName = (idx) => {
    let intervention = '';
    switch (idx) {
        case 0 : intervention = 'radio'; break;
        case 1 : intervention = 'TV'; break;
        case 2 : intervention = 'newspaper'; break;
        case 3 : intervention = 'mixedMassMedia'; break;
        default : intervention = ''; break;
    }
    return intervention
};

export const getMiddleMediaInterventionName = (idx) => {
    let intervention = '';
    switch (idx) {
        case 0 : intervention = 'billboards'; break;
        case 1 : intervention = 'liveDrama'; break;
        case 2 : intervention = 'commAnnouncements'; break;
        default : intervention = ''; break;
    }
    return intervention
};

export const getPhoneSMSInterventionName = (idx) => {
    let intervention = '';
    switch (idx) {
        case 0 : intervention = 'SMSTextMessage'; break;
        default : intervention = ''; break;
    }
    return intervention
};

export const getIPCInterventionName = (idx) => {
    let intervention = '';
    switch (idx) {
        case 0 : intervention = 'householdIPC'; break;
        case 1 : intervention = 'groupIPC'; break;
        case 2 : intervention = 'IPCCommEngagement'; break;
        default : intervention = ''; break;
    }
    return intervention
};

export const getAggregateCatName = (idx) => {
    let result = '';
    switch (idx) {
        case Constants.Mass_Media  : result = 'Mass Media'; break;
        case Constants.MiddleMedia : result = 'Middle Media'; break;
        case Constants.PhoneSMS    : result = 'Phone/SMS'; break;
        case Constants.IPC         : result = 'IPC'; break;
        default : result = ''; break;
    }
    return result
};

export const getIntermediateDeterminantName = (idx) => {
    let result = '';
    switch (idx) {
        case Constants.FP_Approval        : result = 'FP Approval'; break;
        case Constants.PercepOfBenefit    : result = 'Perception of Benefits'; break;
        case Constants.Self_Efficacy      : result = 'Self-efficacy'; break;
        case Constants.MensAttAndSupp     : result = "Men's attitude and support"; break;
        case Constants.CommunWithPart     : result = 'Communication with partner'; break;
        case Constants.CommunWithOthers   : result = 'Communication with others'; break;
        case Constants.Direct_To_mCPR     : result = 'Direct to mCPR'; break;
        case Constants.Ideal_Num_Children : result = 'Ideal # Children'; break;
        default : result = ''; break;
    }
    return result
};

export const get_SBC_Index = (iv) => {
    let result;
    switch (iv) {
        case Constants.radio              : result = Constants.MassMedia; break;
        case Constants.TV                 : result = Constants.MassMedia; break;
        case Constants.newspaper          : result = Constants.MassMedia; break;
        case Constants.mixedMassMedia     : result = Constants.MassMedia; break;
        case Constants.billboards         : result = Constants.MassMedia; break;
        case Constants.liveDrama          : result = Constants.MassMedia; break;
        case Constants.commAnnouncements  : result = Constants.MassMedia; break;
        case Constants.SMSTextMessage     : result = Constants.MobileSMS; break;
        case Constants.householdIPC       : result = Constants.IndividualIPC; break;
        case Constants.groupIPC           : result = Constants.GroupIPC; break;
        case Constants.IPCCommEngagement  : result = Constants.IPCPlusCommunEngage; break;
        default : break;
    }
    return result;
};

export const get_Agg_Cat_Index = (iv) => {
    let result;
    switch (iv) {
        case Constants.radio              : result = Constants.Mass_Media; break;
        case Constants.TV                 : result = Constants.Mass_Media; break;
        case Constants.newspaper          : result = Constants.Mass_Media; break;
        case Constants.mixedMassMedia     : result = Constants.Mass_Media; break;
        case Constants.billboards         : result = Constants.MiddleMedia; break;
        case Constants.liveDrama          : result = Constants.MiddleMedia; break;
        case Constants.commAnnouncements  : result = Constants.MiddleMedia; break;
        case Constants.SMSTextMessage     : result = Constants.PhoneSMS; break;
        case Constants.householdIPC       : result = Constants.IPC; break;
        case Constants.groupIPC           : result = Constants.IPC; break;
        case Constants.IPCCommEngagement  : result = Constants.IPC; break;
        default : break
    }
    return result;
};

export const get_Direct_Serv_Index_From_Meth_Mix = (m) => {
    let result = -1;
    switch (m) {
        case Constants.MethMix_Sterilization : result = Constants.Sterilization; break;
        case Constants.MethMix_IUD           : result = Constants.IUD; break;
        case Constants.MethMix_Implants      : result = Constants.Implants; break;
        case Constants.MethMix_Injection     : result = Constants.Injection; break;
        case Constants.MethMix_Pill          : result = Constants.Pill; break;
        case Constants.MethMix_Condoms       : result = Constants.Condoms; break;
        case Constants.MethMix_Other         : result = Constants.Other; break;
        default : break
    }
    return result;
};

export const getQuotient = (dividend, divisor) =>{
    let result = dividend;
    if (divisor !== 0){
        result /= divisor;
    }
    return (result);
};

export const roundToSigFigure = (num, numDigits) => {
    for (let i = 0; i < numDigits; i++){
        num /= 10;
    }

    num = Math.round(num);

    for (let i = 0; i < numDigits; i++){
        num *= 10;
    }

    return num;
};

export const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator={true}
        decimalScale={0}
      />
    );
  };

export const CloneObj = (obj) => {
    try {
        return JSON.parse(JSON.stringify(obj));
    }
    catch {
        return obj;
    }
};

export const getNewPC = () => {
    return CloneObj(Constants.BASIC_ResultPackChart)
};

export const addRowToPCFromPT = (noShowRowSet, row, pt, pc, header)=>{
    if(!noShowRowSet.includes(row)){
        let rowMerged = false;

        for(let i = 0; i < pt.MergedCells.length; i++){
            rowMerged = rowMerged || (pt.MergedCells[i].startRow === row);
        }

        if (!rowMerged) {
            if ((pt.tableData.value.length === (pt.GBFixedRows + 1)) && pt.tableData.value[row][0] === ''){
                pc.subsetLabels.push(pt.title);
            }
            else if (pt.useSubHeadersInChartLbls){
                if (pt.useHeadersInChartLbls) {
                    pc.subsetLabels.push(header + ' - ' + pt.tableData.value[row][0]);
                }
                else{
                    pc.subsetLabels.push(pt.tableData.value[row][0]);
                }
            }
            else{
                pc.subsetLabels.push(header);
            }

            let subsetData = [];
            for (let col = 1; col < pt.tableData.value[row].length; col++) {
                subsetData.push(pt.tableData.value[row][col]);
            }
            pc.chartData.push(subsetData);
        }
        else{
            header = pt.tableData.value[row][0];
        }
    }
    return ([pc, header]);
};

export const generatePackChart = (pt, rDec, reverseRows, noShowRowSet = [], colorSet = []) => {
    let pc = getNewPC();

    pc.title = pt.Title;
    pc.rDec = rDec;

    pc.hasData = pt.hasData;
    pc.hasDeselectedIntervs = pt.hasDeselectedIntervs;

    for (let c = 1; c < pt.tableData.value[0].length; c++) {
        pc.pointLabels.push(pt.tableData.value[0][c]);
    }

    let header = '';

    if (reverseRows){
        for (let row = pt.tableData.value.length-1; row >= pt.GBFixedRows; row--){
            let rowResult = addRowToPCFromPT(noShowRowSet, row, pt, pc, header);
            pc = rowResult[0];
            header = rowResult[1];
        }
    }
    else
    {
        for (let row = pt.GBFixedRows; row < pt.tableData.value.length; row++ ){
            let rowResult = addRowToPCFromPT(noShowRowSet, row, pt, pc, header);
            pc = rowResult[0];
            header = rowResult[1];
        }
    }

    for (let i = 0; i < colorSet.length; i++){
        pc.subsetColors.push(colorSet[i]);
    }

    return pc;
};