import React from 'react';
import PropTypes from "prop-types";
import TTabs2 from "../@common/components/TTabs2";
import TButton from "../@common/components/TButton";
import GBStdChart from "../@common/components/GBStdChart";
import TComboBox from "../@common/components/TComboBox";
import {GBEditor} from "../editors/GBEditors";
import * as Constants from "../utilities/Constants";
import { doCalculations } from '../utilities/calculations';
import { SBC_Investments } from '../editors/results/impact/SBC_Investments';
import { mCPR_Increase_Pathways } from '../editors/results/impact/mCPR_Increase_Pathways';
import { projectedIncreaseIn_mCPR } from '../editors/results/impact/projectedIncreaseIn_mCPR';
import { RelContrOfSBCIntervToIncIn_mCPR } from '../editors/results/impact/RelContrOfSBCIntervToIncIn_mCPR';
import { RelContrOfChangeToInterOutToIncIn_mCPR } from '../editors/results/impact/RelContrOfChangeToInterOutToIncIn_mCPR';
import { SBC_Investments_CE } from '../editors/results/costEffectiveness/SBC_Investments_CE';
import { CostEffResults } from '../editors/results/costEffectiveness/CostEffResults';
import { CostEffDetermination } from '../editors/results/costEffectiveness/CostEffDetermination';
import { CostPerMatDALY } from '../editors/results/costEffectiveness/CostPerMatDALY';
import Theme from "../app/Theme";
import infoImage from "../img/infoImage1.png";

class ResultsPage extends React.Component {

    static propTypes = {
        onPageChange          : PropTypes.func,
        onStateChange         : PropTypes.func,
        resultsData           : PropTypes.object,
        intervReachIdx        : PropTypes.number,
        startYearIdx          : PropTypes.number,
        shareOfPop            : PropTypes.number,
        selectedCountry       : PropTypes.string,
        interventionsData     : PropTypes.object,
        modelParametersData   : PropTypes.object,
        costsData             : PropTypes.object,
        interQuartileRangeIdx : PropTypes.number,
        impactORsData         : PropTypes.object,
        itemIndex             : PropTypes.number,
        onComboBoxChange      : PropTypes.func,
        onInfoBtnClick        : PropTypes.func,
    };

    static defaultProps = {
        onPageChange          : () => console.log('onPageChange'),
        onStateChange         : () => console.log('onStateChange'),
        resultsData           : {},
        intervReachIdx        : Constants.IntervReach_Manual,
        startYearIdx          : 0,
        shareOfPop            : 100,
        selectedCountry       : 'Afghanistan',
        interventionsData     : {},
        modelParametersData   : {},
        costsData             : {},
        interQuartileRangeIdx : Constants.Median,
        impactORsData         : {},
        itemIndex             : 0,
        onComboBoxChange      : () => console.log('onComboBoxChange'),
        onInfoBtnClick        : () => console.log('onInfoBtnClick'),
    };

    state = {
        packTable1 : undefined,
        packTable2 : undefined,
        packTable3 : undefined,
        packTable4 : undefined,
        packChart1 : undefined,
        packChart2 : undefined,
        packChart3 : undefined,
        packChart4 : undefined,
        packChart5 : undefined,
    };

    //==================================================================================================================
    //
    //                                                 Life Cycles
    //
    //==================================================================================================================

    componentDidMount() {
        doCalculations(this.props);

        let SBC_Investments_Data = SBC_Investments.Create(this.props);
        let mCPR_Increase_Pathways_Data = mCPR_Increase_Pathways.Create(this.props.resultsData);
        let projectedIncreaseIn_mCPR_Data = projectedIncreaseIn_mCPR.Create(this.props);
        let RelContrOfSBCIntervToIncIn_mCPR_Data = RelContrOfSBCIntervToIncIn_mCPR.Create(this.props.resultsData);
        let RelContrOfChangeToInterOutToIncIn_mCPR_Data = RelContrOfChangeToInterOutToIncIn_mCPR.Create(this.props.resultsData);
        let SBC_Investments_CE_Data = SBC_Investments_CE.Create(this.props);
        let CostEffResults_Data = CostEffResults.Create(this.props);
        let CostEffDetermination_Data = CostEffDetermination.Create(this.props, this.props.itemIndex);
        let CostPerMatDALY_Data = CostPerMatDALY.Create(this.props, this.props.itemIndex);

        this.setState({
            packTable1 : SBC_Investments_Data[0],
            packTable2 : SBC_Investments_CE_Data[0],
            packTable3 : CostEffResults_Data[0],
            packTable4 : CostEffDetermination_Data[0],

            packChart1 : projectedIncreaseIn_mCPR_Data[1],
            packChart2 : RelContrOfSBCIntervToIncIn_mCPR_Data[1],
            packChart3 : RelContrOfChangeToInterOutToIncIn_mCPR_Data[1],
            packChart4 : mCPR_Increase_Pathways_Data[1],
            packChart5 : CostPerMatDALY_Data[1],
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const hasChanged =
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState));

        return hasChanged;
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================

    onPackTable1Change = () => {
        //blank on purpose
    };

    onPackTable2Change = () => {
        //blank on purpose
    };

    onPackTable3Change = () => {
        //blank on purpose
    };

    onPackTable4Change = () => {
        //blank on purpose
    };

    onComboboxChange = (value) => {
        this.props.onComboBoxChange(value);
        let CostEffDetermination_Data = CostEffDetermination.Create(this.props, value);
        let CostPerMatDALY_Data = CostPerMatDALY.Create(this.props, value);
        this.setState({
            packTable4 : CostEffDetermination_Data[0],
            packChart5 : CostPerMatDALY_Data[1],
        });
    };

    onBackBtnClick = () => {
        this.props.onPageChange("SenseCheckPage");
    };

    onInfoBtnClick = (info) => {
        this.props.onInfoBtnClick(info);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    getPieChartSeries = (packChart) =>{
        let series = [{
            name: '',
            colorByPoint: true,
            innerSize: '50%',
            data: []
        }];

        if (packChart !== undefined) {
            for (let i = 0; i < packChart.chartData.length; i++) {
                series[0].data.push({
                    y : packChart.chartData[i][0],
                    name : packChart.subsetLabels[i],
                    color : packChart.subsetColors[i]
                })
            }
        }

        return series;
    };

    getLineWithMarkersSeries = (packChart, addDataToChart, showInLegend, shapes, radii, dataLabelsFormat) =>{
        let series = [];

        let min = Number.MAX_VALUE;
        let max = 0;

        if (packChart !== undefined) {
            for (let i = 0; i < packChart.chartData.length; i++) {
                if (addDataToChart[i]) {
                    min = Math.min(min, packChart.chartData[i]);
                    max = Math.max(max, packChart.chartData[i]);

                    series.push({
                        showInLegend: showInLegend[i],
                        data: packChart.chartData[i],
                        name: packChart.subsetLabels[i],
                        color: packChart.subsetColors[i],
                        marker: {
                            enabled: true,
                            radius: radii[i],
                            symbol: shapes[i]
                        },
                        dataLabels: {
                            enabled: true,
                            format: dataLabelsFormat[i],
                            align: 'left',
                            verticalAlign: 'middle',
                            x: 15,
                        }
                    })
                }
            }
        }

        // series.push({
        //     name:'test',
        //     data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6],
        //         type: 'column',
        //     dataLabels: {
        //         enabled: true,
        //         // Formatter function
        //         format: undefined,
        //         formatter: function() {
        //             return this.series.name;
        //         }
        //     }
        // });

        /* Add line to connect the data points */
        // series.push({
        //     showInLegend : false,
        //     enableMouseTracking : false,
        //     data : [
        //         {x : 0, y : min},
        //         {x : 0, y : max}
        //     ],
        //     name : '',
        //     color : '#000000',
        //     lineWidth : 0.5,
        // });

        return series;
    };

    render() {
        const styles = {
            page : {
                marginLeft : 25
            },
            header : {
                fontSize: 24,
                fontWeight: 'bold',
                color: Theme.palette.primary.main,
                marginBottom: 20,
            },
            tabContent : {
                marginLeft: -23,
                paddingBottom: 0
            },
            instructionsText : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginBottom: 25,
                marginRight: 25
            },
            instructionsText2 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
            },
            instructionsText3 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                width: 1200
            },
            instructionsText4 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginLeft : 10,
            },
            instructionsText5 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginLeft : 10,
                width: 1200
            },
            comboBox: {
                marginTop: 0,
                marginBottom: 20,
                marginRight: 25,
                paddingLeft: 0,
                width: 325,
                display: 'inline-block'
            },
            backBtn : {
                marginRight: 20
            },
        };

        let pc1 = this.state.packChart1;
        let pc2 = this.state.packChart2;
        let pc3 = this.state.packChart3;
        let pc4 = this.state.packChart4;
        let pc5 = this.state.packChart5;

        let GPD;
        for (let c = 0; c < this.props.modelParametersData["internalUser"].length; c++) {
            if (this.props.modelParametersData["internalUser"][c]["COUNTRY"] === this.props.selectedCountry) {
                GPD = this.props.modelParametersData["internalUser"][c]["GDP Per Capital Region"];
            }
        }

        let orangeLineUrl = "url(https://sbcbusinesscaseforfp.avenirhealth.org/orange-line.png)";
        let greenLineUrl = "url(https://sbcbusinesscaseforfp.avenirhealth.org/green-line.png)";

        // let orangeLineUrl = "url(https://beta.avstaging.org/FPBusinessCaseModel/orange-line.png)";
        // let greenLineUrl = "url(https://beta.avstaging.org/FPBusinessCaseModel/green-line.png)";

        return (
            <div style={styles.page}>
                <h1 style={styles.header}>
                    Review Impact and Cost-Effectiveness Results
                </h1>
                <p style={styles.instructionsText}>
                    In this final step, you will see the impact and cost-effectiveness results of the proposed SBC
                    program based on the information entered in the previous steps.
                </p>
                <TTabs2
                    variant='scrollable'
                    tabFontSize={14}
                    tabContentStyle={styles.tabContent}
                    tabTitles={['Impact results', 'Cost-effectiveness results']}
                    tabBackgroundColor={Theme.stepper.backgroundColor}
                    appBarBackgroundColor={Theme.stepper.backgroundColor}
                    tabTitleWeight={'bold'}
                    tabContents={[
                        <div>
                            <GBEditor
                                id={0}
                                minHeight={445}
                                maxHeight={445}
                                packTable={this.state.packTable1}
                                onPackTableChanged={this.onPackTable1Change}
                                headerBackgroundColor={'#ffffff'}
                                banding = {false}
                                theme = {"result-table"}
                                disablePopupMenu = {true}
                            />

                            <GBStdChart
                                id        = {'1'}
                                packChart = {pc1}
                                minHeight = {228}
                                maxHeight = {488}
                                options   = {{
                                    chart: {
                                        width: 600
                                    },
                                    tooltip : {
                                        shared: false,
                                        valueSuffix: "%"
                                    },
                                    title: {
                                        text: (pc1 === undefined) ? '' : pc1.title.bold()
                                    },
                                    plotOptions: {
                                        column: {
                                            dataLabels: {
                                                enabled: true,
                                                format: '<b>{point.name}</b><br>{point.y:.1f} %',
                                            }
                                        }
                                    },
                                    legend : {
                                        verticalAlign:'middle',
                                        align: 'right',
                                        layout: 'vertical'
                                    },
                                    yAxis :{
                                        title : '',
                                        tickInterval : 5,
                                        labels:{
                                            format: '{value:.1f}%'
                                        }
                                    }
                                }}
                                showLegend = {true}
                                chartType  = {Constants.CS_ColumnChart}
                                stacked    = {'stacked'}
                            />

                            <br/>
                            <br/>

                            <p style={styles.instructionsText4}>
                                The figure on the left shows the relative contribution to the increase in modern
                                contraceptive use attributable to each of your selected SBC interventions. The figure
                                on the right shows the relative contribution to the increase in modern contraceptive
                                use attributable to each intermediate outcome.
                            </p>

                            <br/>

                            <div style={{display: "inline-block"}}>
                                <GBStdChart
                                    id        = {'2'}
                                    packChart = {pc2}
                                    minHeight = {228}
                                    maxHeight = {488}
                                    options   = {{
                                        chart: {
                                            width: 575,
                                            plotBackgroundColor: null,
                                            plotBorderWidth: null,
                                            plotShadow: false
                                        },
                                        tooltip: {
                                            valueSuffix: "%"
                                        },
                                        accessibility: {
                                            point: {
                                                valueSuffix: '%'
                                            }
                                        },
                                        plotOptions: {
                                            pie: {
                                                dataLabels: {
                                                    enabled: true,
                                                    format: '<b>{point.name}</b><br>{point.y:.0f} %',
                                                }
                                            }
                                        },
                                        title: {
                                            text: (pc2 === undefined) ? '' : pc2.title.bold()
                                        },
                                        series : this.getPieChartSeries(pc2)
                                    }}
                                    showLegend = {true}
                                    chartType  = {Constants.CS_PieChart}
                                    stacked    = {''}
                                />
                            </div>

                            <div style={{display: "inline-block"}}>
                                <GBStdChart
                                    id        = {'3'}
                                    packChart = {pc3}
                                    minHeight = {228}
                                    maxHeight = {488}
                                    options   = {{
                                        chart: {
                                            width: 725,
                                            plotBackgroundColor: null,
                                            plotBorderWidth: null,
                                            plotShadow: false
                                        },
                                        tooltip: {
                                            valueSuffix: "%"
                                        },
                                        accessibility: {
                                            point: {
                                                valueSuffix: '%'
                                            }
                                        },
                                        plotOptions: {
                                            pie: {
                                                dataLabels: {
                                                    enabled: true,
                                                    format: '<b>{point.name}</b><br>{point.y:.0f} %',
                                                }
                                            }
                                        },
                                        title: {
                                            text: (pc3 === undefined) ? '' : pc3.title.bold()
                                        },
                                        series : this.getPieChartSeries(pc3)
                                    }}
                                    showLegend = {true}
                                    chartType  = {Constants.CS_PieChart}
                                    stacked    = {''}
                                />
                            </div>

                            <div style={{display: "inline-block"}}>
                                <img
                                    src     = {infoImage}
                                    alt     = ""
                                    style   = {{cursor: 'pointer'}}
                                    onClick = {() => this.onInfoBtnClick(<p>The chart here shows the proportion of
                                        increase in mCPR attributable to each of the intermediate outcomes. In some
                                        instances, the total impact on mCPR, as measured by the direct relationship
                                        between exposure to SBC interventions and mCPR change, is not captured when
                                        modeling changes via the intermediate outcomes. In these instances, the
                                        “residual” is represented, showing the proportion of mCPR change not captured
                                        by the intermediate outcomes.</p>)}
                                />
                            </div>

                            <br/>
                            <br/>
                            <br/>

                            <p style={styles.instructionsText4}>
                                The chart below shows the percentage point contribution to the estimated resulting
                                increase in mCPR via both the SBC interventions you selected and the pathways they
                                address.
                            </p>

                            <br/>

                            <GBStdChart
                                id        = {'5'}
                                packChart = {pc4}
                                minHeight = {228}
                                maxHeight = {528}
                                options   = {{
                                    chart: {
                                        width: 1400
                                    },
                                    tooltip : {
                                        shared: false,
                                        valueSuffix: "%"
                                    },
                                    title: {
                                        text: (pc4 === undefined) ? '' : pc4.title.bold()
                                    },
                                    legend : {
                                        verticalAlign: 'bottom'
                                    },
                                    yAxis :{
                                        title : '',
                                        tickInterval : 0.2,
                                        labels:{
                                            format: '{value:.2f}%'
                                        }
                                    }
                                }}
                                showLegend = {true}
                                legendReversed = {true}
                                chartType  = {Constants.CS_ColumnChart}
                                stacked    = {'stacked'}
                            />

                            <p style={styles.instructionsText5}>
                                *Other represents changes to mCPR not attributable to the intermediate outcomes
                                specified here.
                            </p>

                        </div>,

                        <div>
                            <GBEditor
                                id={0}
                                minHeight={445}
                                maxHeight={445}
                                packTable={this.state.packTable2}
                                onPackTableChanged={this.onPackTable2Change}
                                headerBackgroundColor={'#ffffff'}
                                banding = {false}
                                theme = {"result-table"}
                                disablePopupMenu = {true}
                            />

                            <div style={{display: "inline-block"}}>
                                <img
                                    src     = {infoImage}
                                    alt     = ""
                                    style   = {{cursor: 'pointer'}}
                                    onClick = {() => this.onInfoBtnClick(<p>A disability-adjusted life year (DALY) is
                                        a time-based measure of health burden that combines years of life lost due to
                                        premature mortality (YLLs) and years of life lost due to disability (YLDs).
                                        DALYs are calculated to assess the cost per DALY averted, which is the cost
                                        per one additional healthy year of life.  The cost per DALY averted is
                                        commonly used as an incremental cost-effectiveness ratio (ICER) to assess
                                        the cost-effectiveness of an intervention.</p>)}
                                />
                            </div>

                            <div style={{width : 1500, display: "inline-block"}}>
                                <GBEditor
                                    id={1}
                                    minHeight={445}
                                    maxHeight={445}
                                    packTable={this.state.packTable3}
                                    onPackTableChanged={this.onPackTable3Change}
                                    headerBackgroundColor={'#ffffff'}
                                    banding = {false}
                                    theme = {"result-table"}
                                    disablePopupMenu = {true}
                                />
                            </div>

                            <div style={{width: 620, height:300, display: "inline-block", verticalAlign: 'top'}}>

                                <p style={styles.instructionsText2}>Select benchmark:</p>

                                <div style={{display: "inline-block"}}>
                                    <TComboBox
                                        caption      = ""
                                        itemIndex    = {this.props.itemIndex}
                                        style        = {styles.comboBox}
                                        onChange     = {this.onComboboxChange}
                                        captionStyle = {styles.captionStyle}
                                        items        = {['National', 'Regional: ' + GPD]}
                                    >
                                    </TComboBox>
                                </div>

                                <div style={{display: "inline-block"}}>
                                    <img
                                        src     = {infoImage}
                                        alt     = ""
                                        style   = {{cursor: 'pointer'}}
                                        onClick = {() => this.onInfoBtnClick([
                                            <p>When comparing the cost per DALY averted to the GDP per capita, two
                                                options are given:</p>,
                                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>National –</b>&nbsp;the
                                                country’s GDP per capita, which is the most common approach for
                                                comparison in cost-effectiveness analysis.</p>,
                                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Regional –</b>&nbsp;the
                                                average GDP per capita in the region, excluding high-income countries.
                                                You may want to consider using this benchmark for a regional approach
                                                to SBC or for countries with a very low GDP per capita.</p>,
                                        ])}
                                    />
                                </div>

                                <br/>
                                <br/>

                                <GBEditor
                                    id={2}
                                    minHeight={445}
                                    maxHeight={445}
                                    packTable={this.state.packTable4}
                                    onPackTableChanged={this.onPackTable4Change}
                                    headerBackgroundColor={'#ffffff'}
                                    banding = {false}
                                    theme = {"result-table"}
                                    disablePopupMenu = {true}
                                />

                            </div>

                            <div style={{display: "inline-block"}}>
                                <GBStdChart
                                    id        = {'3'}
                                    packChart = {pc5}
                                    minHeight = {228}
                                    maxHeight = {528}
                                    options   = {{
                                        chart: {
                                            width: 600
                                        },
                                        tooltip : {
                                            shared: false,
                                            valuePrefix: "$",
                                            valueSuffix: ""
                                        },
                                        title: {
                                            text: (pc5 === undefined) ? '' : pc5.title.bold()
                                        },
                                        legend : {
                                            verticalAlign:'middle',
                                            align: 'right',
                                            layout: 'vertical'
                                        },
                                        yAxis :{
                                            title : '',
                                            min : 0,
                                            tickInterval : 500,
                                            labels:{
                                                format: '${value:,.0f}'
                                            }
                                        },

                                        series : this.getLineWithMarkersSeries(pc5,
                                            [((pc5 !== undefined) && pc5.showOrangeLine), true, true, true, true],
                                            [false, false, true, true, true],
                                            [
                                                orangeLineUrl,
                                                greenLineUrl,
                                                'circle',
                                                'circle',
                                                'circle'
                                            ],
                                            [13, 13, 5, 13, 5],
                                            [
                                                '',
                                                '',
                                                '',
                                                '${y:,.0f}',
                                                ''
                                            ]
                                        )
                                    }}
                                    showLegend = {true}
                                    legendReversed = {true}
                                    chartType  = {Constants.CS_LineChart}
                                    stacked    = {''}
                                />
                            </div>

                            <div style={{display: "inline-block"}}>
                                <img
                                    src     = {infoImage}
                                    alt     = ""
                                    style   = {{cursor: 'pointer'}}
                                    onClick = {() => this.onInfoBtnClick(<p>The cost per maternal DALY averted can be
                                        compared to the gross domestic product (GDP) per capita, where according to
                                        international standards, a cost per DALY less than one times the GDP per capita
                                        is considered “highly cost effective” and a cost per DALY between one and three
                                        times the GDP per capita is considered “cost effective”. Based on these criteria,
                                        results below the green line are highly cost-effective and results between the
                                        green and orange lines are cost-effective. Results above the orange line are
                                        not cost-effective. The “high” and “low” estimates shows what the results would
                                        be if the unit costs were halved or doubled to provide a sense of how much
                                        variation in unit costs would impact the cost-effectiveness results. </p>)}
                                />
                            </div>

                            <br/>
                            <br/>
                            <br/>

                            <p style={styles.instructionsText3}>
                                In addition to median costs, the graph also shows the results when using half the
                                median unit costs (low estimate) and double the median unit costs (high estimate).
                            </p>
                        </div>,
                    ]}
                />
                <TButton
                    caption={"Back"}
                    color={'secondary'}
                    style={styles.backBtn}
                    onClick={this.onBackBtnClick}
                />

            </div>
        );
    }
}

export default ResultsPage;