import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import { addMergedCellsToArray, indentPackTable } from "../../@common/components/GBStdTableAG/other/utilities";
import * as Constants from "../../utilities/Constants"

const Create = (countryName, startYearIdx, shareOfPop, modelParametersData) => {

    let editorName = 'Population, fertility intentions, mCPR';
    let packTable = Setup(startYearIdx);
    packTable = SetSSData(packTable, countryName, startYearIdx, shareOfPop, modelParametersData);
    packTable = RecalcGrid(packTable, countryName, startYearIdx, shareOfPop, modelParametersData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = (startYearIdx) => {
    let numRows = 9;
    let numCols = 3;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][1] = 'Value';
    pt.tableData.value[0][2] = 'Default source';

    pt.tableData.value[1][0] = 'Number of WRA';
    pt.FontStyles[1][0]["intArray"] = [Constants.Bold];
    pt.LockedCells[1][1] = true;
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 1, 0, 1, 3);

    // for (let row = 1; row < numRows; row++) {
    //     pt.LockedCells[row][2] = true;
    // }
    
    for (let r = 2; r <= 6; r++) {
        let yearStr = "Year: " + (startYearIdx + Constants.startYear + r - 1).toString();
        pt.tableData.value[r][0] = yearStr;
        pt = indentPackTable(pt, r, true, Constants.ED_Indent1);
        pt.RDec[r][1] = 0;
    }

    pt.tableData.value[7][0] = 'Mean ideal number of children';
    pt.FontStyles[7][0]["intArray"] = [Constants.Bold];
    pt.RDec[7][1] = 1;

    pt.tableData.value[8][0] = 'mCPR (all women) at starting year';
    pt.FontStyles[8][0]["intArray"] = [Constants.Bold];
    // pt.showPercent[8][1] = Constants.Percent;
    pt.RDec[8][1] = 1;

    for (let r1 = 2; r1 <= 8; r1++) {
        pt.Alignments[r1][1] = Constants.Right;
        pt.MinAllowedVal[r1][1] = 0;
        pt.MaxAllowedVal[r1][1] = 999999999999;
    }

    pt.GBColWidths[1] = 100;
    pt.GBColWidths[2] = 150;

    return pt;
};

const SetSSData = (pt, countryName, startYearIdx, shareOfPop, modelParametersData) => {
    let countryData = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            countryData = modelParametersData["externalUser"][i];
        }
    }

    let years = [];
    
    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }

    pt.tableData.value[2][1] = countryData["WRA " + years[1]] * shareOfPop / 100;
    pt.tableData.value[2][2] = countryData["WRA " + years[1] + ' Source'];
    pt.tableData.value[3][1] = countryData["WRA " + years[2]] * shareOfPop / 100;
    pt.tableData.value[3][2] = countryData["WRA " + years[2] + ' Source'];
    pt.tableData.value[4][1] = countryData["WRA " + years[3]] * shareOfPop / 100;
    pt.tableData.value[4][2] = countryData["WRA " + years[3] + ' Source'];
    pt.tableData.value[5][1] = countryData["WRA " + years[4]] * shareOfPop / 100;
    pt.tableData.value[5][2] = countryData["WRA " + years[4] + ' Source'];
    pt.tableData.value[6][1] = countryData["WRA " + years[5]] * shareOfPop / 100;
    pt.tableData.value[6][2] = countryData["WRA " + years[5] + ' Source'];
    pt.tableData.value[7][1] = countryData["Mean Ideal number of children"];
    pt.tableData.value[7][2] = countryData["Source"];
    pt.tableData.value[8][1] = countryData["mCPR " + years[0]];
    pt.tableData.value[8][2] = countryData["mCPR Source"];

    return pt;
};

const GetSSData = (pt, countryName, startYearIdx, shareOfPop, modelParametersData) => {
    let country;
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            country = i;
        }
    }

    let years = [];
    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }

    if (shareOfPop !== 0) {
        modelParametersData["externalUser"][country]["WRA " + years[1]] = pt.tableData.value[2][1]  / shareOfPop * 100;
        modelParametersData["externalUser"][country]["WRA " + years[2]] = pt.tableData.value[3][1]  / shareOfPop * 100;
        modelParametersData["externalUser"][country]["WRA " + years[3]] = pt.tableData.value[4][1]  / shareOfPop * 100;
        modelParametersData["externalUser"][country]["WRA " + years[4]] = pt.tableData.value[5][1]  / shareOfPop * 100;
        modelParametersData["externalUser"][country]["WRA " + years[5]] = pt.tableData.value[6][1]  / shareOfPop * 100;
        modelParametersData["externalUser"][country]["WRA " + years[1] + ' Source'] = pt.tableData.value[2][2];
        modelParametersData["externalUser"][country]["WRA " + years[2] + ' Source'] = pt.tableData.value[3][2];
        modelParametersData["externalUser"][country]["WRA " + years[3] + ' Source'] = pt.tableData.value[4][2];
        modelParametersData["externalUser"][country]["WRA " + years[4] + ' Source'] = pt.tableData.value[5][2];
        modelParametersData["externalUser"][country]["WRA " + years[5] + ' Source'] = pt.tableData.value[6][2];
    }

    modelParametersData["externalUser"][country]["Mean Ideal number of children"] = pt.tableData.value[7][1];
    modelParametersData["externalUser"][country]["Source"] = pt.tableData.value[7][2];
    modelParametersData["externalUser"][country]["mCPR " + years[0]] = pt.tableData.value[8][1];
    modelParametersData["externalUser"][country]["mCPR Source"] = pt.tableData.value[8][2];

    return (modelParametersData);
};

const RecalcGrid = (pt, countryName, startYearIdx, shareOfPop, modelParametersData) => {
    let user = {};
    let def = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            user = modelParametersData["externalUser"][i];
            def = modelParametersData["externalDefault"][i];
        }
    }

    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }

    let defColor = 0;
    let changedColor = 6930250;

    pt.FontColors[2][1] = user["WRA " + years[1]] === def["WRA " + years[1]] ? defColor : changedColor;
    pt.FontColors[3][1] = user["WRA " + years[2]] === def["WRA " + years[2]] ? defColor : changedColor;
    pt.FontColors[4][1] = user["WRA " + years[3]] === def["WRA " + years[3]] ? defColor : changedColor;
    pt.FontColors[5][1] = user["WRA " + years[4]] === def["WRA " + years[4]] ? defColor : changedColor;
    pt.FontColors[6][1] = user["WRA " + years[5]] === def["WRA " + years[5]] ? defColor : changedColor;
    pt.FontColors[2][2] = user["WRA " + years[1] + ' Source'] === def["WRA " + years[1] + ' Source'] ? defColor : changedColor;
    pt.FontColors[3][2] = user["WRA " + years[2] + ' Source'] === def["WRA " + years[2] + ' Source'] ? defColor : changedColor;
    pt.FontColors[4][2] = user["WRA " + years[3] + ' Source'] === def["WRA " + years[3] + ' Source'] ? defColor : changedColor;
    pt.FontColors[5][2] = user["WRA " + years[4] + ' Source'] === def["WRA " + years[4] + ' Source'] ? defColor : changedColor;
    pt.FontColors[6][2] = user["WRA " + years[5] + ' Source'] === def["WRA " + years[5] + ' Source'] ? defColor : changedColor;
    pt.FontColors[7][1] = user["Mean Ideal number of children"] === def["Mean Ideal number of children"] ? defColor : changedColor;
    pt.FontColors[7][2] = user["Source"] === def["Source"] ? defColor : changedColor;
    pt.FontColors[8][1] = user["mCPR " + years[0]] === def["mCPR " + years[0]] ? defColor : changedColor;
    pt.FontColors[8][2] = user["mCPR Source"] === def["mCPR Source"] ? defColor : changedColor;

    return (pt);
};

const RestoreDefaultValues = (countryName, startYearIdx, shareOfPop, modelParametersData) => {
    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + 5; j++) {
        years.push((Constants.startYear + j).toString());
    }

    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {

            modelParametersData["externalUser"][i]["WRA " + years[1]] = modelParametersData["externalDefault"][i]["WRA " + years[1]];
            modelParametersData["externalUser"][i]["WRA " + years[1] + ' Source'] = modelParametersData["externalDefault"][i]["WRA " + years[1] + ' Source'];
            modelParametersData["externalUser"][i]["WRA " + years[2]] = modelParametersData["externalDefault"][i]["WRA " + years[2]];
            modelParametersData["externalUser"][i]["WRA " + years[2] + ' Source'] = modelParametersData["externalDefault"][i]["WRA " + years[2] + ' Source'];
            modelParametersData["externalUser"][i]["WRA " + years[3]] = modelParametersData["externalDefault"][i]["WRA " + years[3]];
            modelParametersData["externalUser"][i]["WRA " + years[3] + ' Source'] = modelParametersData["externalDefault"][i]["WRA " + years[3] + ' Source'];
            modelParametersData["externalUser"][i]["WRA " + years[4]] = modelParametersData["externalDefault"][i]["WRA " + years[4]];
            modelParametersData["externalUser"][i]["WRA " + years[4] + ' Source'] = modelParametersData["externalDefault"][i]["WRA " + years[4] + ' Source'];
            modelParametersData["externalUser"][i]["WRA " + years[5]] = modelParametersData["externalDefault"][i]["WRA " + years[5]];
            modelParametersData["externalUser"][i]["WRA " + years[5] + ' Source'] = modelParametersData["externalDefault"][i]["WRA " + years[5] + ' Source'];
            modelParametersData["externalUser"][i]["Mean Ideal number of children"] = modelParametersData["externalDefault"][i]["Mean Ideal number of children"];
            modelParametersData["externalUser"][i]["Source"] = modelParametersData["externalDefault"][i]["Source"];
            modelParametersData["externalUser"][i]["mCPR " + years[0]] = modelParametersData["externalDefault"][i]["mCPR " + years[0]];
            modelParametersData["externalUser"][i]["mCPR Source"] = modelParametersData["externalDefault"][i]["mCPR Source"];
        }
    }

    return modelParametersData;
};

export const PopFertilityIntentionsmCPREditor = {
    Create               : Create,
    Setup                : Setup,
    SetSSData            : SetSSData,
    GetSSData            : GetSSData,
    RecalcGrid           : RecalcGrid,
    RestoreDefaultValues : RestoreDefaultValues,
};