import React from 'react';
import PropTypes from "prop-types";
import TButton from "../@common/components/TButton";
import introImage from '../img/introImage.png';
import infoImage from "../img/infoImage3.png";
import Theme from "../app/Theme"

class HomePage extends React.PureComponent {

    static propTypes = {
        onPageChange      : PropTypes.func,
        onStateChange     : PropTypes.func,
    };

    static defaultProps = {
        onPageChange      : () => console.log('onPageChange'),
        onStateChange     : () => console.log('onStateChange'),
    };

    onGetStartedBtnClick = () => {
        this.props.onPageChange("ModelParametersPage");
    };


    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            textDiv : {
                // width: 725,
                // display: 'inline-block',
                marginRight: 30,
            },
            textP1 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginTop: 0
            },
            textP : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginTop: -3
            },
            image : {
                // display: 'inline',
                // marginLeft: -10
                marginTop: 10,
                marginBottom : 20,
                width: 765,
                height: 326,
            },
            beginBtn :{
                float : 'left'
            }
        };

        return (
            <div>
                <div style={styles.textDiv}>
                    <p style={styles.textP1}>
                        This online interactive model was developed based on work done for Breakthrough RESEARCH's &nbsp;
                        <a href="https://breakthroughactionandresearch.org/business-case-sbc-fp/ " target="_blank" rel="noopener noreferrer">Business Case for Investing in Social and Behavior Change for Family Planning</a>.
                    </p>
                    <p style={styles.textP}>
                        This tool is meant to help you plan effective SBC programs by guiding you through a series of
                        steps necessary to see how a potential set of SBC interventions might impact the modern
                        contraceptive prevalence rate (mCPR) and the costs and cost-effectiveness of these interventions.
                        You can use this tool to help you design potential SBC programs, to understand whether a planned
                        investment will have the intended impact and cost-effectiveness, or to adjust potential programming
                        to see what combination of SBC interventions and what intervention reach align with your budget
                        and your intended impact.
                    </p>
                    <p style={styles.textP}>
                        Some model parameters have been pre-loaded (but can be edited). However, in order to generate
                        results, the user must specify SBC intervention reach. This tool can help you do that.
                    </p>

                    <text style={styles.textP}>
                        Throughout the tool, you can click on the
                    </text>

                    <img src={infoImage} alt="" />

                    <text style={styles.textP}>
                        symbol for more information.
                    </text>

                    <br/>
                    <br/>

                    <img style={styles.image} src={introImage} alt="" />

                    <br />

                    <TButton
                        caption={"Begin"}
                        color={'primary'}
                        onClick={this.onGetStartedBtnClick}
                        style={styles.beginBtn}
                    />

                </div>
                
            </div>
        );
    }
}

export default HomePage;