import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';

const MoreMenuBtn = (props) => {
    const { anchorEl, onClick, onClose, onStateChange, iconColor = '#000000', menuItems = [] } = props;

    const styles = {
        button: {
            width: 35, 
            height: 35,
            padding: 0
        },
        icon : {
            color : iconColor,
            fontSize: 28,
        },
        tooltip: {
            marginLeft:7
        },
        MenuPaperProps : {
            style: {
                width: 200
            }
        },
        divider : {
            margin: '10px 0'
        },
        menuItem : {
            fontSize: 14,
        }
    };

    const onMenuItemClick = (fn) => () => {
        onClose();
        fn();
    };

    return (
        <div>
            <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'long-menu' : null}
                aria-haspopup="true"
                onClick={onClick}
                style={styles.button}
            >
                <MoreVert style={styles.icon} />
            </IconButton>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onClose}
                PaperProps={styles.MenuPaperProps}
            >
                {
                    menuItems.map((x, i) => (
                        <MenuItem 
                            key={i}
                            style={styles.menuItem} 
                            onClick={onMenuItemClick(x.onClick)}
                        >
                            {x.name}
                        </MenuItem>
                    ))
                }

                {
                    menuItems.length === 0 ?
                        [
                            <MenuItem key={1} style={styles.menuItem} onClick={() => { onClose(); window.open("http://support.avenirhealth.org")}}>{"Support"}</MenuItem>,
                            <MenuItem key={2} style={styles.menuItem} onClick={() => { onClose(); onStateChange({aboutDialogOpen: true})}}>{"About"}</MenuItem>
                        ] :
                        null
                }

            </Menu>
        </div>
    );
};

export default MoreMenuBtn;