import React from "react";

const PageMissing = (props) => {

    const { pageID, onPageChange } = props;

    const _onPageChange = () => {
        onPageChange('back');
    };

    return (
        <div style={{textAlign: 'center'}}>
            Page does not exist! 
            <br />
            <br />
            PageID: {pageID}
            <br />
            <br />
            <span onClick={_onPageChange} style={{textDecoration: 'underline', cursor: 'pointer'}}>Go back</span>
        </div>
    )
};

export default React.memo(PageMissing);