import React from "react";
import BreakthroughLogo from "../img/BreakthroughLogo.svg";
import PropTypes from "prop-types";
import Theme from "./Theme";

const LeftComponents = (props) => {

    const { onPageChange } = props;

    const styles = {
        icon: {
            height: 50,
            cursor: 'pointer',
            marginRight: 10,
            marginLeft: 15

        },
        header: {
            fontSize: 24,
            marginLeft: 25,
            marginTop: 5,
            marginBottom: 5,
            fontWeight: 'bold',
            color: Theme.palette.primary.main
        }
    };

    const _onPageChange = (pageID) => () => {
        onPageChange(pageID);
    };

    return (
        <React.Fragment>
            <img src={BreakthroughLogo} alt="" style={styles.icon} onClick={_onPageChange("HomePage")} />
            <p style={styles.header}>The Social and Behavior Change Business Case Model for Family Planning: An Interactive Tool</p>
        </React.Fragment>
    );
};

export default LeftComponents;

LeftComponents.propTypes = {
    onPageChange : PropTypes.func.isRequired
};
