import React from 'react';
import PropTypes from "prop-types";
import TEdit from "../@common/components/TEdit";
import TButton from "../@common/components/TButton";
import TComboBox from "../@common/components/TComboBox";
import TTabs2 from "../@common/components/TTabs2";
import * as Constants from "../utilities/Constants";
import { PopFertilityIntentionsmCPREditor } from "../editors/ModelParameters/PopFertilityIntentionsmCPREditor"
import { FPMethodMixEditor } from '../editors/ModelParameters/FPMethodMixEditor';
import { IntermediateDeterminantsEditor } from '../editors/ModelParameters/IntermediateDeterminantsEditor';
import { ImpactAndCostEffectivenessEditor } from '../editors/ModelParameters/ImpactAndCostEffectivenessEditor';
import { GBEditor } from '../editors/GBEditors';
import Theme from "../app/Theme";
import { clone } from "../utilities/GBUtil"
import infoImage from '../img/infoImage1.png';

class ModelParametersPage extends React.Component {

    static propTypes = {
        onPageChange                   : PropTypes.func,
        onStateChange                  : PropTypes.func,
        onInfoBtnClick                 : PropTypes.func,
        selectedCountry                : PropTypes.string,
        programIdx                     : PropTypes.number,
        subnatAreaName                 : PropTypes.string,
        shareOfPop                     : PropTypes.number,
        startYearIdx                   : PropTypes.number,
        modelParametersData            : PropTypes.object,
        info                           : PropTypes.string,
        restoreBtnDisabledModelParams1 : PropTypes.bool,
        restoreBtnDisabledModelParams2 : PropTypes.bool,
        restoreBtnDisabledModelParams3 : PropTypes.bool,
        restoreBtnDisabledModelParams4 : PropTypes.bool,
    };

    static defaultProps = {
        onPageChange                   : () => console.log('onPageChange'),
        onStateChange                  : () => console.log('onStateChange'),
        onInfoBtnClick                 : () => console.log('onInfoBtnClick'),
        selectedCountry                : "Afghanistan",
        programIdx                     : 0,
        subnatAreaName                 : '',
        shareOfPop                     : 100,
        startYearIdx                   : 0,
        modelParametersData            : {},
        info                           : "",
        restoreBtnDisabledModelParams1 : true,
        restoreBtnDisabledModelParams2 : true,
        restoreBtnDisabledModelParams3 : true,
        restoreBtnDisabledModelParams4 : true,
    };

    state = {
        packTable1 : undefined,
        packTable2 : undefined,
        packTable3 : undefined,
        packTable4 : undefined,
        countryIdx : this.getCountryIdx,
        tabIdx     : 0
    };

    const = {
        PopFertilityIntentionsmCPRTab : 0,
        FPMethodMix                   : 1,
        IntermediateDeterminants      : 2,
        ImpactAndCostEffectiveness    : 3,
    };

    //==================================================================================================================
    //
    //                                                 Life Cycles
    //
    //==================================================================================================================

    componentDidMount() {
        this.setState({
            packTable1 : PopFertilityIntentionsmCPREditor.Create(this.props.selectedCountry, this.props.startYearIdx, this.props.shareOfPop, this.props.modelParametersData),
            packTable2 : FPMethodMixEditor.Create(this.props.selectedCountry, this.props.modelParametersData),
            packTable3 : IntermediateDeterminantsEditor.Create(this.props.selectedCountry, this.props.modelParametersData),
            packTable4 : ImpactAndCostEffectivenessEditor.Create(this.props.selectedCountry, this.props.startYearIdx, this.props.modelParametersData),
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const hasChanged = 
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState));
        
        return hasChanged;
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================

    onInfoBtnClick = (info) => {
        this.props.onInfoBtnClick(info);
    };

    getCountryIdx = () => {
        let countryIdx = 0;
        let countryList = this.getCountryList();
        for (let i = 0; i < countryList.length; i++) {
            if (this.props.selectedCountry === countryList[i]) {
                countryIdx = i;
            }
        }
        return (countryIdx)
    };

    getCountryList = () => {
        let countryList = [];
        for (let i = 0; i < this.props.modelParametersData["internalUser"].length; i++) {
            countryList.push(this.props.modelParametersData["internalUser"][i]["COUNTRY"]);
        }
        return (countryList)
    };

    onCountryComboBoxChange = (idx) => {
        let countryList = this.getCountryList();
        let selectedCountry = '';
        for (let i = 0; i < countryList.length; i++) {
            if (i === idx) {
                selectedCountry = countryList[i];
            }
        }
        this.setState ({
            countryIdx : idx,
            packTable1 : PopFertilityIntentionsmCPREditor.Create(selectedCountry, this.props.startYearIdx, this.props.shareOfPop, this.props.modelParametersData),
            packTable2 : FPMethodMixEditor.Create(selectedCountry, this.props.modelParametersData),
            packTable3 : IntermediateDeterminantsEditor.Create(selectedCountry, this.props.modelParametersData),
            packTable4 : ImpactAndCostEffectivenessEditor.Create(selectedCountry, this.props.startYearIdx, this.props.modelParametersData),
        });
        this.props.onStateChange ({
            selectedCountry : selectedCountry
        })
    };

    onProgramComboBoxChange = (idx) => {
        this.setState ({
            packTable1 : PopFertilityIntentionsmCPREditor.Create(this.props.selectedCountry, this.props.startYearIdx, 100, this.props.modelParametersData),
        });
        this.props.onStateChange ({
            programIdx : idx,
            shareOfPop : 100,
        })
    };

    onSubnatAreaNameChange = (value) => {
        this.props.onStateChange ({
            subnatAreaName : value
        })
    };

    onShareOfPopChange = (value) => {
        let shareOfPopVal = Number(value);
        if (shareOfPopVal < 0) {
            shareOfPopVal = 0;
        }
        if (shareOfPopVal > 100) {
            shareOfPopVal = 100;
        }

        this.setState ({
            packTable1 : PopFertilityIntentionsmCPREditor.Create(this.props.selectedCountry, this.props.startYearIdx, shareOfPopVal, this.props.modelParametersData),
        });
   
        this.props.onStateChange ({
            shareOfPop                     : shareOfPopVal,
            restoreBtnDisabledModelParams1 : false,
        })
    };

    onStartYearComboBoxChange = (idx) => {
        this.setState ({
            packTable1 : PopFertilityIntentionsmCPREditor.Create(this.props.selectedCountry, idx, this.props.shareOfPop, this.props.modelParametersData),
            packTable4 : ImpactAndCostEffectivenessEditor.Create(this.props.selectedCountry, idx, this.props.modelParametersData),
        });

        this.props.onStateChange ({
            startYearIdx : idx
        })
    };

    onPackTable1Change = (packTable) => {
        let modelParametersData = PopFertilityIntentionsmCPREditor.GetSSData(packTable, this.props.selectedCountry, this.props.startYearIdx, this.props.shareOfPop, clone(this.props.modelParametersData));
        packTable = PopFertilityIntentionsmCPREditor.Create(this.props.selectedCountry,this.props.startYearIdx, this.props.shareOfPop, modelParametersData);

        this.setState ({
            packTable1 : packTable,
        });
        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            restoreBtnDisabledModelParams1 : false,
        })
    };

    onPackTable2Change = (packTable) => {
        let modelParametersData = FPMethodMixEditor.GetSSData(packTable, this.props.selectedCountry, clone(this.props.modelParametersData));
        packTable = FPMethodMixEditor.Create(this.props.selectedCountry, modelParametersData);

        this.setState ({
            packTable2 : packTable,
        });
        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            restoreBtnDisabledModelParams2 : false,
        })
    };

    onPackTable3Change = (packTable) => {
        let modelParametersData = IntermediateDeterminantsEditor.GetSSData(packTable, this.props.selectedCountry, clone(this.props.modelParametersData));
        packTable = IntermediateDeterminantsEditor.Create(this.props.selectedCountry, modelParametersData);

        this.setState ({
            packTable3 : packTable,
        });
        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            restoreBtnDisabledModelParams3 : false,
        })
    };

    onPackTable4Change = (packTable) => {
        let modelParametersData = ImpactAndCostEffectivenessEditor.GetSSData(packTable, this.props.selectedCountry, this.props.startYearIdx, clone(this.props.modelParametersData));
        packTable = ImpactAndCostEffectivenessEditor.Create(this.props.selectedCountry, this.props.startYearIdx, modelParametersData);

        this.setState ({
            packTable4 : packTable,
        });
        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            restoreBtnDisabledModelParams4 : false,
        })
    };

    onRestoreDefaultValuesClick1 = () => {
        let modelParametersData = PopFertilityIntentionsmCPREditor.RestoreDefaultValues(this.props.selectedCountry, this.props.startYearIdx, 100, this.props.modelParametersData);

        this.setState({
            packTable1 : PopFertilityIntentionsmCPREditor.Create(this.props.selectedCountry, this.props.startYearIdx, 100, modelParametersData),
        });

        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            shareOfPop                     : 100,
            restoreBtnDisabledModelParams1 : true
        })
    };

    onRestoreDefaultValuesClick2 = () => {
        let modelParametersData = FPMethodMixEditor.RestoreDefaultValues(this.props.selectedCountry, this.props.modelParametersData);

        this.setState({
            packTable2 : FPMethodMixEditor.Create(this.props.selectedCountry, modelParametersData)
        });

        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            restoreBtnDisabledModelParams2 : true
        })
    };

    onRestoreDefaultValuesClick3 = () => {
        let modelParametersData = IntermediateDeterminantsEditor.RestoreDefaultValues(this.props.selectedCountry, this.props.modelParametersData);

        this.setState({
            packTable3 : IntermediateDeterminantsEditor.Create(this.props.selectedCountry, modelParametersData)
        });

        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            restoreBtnDisabledModelParams3 : true
        })
    };

    onRestoreDefaultValuesClick4 = () => {
        let modelParametersData = ImpactAndCostEffectivenessEditor.RestoreDefaultValues(this.props.selectedCountry, this.props.startYearIdx, this.props.modelParametersData);

        this.setState({
            packTable4 : ImpactAndCostEffectivenessEditor.Create(this.props.selectedCountry, this.props.startYearIdx, modelParametersData),
        });

        this.props.onStateChange ({
            modelParametersData            : modelParametersData,
            restoreBtnDisabledModelParams4 : true
        })
    };

    onNextBtnClick = () => {
        this.props.onPageChange("ImpactORsPage");
        this.props.onStateChange ({
            parametersSet : true
        })
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    getHeight(){
        let result = 240;
        if (this.props.programIdx === Constants.SubnationalProgram){
            result += 230;
        }
        return result;
    };

    render() {   
        const styles = {
            page : {
                marginLeft : 25
            },
            margins : {
                marginBottom : 30,
            },
            edit : {
                marginBottom : 30,
                width : 270
            },
            endYearEdit : {
                marginTop: 15.75,
                marginBottom: 30,
                width : 111,
                marginLeft: 23
            },
            restoreDefaultValues : {
                marginTop: 10,
                marginBottom: 10,
                fontSize: 12,
                height: 25,
                paddingLeft: 0,
                paddingRight: 0,
                display: "inline-block"
            },
            tabContent : {
                marginLeft: -23, 
                paddingBottom: 0
            },
            helperText : {
                marginBottom: 10,
                fontSize: 12,
                marginRight: 25,
                color: Theme.palette.secondary.main,
            },
            header : {
                fontSize: 24,
                fontWeight: 'bold',
                color: Theme.palette.primary.main,
                marginBottom: 20,
            },
            instructionsText : {
                fontSize: 18,
                color: Theme.palette.primary.main,
            },
            instructionsText2 : {
                fontSize: 18,
                textIndent: 50,
                color: Theme.palette.primary.main,
            },
            instructionsText3 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                display: "inline-block"
            },
        };

        let years = [];
        for (let i = 1; i <= 7; i++) {
            years.push(Constants.startYear + i);
        }

        let tabs = [
            'Population, fertility intentions, mCPR',
            'FP method mix',
            'Intermediate outcomes of FP use',
            'Impact and cost-effectiveness'
        ];

        return (
            <div style={styles.page}>
                <h1 style={styles.header}>
                    Set and Review Model Parameters
                </h1>
                <div style={styles.instructionsText}>
                    {"In this step, make the following selections: "}
                </div>
                <div style={styles.instructionsText2}>
                    {"- Select your country"}
                </div>
                <div style={styles.instructionsText2}>
                    {"- Select whether you are conducting a national versus sub-national application. "}
                </div>
                <div style={styles.instructionsText2}>
                    {"- For sub-national applications, specify the proportion of women of reproductive age (WRA) in the " +
                    "country living in the sub-national area. "}
                </div>
                <div style={styles.instructionsText2}>
                    {"- Select your start year for the five-year investment scenario."}
                </div>

                <br/>

                <text style={{display: "inline-block", fontSize: '12px', color:'grey'}} >
                    Select country
                </text>

                <text style={{display: "inline-block", fontSize: '12px', color:'darkred', fontStyle:'italic'}}>
                    &nbsp;*(required)
                </text>

                <br/>

                <TComboBox
                    caption={""}
                    items={this.getCountryList()}
                    itemIndex={this.getCountryIdx()}
                    onChange={this.onCountryComboBoxChange}
                    width={'270px'}
                    style={styles.margins}
                />

                <br />

                <TComboBox
                    caption={"Program"}
                    items={['National', 'Sub-national']}
                    itemIndex={this.props.programIdx}
                    onChange={this.onProgramComboBoxChange}
                    width={'270px'}
                    style={styles.margins}
                />

                <br />

                {
                    (this.props.programIdx === Constants.SubnationalProgram) ?
                        <div>
                            <div style={styles.helperText}>{'Subnational program could be a geographic focus (e.g., one region) or a focus on a specific population (e.g., married youth)'}</div>
                            <TEdit
                                name="name"
                                caption={'Sub-national area/group'}
                                value={this.props.subnatAreaName}
                                onChange={this.onSubnatAreaNameChange}
                                style={styles.edit}
                            />

                            <br />

                            <div style={styles.helperText}>{'WRA is adjusted based on this value; other indicators default to National but, if available, you can enter updated data for the subnational group selected'}</div>
                            <TEdit
                                name="share"
                                caption={'Share of population in area/group'}
                                value={this.props.shareOfPop}
                                onChange={this.onShareOfPopChange}
                                type={'Number'}
                                style={styles.edit}
                                InputProps={{
                                    inputProps: {
                                        max: 100, min: 0
                                    }
                                }}
                            />

                            <br />
                        </div>
                    : null
                }

                <div style = {{display: "inline-block"}}>

                    <text style={{display: "inline-block", fontSize: '12px', color:'grey'}} >
                        Start year
                    </text>

                    <text style={{display: "inline-block", fontSize: '12px', color:'darkred', fontStyle:'italic'}}>
                        &nbsp;*(required)
                    </text>

                    <br/>

                    <TComboBox
                        caption={""}
                        items={years}
                        itemIndex={this.props.startYearIdx}
                        onChange={this.onStartYearComboBoxChange}
                        width={'111px'}
                        style={styles.margins}
                    />

                </div>

                <div style = {{display: "inline-block"}}>

                    <text style={{marginLeft: 20, fontSize: '12px', color:'grey'}} >
                        End year
                    </text>

                    <br/>

                    <TEdit
                        name="endYear"
                        caption={''}
                        value={Constants.startYear + this.props.startYearIdx + 5}
                        // disabled={true}
                        style={styles.endYearEdit}
                    />

                </div>

                <div style={styles.instructionsText}>In each sub-section below, you can review and edit the default
                    parameters used in the calculations. No changes are necessary, however, the 'value' column for the
                    parameters can be edited to better represent your specific program if data are available. To edit,
                    click on the parameter you would like to change in the value column and enter the revised value.
                    You can also edit the default source column to note the source of your revised value. The text for
                    revised values will be green. </div>

                <br />

                <div style={styles.instructionsText3}>If you make changes to parameters and would like to return to using
                    the defaults, click on the green “Restore default values” button.</div>

                <br />
                <br />

                <TTabs2
                    variant='scrollable'
                    tabFontSize={14}
                    tabContentStyle={styles.tabContent}
                    tabTitles={tabs}
                    tabBackgroundColor={Theme.stepper.backgroundColor}
                    appBarBackgroundColor={Theme.stepper.backgroundColor}
                    tabTitleWeight={'bold'}
                    tabContents={[
                        <div>
                            <TButton
                                caption={"Restore default values"}
                                color={'secondary'}
                                onClick={this.onRestoreDefaultValuesClick1}
                                style={styles.restoreDefaultValues}
                                disabled={this.props.restoreBtnDisabledModelParams1}
                            />

                            <GBEditor
                                key                 = {'modelParam1'} //{Date.now()}
                                id                  = {Constants.Editor_PopFertilityIntentionsmCPR}
                                minHeight           = {445}
                                maxHeight           = {445}
                                packTable           = {this.state.packTable1}
                                onPackTableChanged  = {this.onPackTable1Change}
                            />
                        </div>,
                        <div>
                            <TButton
                                caption={"Restore default values"}
                                color={'secondary'}
                                onClick={this.onRestoreDefaultValuesClick2}
                                style={styles.restoreDefaultValues}
                                disabled={this.props.restoreBtnDisabledModelParams2}
                            />

                            <GBEditor
                                key                 = {'modelParam2'} //{Date.now()+1}
                                id                  = {Constants.Editor_FPMethodMix}
                                minHeight           = {445}
                                maxHeight           = {445}
                                packTable           = {this.state.packTable2}
                                onPackTableChanged  = {this.onPackTable2Change}
                            />
                        </div>,
                        <div>
                            <TButton
                                caption={"Restore default values"}
                                color={'secondary'}
                                onClick={this.onRestoreDefaultValuesClick3}
                                style={styles.restoreDefaultValues}
                                disabled={this.props.restoreBtnDisabledModelParams3}
                            />

                            <br/>

                            <div style={{display: "inline-block"}}>
                                <img
                                    src     = {infoImage}
                                    alt     = ""
                                    style   = {{cursor: 'pointer'}}
                                    onClick = {() => this.onInfoBtnClick([
                                        <p>This tool includes six intermediate outcomes: </p>,
                                        <p style={{textIndent: 50}}>(1) approval of FP</p>,
                                        <p style={{textIndent: 50}}>(2) perception of the benefits of FP</p>,
                                        <p style={{textIndent: 50}}>(3) self-efficacy for FP use</p>,
                                        <p style={{textIndent: 50}}>(4) men’s attitudes and support for FP</p>,
                                        <p style={{textIndent: 50}}>(5) communication with partners about FP, and </p>,
                                        <p style={{textIndent: 50}}>(6) communication with others about FP.  </p>,
                                        <p>Evidence shows that SBC interventions can be effective in
                                        improving these intermediate outcomes, and that in turn, improvements
                                        to these intermediate outcomes can lead to increases in contraceptive use. </p>])}
                                />
                            </div>

                            <div style={{width: 1500, display: "inline-block"}}>
                                <GBEditor
                                    key                 = {'modelParam3'} //Date.now()+2}
                                    id                  = {Constants.Editor_IntermediateDeterminants}
                                    minHeight           = {445}
                                    maxHeight           = {445}
                                    packTable           = {this.state.packTable3}
                                    onPackTableChanged  = {this.onPackTable3Change}
                                />
                            </div>
                        </div>,
                        <div>
                            <TButton
                                caption={"Restore default values"}
                                color={'secondary'}
                                onClick={this.onRestoreDefaultValuesClick4}
                                style={styles.restoreDefaultValues}
                                disabled={this.props.restoreBtnDisabledModelParams4}
                            />

                            <br/>

                            <div style={{display: "inline-block"}}>
                                <img
                                    src     = {infoImage}
                                    alt     = ""
                                    style   = {{cursor: 'pointer'}}
                                    onClick = {() => this.onInfoBtnClick([<p>A disability-adjusted life year (DALY) is
                                        a time-based measure of health burden that combines years of life lost due to
                                        premature mortality (YLLs) and years of life lost due to disability (YLDs).
                                        DALYs are calculated to assess the cost per DALY averted, which is the cost
                                        per one additional healthy year of life.  The cost per DALY averted is
                                        commonly used as an incremental cost-effectiveness ratio (ICER) to assess
                                        the cost-effectiveness of an intervention.</p>,

                                        <p>A discount rate is used to adjust both the costs and DALYs averted to the
                                        present-day value, where the value of money and health in the future is not
                                        as valuable as they are in the present. The default discount rate is 3% in
                                        keeping with standards for cost-effectiveness analysis. Users can adjust
                                        the discount rate to range from 0-6%. We recommend using the same discount
                                        rate for both costs and DALYs.</p>])}
                                />
                            </div>

                            <div style={{width: 1500, display: "inline-block"}}>
                                <GBEditor
                                    key                 = {'modelParam4'} //{Date.now()+3}
                                    id                  = {Constants.Editor_ImpactAndCostEffectiveness}
                                    minHeight           = {445}
                                    maxHeight           = {445}
                                    packTable           = {this.state.packTable4}
                                    onPackTableChanged  = {this.onPackTable4Change}
                                />
                            </div>
                        </div>
                    ]}
                />

                <TButton
                    caption={"Next"}
                    color={'primary'}
                    onClick={this.onNextBtnClick}
                />
            </div>
        );
    }
}

export default ModelParametersPage;