import { createTheme } from '@material-ui/core/styles';

const MyTheme = {
    palette: {
        primary: {
            main: '#003A5D',
            contrastText: '#FFFFFF'
        },
        secondary : {
            main: '#69BF4A',
            contrastText: '#FFFFFF'
        },
        third : {
            main: '#1AA8E0',
            text: '#FFFFFF'
        },
        fourth : {
            main:'#4764AF',
            text: '#FFFFFF' 
        }
    },
    radio : {
        fontFamily: 'Lato, sans-serif',
        fontSize : 16,
    },
    button : {
        fontFamily: 'Lato, sans-serif',
        fontSize : 16,
    },
    typography: {
        fontFamily: 'Lato, sans-serif',
        fontSize: 16,
        body1: {
            fontSize: 16,
        },
    },
    appBar: {
        backgroundColor: '#FFFFFF'
    },
    editor: {
        headerColor : "#1AA8E0",
        bandColor: '#E6F6FC',
        // fixedRowColor: "#9cc025",
        // highlightColor: "#fff1a6",
        cellPadding: "3",
        subheadingRowBackgroundColor : "transparent"
    },
    bandColor : {
        backgroundColor: 'e8ffdd' //'#f0f6df'
    },
    pageHeader : {
        color: '#003A5D',
    },
    card : {
        titleBackgroundColor : "#1AA8E0",
        titleTextColor : "#FFFFFF",
    },
    stepper : {
        backgroundColor: "#E1F3DB" //20% tint of secondary color
    },
    footer : {
        color : "#f2f2f2"
    },
    disabled : {
        color : 'lightgray'
    }
};

const muiTheme = createTheme(MyTheme);

export default muiTheme;