import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import * as Constants from "../../utilities/Constants";

const Create = (interQuartileRangeIdx, impactORsData) => {

    let editorName = '3. Odds Ratios for the Direct Relationship between SBC Interventions and mCPR';
    let packTable = Setup();
    packTable = SetSSData(packTable, interQuartileRangeIdx, impactORsData);
    packTable = RecalcGrid(packTable, interQuartileRangeIdx, impactORsData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = () => {
    let numRows = 6;
    let numCols = 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][1] = 'mCPR';

    pt.tableData.value[1][0] = 'Mass media';
    pt.tableData.value[2][0] = 'Mobile/SMS';
    pt.tableData.value[3][0] = 'Individual IPC';
    pt.tableData.value[4][0] = "Group IPC";
    pt.tableData.value[5][0] = 'IPC + Community engagement';

   
    for (let row = 1; row < numRows; row++) {
        pt.FontStyles[row][0]["intArray"] = [Constants.Bold];
        pt.RDec[row][1] = 2;
        pt.Alignments[row][1] = Constants.Right;
        pt.MinAllowedVal[row][1] = 0;
        pt.MaxAllowedVal[row][1] = 100;
        
    }

    pt.GBColWidths[0] = 195;
    pt.GBColWidths[1] = 70;

    return pt;
};

const SetSSData = (pt, interQuartileRangeIdx, impactORsData) => {
    let data = impactORsData['SBC_mCPRUser'];
    let qt = 'Median';
    if (interQuartileRangeIdx === Constants.Q1) {
        qt = 'Q1'
    }
    else if (interQuartileRangeIdx === Constants.Q3) {
        qt = 'Q3'
    }

    for (let row = 1; row < pt.GBRowCount; row++) {
        pt.tableData.value[row][1] = data[row-1][qt];
    }

    return pt;
};

const GetSSData = (pt, interQuartileRangeIdx,impactORsData) => {
    let qt = 'Median';
    if (interQuartileRangeIdx === Constants.Q1) {
        qt = 'Q1'
    }
    else if (interQuartileRangeIdx === Constants.Q3) {
        qt = 'Q3'
    }

    for (let row = 1; row < pt.GBRowCount; row++) {
        impactORsData['SBC_mCPRUser'][row-1][qt] = pt.tableData.value[row][1];
    }

    return (impactORsData)
};

const RecalcGrid = (pt, interQuartileRangeIdx, impactORsData) => {
    let user = impactORsData['SBC_mCPRUser'];
    let def = impactORsData['SBC_mCPRDefault'];

    let qt = 'Median';
    if (interQuartileRangeIdx === Constants.Q1) {
        qt = 'Q1'
    }
    else if (interQuartileRangeIdx === Constants.Q3) {
        qt = 'Q3'
    }

    let defColor = 0;
    let changedColor = 6930250;

    for (let row = 1; row < pt.GBRowCount; row++) {
        pt.FontColors[row][1] = user[row-1][qt] === def[row-1][qt] ? defColor : changedColor;
    }

    return (pt);
};

export const DirectSBCTomCPREditor = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
    GetSSData        : GetSSData,
    RecalcGrid       : RecalcGrid,
};