import * as React from "react";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';

class TEdit extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption      : PropTypes.string,
        name         : PropTypes.string,
        value        : PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
        type         : PropTypes.string,
        placeholder  : PropTypes.string,
        onChange     : PropTypes.func,
        style        : PropTypes.object,
        rootStyle    : PropTypes.object,
        inputStyle   : PropTypes.object,
        error        : PropTypes.bool,
        errorText    : PropTypes.string,
        disabled     : PropTypes.bool,
        InputProps   : PropTypes.object,
        fullWidth    : PropTypes.bool,
        foc          : PropTypes.bool,
        autoComplete : PropTypes.string,
        variant      : PropTypes.string,
        color        : PropTypes.string,
        helperText   : PropTypes.string,
        focused      : PropTypes.bool,
    };

    static defaultProps = {
        caption      : 'Caption',
        name         : 'TEdit1',
        value        : null,
        type         : '',
        placeholder  : '',
        onChange     : (value) => console.log('onChange [' + value + ']'),
        style        : {},
        rootStyle    : {}, //{padding: 0, margin: 0, height: 40},
        inputStyle   : {},
        error        : false,
        errorText    : '',
        disabled     : false,
        InputProps   : {},
        fullWidth    : false,
        foc          : false,
        autoComplete : 'off',
        variant      : 'standard',
        color        : 'primary',
        helperText   : '',
        focused      : false,
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onChange = (event) => {
        this.props.onChange(event.target.value, this.props.name);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        const selectFieldStyle = {
            marginRight: 25,
            paddingLeft: 0,
            marginBottom: 30
        };

        return (
            <TextField
                id={this.props.name}
                label={this.props.caption}
                value={this.props.value}
                onChange={this.onChange}
                type={this.props.type}
                style={{...selectFieldStyle, ...this.props.style}}
                error={this.props.error}
                disabled={this.props.disabled}
                InputProps={this.props.InputProps}
                fullWidth={this.props.fullWidth}
                autoFocus={this.props.foc}
                autoComplete={this.props.autoComplete}
                variant={this.props.variant}
                color={'secondary'}
                helperText={this.props.helperText}
                focused={this.props.focused}
            />
        );

        // const unstyledComponent = props => (
        //     <TextField
        //         id={this.props.name}
        //         label={this.props.caption}
        //         value={this.props.value}
        //         onChange={this.onChange}
        //         type={this.props.type}
        //         style={{...selectFieldStyle, ...this.props.style}}
        //         classes={{
        //             root : props.classes.root,
        //             // formControl : props.classes.formControl
        //         }}
        //     />
        // );

        // const StyledComponent = withStyles({
        //     root : this.props.rootStyle || {},
        //     // input : this.props.inputStyle || {},
        //     // formControl : {margin : 0, top: -15}
        // })(unstyledComponent);

        // return (
        //     <StyledComponent />
        // );
    }
}

export default TEdit;