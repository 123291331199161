import React from 'react';
import PropTypes from "prop-types";
import * as Constants from "../../utilities/Constants";
import { GBEditor } from '../../editors/GBEditors';
import infoImage from "../../img/infoImage3.png";

class ManualInterventionReach extends React.Component {

    static propTypes = {
        onPackTableChanged : PropTypes.func,
        onInfoBtnClick     : PropTypes.func,
        packTable          : PropTypes.object,
    };

    static defaultProps = {
        onPackTableChanged : () => console.log('onPackTableChanged'),
        onInfoBtnClick     : () => console.log('onInfoBtnClick'),
        packTable          : {}
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
    onPackTableChange = (packTable) => {
        this.props.onPackTableChanged(packTable);
    };

    onInfoBtnClick = (info) => {
        this.props.onInfoBtnClick(info);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        return (
            <div>
                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold'}} >
                    Annual reach
                </text>

                <text style={{display: "inline-block", fontSize: '20px', fontWeight: 'bold', fontStyle:'italic', color:'darkred'}}>
                    &nbsp;*(required)&nbsp;
                </text>

                <br/>

                <div style={{width: 1100, display: "inline-block"}}>
                    <GBEditor
                        key= {'manualIntervReach'}
                        id={Constants.Editor_IntervReachManual}
                        minHeight={445}
                        maxHeight={445}
                        packTable={this.props.packTable}
                        onPackTableChanged={this.onPackTableChange}
                        showTitle={false}
                    />
                </div>
            </div>
        );
    }
}

export default ManualInterventionReach;