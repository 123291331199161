import React from "react";
import PropTypes from "prop-types";
import Theme from "../app/Theme";
import GBDialog from "../@common/components/GBDialog";

class AboutDialog extends React.PureComponent {

    static propTypes = {
        onClose : PropTypes.func,
    };

    static defaultProps = {
        onClose : () => console.log("onClose"),
    };

    render() {
        return (
            <GBDialog
                title={'About FP Business Case Model'}
                Theme={Theme}
                onClose={this.props.onClose}
                content={
                    <div>
                        <p>Some text about FP Business Case Model</p>
                    </div>
                }
            />
        );
    }
}

export default AboutDialog;