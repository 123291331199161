import * as Constants from "../../../utilities/Constants";
import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import {fnAddCommas, generatePackChart, roundToSigFigure} from "../../../utilities/GBUtil"

const Create = (props) => {
    const {startYearIdx, resultsData} = props;

    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + Constants.numYrs; j++) {
        years.push((Constants.startYear + j).toString());
    }

    let editorName = 'SBC investments from ' + years[1] + ' to ' + years[Constants.numYrs] +
        ' are estimated to yield the following costs based on median projections:';

    let packTable = Setup();
    packTable = SetSSData(packTable, resultsData);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true);
    return ([packTable, packChart]);
};

const Setup = () => {
    let pt = createSimpleDefaultPackTable(4, 2, true);

    pt.GBFixedCols = 0;

    pt.tableData.value[1][1] = 'total SBC costs across the total time period';
    pt.tableData.value[2][1] = 'service delivery costs associated with the incremental increase in contraceptive use';
    pt.tableData.value[3][1] = 'total costs for investment time period';

    pt.LockedCells[1][0] = true;
    pt.LockedCells[2][0] = true;
    pt.LockedCells[3][0] = true;

    pt.LockedCells[1][1] = true;
    pt.LockedCells[2][1] = true;
    pt.LockedCells[3][1] = true;

    pt.RDec[1][0] = 0;
    pt.RDec[2][0] = 0;
    pt.RDec[3][0] = 0;

    pt.Alignments[1][0] = Constants.Right;
    pt.Alignments[2][0] = Constants.Right;
    pt.Alignments[3][0] = Constants.Right;

    pt.FontStyles[1][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[2][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[3][0]["intArray"] = [Constants.Bold];

    pt.FontColors[1][0] = 6660569;
    pt.FontColors[2][0] = 6660569;
    pt.FontColors[3][0] = 6660569;

    pt.GBColWidths[0] = 100;
    pt.GBColWidths[1] = 600;

    return pt;
};

const SetSSData = (pt, resultsData) => {

    pt.tableData.value[1][0] = '$ ' + fnAddCommas(roundToSigFigure(resultsData["costEffData"]["Cummulative_IV_Cost_With_Discount_Total"], 5).toString());
    pt.tableData.value[2][0] = '$ ' + fnAddCommas(roundToSigFigure(resultsData["costEffData"]["SD_Cost_Discounted_Cumm"], 5).toString());
    pt.tableData.value[3][0] = '$ ' + fnAddCommas(roundToSigFigure(resultsData["costEffData"]["total costs for investment time period"], 5).toString());

    return pt;
};

export const SBC_Investments_CE = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};