import * as React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import App from './App';
import Theme from './Theme';

const Root = () => (
    <MuiThemeProvider theme={Theme}>
        <App Theme={Theme} />
    </MuiThemeProvider>
);

export default Root;