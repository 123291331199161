import * as React from "react";
import PropTypes from "prop-types";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TLabel from './TLabel';

class TRadioGroup extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption      : PropTypes.string,
        handleChange : PropTypes.func,
        style        : PropTypes.object,
        itemIndex    : PropTypes.number,
        items        : PropTypes.arrayOf(PropTypes.any),
        row          : PropTypes.bool,
        usePaper     : PropTypes.bool,
        titleStyle   : PropTypes.object,
        disabled     : PropTypes.bool,
        itemStyle    : PropTypes.object,
        disabledArr  : PropTypes.array,
        radioBtnStyle : PropTypes.object,
        btnColor      : PropTypes.string,
    };

    static defaultProps = {
        caption      : 'Caption', //'This is a label',
        handleChange : () => console.log('onClick'),
        style        : {},
        itemIndex    : 0,
        items        : ["Item1", "Item2", "Item3"],
        row          : false,
        usePaper     : true,
        titleStyle   : {color: '#000000',
                        fontWeight: 'bold',
                        fontSize : 16,
                        padding : 0,
                        fontFamily: 'Lato, san-serif, serif'},
        disabled     : false,
        itemStyle    : { userSelect: "none" },
        disabledArr  : undefined,
        radioBtnStyle : {
            padding: '8px',
        },
        btnColor : "secondary",
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        //if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        //}
        //else {
        //    return false;
        //}
    }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    handleChange = (obj, value) => {
        this.props.handleChange(parseInt(value, 10));
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderItems = () => {
        // let styles = {
        //     padding: '8px',
        // };

        let items = [];

        for (let i = 0; i <= this.props.items.length-1; i++) {
            items.push(
                <FormControlLabel
                    value={i.toString()}
                    control={<Radio color={this.props.btnColor} style={this.props.radioBtnStyle}/>}
                    label={this.props.items[i]}
                    key={i}
                    disabled={this.props.disabled || (this.props.disabledArr && this.props.disabledArr[i])}
                    style={this.props.itemStyle}
                />
            );
        }

        return items;
    };

    render() {
        let styles = {
            paper : {
                
            },
            radioGroup : {
                marginTop: '5px',
                paddingLeft: 1,
                fontFamily: 'Lato, san-serif, serif',
            }
        };

        (this.props.usePaper) ?
            styles.paper = {
                background : "#FFFFFF",
                margin: '0 0 20px 0', 
                padding: 15,
                boxShadow: '1px 1px 1px 1px #bbbbbb',
                ...this.props.style
            }
            : 
            styles.paper = {
                padding: '15px 0', 
                ...this.props.style
            };

        return (
            <FormControl component="fieldset" style={styles.paper}>
                <TLabel 
                    caption={this.props.caption} 
                    style={this.props.titleStyle}
                />

                <RadioGroup
                    value={this.props.itemIndex.toString()}
                    onChange={this.handleChange}
                    row={this.props.row}
                    style={styles.radioGroup}
                >
                    {this.renderItems()}
                </RadioGroup>
            </FormControl>
        );
    }
}

export default TRadioGroup;