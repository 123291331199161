import * as Constants from "../../../utilities/Constants";
import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import {generatePackChart, roundToSigFigure} from "../../../utilities/GBUtil"

const Create = (props) => {
    const {startYearIdx, resultsData} = props;

    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + Constants.numYrs; j++) {
        years.push((Constants.startYear + j).toString());
    }

    let editorName = 'SBC investments from ' + years[1] + ' to ' + years[Constants.numYrs] +
        ' are estimated to yield the following cumulative outcomes:';

    let packTable = Setup(years);
    packTable = SetSSData(packTable, resultsData);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true);
    return ([packTable, packChart]);
};

const Setup = (years) => {
    let pt = createSimpleDefaultPackTable(5, 2, true);

    pt.GBFixedCols = 0;

    pt.tableData.value[1][1] = 'percentage point increase in mCPR';
    pt.tableData.value[2][1] = 'additional FP users by ' + years[Constants.numYrs];
    pt.tableData.value[3][1] = 'unintended pregnancies averted (cumulatively over 5 years)';
    pt.tableData.value[4][1] = 'maternal disability-adjusted life years (DALYs) averted (cumulatively over 5 years)';

    pt.LockedCells[1][0] = true;
    pt.LockedCells[2][0] = true;
    pt.LockedCells[3][0] = true;
    pt.LockedCells[4][0] = true;

    pt.LockedCells[1][1] = true;
    pt.LockedCells[2][1] = true;
    pt.LockedCells[3][1] = true;
    pt.LockedCells[4][1] = true;

    pt.RDec[1][0] = 1;
    pt.RDec[2][0] = 0;
    pt.RDec[3][0] = 0;
    pt.RDec[4][0] = 0;

    pt.Alignments[1][0] = Constants.Right;
    pt.Alignments[2][0] = Constants.Right;
    pt.Alignments[3][0] = Constants.Right;
    pt.Alignments[4][0] = Constants.Right;

    pt.FontStyles[1][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[2][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[3][0]["intArray"] = [Constants.Bold];
    pt.FontStyles[4][0]["intArray"] = [Constants.Bold];

    pt.FontColors[1][0] = 6660569;
    pt.FontColors[2][0] = 6660569;
    pt.FontColors[3][0] = 6660569;
    pt.FontColors[4][0] = 6660569;

    pt.GBColWidths[0] = 80;
    pt.GBColWidths[1] = 600;

    return pt;
};

const SetSSData = (pt, resultsData) => {

    pt.tableData.value[1][0] = resultsData["impactData"]["Increase"] * 100;
    pt.tableData.value[2][0] = roundToSigFigure(resultsData["costEffData"]["additional FP users"], 3);
    pt.tableData.value[3][0] = roundToSigFigure(resultsData["costEffData"]["Preg_Averted_Cumm"], 3);
    pt.tableData.value[4][0] = roundToSigFigure(resultsData["costEffData"]["DALY_Averted_Discounted_Cumm"], 3);

    return pt;
};

export const SBC_Investments = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};