import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import * as Constants from "../../utilities/Constants";

const Create = (interQuartileRangeIdx, impactORsData) => {

    let editorName = '1. Odds Ratios for the Relationship between SBC Interventions and Intermediate Outcomes';
    let packTable = Setup();
    packTable = SetSSData(packTable, interQuartileRangeIdx, impactORsData);
    packTable = RecalcGrid(packTable, interQuartileRangeIdx, impactORsData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = () => {
    let numRows = 6;
    let numCols = 7;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][1] = 'FP approval (women)';
    pt.tableData.value[0][2] = 'Perception of benefits';
    pt.tableData.value[0][3] = 'Self-efficacy';
    pt.tableData.value[0][4] = "Men's attitudes and support";
    pt.tableData.value[0][5] = 'Communication with partner';
    pt.tableData.value[0][6] = 'Communication with others';

    pt.tableData.value[1][0] = 'Mass media';
    pt.tableData.value[2][0] = 'Mobile/SMS';
    pt.tableData.value[3][0] = 'Individual IPC';
    pt.tableData.value[4][0] = 'Group IPC';
    pt.tableData.value[5][0] = 'IPC + Community engagement';

    
    for (let row = 1; row < numRows; row++) {
        pt.FontStyles[row][0]["intArray"] = [Constants.Bold];
        for (let col = 1; col < numCols; col++) {
            pt.RDec[row][col] = 2;
            pt.Alignments[row][col] = Constants.Right;
            pt.MinAllowedVal[row][col] = 0;
            pt.MaxAllowedVal[row][col] = 100;
        }
    }

    pt.GBColWidths[0] = 195;

    for (let c = 1; c < numCols; c++) {
        pt.GBColWidths[c] = 110;
    }

    return pt;
};

const SetSSData = (pt, interQuartileRangeIdx, impactORsData) => {
    let data = impactORsData['SBC_MedianUser'];
    if (interQuartileRangeIdx === Constants.Q1) {
        data = impactORsData['SBC_Q1User'];
    }
    else if (interQuartileRangeIdx === Constants.Q3) {
        data = impactORsData['SBC_Q3User'];
    }

    for (let row = 1; row < pt.GBRowCount; row++) {
        pt.tableData.value[row][1] = data[row-1]['FP approval'];
        pt.tableData.value[row][2] = data[row-1]['Perception of Benefits'];
        pt.tableData.value[row][3] = data[row-1]['Self-efficacy'];
        pt.tableData.value[row][4] = data[row-1]["Men's attitudes and support"];
        pt.tableData.value[row][5] = data[row-1]['Communication with partner'];
        pt.tableData.value[row][6] = data[row-1]['Communication with others'];
    }

    return pt;
};

const GetSSData = (pt, interQuartileRangeIdx, impactORsData) => {
    let data ='SBC_MedianUser';
    if (interQuartileRangeIdx === Constants.Q1) {
        data = 'SBC_Q1User';
    }
    else if (interQuartileRangeIdx === Constants.Q3) {
        data = 'SBC_Q3User';
    }

    for (let row = 1; row < pt.GBRowCount; row++) {
        impactORsData[data][row-1]['FP approval'] = pt.tableData.value[row][1];
        impactORsData[data][row-1]['Perception of Benefits'] = pt.tableData.value[row][2];
        impactORsData[data][row-1]['Self-efficacy'] = pt.tableData.value[row][3];
        impactORsData[data][row-1]["Men's attitudes and support"] = pt.tableData.value[row][4];
        impactORsData[data][row-1]['Communication with partner'] = pt.tableData.value[row][5];
        impactORsData[data][row-1]['Communication with others'] = pt.tableData.value[row][6];
    }

    return (impactORsData)
};

const RecalcGrid = (pt, interQuartileRangeIdx, impactORsData) => {
    let user = impactORsData['SBC_MedianUser'];
    let def = impactORsData['SBC_MedianDefault'];

    if (interQuartileRangeIdx === Constants.Q1) {
        user = impactORsData['SBC_Q1User'];
        def = impactORsData['SBC_Q1User'];
    }
    else if (interQuartileRangeIdx === Constants.Q3) {
        user = impactORsData['SBC_Q3User'];
        def = impactORsData['SBC_Q3User'];
    }

    let defColor = 0;
    let changedColor = 6930250;

    for (let row = 1; row < pt.GBRowCount; row++) {
        pt.FontColors[row][1] = user[row-1]['FP approval'] === def[row-1]['FP approval'] ? defColor : changedColor;
        pt.FontColors[row][2] = user[row-1]['Perception of Benefits'] === def[row-1]['Perception of Benefits'] ? defColor : changedColor;
        pt.FontColors[row][3] = user[row-1]['Self-efficacy'] === def[row-1]['Self-efficacy'] ? defColor : changedColor;
        pt.FontColors[row][4] = user[row-1]["Men's attitudes and support"] === def[row-1]["Men's attitudes and support"] ? defColor : changedColor;
        pt.FontColors[row][5] = user[row-1]['Communication with partner'] === def[row-1]['Communication with partner'] ? defColor : changedColor;
        pt.FontColors[row][6] = user[row-1]['Communication with others'] === def[row-1]['Communication with others'] ? defColor : changedColor;
    }

    return (pt);
};

export const SBCToIntermeidateDetEditor = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
    GetSSData        : GetSSData,
    RecalcGrid       : RecalcGrid,
};