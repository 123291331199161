import React from 'react';
import PropTypes from "prop-types";
import TButton from "../../@common/components/TButton"
import Theme from "../../app/Theme";
import * as Constants from "../../utilities/Constants";
import TComboBox from "../../@common/components/TComboBox";
import { ManualInterventionReachEditor } from '../../editors/Interventions/ManualInterventionReachEditor';
import { FinalYearInterventionReachEditor } from '../../editors/Interventions/FinalYearInterventionReachEditor';
import { CumulativeInterventionReachEditor } from '../../editors/Interventions/CumulativeInterventionReachEditor';
import { InterventionSenseCheckEditor } from '../../editors/Interventions/InterventionSenseCheckEditor';
import LeaveCustomDialog from '../../dialogs/LeaveCustomDialog';
import InterventionSupportDialog from '../../dialogs/InterventionSupport/InterventionSupportDialog';
import ManualInterventionReach from "./ManualInterventionReach"
import FinalYearInterventionReach from './FinalYearInterventionReach';
import CumulativeInterventionReach from './CumulativeInterventionReach';
import infoImage1 from "../../img/infoImage1.png";
import infoImage2 from "../../img/infoImage2.png";

class InterventionsPage extends React.Component {

    static propTypes = {
        onPageChange            : PropTypes.func,
        onStateChange           : PropTypes.func,
        onInfoBtnClick          : PropTypes.func,
        intervReachIdx          : PropTypes.number,
        startYearIdx            : PropTypes.number,
        interventionsData       : PropTypes.object,
        finalYearScaleIdx       : PropTypes.number,
        cumulativeScaleIdx      : PropTypes.number,
        finalYearRadioGroupIdx  : PropTypes.number,
        cumulativeRadioGroupIdx : PropTypes.number,
        modelParametersData     : PropTypes.object,
        shareOfPop              : PropTypes.number,
        selectedCountry         : PropTypes.string,
        costsData               : PropTypes.object,
        finalYearSupport        : PropTypes.object,
        cumulativeSupport       : PropTypes.object,
    };

    static defaultProps = {
        onPageChange            : () => console.log('onPageChange'),
        onStateChange           : () => console.log('onStateChange'),
        onInfoBtnClick          : () => console.log('onInfoBtnClick'),
        intervReachIdx          : Constants.IntervReach_Manual,
        startYearIdx            : 0,
        interventionsData       : {},
        finalYearScaleIdx       : Constants.Scale_S_Curve,
        cumulativeScaleIdx      : Constants.Scale_S_Curve,
        finalYearRadioGroupIdx  : Constants.ScaledValues,
        cumulativeRadioGroupIdx : Constants.ScaledValues,
        modelParametersData     : {},
        shareOfPop              : 100,
        selectedCountry         : 'Afghanistan',
        costsData               : {},
        finalYearSupport        : {},
        cumulativeSupport       : {},
    };

    state = {
        packTable1                    : undefined,
        packTable2                    : undefined,
        packTable3                    : undefined,
        showLeaveCustomDialog         : false,
        showInterventionSupportDialog : false,
    };

    //==================================================================================================================
    //
    //                                                 Life Cycles
    //
    //==================================================================================================================

    componentDidMount() {
        this.setState({
            packTable1 : ManualInterventionReachEditor.Create(this.props.interventionsData, this.props.startYearIdx),
            packTable2 : FinalYearInterventionReachEditor.Create(this.props.interventionsData, this.props.startYearIdx, this.props.finalYearScaleIdx, this.props.finalYearRadioGroupIdx),
            packTable3 : CumulativeInterventionReachEditor.Create(this.props.interventionsData, this.props.startYearIdx, this.props.cumulativeScaleIdx, this.props.cumulativeRadioGroupIdx),
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const hasChanged = 
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState));
        
        return hasChanged;
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
    getIntervReachEditorValues = (successFn) => {
        let editorIdx = this.props.intervReachIdx;
        let interventionsData;
        if (editorIdx === Constants.IntervReach_Manual) {
            interventionsData = ManualInterventionReachEditor.GetSSData(this.state.packTable1, this.props.interventionsData);
        }
        else if (editorIdx === Constants.IntervReach_FinalYear) {
            interventionsData = FinalYearInterventionReachEditor.GetSSData(this.state.packTable2, this.props.interventionsData);
        }
        else {
            interventionsData = CumulativeInterventionReachEditor.GetSSData(this.state.packTable3, this.props.interventionsData);
        }

        this.props.onStateChange ({
            interventionsData : interventionsData
        }, successFn);

    };

    onIntervReachComboBoxChange = (idx) => {
        this.getIntervReachEditorValues(successFn => {
            this.props.onStateChange ({
                intervReachIdx : idx
            }, successFn)
        });    
    };

    onPackTable1Change = (packTable) => {
        packTable = ManualInterventionReachEditor.RecalcGrid(packTable);

        this.setState ({
            packTable1 : packTable
        }, () => {this.getIntervReachEditorValues(successFn => {
            });  
        }); 
    };

    onPackTable2Change = (packTable) => {
        let useCustomValues = false;
        if (this.props.finalYearRadioGroupIdx === Constants.CustomValues) {
            useCustomValues = true;
        }
        packTable = FinalYearInterventionReachEditor.RecalcGrid(packTable, this.props.finalYearScaleIdx, useCustomValues);

        this.setState ({
            packTable2 : packTable
        }, () => {this.getIntervReachEditorValues(successFn => {
            });  
        }); 
    };

    onPackTable3Change = (packTable) => {
        let useCustomValues = false;
        if (this.props.cumulativeRadioGroupIdx === Constants.CustomValues) {
            useCustomValues = true;
        }
        packTable = CumulativeInterventionReachEditor.RecalcGrid(packTable, this.props.cumulativeScaleIdx, useCustomValues);

        this.setState ({
            packTable3 : packTable
        }, () => {this.getIntervReachEditorValues(successFn => {
            });  
        });  
    };

    onFinalYearScaleComboBoxChange = (idx) => {
        let interventionsData = FinalYearInterventionReachEditor.GetSSData(this.state.packTable2, this.props.interventionsData);
        this.props.onStateChange ({
            interventionsData : interventionsData
        }, () => {
            this.props.onStateChange ({
                finalYearScaleIdx : idx
            });

            this.setState ({
                packTable2 : FinalYearInterventionReachEditor.Create(interventionsData, this.props.startYearIdx, idx, this.props.finalYearRadioGroupIdx)
            }, () => {this.getIntervReachEditorValues(successFn => {
                });  
            });
        })
    };

    onFinalYearRadioGroupChange = (idx) => {
        let interventionsData = FinalYearInterventionReachEditor.GetSSData(this.state.packTable2, this.props.interventionsData);
        this.props.onStateChange ({
            interventionsData : interventionsData
        }, () => {
            if (idx === Constants.ScaledValues) {
                this.setState ({
                    showLeaveCustomDialog : true
                })
            }
            else {
                this.props.onStateChange ({
                    finalYearRadioGroupIdx : idx,
                });
    
                this.setState ({
                    packTable2 : FinalYearInterventionReachEditor.Create(interventionsData, this.props.startYearIdx, this.props.finalYearScaleIdx, idx)
                }, () => {this.getIntervReachEditorValues(successFn => {
                    });  
                });
            }
        })
    };

    onCumulativeScaleComboBoxChange = (idx) => {
        let interventionsData = CumulativeInterventionReachEditor.GetSSData(this.state.packTable3, this.props.interventionsData);
        this.props.onStateChange ({
            interventionsData : interventionsData
        }, () => {
            this.props.onStateChange ({
                cumulativeScaleIdx : idx
            });

            this.setState ({
                packTable3 : CumulativeInterventionReachEditor.Create(interventionsData, this.props.startYearIdx, idx, this.props.cumulativeRadioGroupIdx)
            }, () => {this.getIntervReachEditorValues(successFn => {
                });  
            });
        })
    };

    onCumulativeRadioGroupChange = (idx) => {
        let interventionsData = CumulativeInterventionReachEditor.GetSSData(this.state.packTable3, this.props.interventionsData);
        this.props.onStateChange ({
            interventionsData : interventionsData
        }, () => {
            if (idx === Constants.ScaledValues) {
                this.setState ({
                    showLeaveCustomDialog : true
                })
            }
            else {
                this.props.onStateChange ({
                    cumulativeRadioGroupIdx : idx,
                });
    
                this.setState ({
                    packTable3 : CumulativeInterventionReachEditor.Create(interventionsData, this.props.startYearIdx, this.props.cumulativeScaleIdx, idx)
                }, () => {this.getIntervReachEditorValues(successFn => {
                    });  
                });
            }
        })
    };

    onNoBtnClick = () => {
        this.setState ({
            showLeaveCustomDialog: false
        })
    };

    onYesBtnClick = () => {
        if (this.props.intervReachIdx === Constants.IntervReach_FinalYear) {
            let interventionsData = FinalYearInterventionReachEditor.GetSSData(this.state.packTable2, this.props.interventionsData);
            this.props.onStateChange ({
                finalYearRadioGroupIdx : Constants.ScaledValues,
                interventionsData : interventionsData
            }, () => {
                this.setState ({
                    packTable2 : FinalYearInterventionReachEditor.Create(interventionsData, this.props.startYearIdx, this.props.finalYearScaleIdx, Constants.ScaledValues),
                    showLeaveCustomDialog : false
                }, () => {this.getIntervReachEditorValues(successFn => {
                    });  
                });
            })
        }
        else {
            let interventionsData = CumulativeInterventionReachEditor.GetSSData(this.state.packTable3, this.props.interventionsData);
            this.props.onStateChange ({
                cumulativeRadioGroupIdx : Constants.ScaledValues,
                interventionsData : interventionsData
            }, () => {
                this.setState ({
                    packTable3 : CumulativeInterventionReachEditor.Create(interventionsData, this.props.startYearIdx, this.props.cumulativeScaleIdx, Constants.ScaledValues),
                    showLeaveCustomDialog : false
                }, () => {this.getIntervReachEditorValues(successFn => {
                    });  
                });
            })
        }
    };

    onInterventionSupportBtnClick = () => {
        this.getIntervReachEditorValues(successFn => {
            this.setState ({
                showInterventionSupportDialog : true
            }, successFn)
        }); 
    };

    onInterventionSupportCloseBtnClick = () => {
        this.setState ({
            showInterventionSupportDialog : false
        })
    };

    onInterventionSupportApplyBtnClick = () => {
        let packTable2 = FinalYearInterventionReachEditor.Create(this.props.interventionsData, this.props.startYearIdx, this.props.finalYearScaleIdx, this.props.finalYearRadioGroupIdx);
        let packTable3 = CumulativeInterventionReachEditor.Create(this.props.interventionsData, this.props.startYearIdx, this.props.cumulativeScaleIdx, this.props.cumulativeRadioGroupIdx);
        let packTable4 = InterventionSenseCheckEditor.Create(this.props.intervReachIdx, this.props.startYearIdx, this.props.shareOfPop, this.props.selectedCountry, this.props.interventionsData, 
                                                             this.props.modelParametersData, this.props.costsData);

        if (this.props.intervReachIdx === Constants.IntervReach_FinalYear) {
            this.setState ({
                packTable2                    : packTable2,
                packTable4                    : packTable4,
                showInterventionSupportDialog : false
            }, () => {this.getIntervReachEditorValues(successFn => {
            });  
        });
        }
        else if (this.props.intervReachIdx === Constants.IntervReach_Cumulative) {
            this.setState ({
                packTable3                    : packTable3,
                packTable4                    : packTable4,
                showInterventionSupportDialog : false
            }, () => {this.getIntervReachEditorValues(successFn => {
            });  
        });
        }
    };

    onBackBtnClick = () => {
        this.getIntervReachEditorValues(successFn => {
            this.props.onPageChange("CostsPage")
        }); 
    };

    onNextBtnClick = () => {
        this.getIntervReachEditorValues(successFn => {
            this.props.onPageChange("SenseCheckPage")
        }); 
    };

    onInfoBtnClick = (info) => {
        this.props.onInfoBtnClick(info);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            page : {
                marginLeft : 25
            },
            margins : {
                marginBottom : 30,
            },
            header : {
                fontSize: 24,
                fontWeight: 'bold',
                color: Theme.palette.primary.main,
                marginBottom: 20
            },
            instructionsText : {
                fontSize: 18,
                color: Theme.palette.primary.main,
                marginBottom: 25,
            },
            instructionsText2 : {
                fontSize: 18,
                color: Theme.palette.primary.main,
            },
            backBtn : {
                marginRight: 20
            },
            radioGroup : { 
                width: 400,
                boxShadow: 'none',
                marginBottom: 10,
                paddingLeft: 0
            },
            helpIconStyle : {
                color: Theme.palette.fourth.main,
                marginTop: 8,
                marginLeft: -45
            },
        };

        return (
            <div style={styles.page}>
                <div style={styles.header}>{"Specify intervention reach"}</div>
                <div style={styles.instructionsText2}>
                    {"In this step, you need to enter the number of persons reached by the SBC interventions for each year of the application. There are no default data available for this step, so you must enter values to get results. Only enter values for the SBC interventions you would like to include in your application; leave remaining interventions at zero."}
                </div>
                {/*<div style={styles.instructionsText}>*/}
                {/*    {"To begin, use the dropdown list to select one of three options for entering values. Click on the “i” icons and the “Help me set values” button for guidance."}*/}
                {/*</div>*/}

                <text style={styles.instructionsText}>
                    To begin, use the dropdown list to select one of three options for entering values. Click on the &nbsp;&nbsp;
                </text>

                <img src={infoImage2} alt="" />

                <text style={styles.instructionsText}>
                    &nbsp;&nbsp; icons and the “Help me set values” button for guidance.
                </text>

                <br/>
                <br/>

                <div style={{display: "inline-block"}}>
                    <TComboBox
                        caption={"Intervention reach"}
                        items={[
                            "Enter annual reach manually",
                            "Specify final year 5 reach/scale-up",
                            "Specify cumulative reach over 5 years",
                        ]}
                        itemIndex={this.props.intervReachIdx}
                        onChange={this.onIntervReachComboBoxChange}
                        width={"300px"}
                        style={styles.margins}
                    />
                </div>

                <div style={{display: "inline-block"}}>
                    <img
                        src     = {infoImage1}
                        alt     = ""
                        style   = {{cursor: 'pointer'}}
                        onClick = {() => this.onInfoBtnClick([
                            <p>When entering in intervention scale-up, three options are available:</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Enter reach manually –</b>&nbsp;enter the
                                number of persons expected to be reached for each of the five years</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Specify final year 5 reach/scale-up –</b>
                                &nbsp;enter the number of persons expected to be reached in year 5. The other values will be
                                determined based on the scale-up pattern (e.g. linear, s-cure) selected below</p>,
                            <p style={{textIndent: 50}}>o&nbsp;&nbsp;&nbsp;<b>Specify cumulative reach over 5 years -</b>
                                &nbsp;enter the number of persons expected to be reach in total over five years. The annual
                                values will be determined based on the scale-up pattern (e.g. linear, s-curve) selected
                                below</p>,
                            <p>If you want to use different entry options for different interventions, we recommend you
                                copy your entries into a separate workbook and then aggregate your numbers using the
                                “Enter reach manually” option. Further assistance in specifying reach can be found
                                using the  “Help me set values” button.</p>
                        ])}
                    />
                </div>

                <br />

                {this.props.intervReachIdx === Constants.IntervReach_Manual ? (
                    <ManualInterventionReach
                        packTable={this.state.packTable1}
                        onPackTableChanged={this.onPackTable1Change}
                        onInfoBtnClick={this.onInfoBtnClick}
                    />
                ) : null}

                {this.props.intervReachIdx === Constants.IntervReach_FinalYear ? (
                    <FinalYearInterventionReach
                        onPackTableChanged={this.onPackTable2Change}
                        onFinalYearScaleComboBoxChange={this.onFinalYearScaleComboBoxChange}
                        onFinalYearRadioGroupChange={this.onFinalYearRadioGroupChange}
                        onInterventionSupportBtnClick={this.onInterventionSupportBtnClick}
                        onInfoBtnClick={this.onInfoBtnClick}
                        packTable={this.state.packTable2}
                        finalYearScaleIdx={this.props.finalYearScaleIdx}
                        finalYearRadioGroupIdx={this.props.finalYearRadioGroupIdx}
                    />
                ) : null}

                {this.props.intervReachIdx === Constants.IntervReach_Cumulative ? (
                    <CumulativeInterventionReach
                        onPackTableChanged={this.onPackTable3Change}
                        onCumulativeScaleComboBoxChange={this.onCumulativeScaleComboBoxChange}
                        onCumulativeRadioGroupChange={this.onCumulativeRadioGroupChange}
                        onInterventionSupportBtnClick={this.onInterventionSupportBtnClick}
                        onInfoBtnClick={this.onInfoBtnClick}
                        packTable={this.state.packTable3}
                        cumulativeScaleIdx={this.props.cumulativeScaleIdx}
                        cumulativeRadioGroupIdx={this.props.cumulativeRadioGroupIdx}
                    />

                ) : null}

                <br />

                <TButton 
                    caption={"Back"} 
                    color={"secondary"} 
                    style={styles.backBtn} 
                    onClick={this.onBackBtnClick} 
                />
                <TButton 
                    caption={"Next"} 
                    color={"primary"} 
                    onClick={this.onNextBtnClick} 
                />

                {
                    (this.state.showLeaveCustomDialog) ?
                        <LeaveCustomDialog
                            onYesBtnClick={this.onYesBtnClick}
                            onNoBtnClick={this.onNoBtnClick}
                        />
                        :
                        null
                }

                {
                    (this.state.showInterventionSupportDialog) ?
                        <InterventionSupportDialog
                            onStateChange={this.props.onStateChange}
                            onInterventionSupportApplyBtnClick={this.onInterventionSupportApplyBtnClick}
                            onInterventionSupportCloseBtnClick={this.onInterventionSupportCloseBtnClick}
                            startYearIdx={this.props.startYearIdx}
                            intervReachIdx={this.props.intervReachIdx}
                            interventionSupport={this.props.finalYearSupport}
                            interventionsData={this.props.interventionsData}
                            modelParametersData={this.props.modelParametersData}
                            selectedCountry={this.props.selectedCountry}
                            shareOfPop={this.props.shareOfPop}
                        />
                        :
                        null
                }
            </div>
        );
    }
}

export default InterventionsPage;