import React from 'react';
import PropTypes from "prop-types";
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Constants from "../../utilities/Constants";
import TComboBox from "../../@common/components/TComboBox";
import TEdit from "../../@common/components/TEdit"
import { getIPCInterventionName, NumberFormatCustom  } from "../../utilities/GBUtil"

class IPCInterventions extends React.Component {

    static propTypes = {
        onStateChange                   : PropTypes.func,
        onIPCInterventionComboBoxChange : PropTypes.func,
        onIPCOptionComboBoxChange       : PropTypes.func,
        onIPCEditChange                 : PropTypes.func,
        interventionSupport             : PropTypes.object,  
        IPCInterventionIdx              : PropTypes.number,
        IPCDirectEntryValue             : PropTypes.number,
        IPCReachCHWValue                : PropTypes.number,
        IPCReachProvidersValue          : PropTypes.number,
        IPCReachPeerEducationValue      : PropTypes.number,
        IPCReachGroupCounsellingValue   : PropTypes.number,
        IPCReachSchoolBasedValue        : PropTypes.number,
        IPCReachReligiousLeadersValue   : PropTypes.number,
        IPCReachCommLeadersValue        : PropTypes.number,   
        intervReachIdx                  : PropTypes.number,
        finalYearStr                    : PropTypes.string,
    };

    static defaultProps = {
        onStateChange                   : () => console.log('onStateChange'),
        onIPCInterventionComboBoxChange : () => console.log('onIPCInterventionComboBoxChange'),
        onIPCOptionComboBoxChange       : () => console.log('onIPCOptionComboBoxChange'),
        onIPCEditChange                 : () => console.log('onIPCEditChange'),
        interventionSupport             : {},
        IPCInterventionIdx              : 0,
        IPCDirectEntryValue             : 0,
        IPCReachCHWValue                : 0,
        IPCReachProvidersValue          : 0,
        IPCReachPeerEducationValue      : 0,
        IPCReachGroupCounsellingValue   : 0,
        IPCReachSchoolBasedValue        : 0,
        IPCReachReligiousLeadersValue   : 0,
        IPCReachCommLeadersValue        : 0,
        intervReachIdx                  : 0,
        finalYearStr                    : '',
    };

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================
   
    onIPCInterventionComboBoxChange = (idx) => {
        this.props.onIPCInterventionComboBoxChange(idx);
    };

    onIPCOptionComboBoxChange = (idx) => {
        this.props.onIPCOptionComboBoxChange(idx);
    };

    onIPCEditChange = (value, name) => {
        this.props.onIPCEditChange(Number(value), name);
    };

    getIPCCalculationOptions = () => {
        let idx = this.props.IPCInterventionIdx + 8;
        let options = ['Direct entry'];
        if (idx === Constants.householdIPC) {
            options.push('Community health workers');
            options.push('Providers');
        }
        else if (idx === Constants.groupIPC) {
            options.push('Peer education');
            options.push('Group counselling');
            options.push('School based');
        }
        else {
            options.push('Religious leaders');
            options.push('Community leaders');
        }
        return (options)
    };

    //==================================================================================================================
    //
    //                                                String Functions
    //
    //==================================================================================================================
    getDirectEntryStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Value for ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Value over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getCommHealthWorkersStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'CHWs trained by ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'CHWs trained over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getWomenPerCHWStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Women reached per CHW per year'; break;
            case Constants.IntervReach_Cumulative : str = 'Women reached per CHW over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getReachStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Reach in ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Reach over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getProvidersStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Providers trained by ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Providers trained over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getWomenPerProviderStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Women reached per provider per year'; break;
            case Constants.IntervReach_Cumulative : str = 'Women reached per provider over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getPeerEducatorStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Peer educators trained by ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Peer educators trained over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getClassesPerEducatorStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Classes per peer educator per year'; break;
            case Constants.IntervReach_Cumulative : str = 'Classes per peer educator over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getCounselorsStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Counselors trained by ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Counselors trained over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getSessionsPerCounselorStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Sessions per counselor per year'; break;
            case Constants.IntervReach_Cumulative : str = 'Sessions per counselor over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getTeachersStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Teachers trained by ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Teachers trained over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getClassesPerTeacherStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Classes per teacher per year'; break;
            case Constants.IntervReach_Cumulative : str = 'Classes per teacher over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getReligiousLeadersStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Religious leaders trained by ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Religious leaders trained over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getCongregantsPerLeaderStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Congregants reached per leader per year'; break;
            case Constants.IntervReach_Cumulative : str = 'Congregants reached per leader over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getCommunityLeadersStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Community leaders trained by ' + this.props.finalYearStr; break;
            case Constants.IntervReach_Cumulative : str = 'Community leaders trained over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };

    getEventsPerLeaderStr = () => {
        let str = '';
        switch (this.props.intervReachIdx) {
            case Constants.IntervReach_FinalYear  : str = 'Events held per leader per year'; break;
            case Constants.IntervReach_Cumulative : str = 'Events held per leader over 5 years'; break;
            default : str = ''; break;
        }
        return (str)
    };
    
    //==================================================================================================================
    //
    //                                                Formula functions
    //
    //==================================================================================================================  
    getFormula = () => {
        if (this.props.IPCInterventionIdx === Constants.householdIPCOpt) {
            return(this.getHouseholdIPCFormula());
        }
        else if (this.props.IPCInterventionIdx === Constants.groupIPCOpt) {
            return(this.getGroupIPCFormula());
        }
        else if (this.props.IPCInterventionIdx === Constants.IPCCommEngagementOpt) {
            return(this.getIPCCommEngagementFormula());
        }
    };

    getHouseholdIPCFormula = () => {
        const styles = {
            operandStyle : {
                fontWeight: 'bold',
                fontSize: 18,
                display: 'inline-block',
                marginLeft: 25,
                marginRight: 25,
                marginTop: 35
            },
            TEditStyle : {
                width: 205, 
                marginTop: 20,
                marginBottom: 0,
                marginRight: 0,
                color: 'secondary'
            }
        }
        let directEntry = 0;
        let communityHealthWorkers = 1;
        let providers = 2;
        let option = this.props.interventionSupport.IPC.optionIdxArray[this.props.IPCInterventionIdx];

        if (option === directEntry) {
            return (
                <div>
                    <TEdit
                        key={'directEntry'}
                        name='directEntry'
                        caption={this.getDirectEntryStr()}
                        value={this.props.IPCDirectEntryValue}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter value'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === communityHealthWorkers) {
            return (
                <div>
                    <TEdit
                        key={'commHealthWorkers'}
                        name='commHealthWorkers'
                        caption={this.getCommHealthWorkersStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['commHealthWorkers']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'womenPerCHW'}
                        name='womenPerCHW'
                        caption={this.getWomenPerCHWStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['womenPerCHW']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachCHW'}
                        name='reachCHW'
                        caption={this.getReachStr()}
                        value={this.props.IPCReachCHWValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === providers) {
            return (
                <div>
                    <TEdit
                        key={'providers'}
                        name='providers'
                        caption={this.getProvidersStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['providers']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'womenPerProvider'}
                        name='womenPerProvider'
                        caption={this.getWomenPerProviderStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['womenPerProvider']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachProviders'}
                        name='reachProviders'
                        caption={this.getReachStr()}
                        value={this.props.IPCReachProvidersValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
    };

    getGroupIPCFormula = () => {
        const styles = {
            operandStyle : {
                fontWeight: 'bold',
                fontSize: 18,
                display: 'inline-block',
                marginLeft: 25,
                marginRight: 25,
                marginTop: 35
            },
            TEditStyleDirectEntry : {
                width: 205, 
                marginTop: 20,
                marginBottom: 0,
                marginRight: 0,
                color: 'secondary'
            },
            TEditStyle : {
                width: 160, 
                marginTop: 20,
                marginRight: 0,
                marginBottom: 0,
                color: 'secondary'
            }
        }
        let directEntry = 0;
        let peerEducation = 1;
        let groupCounselling = 2;
        let schoolBased = 3;
        let option = this.props.interventionSupport.IPC.optionIdxArray[this.props.IPCInterventionIdx];

        if (option === directEntry) {
            return (
                <div>
                    <TEdit
                        key={'directEntry'}
                        name='directEntry'
                        caption={this.getDirectEntryStr()}
                        value={this.props.IPCDirectEntryValue}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyleDirectEntry}
                        variant={'outlined'}
                        helperText={'Please enter value'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === peerEducation) {
            return (
                <div>
                    <TEdit
                        key={'peerEducator'}
                        name='peerEducator'
                        caption={this.getPeerEducatorStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['peerEducator']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'classesperEducator'}
                        name='classesPerEducator'
                        caption={this.getClassesPerEducatorStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['classesPerEducator']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'womenPerClass'}
                        name='womenPerClass'
                        caption={'Women per class'}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['womenPerClass']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachPeerEducation'}
                        name='reachPeerEducation'
                        caption={this.getReachStr()}
                        value={this.props.IPCReachPeerEducationValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === groupCounselling) {
            return (
                <div>
                    <TEdit
                        key={'counselors'}
                        name='counselors'
                        caption={this.getCounselorsStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['counselors']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'sessionsPerCounselor'}
                        name='sessionsPerCounselor'
                        caption={this.getSessionsPerCounselorStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['sessionsPerCounselor']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'womenPerSession'}
                        name='womenPerSession'
                        caption={'Women per session'}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['womenPerSession']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachGroupCounselling'}
                        name='reachGroupCounselling'
                        caption={this.getReachStr()}
                        value={this.props.IPCReachGroupCounsellingValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === schoolBased) {
            return (
                <div>
                    <TEdit
                        key={'teachers'}
                        name='teachers'
                        caption={this.getTeachersStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['teachers']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'classesPerTeacher'}
                        name='classesPerTeacher'
                        caption={this.getClassesPerTeacherStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['classesPerTeacher']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'girlsPerClass'}
                        name='girlsPerClass'
                        caption={'Girls per class'}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['girlsPerClass']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachSchoolBased'}
                        name='reachSchoolBased'
                        caption={this.getReachStr()}
                        value={this.props.IPCReachSchoolBasedValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
    };

    getIPCCommEngagementFormula = () => {
        const styles = {
            operandStyle : {
                fontWeight: 'bold',
                fontSize: 18,
                display: 'inline-block',
                marginLeft: 25,
                marginRight: 25,
                marginTop: 35
            },
            TEditStyleDirectEntry : {
                width: 205, 
                marginTop: 20,
                marginBottom: 0,
                marginRight: 0,
                color: 'secondary'
            },
            TEditStyle : {
                width: 160, 
                marginTop: 20,
                marginRight: 0,
                marginBottom: 0,
                color: 'secondary'
            }
        }
        let directEntry = 0;
        let religiousLeaders = 1;
        let communityLeaders = 2;
        let option = this.props.interventionSupport.IPC.optionIdxArray[this.props.IPCInterventionIdx];

        if (option === directEntry) {
            return (
                <div>
                    <TEdit
                        key={'directEntry'}
                        name='directEntry'
                        caption={this.getDirectEntryStr()}
                        value={this.props.IPCDirectEntryValue}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyleDirectEntry}
                        variant={'outlined'}
                        helperText={'Please enter value'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === religiousLeaders) {
            return (
                <div>
                    <TEdit
                        key={'religiousLeaders'}
                        name='religiousLeaders'
                        caption={this.getReligiousLeadersStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['religiousLeaders']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'congregantsPerLeader'}
                        name='congregantsPerLeader'
                        caption={this.getCongregantsPerLeaderStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['congregantsPerLeader']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'perWomenCongregants'}
                        name='percWomenCongregants'
                        caption={'Percent women congregants'}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['percWomenCongregants']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter percent'}
                        focused={true}
                        type={'number'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachReligousLeaders'}
                        name='reachReligiousLeaders'
                        caption={this.getReachStr()}
                        value={this.props.IPCReachReligiousLeadersValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
        else if (option === communityLeaders) {
            return (
                <div>
                    <TEdit
                        key={'communityLeaders'}
                        name='communityLeaders'
                        caption={this.getCommunityLeadersStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['communityLeaders']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'eventsPerLeader'}
                        name='eventsPerLeader'
                        caption={this.getEventsPerLeaderStr()}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['eventsPerLeader']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>x</p>
                    <TEdit
                        key={'womenPerEvent'}
                        name='womenPerEvent'
                        caption={'Women per event'}
                        value={this.props.interventionSupport.IPC[getIPCInterventionName(this.props.IPCInterventionIdx)]['womenPerEvent']}
                        onChange={this.onIPCEditChange}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        helperText={'Please enter number'}
                        focused={true}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                    <p style={styles.operandStyle}>=</p>
                    <TEdit
                        key={'reachCommLeaders'}
                        name='reachCommLeaders'
                        caption={this.getReachStr()}
                        value={this.props.IPCReachCommLeadersValue}
                        style={styles.TEditStyle}
                        variant={'outlined'}
                        disabled={true}
                        helperText={'Calculated value'}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            )
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {   
        const styles = {
            margins : {
                marginBottom : 20,
            },
        }

        return (
            <div>
                  <TComboBox
                    caption={"Interventions"}
                    items={[
                        "Individual/household IPC",
                        "Group IPC",
                        "IPC + Community engagement",
                    ]}
                    itemIndex={this.props.IPCInterventionIdx}
                    onChange={this.onIPCInterventionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />
                <TComboBox
                    caption={"Calculation options"}
                    items={this.getIPCCalculationOptions()}
                    itemIndex={this.props.interventionSupport.IPC.optionIdxArray[this.props.IPCInterventionIdx]}
                    onChange={this.onIPCOptionComboBoxChange}
                    width={"250px"}
                    style={styles.margins}
                />
                {this.getFormula()}
            </div>
        );
    }
}

export default IPCInterventions;