import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Favicon from 'react-favicon';
import { LicenseManager } from "ag-grid-enterprise";
import App from './app/Root';

LicenseManager.setLicenseKey(
    "Avenir_Health_Avenir_Health_Multiple_Applications_2_Devs__28_January_2021_[v2]_MTYxMTc5MjAwMDAwMA==62ff0b74c26c807c9902b7aeb33d19e6"
);

ReactDOM.render(
  <React.Fragment>
    <Favicon url={process.env.PUBLIC_URL + "/favicon.ico"}/>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

