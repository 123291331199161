import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import * as Constants from "../../utilities/Constants"

const Create = (countryName, modelParametersData) => {

    let editorName = 'FP method mix';
    let packTable = Setup();
    packTable = SetSSData(packTable, countryName, modelParametersData);
    packTable = RecalcGrid(packTable, countryName, modelParametersData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = () => {
    let numRows = 9;
    let numCols = 3;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][1] = 'Percent';
    pt.tableData.value[0][2] = 'Default source';

    pt.tableData.value[1][0] = 'Sterilization';
    pt.tableData.value[2][0] = 'IUD';
    pt.tableData.value[3][0] = 'Implants';
    pt.tableData.value[4][0] = 'Injection';
    pt.tableData.value[5][0] = 'Pill';
    pt.tableData.value[6][0] = 'Condoms';
    pt.tableData.value[7][0] = 'LAM';
    pt.tableData.value[8][0] = 'Other';

    // for (let row = 1; row < pt.GBRowCount; row++) {
    //     pt.LockedCells[row][2] = true;
    // }
    
    for (let row = 1; row < numRows; row++) {
        pt.FontStyles[row][0]["intArray"] = [Constants.Bold];
        pt.RDec[row][1] = 1;
        pt.Alignments[row][1] = Constants.Right;
        pt.MinAllowedVal[row][1] = 0;
        pt.MaxAllowedVal[row][1] = 100;
    }

    pt.GBColWidths[1] = 70;

    return pt;
};

const SetSSData = (pt,countryName, modelParametersData) => {
    let countryData = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            countryData = modelParametersData["externalUser"][i];
        }
    }

    pt.tableData.value[1][1] = countryData["Method Mix Sterilization"];
    pt.tableData.value[2][1] = countryData["Method Mix IUD"];
    pt.tableData.value[3][1] = countryData["Method Mix Implants"];
    pt.tableData.value[4][1] = countryData["Method Mix Injection"];
    pt.tableData.value[5][1] = countryData["Method Mix Pill"];
    pt.tableData.value[6][1] = countryData["Method Mix Condoms"];
    pt.tableData.value[7][1] = countryData["Method Mix LAM"];
    pt.tableData.value[8][1] = countryData["Method Mix Other"];

    pt.tableData.value[1][2] = countryData["Method Mix Sterilization Source"];
    pt.tableData.value[2][2] = countryData["Method Mix IUD Source"];
    pt.tableData.value[3][2] = countryData["Method Mix Implants Source"];
    pt.tableData.value[4][2] = countryData["Method Mix Injection Source"];
    pt.tableData.value[5][2] = countryData["Method Mix Pill Source"];
    pt.tableData.value[6][2] = countryData["Method Mix Condoms Source"];
    pt.tableData.value[7][2] = countryData["Method Mix LAM Source"];
    pt.tableData.value[8][2] = countryData["Method Mix Other Source"];

    return pt;
};

const GetSSData = (pt, countryName, modelParametersData) => {
    let country;
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            country = i;
        }
    }

    modelParametersData["externalUser"][country]["Method Mix Sterilization"] = pt.tableData.value[1][1];
    modelParametersData["externalUser"][country]["Method Mix IUD"]           = pt.tableData.value[2][1];
    modelParametersData["externalUser"][country]["Method Mix Implants"]      = pt.tableData.value[3][1];
    modelParametersData["externalUser"][country]["Method Mix Injection"]     = pt.tableData.value[4][1];
    modelParametersData["externalUser"][country]["Method Mix Pill"]          = pt.tableData.value[5][1];
    modelParametersData["externalUser"][country]["Method Mix Condoms"]       = pt.tableData.value[6][1];
    modelParametersData["externalUser"][country]["Method Mix LAM"]           = pt.tableData.value[7][1];
    modelParametersData["externalUser"][country]["Method Mix Other"]         = pt.tableData.value[8][1];

    modelParametersData["externalUser"][country]["Method Mix Sterilization Source"] = pt.tableData.value[1][2];
    modelParametersData["externalUser"][country]["Method Mix IUD Source"]           = pt.tableData.value[2][2];
    modelParametersData["externalUser"][country]["Method Mix Implants Source"]      = pt.tableData.value[3][2];
    modelParametersData["externalUser"][country]["Method Mix Injection Source"]     = pt.tableData.value[4][2];
    modelParametersData["externalUser"][country]["Method Mix Pill Source"]          = pt.tableData.value[5][2];
    modelParametersData["externalUser"][country]["Method Mix Condoms Source"]       = pt.tableData.value[6][2];
    modelParametersData["externalUser"][country]["Method Mix LAM Source"]           = pt.tableData.value[7][2];
    modelParametersData["externalUser"][country]["Method Mix Other Source"]         = pt.tableData.value[8][2];

    return (modelParametersData);
};

const RecalcGrid = (pt, countryName, modelParametersData) => {
    let user = {};
    let def = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {
            user = modelParametersData["externalUser"][i];
            def = modelParametersData["externalDefault"][i];
        }
    }

    let defColor = 0;
    let changedColor = 6930250;

    pt.FontColors[1][1] = user["Method Mix Sterilization"] === def["Method Mix Sterilization"] ? defColor : changedColor;
    pt.FontColors[2][1] = user["Method Mix IUD"] === def["Method Mix IUD"] ? defColor : changedColor;
    pt.FontColors[3][1] = user["Method Mix Implants"] === def["Method Mix Implants"] ? defColor : changedColor;
    pt.FontColors[4][1] = user["Method Mix Injection"] === def["Method Mix Injection"] ? defColor : changedColor;
    pt.FontColors[5][1] = user["Method Mix Pill"] === def["Method Mix Pill"] ? defColor : changedColor;
    pt.FontColors[6][1] = user["Method Mix Condoms"] === def["Method Mix Condoms"] ? defColor : changedColor;
    pt.FontColors[7][1] = user["Method Mix LAM"] === def["Method Mix LAM"] ? defColor : changedColor;
    pt.FontColors[8][1] = user["Method Mix Other"] === def["Method Mix Other"] ? defColor : changedColor;

    pt.FontColors[1][2] = user["Method Mix Sterilization Source"] === def["Method Mix Sterilization Source"] ? defColor : changedColor;
    pt.FontColors[2][2] = user["Method Mix IUD Source"] === def["Method Mix IUD Source"] ? defColor : changedColor;
    pt.FontColors[3][2] = user["Method Mix Implants Source"] === def["Method Mix Implants Source"] ? defColor : changedColor;
    pt.FontColors[4][2] = user["Method Mix Injection Source"] === def["Method Mix Injection Source"] ? defColor : changedColor;
    pt.FontColors[5][2] = user["Method Mix Pill Source"] === def["Method Mix Pill Source"] ? defColor : changedColor;
    pt.FontColors[6][2] = user["Method Mix Condoms Source"] === def["Method Mix Condoms Source"] ? defColor : changedColor;
    pt.FontColors[7][2] = user["Method Mix LAM Source"] === def["Method Mix LAM Source"] ? defColor : changedColor;
    pt.FontColors[8][2] = user["Method Mix Other Source"] === def["Method Mix Other Source"] ? defColor : changedColor;

    return (pt);
};

const RestoreDefaultValues = (countryName, modelParametersData) => {
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === countryName) {

            modelParametersData["externalUser"][i]["Method Mix Sterilization"] = modelParametersData["externalDefault"][i]["Method Mix Sterilization"];
            modelParametersData["externalUser"][i]["Method Mix IUD"] = modelParametersData["externalDefault"][i]["Method Mix IUD"];
            modelParametersData["externalUser"][i]["Method Mix Implants"] = modelParametersData["externalDefault"][i]["Method Mix Implants"];
            modelParametersData["externalUser"][i]["Method Mix Injection"] = modelParametersData["externalDefault"][i]["Method Mix Injection"];
            modelParametersData["externalUser"][i]["Method Mix Pill"] = modelParametersData["externalDefault"][i]["Method Mix Pill"];
            modelParametersData["externalUser"][i]["Method Mix Condoms"] = modelParametersData["externalDefault"][i]["Method Mix Condoms"];
            modelParametersData["externalUser"][i]["Method Mix LAM"] = modelParametersData["externalDefault"][i]["Method Mix LAM"];
            modelParametersData["externalUser"][i]["Method Mix Other"] = modelParametersData["externalDefault"][i]["Method Mix Other"];

            modelParametersData["externalUser"][i]["Method Mix Sterilization Source"] = modelParametersData["externalDefault"][i]["Method Mix Sterilization Source"];
            modelParametersData["externalUser"][i]["Method Mix IUD Source"] = modelParametersData["externalDefault"][i]["Method Mix IUD Source"];
            modelParametersData["externalUser"][i]["Method Mix Implants Source"] = modelParametersData["externalDefault"][i]["Method Mix Implants Source"];
            modelParametersData["externalUser"][i]["Method Mix Injection Source"] = modelParametersData["externalDefault"][i]["Method Mix Injection Source"];
            modelParametersData["externalUser"][i]["Method Mix Pill Source"] = modelParametersData["externalDefault"][i]["Method Mix Pill Source"];
            modelParametersData["externalUser"][i]["Method Mix Condoms Source"] = modelParametersData["externalDefault"][i]["Method Mix Condoms Source"];
            modelParametersData["externalUser"][i]["Method Mix LAM Source"] = modelParametersData["externalDefault"][i]["Method Mix LAM Source"];
            modelParametersData["externalUser"][i]["Method Mix Other Source"] = modelParametersData["externalDefault"][i]["Method Mix Other Source"];
        }
    }

    return modelParametersData;
};

export const FPMethodMixEditor = {
    Create               : Create,
    Setup                : Setup,
    SetSSData            : SetSSData,
    GetSSData            : GetSSData,
    RecalcGrid           : RecalcGrid,
    RestoreDefaultValues : RestoreDefaultValues,
};