import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import {fnAddCommas, generatePackChart} from "../../../utilities/GBUtil"
import * as Constants from "../../../utilities/Constants";

const Create = (props, itemIndex) => {
    let editorName = 'Cost-effectiveness determination';

    let packTable = Setup();
    packTable = SetSSData(packTable, props, itemIndex);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 2, true);
    return ([packTable, packChart]);
};

const Setup = () => {
    let pt = createSimpleDefaultPackTable(4, 2, true);

    pt.GBFixedCols = 0;

    pt.tableData.value[1][0] = 'Threshold for "highly cost-effective" (1 X GDP per capita)';
    pt.tableData.value[2][0] = 'Threshold for "cost-effective" (3 X GDP per capita)';
    pt.tableData.value[3][0] = 'Cost-effectiveness determination:';

    pt.LockedCells[1][0] = true;
    pt.LockedCells[2][0] = true;
    pt.LockedCells[3][0] = true;

    pt.LockedCells[1][1] = true;
    pt.LockedCells[2][1] = true;
    pt.LockedCells[3][1] = true;

    pt.Alignments[1][1] = Constants.Left;
    pt.Alignments[2][1] = Constants.Left;
    pt.Alignments[3][1] = Constants.Left;

    pt.FontStyles[1][1]["intArray"] = [Constants.Bold];
    pt.FontStyles[2][1]["intArray"] = [Constants.Bold];
    pt.FontStyles[3][1]["intArray"] = [Constants.Bold];

    pt.FontColors[1][1] = 6660569;
    pt.FontColors[2][1] = 6660569;
    pt.FontColors[3][1] = 6660569;

    pt.GBColWidths[0] = 420;
    pt.GBColWidths[1] = 200;

    pt.FontSize[3][1] = '20px';

    return pt;
};

const SetSSData = (pt, props, itemIndex) => {
    const {selectedCountry, modelParametersData, resultsData} = props;

    let countryData = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === selectedCountry) {
            countryData = modelParametersData["externalUser"][i];
        }
    }

    let GDP_Per_Cap = 0;

    switch(itemIndex){
        case 0 : GDP_Per_Cap = countryData["National GDP per capita"]; break;
        case 1 : GDP_Per_Cap = countryData["Regional GDP per Capita"]; break;
    }

    let CE_Determination = 'not cost-effective';

    if (resultsData["costEffData"]["cost per maternal DALY averted"][1] < (GDP_Per_Cap * 3)){
        CE_Determination = 'cost-effective';
    }

    if (resultsData["costEffData"]["cost per maternal DALY averted"][1] < GDP_Per_Cap){
        CE_Determination = 'highly cost-effective';
    }

    pt.tableData.value[1][1] = '$ ' + fnAddCommas(((GDP_Per_Cap).toFixed()).toString());
    pt.tableData.value[2][1] = '$ ' + fnAddCommas((((GDP_Per_Cap * 3)).toFixed()).toString());
    pt.tableData.value[3][1] = CE_Determination;

    return pt;
};

export const CostEffDetermination = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};