import React, { useState } from "react";
import PropTypes from 'prop-types';
import MoreMenuBtn from "../@common/components/MoreMenuBtn";
import Theme from "./Theme"

const RightComponents = (props) => {
    const { onStateChange } = props;

    const [anchorEl, setAnchorEl] = useState(undefined);

    const onMoreMenuClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const onMoreMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <MoreMenuBtn
                anchorEl          = {anchorEl}
                onClick           = {onMoreMenuClick}
                onClose           = {onMoreMenuClose}
                onStateChange     = {onStateChange}
                iconColor         = {Theme.palette.primary.main}
            />
        </React.Fragment>
    );
};

export default RightComponents;

RightComponents.propTypes = {
    onStateChange : PropTypes.func.isRequired,
};