import { createSimpleDefaultPackTable } from "../../@common/components/GBStdTableAG/data/defaultPackTable";
import { addMergedCellsToArray, indentPackTable } from "../../@common/components/GBStdTableAG/other/utilities";
import * as Constants from "../../utilities/Constants"

const Create = (costsData) => {

    let editorName = '1. Review SBC unit costs';
    let packTable = Setup();
    packTable = SetSSData(packTable, costsData);
    packTable = RecalcGrid(packTable, costsData);
    packTable.Title = editorName;
    return (packTable);
};

const Setup = () => {
    let numRows = 16;
    let numCols = 3;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    pt.tableData.value[0][0] = 'Intervention';
    pt.tableData.value[0][1] = 'Reach denominator';
    pt.tableData.value[0][2] = 'Median unit costs';

    pt.tableData.value[1][0] = 'Mass media';
    pt.tableData.value[6][0] = 'Middle media';
    pt.tableData.value[10][0] = 'Phone/SMS';
    pt.tableData.value[12][0] = 'Interpersonal communication';

    pt.RowHeaderSet = [1, 6, 10, 12];

    for (let row = 1; row < numRows; row++) {
        if (pt.RowHeaderSet.includes(row)) {
            pt.FontStyles[row][0]["intArray"] = [Constants.Bold];
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 1, 0, 1, 9);
            for (let i = 1; i < numCols; i++) {
                pt.LockedCells[1][i] = true;
            }
        }
        else{
            pt = indentPackTable(pt, row, true, Constants.ED_Indent1);
        }
        pt.RDec[row][2] = 2;
        pt.Alignments[row][2] = Constants.Right;
        pt.MinAllowedVal[row][2] = 0;
        pt.MaxAllowedVal[row][2] = 999999999999;
    }

    for (let c = 1; c < numCols; c++) {
        pt.GBColWidths[c] = 110;
    }

    pt.GBColWidths[1] = 150;

    return pt;
};

const SetSSData = (pt, costsData) => {
    let iv = 0;
    for (let row = 1; row < pt.GBRowCount; row++) {
        if (!pt.RowHeaderSet.includes(row)) {
            pt.tableData.value[row][0] = costsData["SBCUnitCostsUser"][iv]["Intervention"];
            pt.tableData.value[row][1] = costsData["SBCUnitCostsUser"][iv]["Reach denominator"];
            pt.tableData.value[row][2] = costsData["SBCUnitCostsUser"][iv]["Median Unit Cost Defaults"];
            iv++
        }
    }

    return pt;
};

const GetSSData = (pt, costsData) => {
    let iv = 0;
    for (let row = 1; row < pt.GBRowCount; row++) {
        if (!pt.RowHeaderSet.includes(row)) {
            costsData["SBCUnitCostsUser"][iv]["Reach denominator"] = pt.tableData.value[row][1];
            costsData["SBCUnitCostsUser"][iv]["Median Unit Cost Defaults"] = pt.tableData.value[row][2];
            iv++
        }
    }

    return (costsData)
};

const RecalcGrid = (pt, costsData) => {
    let user = costsData["SBCUnitCostsUser"];
    let def = costsData["SBCUnitCostsDefault"];

    let defColor = 0;
    let changedColor = 6930250;

    let iv = 0;
    for (let row = 1; row < pt.GBRowCount; row++) {
        if (!pt.RowHeaderSet.includes(row)) {
            pt.FontColors[row][1] = user[iv]["Reach denominator"] === def[iv]["Reach denominator"] ? defColor : changedColor;
            pt.FontColors[row][2] = user[iv]["Median Unit Cost Defaults"] === def[iv]["Median Unit Cost Defaults"] ? defColor : changedColor;
            iv++
        }
    }

    return (pt);
};

export const SBCUnitCostsEditor = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
    GetSSData        : GetSSData,
    RecalcGrid       : RecalcGrid,
};