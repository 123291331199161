import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const DialogTitleCustom = (props) => {
    // const { title, onClose, allowClose, closeButtonStyle, titleBorderDisable, titleStyle, innerTitleStyle, Theme } = props;
    const { title, onClose, allowClose, titleBorderDisable, titleStyle, Theme } = props;

    const ThemeForApp = Theme || window.ThemeForApp;

    const styles = {
        dialogTitle : {
            // minWidth: '400px',
            // maxWidth: '650px',
            borderBottom: titleBorderDisable ? 'none' : '1px solid lightgray',
            userSelect: 'none',
            padding: '10px 24px',
            minHeight: '57px',
        },
        title : {
            fontSize: '20px',
            color: ThemeForApp ? ThemeForApp.palette.primary.main : "black",
            fontWeight: 'bold',
            padding: allowClose ? '5px 50px 0 0' : '5px 5px 0 0 ',
            // ...innerTitleStyle
        },
        closeIcon : {
            height: '30px',
            width: '30px',
            cursor: 'pointer',
            position: 'absolute',
            top: '14px',
            right: '20px',
            color : ThemeForApp ? ThemeForApp.palette.primary.main : "black",
        }
    };

    return (
        <DialogTitle id="alert-dialog-title" style={{...styles.dialogTitle, ...titleStyle}}>
            <div style={styles.title}>
                {title}
            </div>

            {
                (allowClose) ?
                    <CloseIcon
                        onClick={onClose}
                        style={styles.closeIcon}
                        // style={{...styles.closeIcon, ...closeButtonStyle}}
                    />
                    : null
            }
        </DialogTitle>
    );
};

const DialogContentCustom = (props) => {

    const { content, iconType, hasActions } = props;    
    
    const styles = {
        content : {
            margin: '10px 0',
            padding: hasActions ? '0 22px' : '10px 22px',
            // minWidth: '400px',
            // maxWidth: '650px',
            minHeight: '50px',
        },
        icon : {
            marginRight: '20px', 
            width: '40px', 
            height: '40px', 
            float: 'left', 
            color: '#C03600'
        },
    };

    let icon;
    switch (iconType) {
        case "Warning" : icon = <WarningIcon style={styles.icon} />; break;
        case "Error"   : icon = <ErrorIcon style={styles.icon} />; break;
        default        : icon = undefined;
    }

    return (
        <DialogContent style={styles.content}>
            {icon}
            {content}
        </DialogContent>
    );
};

const DialogActionsCustom = (props) => {

    const { actions, showMoreInfo, moreInfo, onMoreInfoClick, actionStyle } = props;

    if (typeof actions === 'undefined') {
        return null;
    }

    const styles = {
        actions : {
            minHeight: '30px',
            userSelect: 'none',
            padding: '10px 24px 15px 24px',
            margin: 0,
            // minWidth: '400px',
            // maxWidth: '650px',
            display: 'block',
        },
        moreInfo : {
            float: 'left',
            fontSize: 14,
            position: 'relative',
            cursor: 'pointer',
            width: 150,
            height: 25,
            margin: '6px 0'
        },
        icon : {
            position: 'absolute',
            top: 0,
            left: 0
        },
        text: {
            position: 'absolute',
            top: 5,
            left: 25
        },
        buttons: {
            float: 'right'
        },
    };

    return (
        <DialogActions style={{...styles.actions, ...actionStyle}}>
            {
                (moreInfo) ?
                    (showMoreInfo) ? 
                        <div style={styles.moreInfo} onClick={onMoreInfoClick}><ExpandLessIcon style={styles.icon} /><div style={styles.text}>Hide details</div></div> :
                        <div style={styles.moreInfo} onClick={onMoreInfoClick}><ExpandMoreIcon style={styles.icon} /><div style={styles.text}>Show details</div></div>
                : null
            }
            <div style={styles.buttons}>
                {actions}
            </div>
        </DialogActions>
    );
};

const DialogMoreInformationCustom = (props) => {

    const { moreInfo, showMoreInfo } = props;

    const styles = {
        moreInfo : {
            padding: 20,
            background: '#eeeeee',
            fontSize: 14,
            // minWidth: '400px',
            // maxWidth: '650px',
            width: '100%',
        },
    };

    return (
        (showMoreInfo) ? 
            <div style={styles.moreInfo}>{moreInfo}</div> : 
            null
    );
};

const GBDialog = (props) => {
    const { 
        open = true, 
        title, 
        content, 
        actions, 
        onClose, 
        // modal = false, 
        iconType, 
        allowClose = true, 
        moreInfo, 
        titleBorderDisable = false, 
        style = {}, 
        minWidth = 400,
        titleStyle,
        closeButtonStyle,
        contentStyle, 
        actionStyle, 
        innerTitleStyle,
        Theme,
    } = props;
    const [openMoreInfo, setOpenMoreInfo] = useState(0);

    const styles = {
        dialog: {
            minWidth: minWidth,
            maxWidth: 900,
            ...style
        }
    };

    const _setOpenMoreInfo = () => {
        setOpenMoreInfo(!openMoreInfo)
    };

    // const onBackdropClick = () => {
    //     if (!modal) {
    //         onClose('mrClose');
    //     }
    // };

    let hasActions = false;
    if (typeof actions !== 'undefined') {
        hasActions = true;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // disableBackdropClick={true}
            // onBackdropClick={onBackdropClick}
            // maxWidth="md"
            PaperProps={{
                style: styles.dialog
            }}
        >
            <DialogTitleCustom title={title} onClose={onClose} allowClose={allowClose} closeButtonStyle={closeButtonStyle} titleBorderDisable={titleBorderDisable} titleStyle={titleStyle} innerTitleStyle={innerTitleStyle} Theme={Theme} />
            <DialogContentCustom content={content} iconType={iconType} hasActions={hasActions} contentStyle={contentStyle} />
            <DialogActionsCustom actions={actions} showMoreInfo={openMoreInfo} moreInfo={moreInfo} onMoreInfoClick={_setOpenMoreInfo} actionStyle={actionStyle} />
            <DialogMoreInformationCustom showMoreInfo={openMoreInfo} moreInfo={moreInfo} />
        </Dialog>
    );
};

export const GBDialogDP = (props) => {
    return (
        <GBDialog
            innerTitleStyle={{color : '#1d509e'}}
            {...props}
        />
    );
};

export const GBDialogAIM = (props) => {
    return (
        <GBDialog
            innerTitleStyle={{color : '#0f0d98'}}
            {...props}
        />
    );
};

export default React.memo(GBDialog);