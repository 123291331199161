import { fieldNamePrefix } from "./constants";
import {createSimpleDefaultPackTable} from "../data/defaultPackTable";

//=======================================================================================================
//
//                 ######   ######## ##    ## ######## ########     ###    ##       
//                ##    ##  ##       ###   ## ##       ##     ##   ## ##   ##       
//                ##        ##       ####  ## ##       ##     ##  ##   ##  ##       
//                ##   #### ######   ## ## ## ######   ########  ##     ## ##       
//                ##    ##  ##       ##  #### ##       ##   ##   ######### ##       
//                ##    ##  ##       ##   ### ##       ##    ##  ##     ## ##       
//                 ######   ######## ##    ## ######## ##     ## ##     ## ########
//
//=======================================================================================================

export const clone = data => {
    if (typeof data === "undefined") {
        return undefined;
    }
    else {
        return JSON.parse(JSON.stringify(data));
    }
};

export const createArray = (dim, bounds, defaultVal = "", _arr = undefined) => {

    if (typeof _arr === "undefined") {
        _arr = [];
    }

    let len = bounds.slice().reverse()[dim-1];

    if (dim > 1) {
        for (let i = 0; i < len; i++) {
            _arr[i] = createArray(dim - 1, bounds, defaultVal, _arr[i]);
        }
        return _arr;
    } 
    else {
        for (let i = 0; i < len; i++) {
            _arr[i] = clone(defaultVal);
        }
        return _arr;
    }

    //---------------------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = createArray(1, [3], "test");               a[0] = "different"; console.log(a);
    // let b = createArray(1, [3], {test: "test"});       b[0].test = "different"; console.log(b);
    
    // let c = createArray(2, [3, 3], "test");            c[0][0] = "different"; console.log(c);
    // let d = createArray(2, [3, 3], {test: "test"});    d[0][0].test = "different"; console.log(d);
    
    // let e = createArray(2, [5, 6], "test");            e[0][0] = "different"; console.log(e);
    // let f = createArray(2, [5, 6], {test: "test"});    f[0][0].test = "different"; console.log(f);
    
    // let g = createArray(3, [1, 2, 3], "test");         g[0][0][0] = "different"; console.log(g);
    // let h = createArray(3, [1, 2, 3], {test: "test"}); h[0][0][0].test = "different"; console.log(h);

    // let g = createArray(10, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "test"); console.log(g);
    //---------------------------------------------------------------------------------------------------

    //---------------------------------------------------------------------------------------------------
    // Notes
    //
    // TGP: Bad! Elements by reference
    //     return Array(length).fill(clone(value));
    //
    // TGP : Bad!  Elements by reference
    //     let len = bounds.shift();
    //
    //     if (dim > 1) {
    //         return Array.from({length: len}, x => createArray(dim - 1, clone(bounds), value));
    //     } else {
    //         return Array(len).fill(value);
    //     }
    //---------------------------------------------------------------------------------------------------
};

export const createVector = (length, defaultVal = 0) => {

    return createArray(1, [length], defaultVal);

    // let arr = [];

    // for (let r = 0; r < length; r++) {
    //     arr[r] = clone(defaultVal);
    // }
    
    // return arr;

    //--------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = createVector(2, { intArray: [] }); a[0].intArray = [2]; console.log(a);
    //--------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------
    // Notes
    //
    // TGP: Bad! Elements by reference
    // return Array(length).fill(clone(value));
    //--------------------------------------------------------------------------------------  
};

export const createMatrix = (rowCount, columnCount, defaultVal = 0) => {  

    return createArray(2, [rowCount, columnCount], defaultVal);

    // let arr = [];

    // for (let r = 0; r < rowCount; r++) {
    //     arr[r] = [];
    //     for (let c = 0; c < columnCount; c++) {
    //         arr[r][c] = clone(defaultVal);
    //     }
    // }
    
    // return arr;

    //--------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = createMatrix(2, 2, { intArray: [] }); a[0][0].intArray = [2]; console.log(a);
    //--------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------
    // Notes
    //
    // TGP: Bad! Elements by reference
    // return Array.from({length: rowCount}, x => Array(columnCount).fill(clone(value)));
    //
    // TGP: Bad! Elements by reference
    // return Array(rowCount).fill(Array(columnCount).fill(value));  
    //--------------------------------------------------------------------------------------
};

export const size1dArray = (array, len, defaultVal = 0) => {
    let array2 = [];

    for (let x = 0; x < len; x++) {
        array2[x] = defaultVal;
    }

    for (let r = 0; r < array.length; r++) {
        if (array2.length > r) {
            array2[r] = array[r];
        }
    }

    //--------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = size1dArray([], 3, ""); console.log(a);
    // let b = size1dArray([0], 3, ""); console.log(b);
    // let c = size1dArray([0, 0], 3, ""); console.log(c);
    // let d = size1dArray([0, 0, 0], 3, ""); console.log(d);
    // let e = size1dArray([0, 0, 0, 0], 3, ""); console.log(e);
    //--------------------------------------------------------------------------------------

    return array2;
};

export const size2dArray = (array, row, col, defaultVal = 0) => {
    let array2 = [];

    for (let x = 0; x < row; x++) {
        array2[x] = [];
        for (let y = 0; y < col; y++) {
            array2[x][y] = clone(defaultVal);
        }
    }

    for (let r = 0; r < array.length; r++) {
        if (array2.length > r) {
            for (let c = 0; c < array[r].length; c++) {
                if (array2[r].length > c) {
                    array2[r][c] = array[r][c];
                }
            }
        }
    }

    //--------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = size2dArray([[]], 3, 3, ""); console.log(a);
    // let b = size2dArray([[0, 0]], 3, 3, ""); console.log(b);
    // let c = size2dArray([[0, 0], [0, 0]], 3, 3, ""); console.log(c);
    // let d = size2dArray([[0, 0], [0, 0], [0, 0]], 3, 3, ""); console.log(d);
    // let e = size2dArray([[1, 2], [3, 4], [5, 6], [7, 8]], 3, 3, ""); console.log(e);
    //--------------------------------------------------------------------------------------

    return array2;
};

export const TwoDimToOneDimArray = (TwoDimArray) => {
    return [].concat.apply([], TwoDimArray);
};

export const transposeArray = (array) => { 
    try {
        return array[0].map((col, i) => array.map(row => row[i]));

        //--------------------------------------------------------------------------------------
        // Passing tests
        //
        // let a = transposeArray([
        //     [1, 2]
        // ]);                          
        // console.log(clone(a)); 
        // a[0][0] = "test"; 
        // console.log(clone(a));
        // let a2 = transposeArray(a); 
        // console.log(a2); 
        // let a3 = transposeArray(a2); 
        // console.log(a3);
        // let a4 = transposeArray(a3); 
        // console.log(a4); 
        // console.log(""); 
        // console.log(""); 
        // console.log(""); 
        //
        // let b = transposeArray([
        //     [1, 2], 
        //     [3, 4]
        // ]);         
        // console.log(clone(b)); 
        // b[0][0] = "test"; 
        // console.log(clone(b));
        // let b2 = transposeArray(b); 
        // console.log(b2); 
        // let b3 = transposeArray(b2); 
        // console.log(b3);
        // let b4 = transposeArray(b3); 
        // console.log(b4); 
        // console.log(""); 
        // console.log(""); 
        // console.log(""); 
        //
        // let c = transposeArray([
        //     [1, 2], 
        //     [3, 4], 
        //     [5, 6]
        // ]);          
        // console.log(clone(c)); 
        // c[0][0] = "test"; 
        // console.log(clone(c));
        // let c2 = transposeArray(c); 
        // console.log(c2); 
        // let c3 = transposeArray(c2); 
        // console.log(c3);
        // let c4 = transposeArray(c3); 
        // console.log(c4); 
        // console.log(""); 
        // console.log(""); 
        // console.log(""); 
        //
        // let d = transposeArray([
        //     [{ intArray : 1 }, 2], 
        //     [3, 4], 
        //     [5, { intArray : 6 }]
        // ]);          
        // console.log(clone(d)); 
        // d[0][0].intArray = "test"; 
        // console.log(clone(d));
        // let d2 = transposeArray(d); 
        // console.log(d2); 
        // let d3 = transposeArray(d2); 
        // console.log(d3);
        // let d4 = transposeArray(d3); 
        // console.log(d4); 
        // console.log(""); 
        // console.log(""); 
        // console.log(""); 
        //--------------------------------------------------------------------------------------
    }
    catch(e) {
        console.error(e);
    }
};    

//=======================================================================================================
//
//       ########     ###     ######  ##    ## ########    ###    ########  ##       ######## 
//       ##     ##   ## ##   ##    ## ##   ##     ##      ## ##   ##     ## ##       ##       
//       ##     ##  ##   ##  ##       ##  ##      ##     ##   ##  ##     ## ##       ##       
//       ########  ##     ## ##       #####       ##    ##     ## ########  ##       ######   
//       ##        ######### ##       ##  ##      ##    ######### ##     ## ##       ##       
//       ##        ##     ## ##    ## ##   ##     ##    ##     ## ##     ## ##       ##       
//       ##        ##     ##  ######  ##    ##    ##    ##     ## ########  ######## ########
//
//=======================================================================================================

export const transposePackTable = (packTable) => {
    let o = clone(packTable);

    /* General */
    o.GBFixedRows = packTable.GBFixedCols;
    o.GBFixedCols = packTable.GBFixedRows;
    o.GBRowCount = packTable.GBColCount;
    o.GBColCount = packTable.GBRowCount;
    o.TotalRow = packTable.TotalRow;
    o.TotalCol = packTable.TotalCol;
    o.RowIds = clone(packTable.ColIds);
    o.ColIds = clone(packTable.RowIds);

    o.tableData.value = transposeArray(packTable.tableData.value);

    o.RDec = transposeArray(packTable.RDec);
    o.MinAllowedVal = transposeArray(packTable.MinAllowedVal);
    o.MaxAllowedVal = transposeArray(packTable.MaxAllowedVal);
    o.FontStyles = transposeArray(packTable.FontStyles);

    o.FontBold = transposeArray(packTable.FontBold);
    o.FontItalic = transposeArray(packTable.FontItalic);
    o.FontUnderline = transposeArray(packTable.FontUnderline);

    o.FontColors = transposeArray(packTable.FontColors);
    o.BGColors = transposeArray(packTable.BGColors);
    o.GBCellComment = transposeArray(packTable.GBCellComment);
    o.GBUseTriangle = transposeArray(packTable.GBUseTriangle);
    o.LockedCells = transposeArray(packTable.LockedCells);

    o.HasCheckBox = transposeArray(packTable.HasCheckBox);
    o.CheckBoxState = transposeArray(packTable.CheckBoxState);
    o.Alignments = transposeArray(packTable.Alignments);

    o.WordWrappedCols = createVector(o.GBColCount, false);
    o.GBRowHeights = createVector(o.GBRowCount, -1);
    o.GBColWidths = createVector(o.GBColCount, -1);
    o.IndentRowText = createVector(o.GBRowCount, { xoffset: 0, Value: false });
    o.RowSubHeadings = createVector(o.GBRowCount, false);
    
    o.Title = packTable.Title;
    o.editorMstID = packTable.editorMstID;
    o.Proj = packTable.Proj;
    o.ModID = packTable.ModID;
    o.MergedCells = [];
    o.SourceMode = packTable.SourceMode;
    o.SourceMap = [];
    o.Source = packTable.Source;

    o.PercRDec = transposeArray(packTable.PercRDec);
    o.allowPercent = transposeArray(packTable.allowPercent);
    o.showPercent = transposeArray(packTable.showPercent);

    return o;
};

export const resizePackTable = (packTable, rowCount, columnCount) => {
    let o = clone(packTable);

    o.GBRowCount = rowCount;
    o.GBColCount = columnCount;

    o.tableData.value = size2dArray(packTable.tableData.value, rowCount, columnCount, "");
    o.WordWrappedCols = size1dArray(packTable.WordWrappedCols, columnCount, false);
    o.RDec = size2dArray(packTable.RDec, rowCount, columnCount, 0);
    o.MinAllowedVal = size2dArray(packTable.MinAllowedVal, rowCount, columnCount, 0);
    o.MaxAllowedVal = size2dArray(packTable.MaxAllowedVal, rowCount, columnCount, 0);
    o.FontStyles = size2dArray(packTable.FontStyles, rowCount, columnCount, { intArray: [] });

    if (o.FontBold !== undefined) {
        o.FontBold = size2dArray(packTable.FontBold, rowCount, columnCount);
    }
    if (o.FontItalic !== undefined) {
        o.FontItalic = size2dArray(packTable.FontItalic, rowCount, columnCount);
    }
    if (o.FontUnderline !== undefined) {
        o.FontUnderline = size2dArray(packTable.FontUnderline, rowCount, columnCount);
    }

    o.FontColors = size2dArray(packTable.FontColors, rowCount, columnCount, "#000");
    o.BGColors = size2dArray(packTable.BGColors, rowCount, columnCount, 536870911);
    o.GBCellComment = size2dArray(packTable.GBCellComment, rowCount, columnCount, "");
    o.GBUseTriangle = size2dArray(packTable.GBUseTriangle, rowCount, columnCount, false);
    o.LockedCells = size2dArray(packTable.LockedCells, rowCount, columnCount, false);
    o.IndentRowText = size1dArray(packTable.IndentRowText, rowCount, { xoffset: 0, Value: false });
    o.RowSubHeadings = size1dArray(packTable.RowSubHeadings, rowCount, false);
    o.HasCheckBox = size2dArray(packTable.HasCheckBox, rowCount, columnCount, false);
    o.CheckBoxState = size2dArray(packTable.CheckBoxState, rowCount, columnCount, false);
    o.Alignments = size2dArray(packTable.Alignments, rowCount, columnCount, 0);
    o.RowIds = size2dArray(packTable.RowIds, rowCount, 2, 0);
    o.ColIds = size2dArray(packTable.ColIds, columnCount, 2, 0);
    o.GBRowHeights = size1dArray(packTable.GBRowHeights, rowCount, -1);
    o.GBColWidths = size1dArray(packTable.GBColWidths, columnCount, -1);

    o.PercRDec = size2dArray(packTable.PercRDec, rowCount, columnCount, 1);
    o.allowPercent = size2dArray(packTable.allowPercent, rowCount, columnCount, 0);
    o.showPercent = size2dArray(packTable.showPercent, rowCount, columnCount, 0);

    return o;
};

export const deletePackTableRow = (packTable, row) => {
    let o = clone(packTable);

    if (row > o.GBRowCount - 1) {
        return o;
    }

    /* General */
    o.GBRowCount--;

    /* Row */
    o.IndentRowText.splice(row, 1);
    o.RowSubHeadings.splice(row, 1);
    o.RowIds.splice(row, 1);
    o.GBRowHeights.splice(row, 1);

    /* Row Col */
    o.RDec.splice(row, 1);
    o.PercRDec.splice(row, 1);
    o.MinAllowedVal.splice(row, 1);
    o.MaxAllowedVal.splice(row, 1);
    o.FontStyles.splice(row, 1);

    o.FontBold.splice(row, 1);
    o.FontItalic.splice(row, 1);
    o.FontUnderline.splice(row, 1);

    o.FontColors.splice(row, 1);
    o.BGColors.splice(row, 1);
    o.GBCellComment.splice(row, 1);
    o.GBUseTriangle.splice(row, 1);
    o.LockedCells.splice(row, 1);
    o.HasCheckBox.splice(row, 1);
    o.CheckBoxState.splice(row, 1);
    o.Alignments.splice(row, 1);
    o.allowPercent.splice(row, 1);
    o.showPercent.splice(row, 1);

    /* Special */
    o.tableData.value.splice(row, 1);

    if (o.MergedCells) {
        o.MergedCells.forEach(cell => {
            if (row > cell.startRow && row <= cell.startRow + cell.numRows) {
                cell.numRows--;
            } else if (cell.startRow >= row) {
                cell.startRow--;
            }
        });
    }

    return o;
};

export const addPackTableRow = (packTable, row) => {
    let o = clone(packTable);
    const colCount = o.GBColCount;

    if (row > o.GBRowCount) {
        return o;
    }    
    
    if (row === -1) {
        row = o.GBRowCount;
    }

    /* General */
    o.GBRowCount++;

    /* Row */
    o.IndentRowText.splice(row, 0, { xoffset: 0, Value: false });
    o.RowSubHeadings.splice(row, 0, false);
    o.RowIds.splice(row, 0, 0);
    o.GBRowHeights.splice(row, 0, -1);

    /* Row Col */
    o.RDec.splice(row, 0, createArray(1, [0, colCount], 0));
    o.PercRDec.splice(row, 0, createArray(1, [0, colCount], 1));
    o.MinAllowedVal.splice(row, 0, createArray(1, [0, colCount], 0));
    o.MaxAllowedVal.splice(row, 0, createArray(1, [0, colCount], 0));
    o.FontStyles.splice(row, 0, createArray(1, [0, colCount], { intArray: [] }));

    o.FontBold.splice(row, 0, createArray(1, [0, colCount], 0));
    o.FontItalic.splice(row, 0, createArray(1, [0, colCount], 0));
    o.FontUnderline.splice(row, 0, createArray(1, [0, colCount], 0));

    o.FontColors.splice(row, 0, createArray(1, [0, colCount], "#000"));
    o.BGColors.splice(row, 0, createArray(1, [0, colCount], 536870911));
    o.GBCellComment.splice(row, 0, createArray(1, [0, colCount], ""));
    o.GBUseTriangle.splice(row, 0, createArray(1, [0, colCount], false));
    o.LockedCells.splice(row, 0, createArray(1, [0, colCount], false));
    o.HasCheckBox.splice(row, 0, createArray(1, [0, colCount], false));
    o.CheckBoxState.splice(row, 0, createArray(1, [0, colCount], false));
    o.Alignments.splice(row, 0, createArray(1, [0, colCount], 0));
    o.allowPercent.splice(row, 0, createArray(1, [0, colCount], 0));
    o.showPercent.splice(row, 0, createArray(1, [0, colCount], 0));

    /* Special */
    o.tableData.value.splice(row, 0, createArray(1, [0, colCount], ""));

    if (o.MergedCells) {
        o.MergedCells.forEach(cell => {
            if (row > cell.startRow && row <= cell.startRow + cell.numRows - 1) {
                cell.numRows++;
            } else if (cell.startRow >= row) {
                cell.startRow++;
            }
        });
    }

    return o;
};








//----------------------------------------------------------------------------------------

/* May delete these three in the future in favor of addPackTableRow. */

export const addRowToPackTable = (pt, defaultVal = 0, rDec = 0) => {
    let packTable = resizePackTable(pt, pt.GBRowCount + 1, pt.GBColCount);
    
    let r = packTable.GBRowCount - 1;
    for (let c = 0; c < packTable.GBColCount; c++) {
        packTable.RDec[r][c] = rDec;
        packTable.tableData.value[r][c] = defaultVal;
    }

    return packTable;
};

export const addTitleRowToPackTable = (pt, title, numCols, indent = false, isProjTitle = false) => {
    pt = resizePackTable(pt, pt.tableData.value.length + 1, numCols);

    pt.tableData.value[pt.tableData.value.length - 1][0] = title;  //add title to newly created row

    if (indent) {
        pt.IndentRowText[pt.IndentRowText.length - 1].xoffset = 15;
        pt.IndentRowText[pt.IndentRowText.length - 1].value = true;
        pt.IndentRowText[pt.IndentRowText.length - 1].Value = true;
    } else {
        pt.IndentRowText[pt.IndentRowText.length - 1].xoffset = 0;
        pt.IndentRowText[pt.IndentRowText.length - 1].value = false;
        pt.IndentRowText[pt.IndentRowText.length - 1].Value = false;
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, pt.tableData.value.length - 1, 0, 1, numCols);
        pt.FontStyles[pt.IndentRowText.length -1][0].intArray.push(0);
    }

    return pt;
};

export const addDataRowToPackTable = (pt, rowData, RDec = 0, indent = false) => {
    pt = resizePackTable(pt, pt.tableData.value.length + 1, rowData.length);

    for (let y = 0; y <= (rowData.length - 1); y++) {   //copy row data into new row
        pt.tableData.value[pt.tableData.value.length - 1][y] = rowData[y];
    }

    for (let y = 0; y <= (rowData.length - 1); y++) {   //copy row data into new row
        pt.RDec[pt.RDec.length - 1][y] = RDec;
    }

    if (indent) {
        pt.IndentRowText[pt.IndentRowText.length - 1].xoffset = 15;
        pt.IndentRowText[pt.IndentRowText.length - 1].value = true;
        pt.IndentRowText[pt.IndentRowText.length - 1].Value = true;
    } else {
        pt.IndentRowText[pt.IndentRowText.length - 1].xoffset = 0;
        pt.IndentRowText[pt.IndentRowText.length - 1].value = false;
        pt.IndentRowText[pt.IndentRowText.length - 1].Value = false;
    }

    return pt;
};

//----------------------------------------------------------------------------------------








export const addMergedCellsToArray = (array, startRow, startCol, numRows, numCols) => {
    let array1 = JSON.parse(JSON.stringify(array));

    array1.push({
        "startCol" : startCol,
        "startRow" : startRow,
        "numCols" : numCols,
        "numRows" : numRows
    });

    return (array1);
};

export const mergeAndTransposePackTables = (sourcePT, destPT, title, indent = false, noDataFYear = false) => {

    //adds the data row of a 'years as rows' PT to a 'years as cols' PT.
    // noDataFYear should be true if there is no data in first year AND first year of projection is included in the PT.
    let packTable = resizePackTable(destPT, destPT.tableData.value.length + 1, destPT.tableData.value[0].length);

    packTable.tableData.value[packTable.tableData.value.length - 1][0] = title;
    for (let y = 1; y <= (sourcePT.tableData.value.length - 1); y++) {   //copy row data into new row
        if (noDataFYear && (y === 1)) {
            packTable.RDec[packTable.RDec.length - 1][y] = ' - ';
        }
        else {
            packTable.tableData.value[packTable.tableData.value.length - 1][y] = sourcePT.tableData.value[y][1]
        }
    }

    for (let y = 1; y <= (sourcePT.RDec.length - 1); y++) {   //copy row data into new row
        packTable.RDec[packTable.RDec.length - 1][y] = sourcePT.RDec[y][1];
    }

    if (indent) {
        packTable.IndentRowText[packTable.IndentRowText.length - 1].xoffset = 15;
        packTable.IndentRowText[packTable.IndentRowText.length - 1].value = true;
    } else {
        packTable.IndentRowText[packTable.IndentRowText.length - 1].xoffset = 0;
        packTable.IndentRowText[packTable.IndentRowText.length - 1].value = false;
    }

    return packTable;
};

export const mergePackTablesAny = (...packTables) => {

    let arr = [];
    for (let pt of packTables) {
        arr.push(pt);
    }

    // let columns = arr[0].GBColCount;
    // let rows = arr[0].GBRowCount;
    // let newPT = createSimpleDefaultPackTable(rows, columns);

    let newPT = arr[0];
    arr.forEach((pt, i) => {
        if (i !== 0) {
            newPT = mergePackTables(newPT, pt);
        }
    })

    return newPT;
};

export const mergePackTables = (pt1, pt2) => {  

    const defaultPackTable1 = createSimpleDefaultPackTable(
        pt1.GBRowCount || 1,
        pt1.GBColCount || 1,
    );

    const defaultPackTable2 = createSimpleDefaultPackTable(
        pt2.GBRowCount || 1,
        pt2.GBColCount || 1,
    );

    let packTable1  = {
        ...defaultPackTable1,
        ...pt1
    };

    let packTable2  = {
        ...defaultPackTable2,
        ...pt2
    };

    // Make sure columns are the same dimensions
    if (!(packTable1.GBColCount === packTable2.GBColCount)) {
        // return {
        //     packTable: {},
        //     error: true
        // };
        return {};
    }

    let rowCount = packTable1.GBRowCount + packTable2.GBRowCount - packTable2.GBFixedRows;
    let columnCount = packTable1.GBColCount;
    let table = clone(packTable1);
    table = resizePackTable(table, rowCount, columnCount);

    let r2 = packTable1.GBRowCount;
    let c2;
    let value2 = packTable2.tableData.value;
    for (let r = packTable2.GBFixedRows; r < value2.length; r++) {
        c2 = 0;
        for (let c = 0; c < value2[r].length; c++) {
            table.tableData.value[r2][c2] = packTable2.tableData.value[r][c];
            table.WordWrappedCols[c2] = packTable2.WordWrappedCols[c];
            table.RDec[r2][c2] = packTable2.RDec[r][c];
            table.MinAllowedVal[r2][c2] = packTable2.MinAllowedVal[r][c];
            table.MaxAllowedVal[r2][c2] = packTable2.MaxAllowedVal[r][c];
            table.FontStyles[r2][c2] = packTable2.FontStyles[r][c];
            
            table.FontBold[r2][c2] = packTable2.FontBold[r][c];
            table.FontItalic[r2][c2] = packTable2.FontItalic[r][c];
            table.FontUnderline[r2][c2] = packTable2.FontUnderline[r][c];

            table.FontColors[r2][c2] = packTable2.FontColors[r][c];
            table.BGColors[r2][c2] = packTable2.BGColors[r][c];
            table.GBCellComment[r2][c2] = packTable2.GBCellComment[r][c];
            table.GBUseTriangle[r2][c2] = packTable2.GBUseTriangle[r][c];
            table.LockedCells[r2][c2] = packTable2.LockedCells[r][c];
            table.IndentRowText[r2] = packTable2.IndentRowText[r];
            table.RowSubHeadings[r2] = packTable2.RowSubHeadings[r];
            table.HasCheckBox[r2][c2] = packTable2.HasCheckBox[r][c];
            table.CheckBoxState[r2][c2] = packTable2.CheckBoxState[r][c];
            table.Alignments[r2][c2] = packTable2.Alignments[r][c];
            table.RowIds[r2] = packTable2.RowIds[r];
            table.ColIds[c2] = packTable2.ColIds[c];
            table.GBRowHeights[r2] = packTable2.GBRowHeights[r];
            table.GBColWidths[c2] = packTable2.GBColWidths[c];

            c2++;
        }
        r2++;
    }

    // Add in mergedCells
    let packTable2StartRow = packTable1.GBRowCount - 1;
    for (let i = 0; i < packTable2.MergedCells.length; i++) {
        let mergedCellObject = packTable2.MergedCells[i];
        
        mergedCellObject.startCol = mergedCellObject.startCol; // eslint-disable-line no-self-assign
        mergedCellObject.startRow = packTable2StartRow + mergedCellObject.startRow;
        mergedCellObject.numCols = mergedCellObject.numCols; // eslint-disable-line no-self-assign
        mergedCellObject.numRows = mergedCellObject.numRows; // eslint-disable-line no-self-assign

        // Add mergedCellObject to the merged packtable
        table.MergedCells.push(mergedCellObject)
    }

    // Since we merged two unknown packTables, these settings should go to some default.
    table.TotalRow = 0;
    table.TotalCol = 0;
    table.Title = ""; //"Merged";
    table.editorMstID = -1;
    table.Proj = -1;
    table.ModID = -1;
    table.SourceMode = 0;
    table.Source = [];

    return table;

    // return {
    //     packTable: table,
    //     error: false
    // };
};

export const indentPackTable = (packTable, row, indent, offset) => {
    let pt = clone(packTable);

    if (indent) {
        pt.IndentRowText[row] = {xoffset : offset, Value : true};
    }
    else {
        pt.IndentRowText[row] = {xoffset : 0, Value : false};
    }

    return (pt);
};

export const getChartOptionsFromPackTable = (
    title,
    packTable,
    chartType,
    categoriesRow,
    row,
    col,
    lastRow,
    lastCol,
    reverse,
    stacked,
    stackedMax = 100
) => {
    let data;

    if (typeof packTable === "undefined" || packTable === null) {
        return {};
    }

    if (typeof packTable === "undefined" || packTable === null) {
        packTable = {
            GBRowCount: 3,
            GBColCount: 3,
            tableData: {
                value: [
                    ["", "", ""],
                    ["", 1, 2],
                    ["", 3, 4]
                ]
            }
        };

        row = 1;
        col = 1;
        lastRow = 2;
        lastCol = 2;
    }

    data = packTable["tableData"].value;

    if (lastRow === null) {
        lastRow = packTable["GBRowCount"] - 1;
    }

    if (lastCol === null) {
        lastCol = packTable["GBColCount"] - 1;
    }

    //----------------------------------------------------------------------------
    //                              Validation
    //----------------------------------------------------------------------------

    let dataLastRow = data.length;
    let dataLastCol = data[0].length;

    if (dataLastRow <= lastRow) {
        console.error("function getChartOptions says dataLastRow <= lastRow");
        return;
    }

    if (dataLastCol <= lastCol) {
        console.error("function getChartOptions says dataLastCol <= lastCol");
        return;
    }

    //----------------------------------------------------------------------------

    let r;
    let c;

    let categories = [];
    let mySeries = [];

    if (reverse) {
        for (r = row; r <= lastRow; r++) {
            categories.push(data[r][col - 1]);
        }

        for (c = col; c <= lastCol; c++) {
            mySeries.push({ data: [], value: [], name: data[row - 1][c] });
        }

        let x = 0;
        for (c = col; c <= lastCol; c++) {
            for (r = row; r <= lastRow; r++) {
                mySeries[x].data.push(data[r][c]);
                mySeries[x].value.push(data[r][c]);
            }
            x++;
        }
    } else {
        for (c = col; c <= lastCol; c++) {
            categories.push(data[categoriesRow][c]);
        }

        for (r = row; r <= lastRow; r++) {
            try {
                mySeries.push({ data: [], value: [], name: data[r][col - 1] });
            } catch (e) {
                console.error("Error in getChartOptions");
            }
        }

        let x = 0;
        for (r = row; r <= lastRow; r++) {
            for (c = col; c <= lastCol; c++) {
                mySeries[x].data.push(data[r][c]);
                mySeries[x].value.push(data[r][c]);
            }
            x++;
        }
    }

    let isStacked = null;
    if (typeof stacked !== "undefined") {
        if (stacked) {
            isStacked = true;
        }
    }

    let chartTypeNEW;
    switch (chartType) {
        case "pyramid":
            chartTypeNEW = "bar";
            break;
        default:
            chartTypeNEW = chartType;
            break;
    }

    let options = {
        chart: {
            type: chartTypeNEW
        },
        title: {
            text: title,
            style: {
                fontSize: "12px"
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            text: ""
        },
        // exporting: {
        //     buttons: {
        //         contextButton: {
        //             symbol: 'download'
        //         }
        //     }
        // }
        tooltip: {
            enabled: true,
            shared: true,
            crosshairs: true,
            valueDecimals: 2,
            formatter: function() {
                let s = "<b>" + this.x + "</b>";

                const numberWithCommas = x => {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
                };

                this.points.forEach(item => {
                    s +=  '<br/><b><span style="color:' + item.point.color + '"> \u25CF </span> ' + item.series.name + "</b>: " + numberWithCommas(item.y.toFixed(2));
                });

                return s;
            }
        },
        legend: {
            enabled: false
        },
        xAxis: {
            categories: categories
        },
        yAxis : {
            title : {
                text: ''
            },
            max : isStacked ? stackedMax : undefined
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false
                }
            },

            bar : {
                stacking : isStacked
            },
            column : {
                stacking : isStacked
            },
            series: {
                stacking: isStacked
            }
        },
        series: mySeries
    };

    return options;
};







//=======================================================================================================
//
//                        #######  ######## ##     ## ######## ########  
//                       ##     ##    ##    ##     ## ##       ##     ## 
//                       ##     ##    ##    ##     ## ##       ##     ## 
//                       ##     ##    ##    ######### ######   ########  
//                       ##     ##    ##    ##     ## ##       ##   ##   
//                       ##     ##    ##    ##     ## ##       ##    ##  
//                        #######     ##    ##     ## ######## ##     ##
//
//=======================================================================================================

export const hash = function(s, seed = 0) {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;

    for (let i = 0, c; i < s.length; i++) {
        c = s.charCodeAt(i);
        h1 = Math.imul(h1 ^ c, 2654435761);
        h2 = Math.imul(h2 ^ c, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const GBStringify = obj => {

    function stringify(obj, options) {
        // https://github.com/lydell/json-stringify-pretty-compact/blob/master/index.js + custom changes
    
        options = options || {};
        var indent = JSON.stringify([1], null, getSomethingForStringify(options, "indent", 2)).slice(2, -3);
        var addMargin = getSomethingForStringify(options, "margins", false);
        var maxLength = indent === "" ? Infinity : getSomethingForStringify(options, "maxLength", 80);
    
        return (function _stringify(obj, currentIndent, reserved) {
            if (obj && typeof obj.toJSON === "function") {
                obj = obj.toJSON();
            }
    
            var string = JSON.stringify(obj);
    
            if (string === undefined) {
                return string;
            }
    
            var length = maxLength - currentIndent.length - reserved;
    
            if (string.length <= length) {
                if (string[0] !== "{") {
                    // TGP
                    if (!(string[0] === "[" && string[1] === "[")) {
                        // TGP
                        if (!(string[0] === "[" && string[1] === "{")) {
                            // TGP
                            var prettified = prettify(string, addMargin);
                            if (prettified.length <= length) {
                                return prettified;
                            }
                        }
                    }
                }
            }
    
            if (typeof obj === "object" && obj !== null) {
                var nextIndent = currentIndent + indent;
                var items = [];
                var delimiters;
                var comma = function(array, index) {
                    return index === array.length - 1 ? 0 : 1;
                };
    
                if (Array.isArray(obj)) {
                    for (var index = 0; index < obj.length; index++) {
                        items.push(_stringify(obj[index], nextIndent, comma(obj, index)) || "null");
                    }
                    delimiters = "[]";
                } else {
                    Object.keys(obj).forEach(function(key, index, array) {
                        var keyPart = JSON.stringify(key) + ": ";
                        var value = _stringify(obj[key], nextIndent, keyPart.length + comma(array, index));
                        if (value !== undefined) {
                            items.push(keyPart + value);
                        }
                    });
                    delimiters = "{}";
                }
    
                if (items.length > 0) {
                    return [delimiters[0], indent + items.join(",\n" + nextIndent), delimiters[1]].join(
                        "\n" + currentIndent
                    );
                }
            }
    
            return string;
        })(obj, "", 0);
    }
    
    function prettify(string, addMargin) {
        // Note: This regex matches even invalid JSON strings, but since we’re
        // working on the output of `JSON.stringify` we know that only valid strings
        // are present (unless the user supplied a weird `options.indent` but in
        // that case we don’t care since the output would be invalid anyway).
        var stringOrChar = /("(?:[^\\"]|\\.)*")|[:,\][}{]/g;
    
        var m = addMargin ? " " : "";
        var tokens = {
            "{": "{" + m,
            "[": "[" + m,
            "}": m + "}",
            "]": m + "]",
            ",": ", ",
            ":": ": "
        };
        return string.replace(stringOrChar, function(match, string) {
            return string ? match : tokens[match];
        });
    }
    
    function getSomethingForStringify(options, name, defaultValue) {
        return name in options ? options[name] : defaultValue;
    }

    var str = stringify(obj, { indent: 4, maxLength: Infinity });

    return str;

    // document.getElementById("myJSON2").innerHTML = str;
    // var prettyText = syntaxHighlight(str);
    // document.getElementById("responseConverted").innerHTML = prettyText;
};

//------------------------------------------------------------------------------------------------------

export const copyToClipboard = text => {
    const element = document.createElement("textarea");

    element.value = text;

    element.setAttribute("readonly", "");
    element.style.position = "absolute";
    element.style.left = "-9999px";

    let anchorElement = document.body;

    const anchorElements = document.getElementsByClassName("clipboard-anchor");

    if (anchorElements && anchorElements.length) {
        anchorElement = anchorElements[0];
    }

    anchorElement.appendChild(element);

    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

    element.select();
    document.execCommand("copy");

    anchorElement.removeChild(element);

    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
};

export const copyJStoClipboard = obj => {
    const str = GBStringify(obj);
    copyToClipboard(str);
};

export const copyJsonToClipboard = text => {
    // TGP Convert to a nicer format
    const obj = JSON.parse(text);
    const str = GBStringify(obj);
    copyToClipboard(str);
};

export const fieldToColumnIndex = field => parseInt(field.substring(fieldNamePrefix.length), 10) - 1;

export const formatNumber = (value, decimalPlaces, showThousandsSeparator) => {
    let minimumFractionalDigits = decimalPlaces;
    let maximumFractionalDigits = decimalPlaces;

    if (!decimalPlaces && decimalPlaces !== 0) {
        minimumFractionalDigits = 0;
        maximumFractionalDigits = 20;
    }

    if (!isNumeric(value)) {
        return value;
    }

    const number = parseFloat(value);

    return !showThousandsSeparator
        ? number.toFixed(decimalPlaces)
        : number.toLocaleString("en-US", {
              minimumFractionDigits: minimumFractionalDigits,
              maximumFractionDigits: maximumFractionalDigits
          });
};

export const formatPercentage = (value, decimalPlaces = 1) => {
    // return `${value.toFixed(decimalPlaces)}%`;
    if (isNumeric(value)) {
        return `${value.toFixed(decimalPlaces)}%`;
    }
    else {
        console.error("function formatPercentage would have errored!!!");
    }
}

export const isNumeric = value => (value || value === 0) && !isNaN(value);

export const parsePercentage = value => {
  if (!value.match(/%\s*$/)) {
      return NaN;
  }

  return parseFloat(value.replace(/%\s*$/, ""));
}

export const toDelimitedText = (packTable, delimiter) =>
    packTable.tableData.value.map(row => Object.values(row).join(delimiter)).join("\n");

export const toCsv = packTable => toDelimitedText(packTable, ",");

// https://stackoverflow.com/questions/8572826/generic-deep-diff-between-two-objects
export let deepDiffMapper = function () {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      map: function(obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw new Error('Invalid argument. Function given, object expected.');
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj1 === undefined ? obj2 : obj1
          };
        }
  
        var diff = {};
        for (let key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }
  
          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }
  
          diff[key] = this.map(obj1[key], value2);
        }
        for (let key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }
  
          diff[key] = this.map(undefined, obj2[key]);
        }
  
        return diff;
  
      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === '[object Function]';
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === '[object Array]';
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === '[object Date]';
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === '[object Object]';
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x);
      }
    }
}();