export const startYear = 2019;

export const numYrs = 5;

export const NationalProgram = 0;
export const SubnationalProgram = 1;

export const ED_Indent1 = 15;
export const ED_Indent2 = 30;

export const Number    = 0;
export const Percent   = 1;

export const Left = 0;
export const Right = 1;
export const Center = 2;

export const Bold = 0;
export const Italic = 1;
export const Underline = 2;

export const Editor_PopFertilityIntentionsmCPR = 1;
export const Editor_FPMethodMix                = 2;
export const Editor_IntermediateDeterminants   = 3;
export const Editor_ImpactAndCostEffectiveness = 4;
export const Editor_SBCToIntermediateDet       = 5;
export const Editor_IntermediateDetTomCPR      = 6;
export const Editor_DirectSBCTomCPR            = 7;
export const Editor_SBCUnitCosts               = 8;
export const Editor_DirectServiceDeliveryCosts = 9;
export const Editor_IntervReachManual          = 10;
export const Editor_IntervReachFinalYear       = 11;
export const Editor_IntervReachCumulative      = 12;
export const Editor_IntervSenseCheck           = 13;

export const Q1     = 0;
export const Median = 1;
export const Q3     = 2;

export const IntervReach_Manual      = 0;
export const IntervReach_FinalYear   = 1;
export const IntervReach_Cumulative  = 2;

export const Scale_S_Curve  = 0;
export const Scale_Linear   = 1;
export const Scale_Constant = 2;

export const FinalYearCol = 6;
export const CumulativeCol = 7;

export const LockedCellColor = 8421504;
export const UnlockedCellColor = '0x000000';

export const ScaledValues = 0;
export const CustomValues = 1;

//interventions
export const radio             = 0;
export const First_Interv      = radio;
export const TV                = 1;
export const newspaper         = 2;
export const mixedMassMedia    = 3;
export const billboards        = 4;
export const liveDrama         = 5;
export const commAnnouncements = 6;
export const SMSTextMessage    = 7;
export const householdIPC      = 8;
export const groupIPC          = 9;
export const IPCCommEngagement = 10;
export const Max_Num_Intervs   = IPCCommEngagement;

//constants for support popups
export const radioOpt             = 0;
export const TVOpt                = 1;
export const newspaperOpt         = 2;
export const mixedMassMediaOpt    = 3;
export const billboardsOpt        = 0;
export const liveDramaOpt         = 1;
export const commAnnouncementsOpt = 2;
export const SMSTextMessageOpt    = 0;
export const householdIPCOpt      = 0;
export const groupIPCOpt          = 1;
export const IPCCommEngagementOpt = 2;

//Intermediate determinants
export const FP_Approval      = 0;
export const FirstInterDeter  = FP_Approval;
export const PercepOfBenefit  = 1;
export const Self_Efficacy    = 2;
export const MensAttAndSupp   = 3;
export const CommunWithPart   = 4;
export const CommunWithOthers = 5;
export const MaxNumInterDeter = CommunWithOthers;
//Add on's for results
export const Direct_To_mCPR = 6;
export const Ideal_Num_Children = 7;
export const MaxNumInterDeterForResults = Ideal_Num_Children;

//SBC
export const MassMedia           = 0;
export const First_SBC           = MassMedia;
export const MobileSMS           = 1;
export const IndividualIPC       = 2;
export const GroupIPC            = 3;
export const IPCPlusCommunEngage = 4;
export const Max_Num_SBC         = IPCPlusCommunEngage;

//Aggregate categories
export const Mass_Media          = 0;
export const First_Agg_Cat       = Mass_Media;
export const MiddleMedia         = 1;
export const PhoneSMS            = 2;
export const IPC                 = 3;
export const Final_Agg_Cat       = IPC;

//FP Method mix
export const MethMix_Sterilization = 0;
export const FP_Meth_Mix_First     = MethMix_Sterilization;
export const MethMix_IUD           = 1;
export const MethMix_Implants      = 2;
export const MethMix_Injection     = 3;
export const MethMix_Pill          = 4;
export const MethMix_Condoms       = 5;
export const MethMix_LAM           = 6;
export const MethMix_Other         = 7;
export const FP_Meth_Mix_Last      = MethMix_Other;

//Direct service delivery costs
export const Sterilization           = 0;
export const FP_Direct_SD_Cost_First = Sterilization;
export const IUD                     = 1;
export const Implants                = 2;
export const Injection               = 3;
export const Pill                    = 4;
export const Condoms                 = 5;
export const Other                   = 6;
export const FP_Direct_SD_Cost_Last  = Other;

export const CS_PieChart         = 'pie';
export const CS_LineChart        = 'line';
export const CS_BarChart         = 'bar';
export const CS_ColumnChart      = 'column';
export const CS_TableChart       = 'table';
export const CS_ScatterChart     = 'scatter';
export const CS_AreaChart        = 'area';
export const CS_MultiChart       = 'multi';

export const BASIC_ResultPackChart = {
    title                    : '',
    alignTitle               : 'middle',
    subTitle                 : '',
    alignSubTitle            : 'middle',
    MultiSubTitles           : ['', ''],
    chartType                : 'line',
    legendAlign              : 'center',
    ShowLegend               : true,
    xAxisLabel               : '',
    pointLabels              : [],
    yAxisLabel               : '',
    RYAxisLabel              : '',
    ManualMinY               : 0,
    ManualMaxY               : 0,
    ManualStackedMinY        : 0,
    ManualStackedMaxY        : 0,
    chartData                : [],
    subsetLabels             : [],
    subsetColors             : [],
    subsetChartTypes         : [0],
    subsetPointTypes         : [-1],
    subsetLineTypes          : [-1],
    SubsetsToLegend          : [0],
    hasData                  : true,
    hasDeselectedIntervs     : false,
    RDec                     : 0,
    verticalLabels           : [],
    chartLabelLeftMargin     : 75,
};