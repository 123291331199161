import * as React from 'react';
import AppBar from "./AppBar";
import AppContent from "./AppContent";
import './App.css';
import Footer from './Footer';
import Theme from "./Theme";
import * as Constants from "../utilities/Constants"
import InternalData from "../data/modelParameters/InternalData.json";
import ExternalData from "../data/modelParameters/ExternalData.json"
import DataInputsDALYs from "../data/modelParameters/DataInputsDALYs.json"
import SBC_MedianData from "../data/ImpactORs/SBC_Median.json";
import SBC_Q1Data from "../data/ImpactORs/SBC_Q1.json";
import SBC_Q3Data from "../data/ImpactORs/SBC_Q3.json";
import mCPRData from "../data/ImpactORs/mCPR.json";
import SBC_mCPRData from "../data/ImpactORs/SBC_mCPR.json";
import SBCUnitCostsData from "../data/Costs/SBCUnitCosts.json";
import FPCostAIUData from "../data/Costs/FPCostAIU.json"
import IntervReachCumulativeData from "../data/Interventions/IntervReachCumulative.json";
import IntervReachFinalYearData from "../data/Interventions/IntervReachFinalYear.json";
import IntervReachManualData from "../data/Interventions/IntervReachManual.json";
import costEffData from "../data/results/costEffData.json";
import impactData from "../data/results/impactData.json";
import AboutDialog from '../dialogs/AboutDialog';

class App extends React.PureComponent {
    state = {
        //----------------------------------------
        //  Screens
        //----------------------------------------

        pageID                  : "HomePage",  
        backPageID              : "HomePage",  
        aboutDialogOpen         : false,
        parametersSet           : false,
        programIdx              : Constants.NationalProgram,
        subnatAreaName          : 'Enter area/group',
        shareOfPop              : 100,
        startYearIdx            : 0,
        interQuartileRangeIdx   : Constants.Median,
        selectedCountry         : "Afghanistan",
        intervReachIdx          : Constants.IntervReach_FinalYear,
        finalYearScaleIdx       : Constants.Scale_S_Curve,
        cumulativeScaleIdx      : Constants.Scale_S_Curve,
        finalYearRadioGroupIdx  : Constants.ScaledValues,
        cumulativeRadioGroupIdx : Constants.ScaledValues,
        restoreBtnDisabledModelParams1 : true,
        restoreBtnDisabledModelParams2 : true,
        restoreBtnDisabledModelParams3 : true,
        restoreBtnDisabledModelParams4 : true,
        restoreBtnDisabledImpactORs : true,
        restoreBtnDisabledCosts : true,
        itemIndex               : 0,

        finalYearSupport              : {
            massMedia : {
                //the option selected for each of the media types, [radioOpt, TVOpt, newspaperOpt, mixedMassMediaOpt], 
                //where options are Direct entry, By reach and By number of media spots
                //The default is the first selection
                optionIdxArray : [0, 0, 0, 0],
                radio : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                },
                TV : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                },
                newspaper : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                },
                mixedMassMedia : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                }
            },
            middleMedia : {
                //the option selected for each of the media types, [billboardsOpt, liveDramaOpt, newspaperOpt, commAnnouncementsOpt], 
                //where options are Direct entry, By event and By community
                //The default is the first selection
                optionIdxArray : [0, 0, 0],
                billboards : {
                    directEntry   : 0,
                    eventsHeld    : 500,
                    womenPerEvent : 100,
                    communities   : 250, 
                    womenPerComm  : 100,
                    reachEvent    : 0,
                    reachComm     : 0,
                },
                liveDrama :{
                    directEntry   : 0,
                    eventsHeld    : 500,
                    womenPerEvent : 100,
                    communities   : 250, 
                    womenPerComm  : 100,
                    reachEvent    : 0,
                    reachComm     : 0,
                },
                commAnnouncements :{
                    directEntry   : 0,
                    eventsHeld    : 500,
                    womenPerEvent : 100,
                    communities   : 250, 
                    womenPerComm  : 100,
                    reachEvent    : 0,
                    reachComm     : 0,
                }
            },
            phoneSMS : {
                //the option selected for each of the media types, [SMSTextMessageOpt], 
                //where options are Direct entry and By messages
                //The default is the first selection
                optionIdxArray : [0],
                SMSTextMessage : {
                    directEntry      : 0,
                    messagesSent     : 10000,
                    messagesPerWoman : 10,
                    reach            : 0
                }

            },
            IPC : {
                //the option selected for each of the media types, [householdIPCOpt, groupIPCOpt, newspaperOpt, IPCCommEngagementOpt], 
                //where options for household IPC are Direct entry, Community health workers and Providers
                //where options for group IPC are Direct entry, Peer education, Group counselling and School based
                //where options for IPC + community engagement are Direct Entry, Religious leaders and Community leaders
                //The default is the first selection
                optionIdxArray : [0, 0, 0],
                householdIPC : {
                    directEntry          : 0,
                    commHealthWorkers    : 500,
                    womenPerCHW          : 50,
                    providers            : 500,
                    womenPerProvider     : 50,
                    reachCHW             : 0,
                    reachProviders       : 0,
                },
                groupIPC : {
                    directEntry          : 0,
                    peerEducator         : 1000,
                    classesPerEducator   : 2,
                    womenPerClass        : 20,
                    counselors           : 400,
                    sessionsPerCounselor : 50,
                    womenPerSession      : 10,
                    teachers             : 200,
                    classesPerTeacher    : 3,
                    girlsPerClass        : 20,
                    reachCHW             : 0,
                    reachProviders       : 0,
                },
                IPCCommEngagement : {
                    directEntry          : 0,
                    religiousLeaders     : 1000,
                    congregantsPerLeader : 100,
                    percWomenCongregants : 50,
                    communityLeaders     : 500,
                    eventsPerLeader      : 12,
                    womenPerEvent        : 50,
                    reachCHW             : 0,
                    reachProviders       : 0,
                }
            }
        },

        cumulativeSupport              : {
            massMedia : {
                //the option selected for each of the media types, [radioOpt, TVOpt, newspaperOpt, mixedMassMediaOpt], 
                //where options are Direct entry, By reach and By number of media spots
                //The default is the first selection
                optionIdxArray : [0, 0, 0, 0],
                radio : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                },
                TV : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                },
                newspaper : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                },
                mixedMassMedia : {
                    directEntry     : 0,
                    womenReached    : 30,
                    mediaSpots      : 50,
                    listenersPerAd  : 10000,
                    reach           : 0,
                    reachMediaSpots : 0
                }
            },
            middleMedia : {
                //the option selected for each of the media types, [billboardsOpt, liveDramaOpt, newspaperOpt, commAnnouncementsOpt], 
                //where options are Direct entry, By event and By community
                //The default is the first selection
                optionIdxArray : [0, 0, 0],
                billboards : {
                    directEntry   : 0,
                    eventsHeld    : 500,
                    womenPerEvent : 100,
                    communities   : 250, 
                    womenPerComm  : 100,
                    reachEvent    : 0,
                    reachComm     : 0,
                },
                liveDrama :{
                    directEntry   : 0,
                    eventsHeld    : 500,
                    womenPerEvent : 100,
                    communities   : 250, 
                    womenPerComm  : 100,
                    reachEvent    : 0,
                    reachComm     : 0,
                },
                commAnnouncements :{
                    directEntry   : 0,
                    eventsHeld    : 500,
                    womenPerEvent : 100,
                    communities   : 250, 
                    womenPerComm  : 100,
                    reachEvent    : 0,
                    reachComm     : 0,
                }
            },
            phoneSMS : {
                //the option selected for each of the media types, [SMSTextMessageOpt], 
                //where options are Direct entry and By messages
                //The default is the first selectionn
                optionIdxArray : [0],
                SMSTextMessage : {
                    directEntry      : 0,
                    messagesSent     : 10000,
                    messagesPerWoman : 10,
                    reachMessages    : 0
                }

            },
            IPC : {
                 //the option selected for each of the media types, [householdIPCOpt, groupIPCOpt, newspaperOpt, IPCCommEngagementOpt], 
                //where options for household IPC are Direct entry, Community health workers and Providers
                //where options for group IPC are Direct entry, Peer education, Group counselling and School based
                //where options for IPC + community engagement are Direct Entry, Religious leaders and Community leaders
                //The default is the first selection
                optionIdxArray : [0, 0, 0],
                householdIPC : {
                    directEntry           : 0,
                    commHealthWorkers     : 500,
                    womenPerCHW           : 50,
                    providers             : 500,
                    womenPerProvider      : 50,
                    reachCHW              : 0,
                    reachProviders        : 0,
                },
                groupIPC : {
                    directEntry           : 0,
                    peerEducator          : 1000,
                    classesPerEducator    : 2,
                    womenPerClass         : 20,
                    counselors            : 400,
                    sessionsPerCounselor  : 50,
                    womenPerSession       : 10,
                    teachers              : 200,
                    classesPerTeacher     : 3,
                    girlsPerClass         : 20,
                    reachPeerEducation    : 0,
                    reachGroupCounselling : 0,
                    reachSchoolBased      : 0,
                },
                IPCCommEngagement : {
                    directEntry           : 0,
                    religiousLeaders      : 1000,
                    congregantsPerLeader  : 100,
                    percWomenCongregants  : 50,
                    communityLeaders      : 500,
                    eventsPerLeader       : 12,
                    womenPerEvent         : 50,
                    reachReligiousLeaders : 0,
                    reachCommLeaders      : 0,
                }
            }
        },

        modelParametersData        : {
            internalDefault        : InternalData,
            internalUser           : InternalData,
            externalDefault        : ExternalData,
            externalUser           : ExternalData,
            dataInputsDALYsDefault : DataInputsDALYs,
            dataInputsDALYsUser    : DataInputsDALYs,

        },

        impactORsData           : {
            SBC_Q1Default       : SBC_Q1Data,
            SBC_Q1User          : SBC_Q1Data,
            SBC_MedianDefault   : SBC_MedianData,
            SBC_MedianUser      : SBC_MedianData,
            SBC_Q3Default       : SBC_Q3Data,
            SBC_Q3User          : SBC_Q3Data,
            mCPRDefault         : mCPRData,
            mCPRUser            : mCPRData,
            SBC_mCPRDefault     : SBC_mCPRData,
            SBC_mCPRUser        : SBC_mCPRData
        },

        costsData               : {
            SBCUnitCostsDefault : SBCUnitCostsData,
            SBCUnitCostsUser    : SBCUnitCostsData,
            FPCostAIUDefault    : FPCostAIUData,
            FPCostAIUUser       : FPCostAIUData,
        },

        interventionsData       : {
            manualDefault       : IntervReachManualData,
            manualUser          : IntervReachManualData,
            cumulativeDefault   : IntervReachCumulativeData,
            cumulativeUser      : IntervReachCumulativeData,
            finalYearDefault    : IntervReachFinalYearData,
            finalYearUser       : IntervReachFinalYearData,
        },

        resultsData : {
            costEffData         : costEffData,
            impactData          : impactData
        }
    };

    onStateChange = (newState, successFn) => {
        //================================================================================
        //  Merge the final new state
        //================================================================================
        
        this.setState(prevState => {
            let mergedState = {
                ...prevState,
                ...newState
            };

            return mergedState;
        }, () => {
            if (typeof successFn !== "undefined") {
                successFn();
            }
        });
    };

    onAddTasks = (arr) => {
        /* prevState MUST be used to merge the proper new state together. This 
        setState could be called multiple times when stacking api calls, which 
        can lead to certain calls not being added or deleted properly. If not 
        deleted properly, the API dialog screen will forever stay up because 
        it thinks there is still a call running (when in fact we deleted it). */
        this.setState((prevState, props) => ({
            apiTasks : [...prevState.apiTasks, ...arr]
        })); 
    };

    onDeleteTask = (GUID) => {
        /* prevState MUST be used to merge the proper new state together. This 
        setState could be called multiple times when stacking api calls, which 
        can lead to certain calls not being added or deleted properly. If not 
        deleted properly, the API dialog screen will forever stay up because 
        it thinks there is still a call running (when in fact we deleted it). */
        this.setState((prevState, props) => ({
            apiTasks : prevState.apiTasks.filter((el) => (el.GUID === GUID ? false : true))
        }));
    };

    render() {
        return (
            <AppInner
                onStateChange={this.onStateChange}
                onAddTasks={this.onAddTasks}
                onDeleteTask={this.onDeleteTask}
                {...this.state}
            />
        );
    }
};

class AppInner extends React.PureComponent {

    onPageChange = (id) => {
        if (this.props.pageID === id) {
            id = 'reload';
        }

        if ( (typeof id === 'undefined') || (id === '') ) {
            this.props.onStateChange({
                pageID : this.props.pageID
            });
            return;
        }

        if (id === 'back') {
            id = this.props.backPageID;
        }

        if (id === "reload") {
            id = this.props.pageID;
            this.props.onStateChange({pageID: "NotAPage"}, () => this.props.onStateChange({pageID: id}));
        } 
        else {
            this.props.onStateChange({
                backPageID: this.props.pageID,
                pageID: id
            });
        }
    };

    onAboutDialogClose = () => {
        this.props.onStateChange ({
            aboutDialogOpen: false
        })
    };

    onComboBoxChange = (value) => {
        this.props.onStateChange ({
            itemIndex: value
        })
    };

    render() {
        const styles = {
            pageContainer : {
                backgroundColor : Theme.footer.color,
                minHeight       : '100%'
            },
            AppContent : {
                backgroundColor : '#FFFFFF',
            },
            fullPageContent : {
                overflowY : 'auto',
                position  : 'absolute',
                top       : 60,
                bottom    : 0,
                left      : 0,
                right     : 0,
            }
        };

        // console.log(Constants.NationalProgram);
        
        return (
            <div style={styles.pageContainer}>
                <AppBar
                    onStateChange={this.props.onStateChange}
                    onPageChange={this.onPageChange}
                />
                <div style={styles.fullPageContent}>
                    <div style={styles.AppContent}>
                        <AppContent
                            onStateChange={this.props.onStateChange}
                            onPageChange={this.onPageChange}
                            pageID={this.props.pageID}
                            parametersSet={this.props.parametersSet}
                            selectedCountry={this.props.selectedCountry}
                            programIdx={this.props.programIdx}
                            subnatAreaName={this.props.subnatAreaName}
                            shareOfPop={this.props.shareOfPop}
                            startYearIdx={this.props.startYearIdx}
                            modelParametersData={this.props.modelParametersData}
                            impactORsData={this.props.impactORsData}
                            interQuartileRangeIdx={this.props.interQuartileRangeIdx}
                            costsData={this.props.costsData}
                            intervReachIdx={this.props.intervReachIdx}
                            interventionsData={this.props.interventionsData}
                            resultsData={this.props.resultsData}
                            finalYearScaleIdx={this.props.finalYearScaleIdx}
                            cumulativeScaleIdx={this.props.cumulativeScaleIdx} 
                            finalYearRadioGroupIdx={this.props.finalYearRadioGroupIdx}
                            cumulativeRadioGroupIdx={this.props.cumulativeRadioGroupIdx}
                            finalYearSupport={this.props.finalYearSupport}
                            cumulativeSupport={this.props.cumulativeSupport}
                            restoreBtnDisabledModelParams1={this.props.restoreBtnDisabledModelParams1}
                            restoreBtnDisabledModelParams2={this.props.restoreBtnDisabledModelParams2}
                            restoreBtnDisabledModelParams3={this.props.restoreBtnDisabledModelParams3}
                            restoreBtnDisabledModelParams4={this.props.restoreBtnDisabledModelParams4}
                            restoreBtnDisabledImpactORs={this.props.restoreBtnDisabledImpactORs}
                            restoreBtnDisabledCosts={this.props.restoreBtnDisabledCosts}
                            itemIndex={this.props.itemIndex}
                            onComboBoxChange={this.onComboBoxChange}
                        />
                    </div>
                    <Footer />
                </div>
                {
                    (this.props.aboutDialogOpen) ? 
                    <AboutDialog
                        onClose={this.onAboutDialogClose}
                    />
                    : null
                }
             </div>
        );
    };
}

export default App;