import * as Constants from "../../../utilities/Constants";
import { createSimpleDefaultPackTable } from "../../../@common/components/GBStdTableAG/data/defaultPackTable";
import { generatePackChart } from "../../../utilities/GBUtil"

const Create = (props) => {
    let editorName = 'Projected increase in mCPR';

    const {selectedCountry, startYearIdx, modelParametersData, resultsData} = props;

    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + Constants.numYrs; j++) {
        years.push((Constants.startYear + j).toString());
    }

    let countryData = {};
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === selectedCountry) {
            countryData = {
                "internal" : modelParametersData["internalUser"][i],
                "external" : modelParametersData["externalUser"][i]
            }
        }
    }

    let packTable = Setup(years);
    packTable = SetSSData(packTable, countryData, resultsData, years);
    packTable.Title = editorName;
    packTable.useSubHeadersInChartLbls = true;
    packTable.useHeadersInChartLbls = false;
    let packChart = generatePackChart(packTable, 1, true, [], ['#1474F7', '#043A5B']);
    packChart.yAxisLabel = '%';
    return ([packTable, packChart]);
};

const Setup = (years) => {
    let pt = createSimpleDefaultPackTable(3, 2, true);

    pt.tableData.value[0][1] = 'mCPR';

    pt.tableData.value[1][0] = years[0];
    pt.tableData.value[2][0] = 'Increase';

    pt.RDec[1][1] = 1;
    pt.RDec[2][1] = 1;

    return pt;
};

const SetSSData = (pt, countryData, resultsData, years) => {
    pt.tableData.value[1][1] = countryData["external"]["mCPR " + years[0]];
    pt.tableData.value[2][1] = resultsData["impactData"]["Increase"] * 100;

    return pt;
};

export const projectedIncreaseIn_mCPR = {
    Create           : Create,
    Setup            : Setup,
    SetSSData        : SetSSData,
};

