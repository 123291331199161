import * as Constants from "./Constants";
import {
    get_SBC_Index,
    get_Agg_Cat_Index,
    get_Direct_Serv_Index_From_Meth_Mix,
    CloneObj,
    getQuotient} from "./GBUtil";

const const_l = 0;
const const_Range_Min = const_l;
const const_m = 1;
const const_h = 2;
const const_Range_Max = const_h;

const const_Median = 0;
const const_No_Change = 1;

const FP_Meth_Mix_Strings = [
    'Sterilization',
    'IUD',
    'Implants',
    "Injection",
    'Pill',
    'Condoms',
    'LAM',
    'Other'
];

const FP_Direct_SD_Cost_Strings = [
    'Female sterilization',
    'IUD',
    'Implant',
    "Injectable",
    'Pill',
    'Male condom',
    'Other',
];

function createArray(length) {
    var arr = new Array(length || 0),
        i = length;

    if (arguments.length > 1) {
        let args = Array.prototype.slice.call(arguments, 1);
        while(i--) arr[length-1 - i] = createArray.apply(this, args);
    }

    return arr;
}

export const log = (arr) =>{
  let result = CloneObj(arr);

  for (let i = 0; i < result.length; i++){
      if (result[i].length !== undefined) {
          for (let j = 0; j < result[i].length; j++) {
              if (result[i][j].length !== undefined) {
                  for (let x = 0; x < result[i][j].length; x++) {
                      result[i][j][x] = result[i][j][x].toFixed(8);
                  }
              }
              else {
                  result[i][j] = result[i][j].toFixed(8);
              }
          }
      }
      else{
          result[i] = result[i].toFixed(8);
      }
  }

  console.log(result);
};

export const Intermediate_Determinant_Valid = (Intermediate_Determinant) =>{
    return Number.isFinite(Intermediate_Determinant);
};

export const getTotalSBC_Costs = (SBC_Unit_Costs, Cost_Discount_Rate, intervData) =>{
    let UnitCostByYrWDiscount = createArray(const_Range_Max + 1, Constants.Max_Num_Intervs + 1, Constants.numYrs);

    let Cost = createArray(const_Range_Max + 1, Constants.Max_Num_Intervs + 1, Constants.numYrs + 1);

    for (let i = const_Range_Min; i <= const_Range_Max; i++) {
        for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
            for (let t = 0; t < Constants.numYrs; t++) {
                UnitCostByYrWDiscount[i][iv][t] = (1 / Math.pow((1 + Cost_Discount_Rate), t)) *
                    SBC_Unit_Costs[i][iv];
                Cost[i][iv][t] = intervData[iv]["Year " + (t + 1).toString()] * UnitCostByYrWDiscount[i][iv][t];
            }
        }
    }

    let Cummulative_IV_Cost_With_Discount = createArray(const_Range_Max + 1, Constants.Max_Num_Intervs + 1);
    let Cummulative_IV_Cost_With_Discount_Total = createArray(const_Range_Max + 1);

    for (let i = const_Range_Min; i <= const_Range_Max; i++) {
        Cummulative_IV_Cost_With_Discount_Total[i] = 0;
        for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
            Cummulative_IV_Cost_With_Discount[i][iv] = 0;
            for (let t = 0; t < Constants.numYrs; t++) {
                Cummulative_IV_Cost_With_Discount[i][iv] += Cost[i][iv][t];
            }
            Cummulative_IV_Cost_With_Discount_Total[i] += Cummulative_IV_Cost_With_Discount[i][iv];
        }
    }

    return {
        'UnitCostByYrWDiscount'                   : UnitCostByYrWDiscount,
        'Cost'                                    : Cost,
        'Cummulative_IV_Cost_With_Discount'       : Cummulative_IV_Cost_With_Discount,
        'Cummulative_IV_Cost_With_Discount_Total' : Cummulative_IV_Cost_With_Discount_Total
    }
};

export const doCalculations = (props) => {

    //----------------------------------------------------------------------------------------------------------------//
    //                                                                                                                //
    //                                                                                                                //
    //                                                                                                                //
    //                                                                                                                //
    //                                                                                                                //
    //----------------------------------------------------------------------------------------------------------------//

    const {selectedCountry, shareOfPop, startYearIdx, modelParametersData, interQuartileRangeIdx,
        impactORsData, costsData, intervReachIdx, interventionsData, resultsData} = props;

    let years = [];

    for (let j = startYearIdx; j <= startYearIdx + Constants.numYrs; j++) {
        years.push((Constants.startYear + j).toString());
    }

    let qt;

    switch(interQuartileRangeIdx){
        case Constants.Q1     : qt = 'Q1'; break;
        case Constants.Median : qt = 'Median'; break;
        case Constants.Q3     : qt = 'Q3'; break;
        default               : qt = ''; break;
    }

    let reach;

    switch (intervReachIdx) {
        case Constants.IntervReach_Manual     : reach = 'manualUser'; break;
        case Constants.IntervReach_FinalYear  : reach = 'finalYearUser'; break;
        case Constants.IntervReach_Cumulative : reach = 'cumulativeUser'; break;
        default                               : reach = ''; break;
    }

    let countryData = {};
    let region = '';
    for (let i = 0; i < modelParametersData["externalUser"].length; i++) {
        if (modelParametersData["externalUser"][i]["COUNTRY"] === selectedCountry) {
            countryData = {
                "internal" : modelParametersData["internalUser"][i],
                "external" : modelParametersData["externalUser"][i]
            };
            region = modelParametersData['internalUser'][i]["UNPD Region for FP Costs"];
        }
    }

    let regional_Cost_Data = {};

    for (let i = 0; i < costsData['FPCostAIUUser'].length; i++) {
        if (costsData['FPCostAIUUser'][i]["Income/geographic/other group"] === region) {
            regional_Cost_Data = costsData['FPCostAIUUser'][i];
        }
    }

    let countryDataDALYs = {};
    for (let i = 0; i < modelParametersData["dataInputsDALYsUser"].length; i++) {
        if (modelParametersData["dataInputsDALYsUser"][i]["COUNTRY"] === selectedCountry) {
            countryDataDALYs = modelParametersData["dataInputsDALYsUser"][i];
        }
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                                                                                                                //
    //                                                                                                                //
    //                                              Gather inputs                                                     //
    //                                                                                                                //
    //                                                                                                                //
    //----------------------------------------------------------------------------------------------------------------//

    //----------------------------------------------------------------------------------------------------------------//
    //                     Model Parameters Table 1: Population, fertility intentions, mCPR                           //
    //----------------------------------------------------------------------------------------------------------------//

    let WRA = [
        countryData["external"]["WRA " + years[0]] * shareOfPop / 100,
        countryData["external"]["WRA " + years[1]] * shareOfPop / 100,
        countryData["external"]["WRA " + years[2]] * shareOfPop / 100,
        countryData["external"]["WRA " + years[3]] * shareOfPop / 100,
        countryData["external"]["WRA " + years[4]] * shareOfPop / 100,
        countryData["external"]["WRA " + years[5]] * shareOfPop / 100
    ];

    let Ideal_Num_Children = countryData["external"]["Mean Ideal number of children"];
    let mCPR_At_Starting_Year = countryData["external"]["mCPR " + years[0]];

    //----------------------------------------------------------------------------------------------------------------//
    //                         Model Parameters Table 2: FP method mix (among all women)                              //
    //----------------------------------------------------------------------------------------------------------------//

    let FP_Meth_Mix = createArray(Constants.FP_Meth_Mix_Last + 1);

    for (let m = Constants.FP_Meth_Mix_First; m <= Constants.FP_Meth_Mix_Last; m++) {
        FP_Meth_Mix[m] = countryData["external"]["Method Mix " + FP_Meth_Mix_Strings[m]] / 100;
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                       Model Parameters Table 3: Intermediate determinants of FP use                            //
    //----------------------------------------------------------------------------------------------------------------//

    let Intermediate_Determ_Strings = [
        'FP Approval',
        'Perception of Benefits',
        'Self-efficacy',
        "Men's attitude and support",
        'Communication with partner',
        'Communication with others'
    ];

    let Intermediate_Determinants_Of_FP_Use = createArray(Constants.MaxNumInterDeter + 1);

    for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
        let value = countryData["internal"]["Value " + Intermediate_Determ_Strings[i]];
        Intermediate_Determinants_Of_FP_Use[i] = (typeof value !== 'number') ? value : (value * 100) / 100;
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                        Model Parameters Table 4: Impact and cost-effectiveness                                 //
    //----------------------------------------------------------------------------------------------------------------//

    let FP_Failure_Rates = createArray(Constants.FP_Meth_Mix_Last + 1);

    for (let m = Constants.FP_Meth_Mix_First; m <= Constants.FP_Meth_Mix_Last; m++) {
        FP_Failure_Rates[m] = countryData["external"]["Failure Rates " + FP_Meth_Mix_Strings[m]] / 100;
    }
    let Comparison_Pregnancy_Rate = countryData["external"]["Comparison Pregnancy Rate"] / 100;

    let Maternal_Deaths_Per_Unintended_Preg = [
        countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[1]],
        countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[2]],
        countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[3]],
        countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[4]],
        countryDataDALYs["Maternal Deaths Averted Per Preg Averted " + years[5]],
    ];

    let Avg_Age_Mat_Death = countryDataDALYs["Avg age maternal death"];
    let YLD_Per_YLL = countryDataDALYs["Maternal DALY Ratio (YLD per YLL) Global Burden of Disease 2016"];
    let LE = countryData["external"]["Life expectancy for DALY calculations"];
    let DALY_Discount_Rate = countryData["external"]["DALY Discount Rate"] / 100;
    let Cost_Discount_Rate = countryData["external"]["Cost Discount Rate"] / 100;

    //----------------------------------------------------------------------------------------------------------------//
    //                             Impact ORs Table 1: SBC to Intermediate Determinants                               //
    //----------------------------------------------------------------------------------------------------------------//

    Intermediate_Determ_Strings = [
        'FP approval',
        'Perception of Benefits',
        'Self-efficacy',
        "Men's attitudes and support",
        'Communication with partner',
        'Communication with others'
    ];

    let SBC_To_Inter_Determ = createArray(Constants.MaxNumInterDeterForResults + 1, Constants.Max_Num_Intervs + 1);

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        let SBC_Index = get_SBC_Index(iv);
        for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
            SBC_To_Inter_Determ[i][iv] = impactORsData['SBC_'+ qt + 'User'][SBC_Index][Intermediate_Determ_Strings[i]];
        }
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                            Impact ORs Table 2: Intermediate Determinants to mCPR                               //
    //----------------------------------------------------------------------------------------------------------------//

    let Intermediate_Determinants_To_mCPR = createArray(Constants.MaxNumInterDeter + 1);

    for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
        Intermediate_Determinants_To_mCPR[i] = impactORsData['mCPRUser'][i][qt];
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                                  Impact ORs Table 3: Direct SBC to mCPR                                        //
    //----------------------------------------------------------------------------------------------------------------//

    let Direct_SBC_To_mCPR = createArray(Constants.Max_Num_SBC + 1);

    for (let i = Constants.First_SBC; i <= Constants.Max_Num_SBC; i++) {
        Direct_SBC_To_mCPR[i] = impactORsData['SBC_mCPRUser'][i][qt];
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                                       Costs Table 1: SBC unit costs                                            //
    //----------------------------------------------------------------------------------------------------------------//

    let SBC_Unit_Costs = createArray(const_Range_Max + 1, Constants.Max_Num_Intervs + 1);

    for (let i = Constants.First_Interv; i <= Constants.Max_Num_Intervs; i++) {
        SBC_Unit_Costs[const_l][i] = costsData["SBCUnitCostsUser"][i]["Median Unit Cost Defaults"] / 2;
        SBC_Unit_Costs[const_m][i] = costsData["SBCUnitCostsUser"][i]["Median Unit Cost Defaults"];
        SBC_Unit_Costs[const_h][i] = costsData["SBCUnitCostsUser"][i]["Median Unit Cost Defaults"] * 2;
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                                 Costs Table 2: Direct service delivery costs                                   //
    //----------------------------------------------------------------------------------------------------------------//

    let Direct_Service_Deliv_Costs = createArray(Constants.FP_Direct_SD_Cost_Last + 1);

    for (let d = Constants.FP_Direct_SD_Cost_First; d <= Constants.FP_Direct_SD_Cost_Last; d++) {
        Direct_Service_Deliv_Costs[d] = regional_Cost_Data["2020 " + FP_Direct_SD_Cost_Strings[d]];
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                                              Interventions                                                     //
    //----------------------------------------------------------------------------------------------------------------//

    let intervData = CloneObj(interventionsData[reach]);

    //----------------------------------------------------------------------------------------------------------------//
    //                                                                                                                //
    //                                                                                                                //
    //                                             Massage inputs                                                     //
    //                                                                                                                //
    //                                                                                                                //
    //----------------------------------------------------------------------------------------------------------------//

    /* From Spreadsheet: 'Impact Calculation I21' */
    let Direct_To_mCPR = mCPR_At_Starting_Year / 65;

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        let i = get_SBC_Index(iv);
        SBC_To_Inter_Determ[Constants.Direct_To_mCPR][iv] = Direct_SBC_To_mCPR[i];
        SBC_To_Inter_Determ[Constants.Ideal_Num_Children][iv] = 1.03;
    }

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        intervData[iv]["Year 0"] = intervData[iv]["Year 0"] / WRA[0];
        intervData[iv]["Year 1"] = intervData[iv]["Year 1"] / WRA[1];
        intervData[iv]["Year 2"] = intervData[iv]["Year 2"] / WRA[2];
        intervData[iv]["Year 3"] = intervData[iv]["Year 3"] / WRA[3];
        intervData[iv]["Year 4"] = intervData[iv]["Year 4"] / WRA[4];
        intervData[iv]["Year 5"] = intervData[iv]["Year 5"] / WRA[5];
    }

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        intervData[iv]["Year 5"] = intervData[iv]["Year 5"] - intervData[iv]["Year 4"];
        intervData[iv]["Year 4"] = intervData[iv]["Year 4"] - intervData[iv]["Year 3"];
        intervData[iv]["Year 3"] = intervData[iv]["Year 3"] - intervData[iv]["Year 2"];
        intervData[iv]["Year 2"] = intervData[iv]["Year 2"] - intervData[iv]["Year 1"];
        intervData[iv]["Year 1"] = intervData[iv]["Year 1"] - intervData[iv]["Year 0"];
    }

    // intervData[Constants.radio]["Year 1"] = 0.00588870492842334;
    // intervData[Constants.radio]["Year 2"] = 0.03429244953533120;
    // intervData[Constants.radio]["Year 3"] = 0.08377612786844130;
    // intervData[Constants.radio]["Year 4"] = 0.08031229444186300;
    // intervData[Constants.radio]["Year 5"] = 0.03359471774494360;
    //
    // intervData[Constants.TV]["Year 1"] = 0.00294435246421167;
    // intervData[Constants.TV]["Year 2"] = 0.01714622476766560;
    // intervData[Constants.TV]["Year 3"] = 0.04188806393422060;
    // intervData[Constants.TV]["Year 4"] = 0.04015614722093150;
    // intervData[Constants.TV]["Year 5"] = 0.01679735887247180;
    //
    // intervData[Constants.newspaper]["Year 1"] = 0.00014721762321058;
    // intervData[Constants.newspaper]["Year 2"] = 0.00085731123838328;
    // intervData[Constants.newspaper]["Year 3"] = 0.00209440319671103;
    // intervData[Constants.newspaper]["Year 4"] = 0.00200780736104657;
    // intervData[Constants.newspaper]["Year 5"] = 0.00083986794362359;
    //
    // intervData[Constants.mixedMassMedia]["Year 1"] = 0.00014721762321058;
    // intervData[Constants.mixedMassMedia]["Year 2"] = 0.00085731123838328;
    // intervData[Constants.mixedMassMedia]["Year 3"] = 0.00209440319671103;
    // intervData[Constants.mixedMassMedia]["Year 4"] = 0.00200780736104657;
    // intervData[Constants.mixedMassMedia]["Year 5"] = 0.00083986794362359;
    //
    // intervData[Constants.billboards]["Year 1"] = 0.00147217623210583;
    // intervData[Constants.billboards]["Year 2"] = 0.00857311238383281;
    // intervData[Constants.billboards]["Year 3"] = 0.02094403196711030;
    // intervData[Constants.billboards]["Year 4"] = 0.02007807361046580;
    // intervData[Constants.billboards]["Year 5"] = 0.00839867943623589;
    //
    // intervData[Constants.liveDrama]["Year 1"] = 0.00147217623210583;
    // intervData[Constants.liveDrama]["Year 2"] = 0.00857311238383281;
    // intervData[Constants.liveDrama]["Year 3"] = 0.02094403196711030;
    // intervData[Constants.liveDrama]["Year 4"] = 0.02007807361046580;
    // intervData[Constants.liveDrama]["Year 5"] = 0.00839867943623589;
    //
    // intervData[Constants.commAnnouncements]["Year 1"] = 0.00147217623210583;
    // intervData[Constants.commAnnouncements]["Year 2"] = 0.00857311238383281;
    // intervData[Constants.commAnnouncements]["Year 3"] = 0.02094403196711030;
    // intervData[Constants.commAnnouncements]["Year 4"] = 0.02007807361046580;
    // intervData[Constants.commAnnouncements]["Year 5"] = 0.00839867943623589;
    //
    // intervData[Constants.SMSTextMessage]["Year 1"] = 0.00294435246421167;
    // intervData[Constants.SMSTextMessage]["Year 2"] = 0.01714622476766560;
    // intervData[Constants.SMSTextMessage]["Year 3"] = 0.04188806393422060;
    // intervData[Constants.SMSTextMessage]["Year 4"] = 0.04015614722093150;
    // intervData[Constants.SMSTextMessage]["Year 5"] = 0.01679735887247180;
    //
    // intervData[Constants.householdIPC]["Year 1"] = 0.00073608811605292;
    // intervData[Constants.householdIPC]["Year 2"] = 0.00428655619191640;
    // intervData[Constants.householdIPC]["Year 3"] = 0.01047201598355520;
    // intervData[Constants.householdIPC]["Year 4"] = 0.01003903680523290;
    // intervData[Constants.householdIPC]["Year 5"] = 0.00419933971811795;
    //
    // intervData[Constants.groupIPC]["Year 1"] = 0.00147217623210583;
    // intervData[Constants.groupIPC]["Year 2"] = 0.00857311238383281;
    // intervData[Constants.groupIPC]["Year 3"] = 0.02094403196711030;
    // intervData[Constants.groupIPC]["Year 4"] = 0.02007807361046580;
    // intervData[Constants.groupIPC]["Year 5"] = 0.00839867943623589;
    //
    // intervData[Constants.IPCCommEngagement]["Year 1"] = 0.00147217623210583;
    // intervData[Constants.IPCCommEngagement]["Year 2"] = 0.00857311238383281;
    // intervData[Constants.IPCCommEngagement]["Year 3"] = 0.02094403196711030;
    // intervData[Constants.IPCCommEngagement]["Year 4"] = 0.02007807361046580;
    // intervData[Constants.IPCCommEngagement]["Year 5"] = 0.00839867943623589;

    //----------------------------------------------------------------------------------------------------------------//
    //                                                                                                                //
    //                                                                                                                //
    //                                           Start calculations                                                   //
    //                                                                                                                //
    //                                                                                                                //
    //----------------------------------------------------------------------------------------------------------------//

    //----------------------------------------------------------------------------------------------------------------//
    //                                    Impact calculations: Years 1 to 5                                           //
    //----------------------------------------------------------------------------------------------------------------//

    let Odds = createArray(Constants.numYrs, Constants.MaxNumInterDeterForResults + 1);
    let Odds_mCPR_via_link = createArray(Constants.numYrs);
    let Endline_ORs = createArray(Constants.numYrs, Constants.MaxNumInterDeterForResults + 1, Constants.Max_Num_Intervs + 1);
    let Endline_Odds = createArray(Constants.numYrs, Constants.MaxNumInterDeterForResults + 1);
    let Ending_Values = createArray(Constants.numYrs, Constants.MaxNumInterDeterForResults + 1);
    let Starting_Values = createArray(Constants.numYrs, Constants.MaxNumInterDeterForResults + 1);
    let Starting_Values_mCPR_via_link = createArray(Constants.numYrs);
    let Change_To_Intermediate_Outcome = createArray(Constants.numYrs, Constants.MaxNumInterDeter + 1);
    let Endline_OR = createArray(Constants.numYrs, Constants.MaxNumInterDeter + 1);
    let Tot_Endline_Odds = createArray(Constants.numYrs);
    let Ending_Value = createArray(Constants.numYrs);

    /* Starting values for first year come from Intermediate determinants of FP Use, Direct to mCPR, and Ideal
       number of children. */
    for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
        Starting_Values[0][i] = Intermediate_Determinants_Of_FP_Use[i];
    }

    // Starting_Values[0][Constants.FP_Approval] = 0.897984454;
    // Starting_Values[0][Constants.PercepOfBenefit] = 0.7253;
    // Starting_Values[0][Constants.Self_Efficacy] = 0.769;
    // Starting_Values[0][Constants.MensAttAndSupp] = 0.4915;
    // Starting_Values[0][Constants.CommunWithPart] = 0.434665696;
    // Starting_Values[0][Constants.CommunWithOthers] = 0.230178;

    Starting_Values[0][Constants.Direct_To_mCPR] = Direct_To_mCPR;
    Starting_Values[0][Constants.Ideal_Num_Children] = Ideal_Num_Children;

    Starting_Values_mCPR_via_link[0] = Direct_To_mCPR;

    for (let t = 0; t < Constants.numYrs; t++) {
        /* Starting values for subsequent years come from Ending values for the year prior. */
        if (t > 0) {
            for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeterForResults; i++) {
                Starting_Values[t][i] = Ending_Values[t-1][i];
            }
            Starting_Values_mCPR_via_link[t] = Ending_Value[t-1];
        }

        Odds_mCPR_via_link[t] = getQuotient(Starting_Values_mCPR_via_link[t], (1 - Starting_Values_mCPR_via_link[t]));

        for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeterForResults; i++) {
            Odds[t][i] = getQuotient(Starting_Values[t][i], (1 - Starting_Values[t][i]));

            for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
                Endline_ORs[t][i][iv] = 1;
                if ((i >= Intermediate_Determinants_Of_FP_Use.length) || Intermediate_Determinant_Valid(Intermediate_Determinants_Of_FP_Use[i])){
                    Endline_ORs[t][i][iv] = (SBC_To_Inter_Determ[i][iv] - 1) * intervData[iv]["Year " + (t + 1).toString()] + 1;
                }
            }

            Endline_Odds[t][i] = Odds[t][i];
            for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
                Endline_Odds[t][i] *= Endline_ORs[t][i][iv];
            }

            Ending_Values[t][i] = getQuotient(Endline_Odds[t][i], (Endline_Odds[t][i] + 1));
            if (i === Constants.Ideal_Num_Children){
                Ending_Values[t][i] = Math.max(Ending_Values[t][i], (Starting_Values[t][i] - 0.4));
            }

            if (i <= Constants.MaxNumInterDeter) {
                Change_To_Intermediate_Outcome[t][i] = Ending_Values[t][i] - Starting_Values[t][i];
                Endline_OR[t][i] = 1;
                if (Intermediate_Determinant_Valid(Intermediate_Determinants_Of_FP_Use[i])){
                    Endline_OR[t][i] = (Intermediate_Determinants_To_mCPR[i] - 1) * Change_To_Intermediate_Outcome[t][i] + 1;
                }
            }
        }

        Tot_Endline_Odds[t] = Odds_mCPR_via_link[t];
        for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
            Tot_Endline_Odds[t] *= Endline_OR[t][i];
        }
        Ending_Value[t] = getQuotient(Tot_Endline_Odds[t], (Tot_Endline_Odds[t] + 1));
    }

    // console.log('Intermediate_Determinants_Of_FP_Use');
    // console.log(Intermediate_Determinants_Of_FP_Use);

    // console.log('Starting_Values');
    // log(Starting_Values);

    // console.log('Starting_Values_mCPR_via_link');
    // log(Starting_Values_mCPR_via_link);

    //SEEMS A BIT BAD
    // console.log('Odds');
    // log(Odds);

    // console.log('Odds_mCPR_via_link');
    // log(Odds_mCPR_via_link);

    // console.log('Endline_ORs');
    // log(Endline_ORs);

    //SEEMS A BIT BAD
    // console.log('Endline_Odds');
    // log(Endline_Odds);

    // console.log('Ending_Values');
    // log(Ending_Values);

    // console.log('Change_To_Intermediate_Outcome');
    // log(Change_To_Intermediate_Outcome);

    // console.log('Endline_ORs');
    // log(Endline_ORs);
    //
    // console.log('Tot_Endline_Odds');
    // log(Tot_Endline_Odds);
    //
    // console.log('Ending_Value');
    // log(Ending_Value);

    //----------------------------------------------------------------------------------------------------------------//
    //                                 Impact calculations: Allowable Growth                                          //
    //----------------------------------------------------------------------------------------------------------------//

    let Max_mCPR_at_Start = 185 * Math.pow(Math.E, (-0.38 * Ideal_Num_Children)) / 100;
    let Actual_mCPR_at_Start = mCPR_At_Starting_Year / 100;
    let Adjustment_Factor = 0;
    if(Actual_mCPR_at_Start > Max_mCPR_at_Start){
        Adjustment_Factor = Actual_mCPR_at_Start - Max_mCPR_at_Start;
    }

    let FinalYearEndingValues = Ending_Values[Ending_Values.length - 1];

    let Endline_Ideal_Num_Children_From_SBC = FinalYearEndingValues[Constants.Ideal_Num_Children];
    let Endline_Lead_Num_Children_Adding_Natural_Recline = Endline_Ideal_Num_Children_From_SBC - (0.025 * 5);

    let Max_mCPR_at_Endline_WO_Adjustment =
        185 * Math.pow(Math.E, (-0.38 * Endline_Lead_Num_Children_Adding_Natural_Recline)) / 100;

    let Max_mCPR_at_Endline_W_Adjustment = Max_mCPR_at_Endline_WO_Adjustment + Adjustment_Factor;
    let Max_mCPR_Growth = Max_mCPR_at_Endline_W_Adjustment - Actual_mCPR_at_Start;
    let Increase = Math.min((FinalYearEndingValues[Constants.Direct_To_mCPR] - Direct_To_mCPR), Max_mCPR_Growth);

    //----------------------------------------------------------------------------------------------------------------//
    //                                 Impact calculations: Total Change in mCPR                                      //
    //----------------------------------------------------------------------------------------------------------------//

    let mCPR_Change = [
        Ending_Value[Ending_Value.length - 1] - Direct_To_mCPR,
        FinalYearEndingValues[Constants.Direct_To_mCPR] - Direct_To_mCPR,
        FinalYearEndingValues[Constants.Direct_To_mCPR] - Direct_To_mCPR,
        Max_mCPR_Growth,
        Increase
    ];

    //----------------------------------------------------------------------------------------------------------------//
    //                      Impact calculations: Change by Intervention and Intermediate Outcome                      //
    //----------------------------------------------------------------------------------------------------------------//

    let Table1 = createArray(Constants.MaxNumInterDeterForResults + 1, Constants.Max_Num_Intervs + 1);
    let Table2 = createArray(Constants.MaxNumInterDeterForResults + 1, Constants.Max_Num_Intervs + 1);
    let Table3 = createArray(Constants.MaxNumInterDeterForResults + 1, Constants.Max_Num_Intervs + 1);
    let Table4 = createArray(Constants.MaxNumInterDeterForResults + 1, Constants.Max_Num_Intervs + 1);
    let Table5 = createArray(Constants.MaxNumInterDeterForResults + 1, Constants.Max_Num_Intervs + 1);

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeterForResults; i++) {
            Table1[i][iv] = 0;
            Table2[i][iv] = 0;
            Table3[i][iv] = 1;
            Table4[i][iv] = 0;
            Table5[i][iv] = 0;
        }
    }

    let Table1_Sum = 0;
    let Table1_Direct_To_mCPR_Sum = 0;
    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            for (let x = 0; x < Constants.numYrs; x++){
                Table1[i][iv] += (Endline_ORs[x][i][iv] - 1);
            }
            switch(i){
                case Constants.Direct_To_mCPR : Table1_Direct_To_mCPR_Sum += Table1[i][iv]; break;
                default : Table1_Sum += Table1[i][iv]; break;
            }
        }
    }

    let Table2_IV_Sums = createArray(Constants.Max_Num_Intervs + 1);
    let Table2_Difference = createArray(Constants.Max_Num_Intervs + 1);

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        Table2_IV_Sums[iv] = 0;
        Table2_Difference[iv] = 0;
    }

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            switch (i){
                case Constants.Direct_To_mCPR : Table2[i][iv] += getQuotient(Table1[i][iv], Table1_Direct_To_mCPR_Sum) *
                    mCPR_Change[1]; break;
                default : Table2[i][iv] += getQuotient(Table1[i][iv], Table1_Sum) * mCPR_Change[0]; break;
            }
            if (i !== Constants.Direct_To_mCPR) {
                Table2_IV_Sums[iv] += Table2[i][iv];
            }
        }
        Table2_Difference[iv] = Table2[Constants.Direct_To_mCPR][iv] - Table2_IV_Sums[iv];
    }

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
            if (Table2_IV_Sums[iv] > 0) {
                Table3[i][iv] = Table2[i][iv] / Table2_IV_Sums[iv];
            }
        }
    }

    let Table4_Residual = createArray(Constants.Max_Num_Intervs + 1);
    let Table4_Total = createArray(Constants.Max_Num_Intervs + 1);

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        Table4_Residual[iv] = Math.max(0, Table2_Difference[iv]);
        Table4_Total[iv] = Table4_Residual[iv];
        for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
            Table4[i][iv] = Table2[i][iv];
            if (Table2_Difference[iv] <= 0) {
                Table4[i][iv] = (Table3[i][iv] * Table2[Constants.Direct_To_mCPR][iv]);
            }
            Table4_Total[iv] += Table4[i][iv];
        }
    }

    let Table4_AggregateTotal = 0;
    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        Table4_AggregateTotal += Table4_Total[iv];
    }

    let Table5_Residual = createArray(Constants.Max_Num_Intervs + 1);
    let Table5_Total = createArray(Constants.Max_Num_Intervs + 1);

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        Table5_Total[iv] = 0;
        for (let i = Constants.FirstInterDeter; i <= Constants.MaxNumInterDeter; i++) {
            Table5[i][iv] += getQuotient(Table4[i][iv], Table4_AggregateTotal);
            Table5_Total[iv] += Table5[i][iv];
        }
        Table5_Residual[iv] = getQuotient(Table4_Residual[iv], Table4_AggregateTotal);
        Table5_Total[iv] += Table5_Residual[iv];
    }

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            resultsData["impactData"]["RelContrOfSBCIntervToIncIn_mCPR"][i][get_Agg_Cat_Index(iv)] = 0;
        }
    }

    for (let iv = Constants.First_Interv; iv <= Constants.Max_Num_Intervs; iv++) {
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            switch(i){
                case Constants.Direct_To_mCPR :
                    resultsData["impactData"]["RelContrOfSBCIntervToIncIn_mCPR"][i][get_Agg_Cat_Index(iv)] +=
                        Table5_Residual[iv];
                    break;
                default :
                    resultsData["impactData"]["RelContrOfSBCIntervToIncIn_mCPR"][i][get_Agg_Cat_Index(iv)] +=
                        Table5[i][iv];
                    break;
            }
        }
    }

    //----------------------------------------------------------------------------------------------------------------//
    //                  Impact calculations: Final Results to Feed Into Cost and Impact Calculations                  //
    //----------------------------------------------------------------------------------------------------------------//

    let Annual_mCPR = [
        Direct_To_mCPR,
        Ending_Values[0][Constants.Direct_To_mCPR],
        Ending_Values[1][Constants.Direct_To_mCPR],
        Ending_Values[2][Constants.Direct_To_mCPR],
        Ending_Values[3][Constants.Direct_To_mCPR],
        Ending_Values[4][Constants.Direct_To_mCPR],
    ];

    let Change_In_mCPR_From_Baseline = [
        Annual_mCPR[1] - Annual_mCPR[0],
        Annual_mCPR[2] - Annual_mCPR[0],
        Annual_mCPR[3] - Annual_mCPR[0],
        Annual_mCPR[4] - Annual_mCPR[0],
        Annual_mCPR[5] - Annual_mCPR[0],
    ];

    let Ratio_Of_Change_Each_Year_To_Final_Year = [
        getQuotient(Change_In_mCPR_From_Baseline[0], Change_In_mCPR_From_Baseline[4]),
        getQuotient(Change_In_mCPR_From_Baseline[1], Change_In_mCPR_From_Baseline[4]),
        getQuotient(Change_In_mCPR_From_Baseline[2], Change_In_mCPR_From_Baseline[4]),
        getQuotient(Change_In_mCPR_From_Baseline[3], Change_In_mCPR_From_Baseline[4]),
        getQuotient(Change_In_mCPR_From_Baseline[4], Change_In_mCPR_From_Baseline[4])
    ];

    let Final_Change_in_mCPR_With_Adjust = [
        Increase * Ratio_Of_Change_Each_Year_To_Final_Year[0],
        Increase * Ratio_Of_Change_Each_Year_To_Final_Year[1],
        Increase * Ratio_Of_Change_Each_Year_To_Final_Year[2],
        Increase * Ratio_Of_Change_Each_Year_To_Final_Year[3],
        Increase
    ];

    let Final_mCPR_Trend = [
        Actual_mCPR_at_Start,
        Actual_mCPR_at_Start + Final_Change_in_mCPR_With_Adjust[0],
        Actual_mCPR_at_Start + Final_Change_in_mCPR_With_Adjust[1],
        Actual_mCPR_at_Start + Final_Change_in_mCPR_With_Adjust[2],
        Actual_mCPR_at_Start + Final_Change_in_mCPR_With_Adjust[3],
        Actual_mCPR_at_Start + Final_Change_in_mCPR_With_Adjust[4],
    ];

    let Users = [
        WRA[0] * Final_mCPR_Trend[0],
        WRA[1] * Final_mCPR_Trend[1],
        WRA[2] * Final_mCPR_Trend[2],
        WRA[3] * Final_mCPR_Trend[3],
        WRA[4] * Final_mCPR_Trend[4],
        WRA[5] * Final_mCPR_Trend[5],
    ];

    for (let a = Constants.First_Agg_Cat; a <= Constants.Final_Agg_Cat; a++) {
        for (let i = Constants.FirstInterDeter; i <= Constants.Direct_To_mCPR; i++) {
            resultsData["impactData"]["mCPR_Increase_Pathways"][i][a] =
                resultsData["impactData"]["RelContrOfSBCIntervToIncIn_mCPR"][i][a] *
                    Increase;
        }
    }

    resultsData["impactData"]["Increase"] = Increase;

    //----------------------------------------------------------------------------------------------------------------//
    //                                       Cost and impact calculations                                             //
    //----------------------------------------------------------------------------------------------------------------//

    intervData = CloneObj(interventionsData[reach]);

    let TotalSBC_Costs = getTotalSBC_Costs(SBC_Unit_Costs, Cost_Discount_Rate, intervData);

    let ModernUsers = createArray(const_No_Change + 1, Constants.numYrs + 1);

    ModernUsers[const_Median] = Users;

    for (let t = 0; t <= Constants.numYrs; t++) {
        ModernUsers[const_No_Change][t] = Final_mCPR_Trend[0] * WRA[t];
    }

    let Inc_User_Assoc_With_Inc_in_mCPR = createArray(Constants.numYrs);
    let Inc_User_Assoc_With_Inc_in_mCPR_Cumm = 0;

    for (let t = 0; t < Constants.numYrs; t++) {
        Inc_User_Assoc_With_Inc_in_mCPR[t] = ModernUsers[const_Median][t + 1] - ModernUsers[const_No_Change][t + 1];
        Inc_User_Assoc_With_Inc_in_mCPR_Cumm += Inc_User_Assoc_With_Inc_in_mCPR[t];
    }

    let Avg_Cost_Per_User = 0;

    for (let m = Constants.FP_Meth_Mix_First; m <= Constants.FP_Meth_Mix_Last; m++) {
        let d = get_Direct_Serv_Index_From_Meth_Mix(m);
        if (d !== -1) {
            Avg_Cost_Per_User += FP_Meth_Mix[m] * Direct_Service_Deliv_Costs[d];
        }
    }

    let Preg_Avert_Per_User = 0;

    for (let m = Constants.FP_Meth_Mix_First; m <= Constants.FP_Meth_Mix_Last; m++) {
        Preg_Avert_Per_User += FP_Meth_Mix[m] * FP_Failure_Rates[m];
    }

    Preg_Avert_Per_User = Comparison_Pregnancy_Rate - Preg_Avert_Per_User;

    let SD_Cost_Discounted = createArray(Constants.numYrs);
    let SD_Cost_Discounted_Cumm = 0;

    for (let t = 0; t < Constants.numYrs; t++) {
        SD_Cost_Discounted[t] = (Inc_User_Assoc_With_Inc_in_mCPR[t] * Avg_Cost_Per_User) *
            (1 / Math.pow((1 + Cost_Discount_Rate), t));
        SD_Cost_Discounted_Cumm += SD_Cost_Discounted[t];
    }

    let Preg_Averted = createArray(Constants.numYrs);
    let Preg_Averted_Cumm = 0;

    for (let t = 0; t < Constants.numYrs; t++) {
        Preg_Averted[t] = Inc_User_Assoc_With_Inc_in_mCPR[t] * Preg_Avert_Per_User;
        Preg_Averted_Cumm += Preg_Averted[t];
    }

    let Maternal_Deaths_Averted = createArray(Constants.numYrs);
    let Maternal_Deaths_Averted_Cumm = 0;

    for (let t = 0; t < Constants.numYrs; t++) {
        Maternal_Deaths_Averted[t] = Maternal_Deaths_Per_Unintended_Preg[t] * Preg_Averted[t];
        Maternal_Deaths_Averted_Cumm += Maternal_Deaths_Averted[t];
    }

    let discrete_time_disc_factor = createArray(74);
    let cumm_sum_of_discrete_time_disc_factor = createArray(74, Constants.numYrs);

    for (let t = 0; t <= 73; t++){
        discrete_time_disc_factor[t] = 1 / (Math.pow((1+DALY_Discount_Rate), t));

        cumm_sum_of_discrete_time_disc_factor[t][0] = 0;
        cumm_sum_of_discrete_time_disc_factor[t][1] = 0;
        cumm_sum_of_discrete_time_disc_factor[t][2] = 0;
        cumm_sum_of_discrete_time_disc_factor[t][3] = 0;
        cumm_sum_of_discrete_time_disc_factor[t][4] = 0;
    }

    for (let t = 0; t <= 73; t++) {
        for (let t2 = 0; t2 <= t; t2++) {
            for (let year = 0; year < Constants.numYrs; year++) {
                let offset_index = t2 + year;
                if (offset_index <= 73) {
                    cumm_sum_of_discrete_time_disc_factor[t][year] += discrete_time_disc_factor[offset_index];
                }
            }
        }
    }

    let num_yrs_saved = Math.round(LE - Avg_Age_Mat_Death);

    let index = num_yrs_saved - 1;

    let YLL_Per_Death_W_Discount = createArray(Constants.numYrs);
    let YLD_Per_YLL_Result = createArray(Constants.numYrs);
    let Total_DALY_Per_Death_Avert = createArray(Constants.numYrs);

    for (let t = 0; t < Constants.numYrs; t++) {
        YLL_Per_Death_W_Discount[t] = cumm_sum_of_discrete_time_disc_factor[index][t];
        YLD_Per_YLL_Result[t] = YLL_Per_Death_W_Discount[t] * YLD_Per_YLL;
        Total_DALY_Per_Death_Avert[t] = YLL_Per_Death_W_Discount[t] + YLD_Per_YLL_Result[t];
    }

    let DALY_Averted_Discounted = createArray(Constants.numYrs);
    let DALY_Averted_Discounted_Cumm = 0;

    for (let t = 0; t < Constants.numYrs; t++) {
        DALY_Averted_Discounted[t] = Total_DALY_Per_Death_Avert[t] * Maternal_Deaths_Averted[t];
        DALY_Averted_Discounted_Cumm += DALY_Averted_Discounted[t];
    }

    let Total_Cost = createArray(const_Range_Max + 1);

    let Cost_per_additional_user_year   = createArray(const_Range_Max + 1);
    let Cost_per_pregnancy_averted      = createArray(const_Range_Max + 1);
    let Cost_per_maternal_death_averted = createArray(const_Range_Max + 1);
    let Cost_per_DALY_averted           = createArray(const_Range_Max + 1);

    for (let i = const_Range_Min; i <= const_Range_Max; i++) {
        Total_Cost[i] = TotalSBC_Costs.Cummulative_IV_Cost_With_Discount_Total[i] + SD_Cost_Discounted_Cumm;
        Cost_per_additional_user_year[i] = getQuotient(Total_Cost[i], Inc_User_Assoc_With_Inc_in_mCPR_Cumm);
        Cost_per_pregnancy_averted[i] = getQuotient(Total_Cost[i], Preg_Averted_Cumm);
        Cost_per_maternal_death_averted[i] = getQuotient(Total_Cost[i], Maternal_Deaths_Averted_Cumm);
        Cost_per_DALY_averted[i] = getQuotient(Total_Cost[i], DALY_Averted_Discounted_Cumm);
    }

    resultsData["costEffData"]["Cummulative_IV_Cost_With_Discount_Total"] = TotalSBC_Costs.Cummulative_IV_Cost_With_Discount_Total[const_m];
    resultsData["costEffData"]["additional FP users"] = Inc_User_Assoc_With_Inc_in_mCPR[Constants.numYrs - 1];
    resultsData["costEffData"]["SD_Cost_Discounted_Cumm"] = SD_Cost_Discounted_Cumm;
    resultsData["costEffData"]["Preg_Averted_Cumm"] = Preg_Averted_Cumm;
    resultsData["costEffData"]["DALY_Averted_Discounted_Cumm"] = DALY_Averted_Discounted_Cumm;
    resultsData["costEffData"]["total costs for investment time period"] = Total_Cost[const_m];
    resultsData["costEffData"]["cost per additional FP user year"] = Cost_per_additional_user_year[const_m];
    resultsData["costEffData"]["cost per unintended pregnancy averted"] = Cost_per_pregnancy_averted[const_m];
    resultsData["costEffData"]["cost per maternal death averted"] = Cost_per_maternal_death_averted[const_m];
    resultsData["costEffData"]["cost per maternal DALY averted"] = Cost_per_DALY_averted;

    //----------------------------------------------------------------------------------------------------------------//
    //                                          Set results into app                                                  //
    //----------------------------------------------------------------------------------------------------------------//

    props.onStateChange({
        resultsData : resultsData,
    }, () => {});
};